import {CompleteStatus, IControlCardTemplate} from "app/gql/graphqlSchema";
import {z} from "zod";
import {zControlCardTemplateId, zLocalDate, zNormalizedStringToNull, zToNull} from "app/utils/validator";
import {zCompleteStatus} from "app/employee/controlCard/location/useLocationControlCardGlobalFilter";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {RhfFstIonDatetimeButton} from "app/employee/rhf/RhfFstIonDatetimeButton";
import {assertNotNil, isNil} from "app/utils/stdlib";
import {filterOutline, trashBinOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {RhfIonInput} from "app/employee/rhf/RhfIonInput";
import {RhfFstIonSelect} from "app/employee/rhf/RhfFstIonSelect";
import {useMemo} from "react";
import {
  getDefaultRecurringRange,
  useLocationDeckFilterContext
} from "app/employee/controlCard/location/useLocationDeckFilter";
import {RelativeDateDisplay} from "app/employee/RelativeDateDisplay";

type IAvailableTemplate = Pick<IControlCardTemplate, "id" | "name">;

interface IProps {
  templates: IAvailableTemplate[];
  onApply: () => void;
}

const formSchema = z.object({
  startDate: zLocalDate.nullable(),
  endDate: zLocalDate.nullable(),
  templateIds: z.array(zControlCardTemplateId),
  query: zToNull.or(zNormalizedStringToNull),
  completeStatus: zCompleteStatus
});

export type IFormData = z.infer<typeof formSchema>;

export const RecurringFilter = ({onApply, templates}: IProps) => {
  const {t} = useTranslation();

  const statuses = useMemo(
    () => [
      {name: t("control-card.location.recurring.filter.statuses.all"), status: CompleteStatus.All},
      {name: t("control-card.location.recurring.filter.statuses.incomplete"), status: CompleteStatus.Incomplete},
      {name: t("control-card.location.recurring.filter.statuses.complete"), status: CompleteStatus.Complete}
    ],
    [t]
  );

  const {filter, setFilter} = useLocationDeckFilterContext();

  const values = useMemo(
    () =>
      ({
        startDate: filter?.recurringDateRange.start ?? null,
        endDate: filter?.recurringDateRange.end ?? null,
        completeStatus: filter?.completeStatus ?? CompleteStatus.All,
        templateIds: filter?.recurringTemplateIds ?? [],
        query: filter?.query ?? null
      }) satisfies IFormData,
    [
      filter?.completeStatus,
      filter?.query,
      filter?.recurringDateRange.end,
      filter?.recurringDateRange.start,
      filter?.recurringTemplateIds
    ]
  );

  const {
    control,
    formState: {isValid, isSubmitting},
    handleSubmit,
    watch
  } = useForm<IFormData>({
    values,
    resolver: zodResolver(formSchema)
  });

  const applyFilter = (data: IFormData) => {
    const {startDate, endDate, completeStatus, templateIds, query} = data;
    setFilter((draft) => {
      assertNotNil(draft);
      draft.query = query;
      draft.completeStatus = completeStatus;
      draft.recurringDateRange.start = startDate;
      draft.recurringDateRange.end = endDate;
      draft.recurringTemplateIds = templateIds;
    });
    onApply();
  };

  const clearFilter = () => {
    setFilter((draft) => {
      assertNotNil(draft);
      draft.query = null;
      draft.completeStatus = CompleteStatus.All;
      draft.recurringDateRange = getDefaultRecurringRange();
      draft.recurringTemplateIds = [];
    });
    onApply();
  };

  const startDate = watch("startDate");
  const endDate = watch("endDate");

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{t("control-card.location.recurring.filter.title")}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          <IonListHeader>
            <IonLabel>{t("control-card.location.recurring.filter.subtitle")}</IonLabel>
          </IonListHeader>
          <IonItem lines="full">
            <IonLabel>
              {t("control-card.location.recurring.filter.startDate")}
              {!isNil(startDate) && (
                <p>
                  <RelativeDateDisplay dt={startDate} />
                </p>
              )}
            </IonLabel>
            <RhfFstIonDatetimeButton multiple={false} presentation="date" rhf={{control, name: "startDate"}} slot="end">
              <IonLabel slot="title">{t("control-card.location.recurring.filter.startDate")}</IonLabel>
            </RhfFstIonDatetimeButton>
          </IonItem>
          <IonItem lines="full">
            <IonLabel>
              {t("control-card.location.recurring.filter.endDate")}
              {!isNil(endDate) && (
                <p>
                  <RelativeDateDisplay dt={endDate} />
                </p>
              )}
            </IonLabel>
            <RhfFstIonDatetimeButton multiple={false} presentation="date" rhf={{control, name: "endDate"}} slot="end">
              <IonLabel slot="title">{t("control-card.location.recurring.filter.endDate")}</IonLabel>
            </RhfFstIonDatetimeButton>
          </IonItem>
          <IonItem lines="full">
            <RhfFstIonSelect
              label={t("control-card.location.recurring.filter.template")}
              multiple
              placeholder={t("control-card.location.recurring.filter.templatePlaceholder")}
              rhf={{control, name: "templateIds"}}
            >
              {templates.map((template) => (
                <IonSelectOption key={template.id.templateId} value={template.id}>
                  {template.name}
                </IonSelectOption>
              ))}
            </RhfFstIonSelect>
          </IonItem>
          <IonItem lines="full">
            <RhfFstIonSelect
              label={t("control-card.location.recurring.filter.status")}
              rhf={{control, name: "completeStatus"}}
            >
              {statuses.map((status) => (
                <IonSelectOption key={status.status} value={status.status}>
                  {status.name}
                </IonSelectOption>
              ))}
            </RhfFstIonSelect>
          </IonItem>
          <IonItem lines="full">
            <RhfIonInput
              clearInput
              label={t("control-card.location.recurring.filter.text")}
              placeholder={t("control-card.location.recurring.filter.textPlaceholder")}
              rhf={{control, name: "query"}}
            />
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton color="primary" fill="outline" onClick={clearFilter}>
              <IonIcon icon={trashBinOutline} slot="start" />
              {t("g.clear", {defaultValue: "Изчисти"})}
            </IonButton>
            <IonButton
              color="primary"
              disabled={isSubmitting || !isValid}
              fill="solid"
              onClick={handleSubmit(applyFilter)}
            >
              <IonIcon icon={filterOutline} slot="start" />
              {t("g.filter")}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
