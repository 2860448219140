import {UnreachableCaseError} from "ts-essentials";
import {DailyLocationControlCards} from "app/employee/controlCard/location/DailyLocationControlCards";
import {RecurringLocationControlCards} from "app/employee/controlCard/location/RecurringLocationControlCards";
import {ControlCardCategory, ILocationControlCardDeckFragment} from "app/gql/graphqlSchema";

interface IProps {
  deck: ILocationControlCardDeckFragment;
}

export const DisplayLocationControlCards = ({deck}: IProps) => {
  const {category} = deck;

  switch (category) {
    case ControlCardCategory.Daily: {
      const {byDateDeck} = deck;
      const dailyDeck = byDateDeck.length > 0 ? byDateDeck[0].cards : [];
      return <DailyLocationControlCards dailyDeck={dailyDeck} />;
    }
    case ControlCardCategory.Recurring:
      return <RecurringLocationControlCards deck={deck} />;
    case undefined:
      return null;
    default:
      throw new UnreachableCaseError(category);
  }
};
