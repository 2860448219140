import {IVideoLessonChapter} from "app/gql/graphqlSchema";
import {IPlayerControl} from "app/employee/video/VideoPlayer";
import {RefObject, useEffect, useMemo, useRef, useState} from "react";
import {durationCompareFn, isNotNil} from "app/utils/stdlib";
import {noop} from "app/utils/types";
import {Duration} from "luxon";
import {DURATION_ZERO, parseDuration} from "app/utils/dates";

type ICheckPauseAtCallback = (currentTime: Duration) => Duration | undefined;

interface IReturn {
  checkPauseAt: RefObject<ICheckPauseAtCallback>;
}

const getWatchedLength = (startControl: IPlayerControl): number | null => {
  if (startControl.type === "current") {
    return (startControl.watchedLength ?? DURATION_ZERO).toMillis();
  } else {
    return null;
  }
};

const debugWatchedLengthMs = (watchedLengthMs: number | null) => {
  const watchedLength = watchedLengthMs !== null ? Duration.fromMillis(watchedLengthMs) : null;
  console.log("🐫 useChapters: rendered watchedLength", watchedLength?.toFormat("hh:mm:ss.SSS"));
};

export const useChapters = (chapters: IVideoLessonChapter[], startControl: IPlayerControl): IReturn => {
  const checkPauseAt = useRef<ICheckPauseAtCallback>(noop);
  const [nextPauseAt, setNextPauseAt] = useState<number | undefined>(undefined);

  const chaptersSorted = useMemo(() => {
    const chaptersAsSeconds = chapters.map((c) => parseDuration(c.endDuration));
    chaptersAsSeconds.sort(durationCompareFn);
    return chaptersAsSeconds;
  }, [chapters]);

  const watchedLengthMs = getWatchedLength(startControl);
  debugWatchedLengthMs(watchedLengthMs);
  useEffect(() => {
    if (isNotNil(watchedLengthMs)) {
      const watchedLength = Duration.fromMillis(watchedLengthMs);
      console.log("🐫 useChapters: Updating segments for watchedLength", watchedLength.toFormat("hh:mm:ss.SSS"));
      const nextPauseAt = chaptersSorted.findIndex((chapter) => chapter >= watchedLength);
      if (nextPauseAt >= 0) {
        setNextPauseAt(nextPauseAt);
        console.log(
          "🐫 useChapters: Next pause at %s [%s]",
          nextPauseAt,
          chaptersSorted[nextPauseAt].toFormat("hh:mm:ss.SSS")
        );
      }
    }
  }, [chaptersSorted, watchedLengthMs]);

  checkPauseAt.current = (currentWatchTime) => {
    if (isNotNil(nextPauseAt)) {
      if (currentWatchTime >= chaptersSorted[nextPauseAt]) {
        if (nextPauseAt < chaptersSorted.length - 1) {
          setNextPauseAt(nextPauseAt + 1);
        } else {
          setNextPauseAt(undefined);
        }
        return chaptersSorted[nextPauseAt];
      }
    }
  };

  return {
    checkPauseAt
  };
};
