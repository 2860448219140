import {
  EmployeeCompleteCurrentTestDocument,
  EmployeeSaveAnswerDocument,
  EmployeeSyllabusDocument,
  EmployeeUpdateCurrentVideoProgressDocument,
  EmployeeVerifyCurrentTestDocument,
  EmployeeWatchQuestionDocument,
  IEmployeeAnswerFragment,
  IEmployeeSyllabusQuery,
  IEmployeeUpdateCurrentVideoProgressMutationVariables,
  IMutationEmployeeSaveAnswerArgs,
  IMutationEmployeeWatchQuestionArgs
} from "app/gql/graphqlSchema";
import {
  ApiState,
  ILoadable,
  loadedOk,
  LOADING_STATE_ERROR,
  LOADING_STATE_NA,
  selectMaybeLoaded
} from "app/state/common/loadable";
import {MaybeNil} from "app/utils/types";
import {query} from "app/gql/client";
import {isNotNil} from "app/utils/stdlib";
import {createWithEqualityFn as zustandCreate} from "zustand/traditional";
import {immer} from "zustand/middleware/immer";
import {ElementOf} from "ts-essentials";
import {getInProgressVideoLesson} from "app/employee/state/syllabus/inProgressVideoLessons";
import {RefresherEventDetail} from "@ionic/react";
import {DateTime} from "luxon";

export type ISyllabus = IEmployeeSyllabusQuery["employeeSyllabus"];
// export type ISyllabusAssignment = ElementOf<ISyllabus["past"]>;
// export type ISyllabusVideoLesson = ElementOf<ISyllabus["pending"]>;
// export type ISyllabusCurrentAssignment = NonNullable<ISyllabus["current"]>;
// export type ISyllabusAnswer = ElementOf<ISyllabusQuestion["answers"]>;
type ISyllabusTermProgress = ISyllabus["termProgress"];
export type ISyllabusTerm = ElementOf<ISyllabusTermProgress["terms"]>;
export type ISyllabusTermPlan = ElementOf<ISyllabusTerm["termPlans"]>;
export type ISyllabusTermVideoLesson = ElementOf<ISyllabusTermPlan["lessons"]>;
export type ISyllabusCompletedVideoLesson = Extract<
  ISyllabusTermVideoLesson,
  {__typename: "TermPlanCompletedVideoLesson"}
>;
export type ISyllabusInProgressVideoLesson = Extract<
  ISyllabusTermVideoLesson,
  {__typename: "TermPlanInProgressVideoLesson"}
>;
export type ISyllabusTermPlanPendingVideoLesson = Extract<
  ISyllabusTermVideoLesson,
  {__typename: "TermPlanPendingVideoLesson"}
>;
export type ISyllabusPlanVideoLessonKind = ISyllabusTermVideoLesson["__typename"];

export type ISyllabusQuestion = ElementOf<ISyllabusInProgressVideoLesson["videoLesson"]["test"]["questions"]>;
export type ITestResult = ISyllabusInProgressVideoLesson["testResult"];

export enum RedirectType {
  NONE = "NONE",
  TEST = "TEST",
  TEST_RESULT = "TEST_RESULT",
  NEXT_TOPIC = "NEXT_TOPIC"
}

export interface ISyllabusState {
  syllabus: ILoadable<ISyllabus>;
  saveAnswerState: ApiState;
  watchQuestionState: ApiState;
  redirect: RedirectType;
  lastRefreshMs: MaybeNil<number>;
}

const initialState: ISyllabusState = {
  syllabus: LOADING_STATE_NA,
  saveAnswerState: ApiState.NA,
  watchQuestionState: ApiState.NA,
  redirect: RedirectType.NONE,
  lastRefreshMs: null
};

export const useSyllabusStore = zustandCreate(
  immer(() => initialState),
  Object.is
);

type IUpdateProgressArg = IEmployeeUpdateCurrentVideoProgressMutationVariables["input"];
export const updateCurrentVideoProgress = async (arg: IUpdateProgressArg) => {
  await query(EmployeeUpdateCurrentVideoProgressDocument, {input: arg});
};

export const getEmployeeSyllabus = async () => {
  try {
    useSyllabusStore.setState((state) => {
      state.syllabus.loadingState = ApiState.LOADING;
      state.lastRefreshMs = DateTime.now().toMillis();
    });
    // await delay(2000);
    const clientResult = await query(EmployeeSyllabusDocument);
    useSyllabusStore.setState((state) => {
      state.syllabus = loadedOk(clientResult.employeeSyllabus);
    });
  } catch (e) {
    useSyllabusStore.setState((state) => {
      state.syllabus = LOADING_STATE_ERROR;
    });
    throw e;
  }
};

export type ISaveAnswer = IMutationEmployeeSaveAnswerArgs["input"];
export const saveAnswer = async (input: ISaveAnswer) => {
  updateAnswer(input);
  try {
    useSyllabusStore.setState((state) => {
      state.saveAnswerState = ApiState.LOADING;
    });
    await query(EmployeeSaveAnswerDocument, {input});
    useSyllabusStore.setState((state) => {
      state.saveAnswerState = ApiState.OK;
    });
  } catch (e) {
    useSyllabusStore.setState((state) => {
      state.saveAnswerState = ApiState.ERROR;
    });
    throw e;
  }
};

export const updateAnswer = (answer: ISaveAnswer) => {
  useSyllabusStore.setState((state) => {
    if (state.syllabus.loadingState === ApiState.OK) {
      const {answerId, questionId} = answer;
      const videoLesson = getInProgressVideoLesson(selectMaybeLoaded(state.syllabus)?.termProgress?.terms ?? []);
      videoLesson?.lesson.videoLesson.test.questions.forEach((q) => {
        if (q.id === questionId) {
          q.employeeAnswer = {
            answerId,
            employeeAnswerId: "",
            watchCount: 0
          };
        }
      });
    }
  });
};

export type IWatchQuestion = IMutationEmployeeWatchQuestionArgs["input"];
export const watchQuestion = async (input: IWatchQuestion) => {
  try {
    useSyllabusStore.setState((state) => {
      state.watchQuestionState = ApiState.LOADING;
    });
    const clientResult = await query(EmployeeWatchQuestionDocument, {input});
    useSyllabusStore.setState((state) => {
      updateEmployeeAnswer(state.syllabus, clientResult.employeeWatchQuestion.employeeAnswer);
      state.watchQuestionState = ApiState.OK;
    });
  } catch (e) {
    useSyllabusStore.setState((state) => {
      state.watchQuestionState = ApiState.ERROR;
    });
    throw e;
  }
};

export const completeCurrentTest = async () => {
  try {
    useSyllabusStore.setState((state) => {
      state.syllabus.loadingState = ApiState.LOADING;
      state.lastRefreshMs = DateTime.now().toMillis();
    });
    const clientResult = await query(EmployeeCompleteCurrentTestDocument);
    useSyllabusStore.setState((state) => {
      state.syllabus = loadedOk(clientResult.employeeCompleteCurrentTest.syllabus);
    });
  } catch (e) {
    useSyllabusStore.setState((state) => {
      state.syllabus.loadingState = ApiState.ERROR;
    });
    throw e;
  }
};

export const verifyCurrentTest = async () => {
  try {
    useSyllabusStore.setState((state) => {
      state.syllabus.loadingState = ApiState.LOADING;
      state.lastRefreshMs = DateTime.now().toMillis();
    });
    const clientResult = await query(EmployeeVerifyCurrentTestDocument);
    useSyllabusStore.setState((state) => {
      state.syllabus = loadedOk(clientResult.employeeVerifyCurrentTest.syllabus);
    });
  } catch (e) {
    useSyllabusStore.setState((state) => {
      state.syllabus.loadingState = ApiState.ERROR;
    });
    throw e;
  }
};

const updateEmployeeAnswer = (syllabus: ILoadable<ISyllabus>, newAnswer: MaybeNil<IEmployeeAnswerFragment>) => {
  if (syllabus.loadingState === ApiState.OK && isNotNil(newAnswer)) {
    const newEmployeeAnswerId = newAnswer.employeeAnswerId;
    const videoLesson = getInProgressVideoLesson(syllabus.value.termProgress.terms)?.lesson.videoLesson;

    videoLesson?.test.questions.forEach((q) => {
      if (q.employeeAnswer?.employeeAnswerId === newEmployeeAnswerId) {
        q.employeeAnswer = newAnswer;
      }
    });
  }
};

export const redirectTo = (redirectType: RedirectType) => {
  useSyllabusStore.setState((state) => {
    state.redirect = redirectType;
  });
};

export const popRedirect = () => {
  const ret = useSyllabusStore.getState().redirect;
  useSyllabusStore.setState((state) => {
    state.redirect = RedirectType.NONE;
  });
  return ret;
};

export const refreshSyllabus = async (event?: CustomEvent<RefresherEventDetail>) => {
  const style = "color: blue; font-weight: bold;";
  console.log("%cReloading syllabus", style);
  try {
    await getEmployeeSyllabus();
    console.log("%cReloaded syllabus ✅", style);
  } catch (e) {
    console.warn("%cReloaded syllabus ❌", e);
    throw e;
  } finally {
    event?.detail.complete();
  }
};

export const reset = () => useSyllabusStore.setState(initialState, true);
