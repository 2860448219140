import {IControlCardNumberRangeFragment} from "app/gql/graphqlSchema";
import {MaybeNil} from "app/utils/types";
import {useTranslation} from "react-i18next";
import {isNil} from "app/utils/stdlib";
import {UnreachableCaseError} from "ts-essentials";
import {toBigNumber} from "app/utils/validator";

export const DisplayNumberRange = ({range, unit}: {range: IControlCardNumberRangeFragment; unit: MaybeNil<string>}) => {
  const {t} = useTranslation();
  if (!isNil(unit)) {
    switch (range.__typename) {
      case "ControlCardClosedNumberRange":
        return (
          <>
            {t("control-card.number-range.unit.closed", {
              start: toBigNumber(range.start).toFixed(),
              end: toBigNumber(range.end).toFixed(),
              unit
            })}
          </>
        );
      case "ControlCardOpenStartNumberRange":
        return <>{t("control-card.number-range.unit.open-start", {end: toBigNumber(range.end).toFixed(), unit})}</>;
      case "ControlCardOpenEndNumberRange":
        return <>{t("control-card.number-range.unit.open-end", {start: toBigNumber(range.start).toFixed(), unit})}</>;
      default:
        throw new UnreachableCaseError(range);
    }
  } else {
    switch (range.__typename) {
      case "ControlCardClosedNumberRange":
        return (
          <>
            {t("control-card.number-range.no-unit.closed", {
              start: toBigNumber(range.start).toFixed(),
              end: toBigNumber(range.end).toFixed()
            })}
          </>
        );
      case "ControlCardOpenStartNumberRange":
        return <>{t("control-card.number-range.no-unit.open-start", {end: toBigNumber(range.end).toFixed()})}</>;
      case "ControlCardOpenEndNumberRange":
        return <>{t("control-card.number-range.no-unit.open-end", {start: toBigNumber(range.start).toFixed()})}</>;
      default:
        throw new UnreachableCaseError(range);
    }
  }
};
