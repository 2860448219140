import {IonBadge} from "@ionic/react";

import {useNotificationCount} from "app/employee/notification/useNotificationCount";

export const NotificationCountBadge = () => {
  const {unreadCount} = useNotificationCount();
  if (unreadCount <= 0) {
    return null;
  }

  return <IonBadge color="primary">{unreadCount}</IonBadge>;
};
