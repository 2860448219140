import {IColumnFragment, IValueFragment} from "app/gql/graphqlSchema";
import {ComboBox} from "app/employee/controlCard/ComboBox";
import {useTranslation} from "react-i18next";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardComboBoxColumn"};
  setValues: (value: IValueFragment[]) => void | Promise<void>;
}

export const PrimaryRowComboBox = ({column, setValues: setControlCardValues}: IProps) => {
  const {t} = useTranslation();
  const handleSelect = async (values: string[]) => {
    await setControlCardValues(values.map((value) => ({__typename: "ControlCardTextValue", text: value})));
  };

  const title = t("control-card.add-new-record-title", {title: column.title});

  return <ComboBox listKey={column.listKey} multiselect onSelect={handleSelect} title={title} values={[]} />;
};
