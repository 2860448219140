import {FieldPath, FieldValues, useController} from "react-hook-form";
import {hofForwardRef, IIonSearchbar, noop, RhfProps, RhfRef} from "app/utils/types";
import {IonSearchbar} from "@ionic/react";
import {ForwardedRef, RefCallback, useImperativeHandle, useRef} from "react";
import {assignRef} from "app/utils/stdlib";

const extraClassNames = "ion-invalid ion-touched";

const reportValidity = () => true;

export const RhfIonSearchbar = hofForwardRef(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    {rhf, className, disabled, ...rest}: Omit<IIonSearchbar, "onIonInput"> & RhfProps<TFieldValues, TName>,
    ref: ForwardedRef<HTMLIonSearchbarElement>
  ) => {
    const {
      field: {onChange, ref: fieldRef, disabled: rhfDisabled, ...restField},
      fieldState: {error},
      formState: {isSubmitting}
    } = useController(rhf);

    const classNames = [];
    if (className) {
      classNames.push(className);
    }

    if (error) {
      classNames.push(extraClassNames);
    }

    const rhfRef = useRef<RhfRef | null>(null);
    const inputRef: RefCallback<HTMLIonSearchbarElement> = (element) => {
      assignRef(ref, element);
      rhfRef.current = {
        focus: () => element?.setFocus(),
        select: () => element?.getInputElement?.()?.then((e) => e?.select()),
        setCustomValidity: noop,
        reportValidity
      };
    };
    useImperativeHandle(fieldRef, () => rhfRef.current);

    return (
      <IonSearchbar
        ref={inputRef}
        {...rest}
        {...restField}
        className={classNames.join(" ")}
        disabled={isSubmitting || disabled || rhfDisabled}
        onIonInput={(event) => onChange(event.detail.value)}
      />
    );
  }
);
