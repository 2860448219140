import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {useBackNavigation} from "app/employee/hooks/useBackNavigation";
import {linkEmplControlCardInstance} from "app/employee/core/employeeLinks";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {isEqual, isNil, isNotBlank} from "app/utils/stdlib";
import {IRefOption, refMapping} from "app/employee/controlCard/refUtils";
import {ReactNode} from "react";
import {useFuzzySearch} from "app/employee/controlCard/hook/useFuzzySearch";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {RhfIonSearchbar} from "app/employee/rhf/RhfIonSearchbar";
import {CheckedLabel} from "app/employee/incdoc/supplier/TypeaheadSelect";
import {checkmarkCircle, checkmarkCircleOutline, closeOutline, trashBinOutline} from "ionicons/icons";
import {z} from "zod";
import {zValueRef} from "app/utils/validator";
import {IValueFragment} from "app/gql/graphqlSchema";
import {ExtractType, IIonItem} from "app/utils/types";
import {ISelectedValue} from "app/employee/controlCard/primaryKey/types";

const formSchema = z.object({
  searchValue: z.string().optional(),
  selectedValues: zValueRef.array().min(1, "g.required")
});

type IFormData = z.infer<typeof formSchema>;

type IRefValue = ExtractType<IValueFragment, "ControlCardRefValue">;

interface IProps {
  refOption: IRefOption;
  title: ReactNode;
  setValues: (values: IRefValue[]) => void | Promise<void>;
  preselectedValues: ISelectedValue[];
}

export const PrimaryRowRefValue = ({refOption, title, setValues: setRefValues, preselectedValues}: IProps) => {
  const {instance, canEdit} = useControlCardInstanceContext();
  const disabled = !canEdit;
  const backNavigation = useBackNavigation(linkEmplControlCardInstance(instance.id.instanceId));
  const {t} = useTranslation();
  const {
    setValue,
    control,
    watch,
    handleSubmit,
    formState: {isSubmitting, isValid}
  } = useForm<IFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      selectedValues: [],
      searchValue: ""
    }
  });

  const {textExtractor, filter} = refMapping[refOption.__typename];
  const items = refOption.options;

  const term = watch("searchValue");
  const {filteredItems} = useFuzzySearch(term, items, textExtractor, filter);

  const saveSelectedValues = handleSubmit(async (data) => {
    const value = data.selectedValues;
    await setRefValues(value);
  });

  const selectedValues = watch("selectedValues");
  const toggleSelectedValue = (value: IRefValue) => {
    const index = selectedValues.findIndex((v) => isEqual(v, value));
    if (index === -1) {
      setValue("selectedValues", [...selectedValues, value], {shouldValidate: true});
    } else {
      setValue("selectedValues", [...selectedValues.slice(0, index), ...selectedValues.slice(index + 1)], {
        shouldValidate: true
      });
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{t("control-card.add-new-record-title", {title})}</IonTitle>
          <MenuButton />
        </IonToolbar>
        <IonToolbar>
          <RhfIonSearchbar
            debounce={200}
            placeholder={t("g.search", {defaultValue: "Търсене"})}
            rhf={{control, name: "searchValue"}}
          />
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          <IonListHeader>
            {isNotBlank(term)
              ? t("g.search-results-term", {
                  term,
                  count: filteredItems.length
                })
              : t("g.search-results-no-term", {
                  term,
                  count: filteredItems.length
                })}
          </IonListHeader>
          {filteredItems.map((filteredItem) => {
            const {item, highlighted} = filteredItem;
            const isSelected = selectedValues.some((v) => isEqual(v, item.ref));
            const preselectedValue = preselectedValues.find((v) => isEqual(v.value, item.ref));
            let checkmark: ReactNode | null;
            let itemProps: IIonItem;
            if (!isNil(preselectedValue)) {
              itemProps = {};
              checkmark = <IonIcon color="success" icon={checkmarkCircle} slot="end" />;
            } else {
              itemProps = {
                button: true,
                onClick: () => toggleSelectedValue(item.ref),
                color: isSelected ? "primary" : undefined
              };
              checkmark = null;
            }

            return (
              <IonItem key={item.ref.id} {...itemProps}>
                <CheckedLabel $checked={isSelected} className="ion-text-wrap">
                  {highlighted}
                </CheckedLabel>
                {checkmark}
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonToolbar>
            <IonButtons slot="primary">
              <IonButton color="primary" fill="outline" onClick={backNavigation}>
                <IonIcon icon={closeOutline} slot="start" />
                {t("g.cancel")}
              </IonButton>
              <IonButton
                color="primary"
                disabled={selectedValues.length === 0 || isSubmitting || disabled}
                fill="outline"
                onClick={() => setValue("selectedValues", [], {shouldValidate: true})}
              >
                <IonIcon icon={trashBinOutline} slot="start" />
                {t("g.clear")}
              </IonButton>
              <IonButton
                color="primary"
                disabled={isSubmitting || !isValid || disabled}
                fill="solid"
                onClick={saveSelectedValues}
              >
                <IonIcon icon={checkmarkCircleOutline} slot="start" />
                {t("g.add-count", {count: selectedValues.length})}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
