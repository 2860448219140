import {assertCondition} from "app/utils/stdlib";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {useCallback} from "react";
import {
  ControlCardInstanceAddPrimaryKeyRowsDocument,
  IControlCardInstanceId,
  IValueFragment
} from "app/gql/graphqlSchema";
import {query} from "app/gql/client";
import {linkEmplControlCardInstance} from "app/employee/core/employeeLinks";
import {useIonRouter} from "@ionic/react";
import {PrimaryRowTextValue} from "./PrimaryRowTextValue";
import {PrimaryRowComboBox} from "app/employee/controlCard/primaryKey/PrimaryRowComboBox";
import {useLoadingContext} from "app/employee/LoadingContext";
import {PrimaryRowEmployeeRefValue} from "app/employee/controlCard/primaryKey/PrimaryRowEmployeeRefValue";

const addRows = async (instanceId: IControlCardInstanceId, value: IValueFragment[]) => {
  assertCondition(value.length > 0);
  const result = await query(ControlCardInstanceAddPrimaryKeyRowsDocument, {
    input: {instanceId, primaryKeyValues: JSON.stringify(value)}
  });
  const newRows = result.controlCardInstanceAddPrimaryKeyRows.rows;
  assertCondition(newRows.length > 0);
  return newRows.map((r) => r.rowId);
};

export const AddPrimaryKeyRow = () => {
  const {instance, primaryColumn} = useControlCardInstanceContext();
  const {startLoading, finishLoading} = useLoadingContext();
  const {push} = useIonRouter();

  const setValues = useCallback(
    async (values: IValueFragment[]) => {
      startLoading();
      try {
        const newRowIds = await addRows(instance.id, values);
        if (newRowIds.length >= 0) {
          push(linkEmplControlCardInstance(instance.id.instanceId, newRowIds[0]), "forward");
        } else {
          push(linkEmplControlCardInstance(instance.id.instanceId), "back");
        }
      } finally {
        finishLoading();
      }
    },
    [finishLoading, instance.id, push, startLoading]
  );

  if (primaryColumn?.__typename === "ControlCardEmployeeRefColumn") {
    return <PrimaryRowEmployeeRefValue column={primaryColumn} setValues={setValues} />;
  } else if (primaryColumn?.__typename === "ControlCardTextColumn") {
    return <PrimaryRowTextValue column={primaryColumn} setValues={setValues} />;
  } else if (primaryColumn?.__typename === "ControlCardComboBoxColumn") {
    return <PrimaryRowComboBox column={primaryColumn} setValues={setValues} />;
  } else {
    return null;
  }
};
