import {
  IonButton,
  IonContent,
  IonFab,
  IonHeader,
  IonIcon,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
  useIonViewWillEnter
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {useSearchStore} from "app/state/hooks/useSearchStore";
import {init, search, useSupplierStore} from "app/employee/state/supplier/mutations";
import {LoadingLoadable} from "app/employee/Loading";
import {FlexColumn} from "app/employee/flexUtils";
import {IPageFunc, Pagination} from "app/employee/incdoc/Pagination";
import {isEmpty} from "app/utils/stdlib";
import {useTranslation} from "react-i18next";
import {SupplierCard} from "app/employee/incdoc/supplier/SupplierCard";
import {linkEmplIncDocAddSupplier} from "app/employee/core/employeeLinks";
import {cartOutline} from "ionicons/icons";
import {ListPadding} from "app/employee/incdoc/ListPadding";
import {TitleRow} from "app/employee/incdoc/TitleRow";
import {FilterContainer} from "app/employee/incdoc/FilterContainer";
import {SupplierFilter} from "app/employee/incdoc/supplier/SupplierFilter";
import {useCallback} from "react";

export const refreshSuppliers = async (event?: CustomEvent<RefresherEventDetail>) => {
  console.log("Reloading Suppliers...");
  try {
    await search();
    console.log("Reloaded Suppliers ✅");
  } catch (e) {
    console.warn("Reloaded Suppliers ❌", e);
    throw e;
  } finally {
    event?.detail.complete();
  }
};

export const SupplierList = () => {
  const {t} = useTranslation();
  useIonViewWillEnter(() => {
    init();
  }, []);

  const {
    selectList: suppliers,
    selectListLoadable: suppliersLoadable,
    selectPage: page,
    // selectSort: sort,
    // toggleSort,
    setPageNumber
    // setPageSize
  } = useSearchStore(useSupplierStore);

  const pageFunc: IPageFunc = useCallback((itemCount) => t("incdoc.supplier.pagination-count", {itemCount}), [t]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{t("incdoc.supplier.list-title", {defaultValue: "Доставчици"})}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal">
        <LoadingLoadable loadable={suppliersLoadable} />
        <IonRefresher onIonRefresh={refreshSuppliers} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>
        <FlexColumn>
          <TitleRow>
            <h1>{t("incdoc.supplier.list-title", {defaultValue: "Доставчици"})}</h1>
            <FilterContainer>
              <SupplierFilter refreshSuppliers={refreshSuppliers} />
            </FilterContainer>
          </TitleRow>
          <Pagination
            page={page}
            pageFunc={pageFunc}
            setPage={async (page) => {
              setPageNumber(page);
              await refreshSuppliers();
            }}
          />
          {suppliers?.map((s) => <SupplierCard key={s.id.supplierId} supplier={s} />)}
          {isEmpty(suppliers) && (
            <h3>{t("incdoc.supplier.no-suppliers", {defaultValue: "Няма намерени доставчици"})}</h3>
          )}
          <Pagination
            page={page}
            pageFunc={pageFunc}
            setPage={async (page) => {
              setPageNumber(page);
              await refreshSuppliers();
            }}
          />
        </FlexColumn>
        <ListPadding />
        <IonFab horizontal="end" slot="fixed" vertical="bottom">
          <FlexColumn>
            <IonButton routerDirection="forward" routerLink={linkEmplIncDocAddSupplier()} shape="round">
              <IonIcon icon={cartOutline} slot="start" />
              <IonLabel>{t("incdoc.add-supplier", {defaultValue: "Добави доставчик"})}</IonLabel>
            </IonButton>
          </FlexColumn>
        </IonFab>
      </IonContent>
    </>
  );
};
