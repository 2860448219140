import {IonProgressBar} from "@ionic/react";

export const SavingProgress = ({isSubmitting}: {isSubmitting: boolean}) => {
  return (
    <IonProgressBar
      color={isSubmitting ? "primary" : "light"}
      type={isSubmitting ? "indeterminate" : "determinate"}
      value={isSubmitting ? undefined : 1}
    />
  );
};
