import {IValueFragment} from "app/gql/graphqlSchema";
import {EmptyValue} from "app/employee/controlCard/display/EmptyValue";
import {InvalidValue} from "app/employee/controlCard/display/InvalidValue";
import {RichTextView} from "app/components/admin/rte/RichTextView";

interface IProps {
  value: IValueFragment;
}
export const DisplayText = ({value}: IProps) => {
  if (value.__typename === "ControlCardTextValue") {
    return <>{value.text}</>;
  }
  if (value.__typename === "ControlCardRichTextValue") {
    return <RichTextView html={value.html} />;
  } else if (value.__typename === "ControlCardEmptyValue") {
    return <EmptyValue />;
  } else {
    return <InvalidValue />;
  }
};
