import {
  IEmployeeLocationFragment,
  IOrganizerDocumentFragment,
  IOrganizerFolderFragment,
  IOrganizerFragment
} from "app/gql/graphqlSchema";
import {useTranslation} from "react-i18next";
import {usePermissions} from "app/employee/hooks/usePermissions";
import {useBoolean} from "@fluentui/react-hooks";
import {useCallback, useEffect, useRef} from "react";
import {FolderFab, IFolderFabHandle} from "app/employee/organizer/folder/FolderFab";
import {CustomPanel, Table} from "app/employee/organizer/OrganizerUI";
import {
  IonBadge,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  useIonViewWillLeave
} from "@ionic/react";
import {cameraOutline, closeOutline, folderOutline, locationOutline, trashOutline} from "ionicons/icons";
import {getLocationText} from "app/employee/utils";
import {FlexRow} from "app/employee/flexUtils";
import {ListPadding} from "app/employee/incdoc/ListPadding";
import {DocumentItem} from "app/employee/organizer/folder/DocumentItem";
import {DeletableDocumentItem} from "app/employee/organizer/folder/DeletableDocumentItem";

interface IProps {
  organizer: IOrganizerFragment;
  location: IEmployeeLocationFragment;
  folder: IOrganizerFolderFragment;
  documents: IOrganizerDocumentFragment[];
  onRefresh: () => void;
  onBack: () => void;
}

export const FolderContent = ({organizer, location, folder, documents, onRefresh, onBack}: IProps) => {
  const {t} = useTranslation();

  const {canEditOrganizerLocation} = usePermissions();
  const canEditOrganizer = canEditOrganizerLocation(location.id);
  const [deleteMode, {toggle: toggleDelete, setFalse: cancelDelete}] = useBoolean(false);

  const folderFabRef = useRef<IFolderFabHandle>(null);
  const handleAddDocument = useCallback(() => folderFabRef.current?.showFileUpload(), []);

  useIonViewWillLeave(() => {
    cancelDelete();
  }, [cancelDelete]);

  useEffect(() => {
    if (documents.length === 0) {
      cancelDelete();
    }
  }, [cancelDelete, documents.length]);

  return (
    <>
      <CustomPanel>
        <Table>
          <div>
            <IonIcon icon={folderOutline} />
          </div>
          <div>
            <strong>{folder.name}</strong>
          </div>
          <div>
            <IonIcon icon={locationOutline} />
          </div>
          <div>{getLocationText(location)}</div>
        </Table>
      </CustomPanel>
      <IonList>
        <IonListHeader lines="full">
          <IonLabel>
            <FlexRow>
              {t("organizer.folder.title", {defaultValue: "Документи"})}
              <span>
                <IonBadge color="primary">{documents.length}</IonBadge>
              </span>
            </FlexRow>
          </IonLabel>
        </IonListHeader>
        {canEditOrganizer && documents.length > 0 && (
          <IonItem button={false} color="highlight" lines="full">
            <IonButton fill="outline" onClick={toggleDelete} slot="start">
              <IonIcon icon={deleteMode ? closeOutline : trashOutline} slot="start" />
              {deleteMode ? t("g.cancel") : t("g.delete")}
            </IonButton>
            <IonButton onClick={handleAddDocument} slot="end">
              <IonIcon icon={cameraOutline} slot="start" />
              {t("organizer.folder.add-new-document", {defaultValue: "Нов документ"})}
            </IonButton>
          </IonItem>
        )}
        {canEditOrganizer && documents.length === 0 && (
          <IonItem button={false} color="highlight" lines="full">
            <IonLabel>
              <IonButton expand="block" onClick={handleAddDocument} size="default">
                <IonIcon icon={cameraOutline} slot="start" />
                {t("organizer.folder.add-new-document", {defaultValue: "Нов документ"})}
              </IonButton>
            </IonLabel>
          </IonItem>
        )}
        {documents.map((document) => {
          if (deleteMode) {
            return (
              <DeletableDocumentItem document={document} key={`delete-${document.id.documentId}`} onBack={onRefresh} />
            );
          } else {
            return <DocumentItem document={document} key={`view-${document.id.documentId}`} location={location} />;
          }
        })}
      </IonList>
      <ListPadding />
      {canEditOrganizer && (
        <FolderFab folder={folder} onBack={onBack} onRefresh={onRefresh} organizer={organizer} ref={folderFabRef} />
      )}
    </>
  );
};
