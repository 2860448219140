import {linkEmplOrganizerDocument} from "app/employee/core/employeeLinks";
import {IonIcon, IonItem, IonLabel} from "@ionic/react";
import {FlexCellFull, FlexRow} from "app/employee/flexUtils";
import {documentOutline} from "ionicons/icons";
import {DocumentIcon} from "app/employee/organizer/folder/DocumentIcon";
import {IEmployeeLocationFragment, IOrganizerDocumentFragment} from "app/gql/graphqlSchema";

interface IProps {
  document: IOrganizerDocumentFragment;
  location: IEmployeeLocationFragment;
}

export const DocumentItem = ({location, document}: IProps) => {
  return (
    <IonItem detail lines="full" routerLink={linkEmplOrganizerDocument(location.id.locationId, document.id.documentId)}>
      <IonLabel>
        <FlexRow>
          <FlexCellFull>
            <IonIcon icon={documentOutline} />
          </FlexCellFull>
          <span>{document.name}</span>
        </FlexRow>
      </IonLabel>
      <FlexRow slot="end">
        <FlexCellFull>
          <DocumentIcon document={document} />
        </FlexCellFull>
      </FlexRow>
    </IonItem>
  );
};
