import {IColumnFragment, IControlCardKey, IControlCardValue, IValueInstanceFragment} from "app/gql/graphqlSchema";
import {
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonList,
  IonRadio
} from "@ionic/react";
import {assertNotNil, isEqual, isNil, isNotBlank} from "app/utils/stdlib";
import {useTranslation} from "react-i18next";
import {useSaveControlCardValue} from "app/employee/controlCard/hook/useSaveControlCardValue";
import {IonCardFooter} from "app/employee/controlCard/ControlCardUI";
import {checkmarkCircleOutline, closeOutline} from "ionicons/icons";
import {Control, useForm, useWatch} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {zControlCardKey} from "app/utils/validator";
import {RhfIonRadioGroup} from "app/employee/rhf/RhfIonRadioGroup";
import {RhfIonInput} from "app/employee/rhf/RhfIonInput";
import {EMPTY_VALUE} from "app/employee/controlCard/types";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {useEffect, useMemo} from "react";
import {SavingProgress} from "app/employee/controlCard/input/SavingProgress";
import {DisplayRootError} from "app/employee/controlCard/input/DisplayRootError";
import {transformToApplicationError} from "app/gql/handleGraphqlErrors";
import {setApplicationError} from "app/utils/parseErrorResult";
import {DisplayValueInstanceError} from "app/employee/controlCard/input/DisplayValueInstanceError";
import {useHasControlCardValueError} from "app/employee/controlCard/hook/useHasControlCardValueError";
import {InputIonCard} from "app/employee/controlCard/input/InputIonCard";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardChoiceColumn"};
  valueInstance: IValueInstanceFragment;
  disabled?: boolean;
}

const commentSchema = z
  .string()
  .min(1)
  .transform((v) => v.trim());
const formSchema = z
  .object({
    optionKey: zControlCardKey.nullable(),
    comments: z.record(z.string())
  })
  .superRefine((data, ctx) => {
    const key = data.optionKey?.value;
    if (!isNil(key) && key in data.comments) {
      const result = commentSchema.safeParse(data.comments[key]);
      if (!result.success) {
        result.error.issues.forEach((issue) => {
          ctx.addIssue({...issue, path: ["comments", key]});
        });

        return z.NEVER;
      } else {
        data.comments[key] = result.data;
      }
    }

    return data;
  });

export type IFormData = z.infer<typeof formSchema>;

type IOption = IProps["column"]["options"][number];

const ChoiceOption = ({
  option,
  control,
  disabled
}: {
  option: IOption;
  control: Control<IFormData>;
  disabled: boolean;
}) => {
  const optionKey = useWatch({control, name: "optionKey"});
  const {t} = useTranslation();
  switch (option.__typename) {
    case "ControlCardSingleChoiceOption":
      return (
        <IonItem lines="full">
          <IonRadio disabled={disabled} justify="start" labelPlacement="end" value={option.key}>
            {option.text}
          </IonRadio>
        </IonItem>
      );
    case "ControlCardChoiceWithCommentOption": {
      const name = `comments.${option.key.value}` as const;
      const showInput = isEqual(optionKey, option.key);
      return (
        <>
          <IonItem lines={showInput ? "none" : "full"}>
            <IonRadio disabled={disabled} justify="start" labelPlacement="end" value={option.key}>
              {option.text}
            </IonRadio>
          </IonItem>
          {showInput && (
            <IonItem lines="full">
              <RhfIonInput
                clearInput
                disabled={disabled}
                label={option.commentLabel ?? t("control-card.comment", {defaultValue: "Коментар"})}
                labelPlacement="stacked"
                rhf={{control, name}}
              />
            </IonItem>
          )}
        </>
      );
    }
  }
};

export const InputChoice = ({column, valueInstance, disabled}: IProps) => {
  const {saveValue} = useSaveControlCardValue(valueInstance);
  const {isComplete, canEdit} = useControlCardInstanceContext();
  const hasError = useHasControlCardValueError(valueInstance);
  const localDisabled = isComplete || !canEdit || !!disabled;
  const {t} = useTranslation();
  let optionKey: IControlCardKey | null = null;

  const comments: Record<string, string> = {};
  for (const options of column.options) {
    if (options.__typename === "ControlCardChoiceWithCommentOption") {
      comments[options.key.value] = "";
    }
  }

  const value = valueInstance.value;
  if (value.__typename === "ControlCardSingleChoiceValue" || value.__typename === "ControlCardChoiceWithCommentValue") {
    optionKey = value.optionKey;
    if (value.__typename === "ControlCardChoiceWithCommentValue") {
      comments[optionKey.value] = value.comment;
    }
  }

  const {
    control,
    formState: {isDirty, isSubmitting, errors},
    handleSubmit,
    reset,
    watch,
    setError
  } = useForm<IFormData>({
    values: {
      optionKey,
      comments
    },
    resolver: zodResolver(formSchema)
  });

  const handleSave = useMemo(
    () =>
      handleSubmit(async (data) => {
        try {
          let value: IControlCardValue;
          if (isNil(data.optionKey)) {
            value = EMPTY_VALUE;
          } else {
            const option = column.options.find((option) => isEqual(option.key, data.optionKey));
            assertNotNil(option);
            if (option.__typename === "ControlCardChoiceWithCommentOption") {
              assertNotNil(data.comments[data.optionKey.value]);
              value = {
                __typename: "ControlCardChoiceWithCommentValue",
                optionKey: data.optionKey,
                comment: data.comments[data.optionKey.value]
              };
            } else {
              value = {__typename: "ControlCardSingleChoiceValue", optionKey: data.optionKey};
            }
          }
          await saveValue(value);
        } catch (e) {
          const appError = transformToApplicationError(e);
          setApplicationError(appError, setError);
        }
      }),
    [column.options, handleSubmit, saveValue, setError]
  );

  const handleClear = () => reset();

  useEffect(() => {
    if (isComplete) {
      reset();
    }
  }, [isComplete, reset]);

  useEffect(() => {
    const subscription = watch((_, {name, type}) => {
      if (name === "optionKey" && type === "change") {
        handleSave();
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSave, watch]);

  return (
    <InputIonCard $hasError={hasError} data-scroll-to-value-instance-id={valueInstance.id}>
      <IonCardHeader>
        <IonCardTitle>{column.title}</IonCardTitle>
        {isNotBlank(column.description) && <IonCardSubtitle>{column.description}</IonCardSubtitle>}
      </IonCardHeader>

      <IonCardContent>
        <DisplayRootError errors={errors} />
        <IonList>
          <RhfIonRadioGroup rhf={{control, name: "optionKey"}}>
            {column.options.map((option) => {
              return (
                <ChoiceOption
                  control={control}
                  disabled={localDisabled || isSubmitting}
                  key={option.key.value}
                  option={option}
                />
              );
            })}
          </RhfIonRadioGroup>
        </IonList>
        <DisplayValueInstanceError column={column} disabled={localDisabled} valueInstance={valueInstance} />
      </IonCardContent>
      {isDirty && !isSubmitting && (
        <IonCardFooter>
          <IonButton color="primary" fill="outline" onClick={handleClear} size="small">
            <IonIcon icon={closeOutline} slot="start" />
            {t("g.cancel")}
          </IonButton>
          <IonButton color="primary" onClick={handleSave} size="small">
            <IonIcon icon={checkmarkCircleOutline} slot="start" />
            {t("g.save")}
          </IonButton>
        </IonCardFooter>
      )}
      <SavingProgress isSubmitting={isSubmitting} />
    </InputIonCard>
  );
};
