import {ITestProgress} from "app/employee/state/syllabus/selectors";
import {redirectTo, RedirectType, verifyCurrentTest} from "app/employee/state/syllabus/mutations";
import {DeepReadonly} from "ts-essentials";
import {IonButton, IonButtons, IonIcon, useIonRouter} from "@ionic/react";
import {arrowBack, arrowForward, checkmarkCircle} from "ionicons/icons";
import {Trans, useTranslation} from "react-i18next";
import {IIonButton} from "app/utils/types";
import {linkEmplCourseHome, linkEmplCurrentTest} from "app/employee/core/employeeLinks";

type IProps = Pick<ITestProgress, "prev" | "next"> & {
  hasAnswer: boolean;
};

export const TestNavigation = ({prev, next, hasAnswer}: DeepReadonly<IProps>) => {
  const ionRouter = useIonRouter();
  const {t} = useTranslation();
  const onCompleteTest = async () => {
    await verifyCurrentTest();
    redirectTo(RedirectType.TEST_RESULT);
    ionRouter.push(linkEmplCourseHome(), "back", "push");
  };

  let nextButton: JSX.Element;
  if (next) {
    nextButton = (
      <IonButton
        color="primary"
        disabled={!next || !hasAnswer}
        fill="solid"
        routerDirection="forward"
        routerLink={linkEmplCurrentTest(next.id)}
      >
        <IonIcon icon={arrowForward} slot="end" />
        <Trans i18nKey="test.next-question" t={t}>
          Следващ
        </Trans>
      </IonButton>
    );
  } else {
    nextButton = (
      <IonButton color="primary" disabled={!hasAnswer} fill="solid" onClick={onCompleteTest} routerDirection="forward">
        <IonIcon icon={checkmarkCircle} slot="start" />
        <Trans i18nKey="test.complete-test" t={t}>
          Предай теста
        </Trans>
      </IonButton>
    );
  }

  let backButtonProps: Partial<IIonButton>;
  if (ionRouter.canGoBack()) {
    backButtonProps = {
      onClick: () => {
        ionRouter.goBack();
      }
    };
  } else {
    backButtonProps = {
      routerDirection: "back",
      routerLink: linkEmplCurrentTest(prev?.id)
    };
  }

  return (
    <IonButtons slot="end">
      {}
      <IonButton disabled={!prev} fill="outline" {...backButtonProps}>
        <IonIcon icon={arrowBack} slot="start" />
        <Trans i18nKey="test.prev-question" t={t}>
          Предишен
        </Trans>
      </IonButton>
      {nextButton}
    </IonButtons>
  );
};
