import {useBackNavigation} from "app/employee/hooks/useBackNavigation";
import {useModalContext} from "app/employee/incdoc/FstIonModal";
import {useCallback} from "react";

export const useDismiss = (fallbackPath: string) => {
  const navigateBack = useBackNavigation(fallbackPath);
  const modalContext = useModalContext();

  return useCallback(
    async (data?: unknown) => {
      if (modalContext?.isOpen) {
        await modalContext.dismiss(data);
      } else {
        navigateBack();
      }
    },
    [modalContext, navigateBack]
  );
};
