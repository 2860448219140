import {IColumnFragment, IValueInstanceFragment} from "app/gql/graphqlSchema";
import {InputLabel} from "app/employee/controlCard/input/InputLabel";
import {InputText} from "app/employee/controlCard/input/InputText";
import {InputChoice} from "app/employee/controlCard/input/InputChoice";
import {InputTemperature} from "app/employee/controlCard/input/InputTemperature";
import {InputTime} from "app/employee/controlCard/input/InputTime";
import {InputEmployeeRef} from "app/employee/controlCard/input/InputEmployeeRef";
import {UnreachableCaseError} from "ts-essentials";
import {InputComboBox} from "app/employee/controlCard/input/InputComboBox";
import {InputDate} from "app/employee/controlCard/input/InputDate";
import {InputMultiChoice} from "app/employee/controlCard/input/InputMultiChoice";
import {InputSignature} from "app/employee/controlCard/input/InputSignature";
import {InputNumber} from "app/employee/controlCard/input/InputNumber";

interface IProps {
  column: IColumnFragment;
  valueInstance: IValueInstanceFragment;
  disabled?: boolean;
}

export const DisplayInput = ({valueInstance, column, disabled}: IProps) => {
  switch (column.__typename) {
    case "ControlCardLabelColumn":
      return <InputLabel column={column} disabled={disabled} valueInstance={valueInstance} />;
    case "ControlCardTextColumn":
      return <InputText column={column} disabled={disabled} valueInstance={valueInstance} />;
    case "ControlCardChoiceColumn":
      return <InputChoice column={column} disabled={disabled} valueInstance={valueInstance} />;
    case "ControlCardMultiChoiceColumn":
      return <InputMultiChoice column={column} disabled={disabled} valueInstance={valueInstance} />;
    case "ControlCardTemperatureColumn":
      return <InputTemperature column={column} disabled={disabled} valueInstance={valueInstance} />;
    case "ControlCardNumberColumn":
      return <InputNumber column={column} disabled={disabled} valueInstance={valueInstance} />;
    case "ControlCardTimeColumn":
      return <InputTime column={column} disabled={disabled} valueInstance={valueInstance} />;
    case "ControlCardDateColumn":
      return <InputDate column={column} disabled={disabled} valueInstance={valueInstance} />;
    case "ControlCardEmployeeRefColumn":
      return <InputEmployeeRef column={column} disabled={disabled} valueInstance={valueInstance} />;
    case "ControlCardComboBoxColumn":
      return <InputComboBox column={column} disabled={disabled} valueInstance={valueInstance} />;
    case "ControlCardSignatureColumn":
      return <InputSignature column={column} disabled={disabled} valueInstance={valueInstance} />;
    case "ControlCardTransposedColumn":
      return null;
    default:
      throw new UnreachableCaseError(column);
  }
};
