import {PropsWithChildren, ReactNode, useState} from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonRouter,
  useIonViewWillEnter
} from "@ionic/react";
import {useSyllabusLoadingState} from "app/employee/state/syllabus/selectors";
import {ApiState} from "app/state/common/loadable";
import {popRedirect, RedirectType, refreshSyllabus} from "app/employee/state/syllabus/mutations";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {ContentSpinner} from "app/employee/ContentSpinner";
import {isNil} from "app/utils/stdlib";
import {useTranslation} from "react-i18next";
import {Loading} from "app/employee/Loading";
import {UnreachableCaseError} from "ts-essentials";
import {
  linkEmplCurrentTest,
  linkEmplCurrentTestResult,
  linkEmplWatchCurrentVideo
} from "app/employee/core/employeeLinks";
import {MaybeNil} from "app/utils/types";
import {DateTime, Duration} from "luxon";
import {refreshCircle} from "ionicons/icons";
import {fullRefresh} from "app/employee/core/fullRefresh";

interface IProps {
  alwaysRefresh?: boolean;
}

const AUTO_REFRESH_INTERVAL_MS = 5 * 60 * 1000;

const checkTimeToRefresh = (lastRefreshMs: MaybeNil<number>): boolean => {
  if (isNil(lastRefreshMs)) return false;
  const nowMs = DateTime.now().toMillis();

  const timeSinceLastRefreshMs = nowMs - lastRefreshMs;
  if (timeSinceLastRefreshMs > AUTO_REFRESH_INTERVAL_MS) {
    console.log(
      "Refreshing... (time since last refresh: %s)",
      Duration.fromMillis(timeSinceLastRefreshMs).toFormat("mm:ss.SSS")
    );
    return true;
  } else {
    return false;
  }
};

export const SyllabusLoadingPage = ({children, alwaysRefresh}: PropsWithChildren<IProps>) => {
  const {t} = useTranslation();
  const {loadingState, lastRefreshMs, appError} = useSyllabusLoadingState();
  const [fullReload, setFullReload] = useState<boolean | undefined>(undefined);
  const ionRouter = useIonRouter();

  useIonViewWillEnter(() => {
    const fullReload = loadingState === ApiState.NA || loadingState == ApiState.ERROR;
    const isTimeToRefresh = checkTimeToRefresh(lastRefreshMs);
    setFullReload(fullReload);
    if (alwaysRefresh || fullReload || isTimeToRefresh) {
      refreshSyllabus()
        .then(() => {
          setFullReload(false);
          const redirect = popRedirect();
          switch (redirect) {
            case RedirectType.TEST:
              ionRouter.push(linkEmplCurrentTest(), "forward", "push");
              break;
            case RedirectType.NEXT_TOPIC:
              ionRouter.push(linkEmplWatchCurrentVideo(), "forward", "push");
              break;
            case RedirectType.TEST_RESULT:
              ionRouter.push(linkEmplCurrentTestResult(), "forward", "push");
              break;
            case RedirectType.NONE:
              break;
            default:
              throw new UnreachableCaseError(redirect);
          }
        })
        .catch(() => setFullReload(true));
    }
  }, [loadingState, lastRefreshMs, alwaysRefresh, ionRouter]);

  let content: ReactNode;
  if (isNil(fullReload)) {
    content = null;
  } else if (fullReload && loadingState === ApiState.LOADING) {
    content = (
      <>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <IonTitle>{t("g.loading")}</IonTitle>
            <MenuButton />
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <ContentSpinner loadingState={loadingState} />
        </IonContent>
      </>
    );
  } else if (loadingState === ApiState.LOADING || loadingState === ApiState.OK) {
    content = (
      <>
        <Loading appError={appError} state={loadingState} />
        {children}
      </>
    );
  } else if (loadingState === ApiState.ERROR) {
    content = (
      <>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <MenuButton />
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding ion-text-center">
          <IonRefresher onIonRefresh={refreshSyllabus} slot="fixed">
            <IonRefresherContent />
          </IonRefresher>
          <h1>{t("g.loading-error")}</h1>
          <IonButton onClick={fullRefresh}>
            <IonIcon icon={refreshCircle} slot="start" />
            {t("error.unexpected.reload-button")}
          </IonButton>
        </IonContent>
      </>
    );
  }

  return <IonPage>{content}</IonPage>;
};
