import {IColumnFragment, IValueFragment} from "app/gql/graphqlSchema";
import {EmptyValue} from "app/employee/controlCard/display/EmptyValue";
import {InvalidValue} from "app/employee/controlCard/display/InvalidValue";
import {DisplayTemperatureRange} from "app/employee/controlCard/display/DisplayTemperatureRange";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardTemperatureColumn"};
  value: IValueFragment;
}

export const DisplayTemperature = ({column, value}: IProps) => {
  let result;
  if (value.__typename === "ControlCardTemperatureValue") {
    result = <>{value.temperature} °C</>;
  } else if (value.__typename === "ControlCardEmptyValue") {
    result = <EmptyValue />;
  } else {
    result = <InvalidValue />;
  }

  return (
    <span>
      {result} (<DisplayTemperatureRange range={column.range} />)
    </span>
  );
};
