import {ISyllabusTermPlan} from "app/employee/state/syllabus/mutations";
import {deadlineDayCount} from "app/employee/syllabus/utils";
import {IonBadge} from "@ionic/react";
import {DateDisplay} from "app/employee/DateDisplay";
import {useTranslation} from "react-i18next";

export const PlanPeriodAndDeadline = ({termPlan}: {termPlan: ISyllabusTermPlan}) => {
  const {days, isOverdue} = deadlineDayCount(termPlan);
  const {t} = useTranslation();
  return (
    <>
      (<DateDisplay dt={termPlan.startedAt} /> – <DateDisplay dt={termPlan.deadline} />){" "}
      {!termPlan.completed &&
        (isOverdue ? (
          <IonBadge color="danger">
            {t("syllabus.in-progress.overdue", {days, defaultValue: "Просрочено с {{days}} дни!"})}
          </IonBadge>
        ) : (
          <IonBadge color={days <= 7 ? "warning" : "medium"}>
            {t("syllabus.in-progress.days-remaining", {days, defaultValue: "{{days}} дни до крайния срок."})}
          </IonBadge>
        ))}
    </>
  );
};
