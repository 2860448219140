import {FallbackRender} from "@sentry/react/types/errorboundary";
import {useIonAlert} from "@ionic/react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {fullRefresh} from "app/employee/core/fullRefresh";

export const fallbackRender: FallbackRender = (errorData) => <FallbackComponent errorData={errorData} />;

type ErrorData = Parameters<FallbackRender>[0];

interface IProps {
  errorData: ErrorData;
}

const FallbackComponent = ({errorData}: IProps) => {
  const [presentAlert, dismissAlert] = useIonAlert();
  const {t} = useTranslation();

  useEffect(() => {
    presentAlert({
      header: t("error.unexpected.header", {defaultValue: "Грешка"}),
      message: t("error.unexpected.message", {defaultValue: "Моля, презаредете приложението."}),
      buttons: [
        {
          role: "cancel",
          text: t("error.unexpected.reload-button", {defaultValue: "Презареди"}),
          handler: () => {
            errorData.resetError();
            fullRefresh();
          }
        }
      ]
    });

    return () => {
      dismissAlert();
    };
  }, [dismissAlert, presentAlert, t, errorData]);

  return null;
};
