import {AddEditDocument} from "app/employee/incdoc/AddEditDocument";
import {forwardRef, PropsWithChildren, useCallback, useImperativeHandle, useRef} from "react";
import {useImmer} from "use-immer";
import {ISearchResultIncomingDocument} from "app/employee/state/incdoc/mutations";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {useAttachmentCollectionEditor} from "app/components/hooks/useAttachmentCollectionEditor";

export interface IDocumentUploaderHandle {
  showFileUpload(): void;
  editDocument(document: ISearchResultIncomingDocument): void;
}

interface IProps {
  onCompleted?: () => void;
}

export const DocumentUploader = forwardRef<IDocumentUploaderHandle, PropsWithChildren<IProps>>(({onCompleted}, ref) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [editDocument, setEditDocument] = useImmer<ISearchResultIncomingDocument | null>(null);
  const {showFileUpload, unifiedAttachments, uploadProgress, updateAttachments, cleanup} =
    useAttachmentCollectionEditor(editDocument?.attachmentCollection, () => modal.current?.present());

  const dismissDialog = useCallback(() => {
    cleanup();
    setEditDocument(null);

    modal.current?.dismiss();
    onCompleted?.();
  }, [cleanup, onCompleted, setEditDocument]);

  useImperativeHandle(
    ref,
    () => ({
      showFileUpload,
      editDocument: (document: ISearchResultIncomingDocument) => {
        setEditDocument(() => document);
        modal.current?.present();
      }
    }),
    [setEditDocument, showFileUpload]
  );

  return (
    <FstIonModal onIonModalDidDismiss={dismissDialog} ref={modal}>
      <AddEditDocument
        incomingDocument={editDocument}
        showFileUpload={showFileUpload}
        unifiedAttachments={unifiedAttachments}
        updateAttachments={updateAttachments}
        uploadProgress={uploadProgress}
      />
    </FstIonModal>
  );
});

DocumentUploader.displayName = "DocumentUploader";
