import {IColumnFragment, IValueFragment} from "app/gql/graphqlSchema";
import {UnreachableCaseError} from "ts-essentials";
import {DisplayText} from "./display/DisplayText";
import {DisplayEmployeeRef} from "app/employee/controlCard/display/DisplayEmployeeRef";
import {DisplayTemperature} from "app/employee/controlCard/display/DisplayTemperature";
import {DisplayChoice} from "app/employee/controlCard/display/DisplayChoice";
import {DisplayTime} from "app/employee/controlCard/display/DisplayTime";
import {DisplayDate} from "app/employee/controlCard/display/DisplayDate";
import {DisplayMultiChoice} from "app/employee/controlCard/display/DisplayMultiChoice";
import {DisplaySignature} from "app/employee/controlCard/display/DisplaySignature";
import {DisplayNumber} from "app/employee/controlCard/display/DisplayNumber";

interface IProps {
  column: IColumnFragment;
  value: IValueFragment;
}

export const DisplayValue = ({value, column}: IProps) => {
  if (value.__typename === "ControlCardExpressionValue") {
    return <DisplayValue column={column} value={value.result} />;
  } else {
    switch (column.__typename) {
      case "ControlCardLabelColumn":
      case "ControlCardTextColumn":
      case "ControlCardComboBoxColumn":
        return <DisplayText value={value} />;
      case "ControlCardChoiceColumn":
        return <DisplayChoice column={column} value={value} />;
      case "ControlCardMultiChoiceColumn":
        return <DisplayMultiChoice column={column} value={value} />;
      case "ControlCardTemperatureColumn":
        return <DisplayTemperature column={column} value={value} />;
      case "ControlCardNumberColumn":
        return <DisplayNumber column={column} value={value} />;
      case "ControlCardTimeColumn":
        return <DisplayTime column={column} value={value} />;
      case "ControlCardDateColumn":
        return <DisplayDate column={column} value={value} />;
      case "ControlCardEmployeeRefColumn":
        return <DisplayEmployeeRef column={column} value={value} />;
      case "ControlCardSignatureColumn":
        return <DisplaySignature value={value} />;
      case "ControlCardTransposedColumn":
        return null;
      default:
        throw new UnreachableCaseError(column);
    }
  }
};
