import {IonCard, IonCardContent} from "@ionic/react";
import {isNil} from "app/utils/stdlib";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {RichTextView} from "app/components/admin/rte/RichTextView";

export const ControlCardInstanceHeaderFooter = ({headerOrFooter}: {headerOrFooter: "header" | "footer"}) => {
  const {instance} = useControlCardInstanceContext();

  const richTextBlock = instance.definition[headerOrFooter];
  if (isNil(richTextBlock)) {
    return null;
  } else {
    return (
      <IonCard>
        <IonCardContent>
          <RichTextView html={richTextBlock.html} />
        </IonCardContent>
      </IonCard>
    );
  }
};
