import {IFailedQuestion} from "app/employee/state/syllabus/selectors";
import {FailedQuestion} from "app/employee/test/result/FailedQuestion";
import {IonGrid} from "@ionic/react";

interface IProps {
  failedQuestions: IFailedQuestion[];
  showAnswer?: boolean;
}

export const FailedQuestions = ({failedQuestions, showAnswer}: IProps) => {
  return (
    <IonGrid>
      {failedQuestions.map((failedQuestion) => {
        return (
          <FailedQuestion failedQuestion={failedQuestion} key={failedQuestion.question.id} showAnswer={showAnswer} />
        );
      })}
    </IonGrid>
  );
};
