import {ISearchResultSupplier} from "app/employee/state/supplier/mutations";
import {EditContainer} from "app/employee/incdoc/EditContainer";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  useIonActionSheet,
  useIonRouter
} from "@ionic/react";
import {closeOutline, ellipsisVerticalCircleOutline, pencilOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {FlexColumn} from "app/employee/flexUtils";
import {DocumentNotes} from "app/employee/incdoc/DocumentNotes";
import {useCallback} from "react";
import {linkEmplIncDocEditSupplier} from "app/employee/core/employeeLinks";

interface IProps {
  supplier: ISearchResultSupplier;
}

export const SupplierCard = ({supplier}: IProps) => {
  const {t} = useTranslation();
  const [presentActionSheet] = useIonActionSheet();
  // const [presentConfirmation] = useIonAlert();
  const {push} = useIonRouter();

  // const onDelete = noop;

  const onEdit = useCallback(() => {
    push(linkEmplIncDocEditSupplier(supplier.id.supplierId), "forward", "push");
  }, [push, supplier.id.supplierId]);

  const showOptions = useCallback(
    async () =>
      presentActionSheet({
        header: t("incdoc.action.title", {defaultValue: "Действия"}),

        buttons: [
          {
            text: t("incdoc.action.edit", {defaultValue: "Редактирай"}),
            icon: pencilOutline,
            handler: onEdit
          },
          // {
          //   text: t("incdoc.action.delete", {defaultValue: "Изтрий"}),
          //   role: "destructive",
          //   icon: trashOutline,
          //   handler: async () => {
          //     await presentConfirmation({
          //       header: t("incdoc.supplier.action.confirm-delete.title", {
          //         defaultValue: "Сигурни ли сте че искате да изтриете този доставчик?"
          //       }),
          //       buttons: [
          //         {
          //           text: t("g.delete", {defaultValue: "Изтрий"}),
          //           role: "destructive",
          //           handler: onDelete
          //         },
          //         {
          //           text: t("g.cancel"),
          //           role: "cancel"
          //         }
          //       ]
          //     });
          //   }
          // },
          {
            text: t("g.close"),
            icon: closeOutline,
            role: "cancel"
          }
        ]
      }),
    [onEdit, presentActionSheet, t]
  );

  return (
    <IonCard className="ion-no-margin">
      <EditContainer>
        <IonButton fill="clear" onClick={showOptions}>
          <IonIcon icon={ellipsisVerticalCircleOutline} slot="icon-only" />
        </IonButton>
      </EditContainer>
      <IonCardHeader>
        <IonCardTitle>{supplier.name}</IonCardTitle>
        <IonCardSubtitle>{supplier.eik}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <FlexColumn>
          <DocumentNotes notes={supplier.notes} />
        </FlexColumn>
      </IonCardContent>
    </IonCard>
  );
};
