import {css, styled} from "styled-components";
import {IonCard} from "@ionic/react";

const errorOutline = css`
  outline: 2px solid #eb445a;
  box-shadow: 0 0 5px 2px rgb(235, 68, 90, 0.5);
`;

const normalOutline = css`
  outline: 1px solid var(--fst-border-color);
`;

export const InputIonCard = styled(IonCard)<{$hasError: boolean}>`
  ${({$hasError}) => ($hasError ? errorOutline : normalOutline)}
`;
