import {VideoLessonTestCompletePassed} from "app/employee/test/result/VideoLessonTestCompletePassed";
import {VideoLessonTestCompleteFailed} from "app/employee/test/result/VideoLessonTestCompleteFailed";
import {useCompletedTest, useTestResultState} from "app/employee/state/syllabus/selectors";
import {IonButton, IonContent, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {Trans, useTranslation} from "react-i18next";
import {homeOutline} from "ionicons/icons";
import {FlexColumnCenter} from "app/employee/flexUtils";
import {linkEmplCourseHome} from "app/employee/core/employeeLinks";
import {MenuButton} from "app/employee/menu/MenuButton";

export const ScreenVideoLessonTestComplete = () => {
  const {t} = useTranslation();
  const completedTest = useCompletedTest();
  const {canDisplayTestResult} = useTestResultState();

  if (canDisplayTestResult && completedTest) {
    if (completedTest.passed) {
      return <VideoLessonTestCompletePassed completedTest={completedTest} />;
    } else {
      return <VideoLessonTestCompleteFailed />;
    }
  } else {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <IonTitle>
              <Trans i18nKey="test.result-title" t={t}>
                Резултат от теста
              </Trans>
            </IonTitle>
            <MenuButton />
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <h1 className="ion-text-center">{t("test.cant-view-test-result")}</h1>
          <FlexColumnCenter>
            <IonButton color="primary" routerDirection="back" routerLink={linkEmplCourseHome()}>
              <IonIcon icon={homeOutline} slot="start" />

              {t("g.back-to-home")}
            </IonButton>
          </FlexColumnCenter>
        </IonContent>
      </>
    );
  }
};
