import {IColumnFragment, IControlCardValue, IValueInstanceFragment} from "app/gql/graphqlSchema";
import {IonButton, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {RhfIonInput} from "app/employee/rhf/RhfIonInput";
import {z} from "zod";
import {isNil, isNotBlank, normalizeToNull} from "app/utils/stdlib";
import {zodResolver} from "@hookform/resolvers/zod";
import {EMPTY_VALUE} from "app/employee/controlCard/types";
import {IonCardFooter} from "app/employee/controlCard/ControlCardUI";
import {checkmarkCircleOutline, closeOutline, refreshCircleOutline} from "ionicons/icons";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {useEffect} from "react";
import {SavingProgress} from "app/employee/controlCard/input/SavingProgress";
import {useExpressionValue} from "app/employee/controlCard/hook/useExpressionValue";
import {EditContainer} from "app/employee/incdoc/EditContainer";
import {transformToApplicationError} from "app/gql/handleGraphqlErrors";
import {setApplicationError} from "app/utils/parseErrorResult";
import {DisplayRootError} from "app/employee/controlCard/input/DisplayRootError";
import {DisplayValueInstanceError} from "app/employee/controlCard/input/DisplayValueInstanceError";
import {useHasControlCardValueError} from "app/employee/controlCard/hook/useHasControlCardValueError";
import {InputIonCard} from "app/employee/controlCard/input/InputIonCard";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardTextColumn"};
  valueInstance: IValueInstanceFragment;
  disabled?: boolean;
}

const formSchema = z.object({
  value: z.string().nullable().transform(normalizeToNull)
});

export type IFormData = z.infer<typeof formSchema>;

export const InputText = ({column, valueInstance, disabled}: IProps) => {
  const {t} = useTranslation();
  const {isComplete, canEdit} = useControlCardInstanceContext();
  const hasError = useHasControlCardValueError(valueInstance);

  const {persistedValue, convertToComputed, showRestoreExpression, saving, saveValue} = useExpressionValue(
    column,
    valueInstance
  );

  let value: string | null = null;
  if (persistedValue.__typename === "ControlCardTextValue") {
    value = persistedValue.text;
  }

  const {
    control,
    formState: {isDirty, isSubmitting, disabled: formDisabled, errors},
    handleSubmit,
    reset,
    setError
  } = useForm<IFormData>({
    values: {
      value
    },
    disabled: isComplete || !canEdit || !!disabled,
    resolver: zodResolver(formSchema)
  });

  const handleSave = handleSubmit(async (data) => {
    try {
      let value: IControlCardValue;
      if (isNil(data.value)) {
        value = EMPTY_VALUE;
      } else {
        value = {__typename: "ControlCardTextValue", text: data.value};
      }

      await saveValue(value);
    } catch (e) {
      const appError = transformToApplicationError(e);
      setApplicationError(appError, setError);
    }
  });

  const handleClear = () => reset();

  useEffect(() => {
    if (isComplete) {
      reset();
    }
  }, [isComplete, reset]);

  return (
    <InputIonCard $hasError={hasError} data-scroll-to-value-instance-id={valueInstance.id}>
      <EditContainer>
        {showRestoreExpression && (
          <IonButton disabled={formDisabled} fill="clear" onClick={convertToComputed}>
            <IonIcon icon={refreshCircleOutline} slot="icon-only" />
          </IonButton>
        )}
      </EditContainer>
      <IonCardHeader>
        <IonCardTitle>{column.title}</IonCardTitle>
        {isNotBlank(column.description) && <IonCardSubtitle>{column.description}</IonCardSubtitle>}
      </IonCardHeader>
      <IonCardContent>
        <DisplayRootError errors={errors} />
        <RhfIonInput
          clearInput
          fill="outline"
          label={column.title}
          labelPlacement="floating"
          onSubmitValue={handleSave}
          rhf={{control, name: "value"}}
        />
        <DisplayValueInstanceError column={column} disabled={formDisabled} valueInstance={valueInstance} />
      </IonCardContent>
      {isDirty && !isSubmitting && (
        <IonCardFooter>
          <IonButton color="primary" fill="outline" onClick={handleClear} size="small">
            <IonIcon icon={closeOutline} slot="start" />
            {t("g.cancel")}
          </IonButton>
          <IonButton color="primary" disabled={formDisabled} onClick={handleSave} size="small">
            <IonIcon icon={checkmarkCircleOutline} slot="start" />
            {t("g.save")}
          </IonButton>
        </IonCardFooter>
      )}
      <SavingProgress isSubmitting={saving} />
    </InputIonCard>
  );
};
