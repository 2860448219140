import {
  IChoiceOptionFragment,
  IColumnFragment,
  IControlCardEmptyValue,
  IControlCardMultiValue,
  IControlCardSingleChoiceValue,
  IControlCardValue,
  IValueInstanceFragment
} from "app/gql/graphqlSchema";
import {
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonList
} from "@ionic/react";
import {isEqual, isNil, isNotBlank} from "app/utils/stdlib";
import {useTranslation} from "react-i18next";
import {useSaveControlCardValue} from "app/employee/controlCard/hook/useSaveControlCardValue";
import {IonCardFooter} from "app/employee/controlCard/ControlCardUI";
import {checkmarkCircleOutline, closeOutline} from "ionicons/icons";
import {Control, useForm, useWatch} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {zControlCardKey} from "app/utils/validator";
import {EMPTY_VALUE} from "app/employee/controlCard/types";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {useEffect, useMemo} from "react";
import {UnreachableCaseError} from "ts-essentials";
import {RhfIonCheckbox} from "app/employee/rhf/RhfIonCheckbox";
import {RhfIonInput} from "app/employee/rhf/RhfIonInput";
import {SavingProgress} from "app/employee/controlCard/input/SavingProgress";
import {transformToApplicationError} from "app/gql/handleGraphqlErrors";
import {setApplicationError} from "app/utils/parseErrorResult";
import {DisplayRootError} from "app/employee/controlCard/input/DisplayRootError";
import {DisplayValueInstanceError} from "app/employee/controlCard/input/DisplayValueInstanceError";
import {useHasControlCardValueError} from "app/employee/controlCard/hook/useHasControlCardValueError";
import {InputIonCard} from "app/employee/controlCard/input/InputIonCard";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardMultiChoiceColumn"};
  valueInstance: IValueInstanceFragment;
  disabled?: boolean;
}

const commentSchema = z
  .string()
  .min(1)
  .transform((v) => v.trim());

const multiValue = z
  .object({
    type: z.union([z.literal("single"), z.literal("withComment")]),
    key: zControlCardKey,
    comment: z.string().trim(),
    checked: z.boolean()
  })
  .superRefine((data, ctx) => {
    if (data.type === "withComment" && data.checked) {
      const result = commentSchema.safeParse(data.comment);
      if (!result.success) {
        result.error.issues.forEach((issue) => {
          ctx.addIssue({...issue, path: ["comment"]});
        });

        return z.NEVER;
      }
    }

    return data;
  });

const formSchema = z.object({
  values: z.array(multiValue)
});

const fieldNamePattern = /^values\.\d+\.checked$/;

export type IFormData = z.infer<typeof formSchema>;

const MultiChoiceOption = ({
  option,
  control,
  disabled,
  index
}: {
  option: IChoiceOptionFragment;
  control: Control<IFormData>;
  disabled: boolean;
  index: number;
}) => {
  const name = `values.${index}` as const;
  const checkedName = `${name}.checked` as const;
  const commentName = `${name}.comment` as const;

  const checked = useWatch({control, name: checkedName});
  const {t} = useTranslation();
  switch (option.__typename) {
    case "ControlCardSingleChoiceOption":
      return (
        <IonItem lines="full">
          <RhfIonCheckbox disabled={disabled} justify="start" labelPlacement="end" rhf={{control, name: checkedName}}>
            {option.text}
          </RhfIonCheckbox>
        </IonItem>
      );
    case "ControlCardChoiceWithCommentOption": {
      const showInput = checked;

      return (
        <>
          <IonItem lines={showInput ? "none" : "full"}>
            <RhfIonCheckbox disabled={disabled} justify="start" labelPlacement="end" rhf={{control, name: checkedName}}>
              {option.text}
            </RhfIonCheckbox>
          </IonItem>
          {showInput && (
            <IonItem lines="full">
              <RhfIonInput
                clearInput
                disabled={disabled}
                label={option.commentLabel ?? t("control-card.comment", {defaultValue: "Коментар"})}
                labelPlacement="stacked"
                rhf={{control, name: commentName}}
              />
            </IonItem>
          )}
        </>
      );
    }
  }
};

export const InputMultiChoice = ({column, valueInstance, disabled}: IProps) => {
  const {saveValue} = useSaveControlCardValue(valueInstance);
  const {isComplete, canEdit} = useControlCardInstanceContext();
  const hasError = useHasControlCardValueError(valueInstance);

  const localDisabled = isComplete || !canEdit || !!disabled;
  const {t} = useTranslation();

  const formValues = useMemo(() => {
    const formValues: IFormData = {values: []};
    for (const options of column.options) {
      let type: IFormData["values"][number]["type"];
      switch (options.__typename) {
        case "ControlCardSingleChoiceOption":
          type = "single";
          break;
        case "ControlCardChoiceWithCommentOption":
          type = "withComment";
          break;
        default:
          throw new UnreachableCaseError(options);
      }
      formValues.values.push({
        type,
        key: options.key,
        comment: "",
        checked: false
      });
    }
    const value = valueInstance.value;
    if (value.__typename === "ControlCardMultiValue") {
      for (const subValue of value.values) {
        if (
          subValue.__typename === "ControlCardSingleChoiceValue" ||
          subValue.__typename === "ControlCardChoiceWithCommentValue"
        ) {
          const formValue = formValues.values.find((v) => isEqual(v.key, subValue.optionKey));
          if (!isNil(formValue)) {
            formValue.checked = true;
            if (subValue.__typename === "ControlCardChoiceWithCommentValue") {
              formValue.comment = subValue.comment;
            }
          }
        }
      }
    }
    return formValues;
  }, [column.options, valueInstance]);

  const {
    control,
    formState: {isDirty, isSubmitting, errors},
    handleSubmit,
    reset,
    watch,
    setError
  } = useForm<IFormData>({
    values: formValues,
    resolver: zodResolver(formSchema)
  });

  const handleSave = useMemo(
    () =>
      handleSubmit(async (data) => {
        try {
          let value: IControlCardEmptyValue | IControlCardMultiValue;
          if (data.values.length === 0) {
            value = EMPTY_VALUE;
          } else {
            value = {__typename: "ControlCardMultiValue", values: []};
            const checkedFormValues = data.values.filter((v) => v.checked);
            for (const checkedValue of checkedFormValues) {
              let subValue: IControlCardSingleChoiceValue | IControlCardValue;
              switch (checkedValue.type) {
                case "single":
                  subValue = {__typename: "ControlCardSingleChoiceValue", optionKey: checkedValue.key};
                  break;
                case "withComment":
                  subValue = {
                    __typename: "ControlCardChoiceWithCommentValue",
                    optionKey: checkedValue.key,
                    comment: checkedValue.comment
                  };
                  break;
                default:
                  throw new UnreachableCaseError(checkedValue.type);
              }
              value.values.push(subValue);
            }
          }
          await saveValue(value);
        } catch (e) {
          const appError = transformToApplicationError(e);
          setApplicationError(appError, setError);
        }
      }),
    [handleSubmit, saveValue, setError]
  );

  const handleClear = () => reset();

  useEffect(() => {
    if (isComplete) {
      reset();
    }
  }, [isComplete, reset]);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name?.match(fieldNamePattern) && type === "change") {
        handleSave();
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSave, watch]);

  return (
    <InputIonCard $hasError={hasError} data-scroll-to-value-instance-id={valueInstance.id}>
      <IonCardHeader>
        <IonCardTitle>{column.title}</IonCardTitle>
        {isNotBlank(column.description) && <IonCardSubtitle>{column.description}</IonCardSubtitle>}
      </IonCardHeader>
      <IonCardContent>
        <DisplayRootError errors={errors} />
        <IonList>
          {column.options.map((option, i) => {
            return (
              <MultiChoiceOption
                control={control}
                disabled={localDisabled || isSubmitting}
                index={i}
                key={option.key.value}
                option={option}
              />
            );
          })}
        </IonList>
        <DisplayValueInstanceError column={column} disabled={localDisabled} valueInstance={valueInstance} />
      </IonCardContent>
      {isDirty && !isSubmitting && (
        <IonCardFooter>
          <IonButton color="primary" fill="outline" onClick={handleClear} size="small">
            <IonIcon icon={closeOutline} slot="start" />
            {t("g.cancel")}
          </IonButton>
          <IonButton color="primary" onClick={handleSave} size="small">
            <IonIcon icon={checkmarkCircleOutline} slot="start" />
            {t("g.save")}
          </IonButton>
        </IonCardFooter>
      )}
      <SavingProgress isSubmitting={isSubmitting} />
    </InputIonCard>
  );
};
