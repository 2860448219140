import {UnreachableCaseError} from "ts-essentials";
import {useMemo} from "react";
import {isNil} from "app/utils/stdlib";
import {IControlCardInstanceDeckFragment, IDeckControlCardFragment} from "app/gql/graphqlSchema";

export const getKey = (deckControlCard: IDeckControlCardFragment) => {
  const instanceOrTemplate = getInstanceOrTemplate(deckControlCard);
  if (instanceOrTemplate.__typename === "ControlCardInstanceSummary") {
    return `instance-${instanceOrTemplate.instanceId.instanceId}`;
  } else if (instanceOrTemplate.__typename === "ControlCardTemplate") {
    return `template-${instanceOrTemplate.id.templateId}`;
  } else {
    throw new UnreachableCaseError(instanceOrTemplate);
  }
};
export const getInstanceOrTemplate = (deckControlCard: IDeckControlCardFragment) => {
  if (deckControlCard.instance) {
    return deckControlCard.instance;
  } else if (deckControlCard.template) {
    return deckControlCard.template;
  } else {
    throw new Error("Control card has neither instance nor template");
  }
};

export const useArchivedControlCards = (deckControlCards: IDeckControlCardFragment[]) => {
  return useMemo(() => {
    const archivedInstances: IControlCardInstanceDeckFragment[] = [];
    for (const deckControlCard of deckControlCards) {
      if (!isNil(deckControlCard.instance) && isNil(deckControlCard.template)) {
        archivedInstances.push(deckControlCard.instance);
      }
    }
    return archivedInstances;
  }, [deckControlCards]);
};

export const useActiveTemplatesControlCards = (deckControlCards: IDeckControlCardFragment[]) => {
  return useMemo(() => {
    return deckControlCards.filter((deckControlCard) => !isNil(deckControlCard.template));
  }, [deckControlCards]);
};
