import {useIncorrectQuestions, useIsWatchedAllIncorrectQuestions} from "app/employee/state/syllabus/selectors";
import {FailedTestBanner} from "app/employee/test/result/FailedTestBanner";
import {FailedQuestions} from "app/employee/test/result/FailedQuestions";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  useIonAlert
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {Trans, useTranslation} from "react-i18next";
import {refreshCircle} from "ionicons/icons";
import {linkEmplCurrentTest} from "app/employee/core/employeeLinks";

export const VideoLessonTestCompleteFailed = () => {
  const {t} = useTranslation();
  const incorrectQuestions = useIncorrectQuestions();
  const canTakeTestAgain = useIsWatchedAllIncorrectQuestions();
  const [showAlert] = useIonAlert();

  const showWhyDisabled = () => {
    if (!canTakeTestAgain) {
      showAlert({
        message: t("test.take-test-again-hint", {
          defaultValue:
            "За да решите теста отново трябва да изгледате всички откъси от темата, на които се дали грешен отговор."
        }),
        buttons: [
          {
            role: "cancel",
            text: t("g.ok")
          }
        ]
      });
    }
  };
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>
            <Trans i18nKey="test.result-title" t={t}>
              Резултат от теста
            </Trans>
          </IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <FailedTestBanner questionsCount={incorrectQuestions.length} />
        <FailedQuestions failedQuestions={incorrectQuestions} />
      </IonContent>
      <IonFooter className="ion-align-items-center" onClick={showWhyDisabled}>
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton
              color="primary"
              disabled={!canTakeTestAgain}
              fill="solid"
              routerDirection="forward"
              routerLink={linkEmplCurrentTest()}
            >
              <IonIcon icon={refreshCircle} slot="start" />
              <Trans i18nKey="test.take-test-again" t={t}>
                Решете сгрешените въпроси отново
              </Trans>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
