import {AddEditFolder} from "app/employee/organizer/modal/AddEditFolder";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {IonButton, IonFab, IonIcon, IonLabel} from "@ionic/react";
import {ellipsisVerticalCircleOutline} from "ionicons/icons";
import {IOrganizerFragment} from "app/gql/graphqlSchema";
import {useTranslation} from "react-i18next";
import {forwardRef, useCallback, useImperativeHandle, useRef} from "react";
import {useFolderListContextMenu} from "app/employee/organizer/folderList/useFolderListContextMenu";

interface IProps {
  organizer: IOrganizerFragment;
  onRefresh: () => void | Promise<void>;
}

export interface IFolderListFabHandle {
  addFolder: () => void;
}

export const FolderListFab = forwardRef<IFolderListFabHandle, IProps>(({organizer, onRefresh}, ref) => {
  const {t} = useTranslation();
  const folderRef = useRef<HTMLIonModalElement | null>(null);

  const handleNewFolder = useCallback(() => {
    onRefresh();
  }, [onRefresh]);

  const onAdd = useCallback(() => {
    folderRef.current?.present();
  }, []);

  const {showOptions} = useFolderListContextMenu({onAdd});

  useImperativeHandle(
    ref,
    () => ({
      addFolder: onAdd
    }),
    [onAdd]
  );

  return (
    <>
      <FstIonModal onIonModalDidDismiss={handleNewFolder} ref={folderRef}>
        <AddEditFolder organizerId={organizer.id} />
      </FstIonModal>
      <IonFab horizontal="end" slot="fixed" vertical="bottom">
        <IonButton onClick={showOptions} shape="round">
          <IonIcon icon={ellipsisVerticalCircleOutline} slot="end" />
          <IonLabel>{t("g.action")}</IonLabel>
        </IonButton>
      </IonFab>
    </>
  );
});

FolderListFab.displayName = "FolderListFab";
