import {useEffect} from "react";
import {ApiState, ILoadable} from "app/state/common/loadable";
import {GENERIC_ERROR, IApplicationError} from "app/gql/handleGraphqlErrors";
import {isNil} from "app/utils/stdlib";
import {MaybeNil} from "app/utils/types";
import {useLoadingContext} from "app/employee/LoadingContext";

export const Loading = ({state, appError}: {state: ApiState; appError?: MaybeNil<IApplicationError>}) => {
  const {startLoading, finishLoading, showAppError} = useLoadingContext();
  useEffect(() => {
    if (state === ApiState.LOADING) {
      startLoading();
      return finishLoading;
    }
  }, [finishLoading, startLoading, state]);

  useEffect(() => {
    if (state === ApiState.ERROR) {
      const error = !isNil(appError) ? appError : GENERIC_ERROR;
      showAppError(error);
    }
  }, [appError, showAppError, state]);

  return null;
};

export const LoadingLoadable = <T,>({loadable}: {loadable: ILoadable<T>}) => {
  const appError = loadable.loadingState === ApiState.ERROR ? loadable.appError : undefined;
  return <Loading appError={appError} state={loadable.loadingState} />;
};
