import {css, styled} from "styled-components";
import {IonIcon} from "@ionic/react";

export const AnimatedIonIconBadge = styled(IonIcon)`
  position: absolute;
  transform: scale(0.5) translateY(-60%) translateX(60%);

  animation-name: bellring2;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @keyframes bellring2 {
    0% {
      transform: scale(0.5) translateY(-60%) translateX(60%) rotate(0deg);
    }
    10% {
      transform: scale(0.5) translateY(-60%) translateX(60%) rotate(10deg);
    }
    20% {
      transform: scale(0.5) translateY(-60%) translateX(60%) rotate(-10deg);
    }
    30% {
      transform: scale(0.5) translateY(-60%) translateX(60%) rotate(10deg);
    }
    40% {
      transform: scale(0.5) translateY(-60%) translateX(60%) rotate(-10deg);
    }
    50% {
      transform: scale(0.5) translateY(-60%) translateX(60%) rotate(0deg);
    }
  }
`;

const bellRingAnimation = css`
  animation-name: bellring;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @keyframes bellring {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(10deg);
    }
    20% {
      transform: rotate(-10deg);
    }
    30% {
      transform: rotate(10deg);
    }
    40% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(0deg);
    }
  }
`;

export const AnimatedIonIcon = styled(IonIcon)<{$animated: boolean}>`
  ${({$animated}) => $animated && bellRingAnimation}
`;
