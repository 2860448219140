import {PropsWithChildren} from "react";
import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle} from "@ionic/react";
import {ISyllabusTermVideoLesson} from "app/employee/state/syllabus/mutations";
import {styled} from "styled-components";
import {VideoThumbnail} from "app/employee/syllabus/VideoThumbnail";
import {isNil} from "app/utils/stdlib";
import {VideoLessonTitleBadge} from "app/employee/syllabus/VideoLessonTitleBadge";
import {OptionalLink} from "app/employee/syllabus/OptionalLink";
import {getVideoStats} from "app/employee/syllabus/getVideoStats";

interface ILessonCardProps {
  lesson: ISyllabusTermVideoLesson;
  subtitle?: JSX.Element;
}

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ion-card-title {
    font-size: 1rem;
    font-weight: 600;
  }
`;

const ThumbnailWrapper = styled.div`
  flex: 0 0 30%;
  display: flex;
  padding: var(--padding, 16px);
  min-width: 100px;
`;

const ContentWrapper = styled.div`
  flex: 1 1 auto;
`;

const TitleLink = styled(OptionalLink)`
  color: inherit;
  text-decoration: inherit;
`;

export const SmallLessonCard = ({lesson, children, subtitle}: PropsWithChildren<ILessonCardProps>) => {
  const videoLesson = lesson.videoLesson;
  const title = videoLesson.title;
  const {canPlay, playLink} = getVideoStats(lesson);

  return (
    <IonCard className="ion-no-margin">
      <CardContentWrapper>
        <ThumbnailWrapper>
          <VideoThumbnail lesson={lesson} />
        </ThumbnailWrapper>
        <ContentWrapper>
          <IonCardHeader>
            <IonCardTitle>
              <TitleLink disabled={!canPlay} to={playLink}>
                {title}
              </TitleLink>{" "}
              <VideoLessonTitleBadge lesson={lesson} />
            </IonCardTitle>
            {!isNil(subtitle) && <IonCardSubtitle>{subtitle}</IonCardSubtitle>}
          </IonCardHeader>
          <IonCardContent>{children}</IonCardContent>
        </ContentWrapper>
      </CardContentWrapper>
    </IonCard>
  );
};
