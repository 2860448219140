import {useIncorrectQuestions} from "app/employee/state/syllabus/selectors";
import {completeCurrentTest, ITestResult, redirectTo, RedirectType} from "app/employee/state/syllabus/mutations";
import {FailedQuestions} from "app/employee/test/result/FailedQuestions";
import {DeepReadonly} from "ts-essentials";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  useIonRouter
} from "@ionic/react";
import {Trans, useTranslation} from "react-i18next";
import {arrowForward} from "ionicons/icons";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {linkEmplCourseHome} from "app/employee/core/employeeLinks";
import {MenuButton} from "app/employee/menu/MenuButton";

interface IProps {
  completedTest: ITestResult;
}

export const VideoLessonTestCompletePassed = ({completedTest}: DeepReadonly<IProps>) => {
  const {t} = useTranslation();
  const ionRouter = useIonRouter();
  const currentScorePercent = Math.round(parseFloat(completedTest.currentScorePercent));
  const incorrectQuestions = useIncorrectQuestions();
  const incorrectQuestionsCount = incorrectQuestions.length;

  const completeLesson = async () => {
    await completeCurrentTest();
    redirectTo(RedirectType.NEXT_TOPIC);
    ionRouter.push(linkEmplCourseHome(), "back", "push");
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{t("test.title", {defaultValue: "Резултат от теста"})}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h1 className="ion-text-center">🎉 {t("test.congrats", {defaultValue: "Поздравления!"})}</h1>
        <h2 className="ion-text-center">
          <Trans currentScorePercent={currentScorePercent} i18nKey="test.success-rate" t={t}>
            Вие успешно решихте теста с {{currentScorePercent}}% успеваемост
          </Trans>
        </h2>
        {incorrectQuestionsCount > 0 && (
          <>
            <h3>
              <Trans i18nKey="test.wrong-questions-title" questionsCount={incorrectQuestionsCount} t={t}>
                Имате {{questionsCount: incorrectQuestionsCount}} грешни отговора, eто и верните отговори:
              </Trans>
            </h3>
            <FailedQuestions failedQuestions={incorrectQuestions} showAnswer />
          </>
        )}
      </IonContent>
      <IonFooter className="ion-align-items-center">
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton color="primary" fill="solid" onClick={completeLesson}>
              <IonIcon icon={arrowForward} slot="end" />
              {t("test.continue-to-next")}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
