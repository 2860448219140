import {IBootstrapResult} from "app/employee/state/bootstrapEmployee/mutations";
import {Trans, useTranslation} from "react-i18next";
import {IonIcon, IonItem, IonLabel, IonList, IonMenuToggle} from "@ionic/react";
import {
  bookOutline,
  cartOutline,
  documentsOutline,
  documentTextOutline,
  fileTrayFullOutline,
  helpCircleOutline,
  notificationsOutline,
  readerOutline
} from "ionicons/icons";
import {useRouteMatch} from "react-router-dom";
import {useEnabledFeatures} from "app/employee/state/bootstrapEmployee/selectors";
import {Feature} from "app/gql/graphqlSchema";
import {styled} from "styled-components";
import {NotificationCountBadge} from "app/employee/menu/NotificationCountBadge";
import {AnimatedIonIcon} from "app/employee/menu/AnimatedIonIconBadge";
import {useNotificationCount} from "app/employee/notification/useNotificationCount";
import {NotificationPoll} from "app/employee/notification/NotificationPoll";
import {IIonItem} from "app/utils/types";

interface INavIonItemProps extends IIonItem {
  exact?: boolean;
  $nested?: boolean;
  parentPath?: string;
}

const StyledIonItem = styled(IonItem)<Pick<INavIonItemProps, "$nested">>`
  ${({$nested}) =>
    $nested &&
    `
    --padding-start: 32px;  
  `}
`;

const NavIonItem = (props: INavIonItemProps) => {
  const {parentPath, routerLink, $nested, exact} = props;
  const path = routerLink ?? "";
  const match = useRouteMatch({path, exact: exact ?? false});
  const partialMatch = useRouteMatch({path: parentPath ?? path, exact: false});
  if ($nested && !partialMatch) {
    return null;
  }
  return <StyledIonItem {...props} color={match ? "primary" : undefined} />;
};

const DisabledText = styled.p`
  &&& {
    font-size: 0.7rem;
    white-space: normal;
    line-height: 1rem;
    font-weight: bold;
  }
`;

const DisabledIonLabel = ({feature}: {feature: Feature}) => {
  const checkFeature = useEnabledFeatures();
  const {t} = useTranslation();

  return checkFeature(feature) ? null : (
    <DisabledText>{t("g.feature-disabled", {defaultValue: "Тази услуга не е активна за Вашата фирма"})}</DisabledText>
  );
};

export const LoggedInMenuItems = ({bootstrapResult}: {bootstrapResult?: IBootstrapResult}) => {
  const {t} = useTranslation();
  const checkFeature = useEnabledFeatures();
  const {unreadCount} = useNotificationCount();

  if (bootstrapResult?.__typename !== "BootstrapEmployee") {
    return null;
  }

  const employeeName = bootstrapResult.employee.name;
  const employeeClientName = bootstrapResult.employee.client.name;

  const welcomeMessage = t("menu.welcome", {
    defaultValue: "Добре дошъл, {{employeeName}} ({{employeeClientName}})",
    employeeName,
    employeeClientName
  });

  return (
    <>
      <IonList>
        <IonItem>
          <IonLabel class="ion-text-wrap">{welcomeMessage}</IonLabel>
        </IonItem>
        <IonMenuToggle>
          <NavIonItem
            disabled={!checkFeature(Feature.Course)}
            routerDirection="root"
            routerLink="/employees/course/current"
          >
            <IonIcon icon={bookOutline} slot="start" />
            <IonLabel>
              <Trans i18nKey="menu.course" t={t} />
              <DisabledIonLabel feature={Feature.Course} />
            </IonLabel>
          </NavIonItem>
          <IonItem
            disabled={!checkFeature(Feature.IncomingControl)}
            routerDirection="root"
            routerLink="/employees/inc-doc"
          >
            <IonIcon icon={fileTrayFullOutline} slot="start" />
            <IonLabel>
              <Trans defaultValue="Входящ контрол" i18nKey="menu.incoming-control" t={t} />
              <DisabledIonLabel feature={Feature.IncomingControl} />
            </IonLabel>
          </IonItem>
          <NavIonItem
            $nested
            disabled={!checkFeature(Feature.IncomingControl)}
            exact
            parentPath="/employees/inc-doc"
            routerDirection="root"
            routerLink="/employees/inc-doc"
          >
            <IonIcon icon={documentsOutline} slot="start" />
            <IonLabel>
              <Trans defaultValue="Документи" i18nKey="menu.incoming-document" t={t} />
            </IonLabel>
          </NavIonItem>
          <NavIonItem
            $nested
            disabled={!checkFeature(Feature.IncomingControl)}
            parentPath="/employees/inc-doc"
            routerDirection="root"
            routerLink="/employees/inc-doc/supplier"
          >
            <IonIcon icon={cartOutline} slot="start" />
            <IonLabel>
              <Trans defaultValue="Доставчици" i18nKey="menu.suppliers" t={t} />
            </IonLabel>
          </NavIonItem>
          <NavIonItem
            disabled={!checkFeature(Feature.Organizer)}
            routerDirection="root"
            routerLink="/employees/organizer"
          >
            <IonIcon icon={documentTextOutline} slot="start" />
            <IonLabel>
              <Trans defaultValue="Органайзер" i18nKey="menu.organizer" t={t} />
              <DisabledIonLabel feature={Feature.Organizer} />
            </IonLabel>
          </NavIonItem>
          <NavIonItem
            disabled={!checkFeature(Feature.ControlCard)}
            routerDirection="root"
            routerLink="/employees/control-card"
          >
            <IonIcon icon={readerOutline} slot="start" />
            <IonLabel>
              <Trans defaultValue="Контролни карти" i18nKey="menu.control-card" t={t} />
              <DisabledIonLabel feature={Feature.ControlCard} />
            </IonLabel>
          </NavIonItem>
          <NavIonItem routerDirection="root" routerLink="/employees/notifications">
            <AnimatedIonIcon $animated={unreadCount > 0} icon={notificationsOutline} slot="start" />
            <IonLabel>
              <Trans defaultValue="Известия" i18nKey="menu.notifications" t={t} /> <NotificationCountBadge />
            </IonLabel>
          </NavIonItem>
          <NavIonItem routerDirection="root" routerLink="/employees/help">
            <IonIcon icon={helpCircleOutline} slot="start" />
            <IonLabel>
              <Trans defaultValue="Помощ" i18nKey="menu.help" t={t} />
            </IonLabel>
          </NavIonItem>
        </IonMenuToggle>
      </IonList>
      <NotificationPoll />
    </>
  );
};
