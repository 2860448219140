import {IAnyDate, MaybeNil} from "app/utils/types";
import {parseAnyDate, SHORT_DATE_FORMAT_OPTS} from "app/utils/dates";
import {isNil} from "app/utils/stdlib";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";

interface Props {
  dt: MaybeNil<IAnyDate>;
}

export const DateDisplay = ({dt}: Props) => {
  const {i18n} = useTranslation();
  const tsStr = useMemo(() => {
    if (isNil(dt)) {
      return null;
    }
    const luxonTs = parseAnyDate(dt);
    return luxonTs.toLocaleString(SHORT_DATE_FORMAT_OPTS, {locale: i18n.language});
  }, [dt, i18n.language]);

  if (isNil(tsStr)) {
    return null;
  } else {
    return <>{tsStr}</>;
  }
};
