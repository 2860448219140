import {forwardRef} from "react";
import {styled} from "styled-components";

export type ButtonAction = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "0" | "back";

interface IProps {
  action: ButtonAction;
  value?: JSX.Element;
  disabled?: boolean;
  onClick: (action: ButtonAction) => void;
  className?: string;
}

const StyledButton = styled.button`
  font-size: 2rem;
  width: 4rem;
  height: 4rem;
  border: 1px solid var(--fst-border-color);
  background-color: var(--fst-background-color);
  color: var(--ion-text-color);
  border-radius: 2rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: var(--fst-hover-background-color);
  }

  &:active {
    background-color: var(--fst-active-background-color);
  }

  &:disabled {
    background-color: var(--fst-disabled-background-color);
    color: var(--fst-disabled-text-color);
  }
`;

export const Button = forwardRef<HTMLButtonElement, IProps>(function Button(
  {value, action, onClick, disabled, className},
  ref
) {
  const text = value ?? action;
  return (
    <StyledButton className={className} disabled={disabled} onClick={() => onClick(action)} ref={ref} type="button">
      {text}
    </StyledButton>
  );
});
