import {
  IControlCardInstanceOverrideValidationResultInput,
  IValueInstanceFragment,
  OverrideControlCardValidationResultDocument
} from "app/gql/graphqlSchema";
import {query} from "app/gql/client";
import {useCallback} from "react";
import {useLoadingContext} from "app/employee/LoadingContext";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";

const overrideControlCardValidationResultApi = async (input: IControlCardInstanceOverrideValidationResultInput) => {
  const result = await query(OverrideControlCardValidationResultDocument, {input});
  return result.controlCardInstanceOverrideValidationResult;
};

export const useOverrideControlCardValidationResult = (valueInstance: Pick<IValueInstanceFragment, "id">) => {
  const {instance, updateValue} = useControlCardInstanceContext();

  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();

  const overrideValidationResult = useCallback(
    async (reason: string) => {
      try {
        startLoading();
        const result = await overrideControlCardValidationResultApi({
          instanceId: instance.id,
          valueIds: [valueInstance.id],
          reason
        });
        const updatedValues = result.values;
        for (const updatedValue of updatedValues) {
          updateValue(updatedValue);
        }
      } catch (e) {
        await showExceptionError(e);
      } finally {
        finishLoading();
      }
    },
    [finishLoading, instance.id, showExceptionError, startLoading, updateValue, valueInstance]
  );

  return {overrideValidationResult};
};
