import {IIonSelect} from "app/utils/types";
import {useTranslation} from "react-i18next";
import {isEqual} from "app/utils/stdlib";
import {IonSelect} from "@ionic/react";
import {forwardRef} from "react";

export type IFstIonSelectProps = Omit<IIonSelect, "cancelText" | "compareWith" | "okText">;

export const FstIonSelect = forwardRef<HTMLIonSelectElement, IFstIonSelectProps>((props, ref) => {
  const {t} = useTranslation();
  return <IonSelect {...props} cancelText={t("g.cancel")} compareWith={isEqual} okText={t("g.ok")} ref={ref} />;
});

FstIonSelect.displayName = "FstIonSelect";
