import {IColumnFragment, IValueInstanceFragment} from "app/gql/graphqlSchema";
import {IonIcon, IonText} from "@ionic/react";
import {chatbubbleEllipsesOutline, warningOutline} from "ionicons/icons";
import {styled} from "styled-components";
import {isNil} from "lodash-es";
import {useRemoveOverrideControlCardValidationResult} from "app/employee/controlCard/actions/useRemoveOverrideControlCardValidationResult";
import {OverrideValidationResultDialog} from "app/employee/controlCard/input/OverrideValidationResultDialog";
import {useCallback, useRef} from "react";
import {FlexColumn} from "app/employee/flexUtils";
import {LinkButton} from "app/employee/LinkButton";
import {useTranslation} from "react-i18next";

interface DisplayValueInstanceError {
  valueInstance: IValueInstanceFragment;
  column: IColumnFragment;
  disabled: boolean;
}

const Wrapper = styled.div`
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

export const DisplayValueInstanceError = ({valueInstance, disabled, column}: DisplayValueInstanceError) => {
  const {validationResult} = valueInstance;
  switch (validationResult.__typename) {
    case "NoneValidationResult":
    case "ValidValidationResult":
      return null;
    case "ErrorValidationResult":
      return <OverrideValidation column={column} disabled={disabled} valueInstance={valueInstance} />;
    default:
      return null;
  }
};

const OverrideValidation = ({
  valueInstance,
  disabled,
  column
}: {
  valueInstance: IValueInstanceFragment;
  disabled: boolean;
  column: IColumnFragment;
}) => {
  const {validationResult, overriddenValidationResult} = valueInstance;

  const canOverride = !isNil(column.validation) && column.validation.canOverride;
  return (
    <>
      {!isNil(overriddenValidationResult) ? (
        <Wrapper>
          <IonText>
            <IonIcon icon={chatbubbleEllipsesOutline} /> {overriddenValidationResult.reason}{" "}
            {canOverride && <RemoveOverrideButton disabled={disabled} valueInstance={valueInstance} />}
          </IonText>
        </Wrapper>
      ) : (
        <Wrapper>
          <FlexColumn>
            {validationResult.__typename === "ErrorValidationResult" && !validationResult.initial && (
              <IonText color="danger">
                <IonIcon icon={warningOutline} /> {validationResult.message}
              </IonText>
            )}
            {canOverride && <OverrideButton disabled={disabled} valueInstance={valueInstance} />}
          </FlexColumn>
        </Wrapper>
      )}
    </>
  );
};

const OverrideButton = ({valueInstance, disabled}: {valueInstance: IValueInstanceFragment; disabled: boolean}) => {
  const overrideValidationResultDialogRef = useRef<HTMLIonModalElement>(null);
  const {t} = useTranslation();

  const showOverrideValidationResultDialog = useCallback(() => {
    overrideValidationResultDialogRef.current?.present();
  }, []);

  return (
    <div>
      <LinkButton disabled={disabled} onClick={showOverrideValidationResultDialog} type="button">
        {t("control-card.override-validation-result.mark-not-applicable", {defaultValue: "Отбележи като неприложимо"})}
      </LinkButton>
      <OverrideValidationResultDialog ref={overrideValidationResultDialogRef} valueInstance={valueInstance} />
    </div>
  );
};
const RemoveOverrideButton = ({
  valueInstance,
  disabled
}: {
  valueInstance: IValueInstanceFragment;
  disabled: boolean;
}) => {
  const {removeOverrideValidationResult} = useRemoveOverrideControlCardValidationResult(valueInstance);
  const {t} = useTranslation();
  return (
    <LinkButton disabled={disabled} onClick={removeOverrideValidationResult} type="button">
      {t("g.discard", {defaultValue: "Отмени"})}
    </LinkButton>
  );
};
