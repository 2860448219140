import {ISaveAnswer, ISyllabusQuestion, saveAnswer} from "app/employee/state/syllabus/mutations";
import {DeepReadonly} from "ts-essentials";
import {useAdminStore} from "app/state/admin";
import {IonItem, IonRadio, IonRadioGroup, RadioGroupChangeEventDetail} from "@ionic/react";
import {IonRadioGroupCustomEvent} from "@ionic/core/dist/types/components";
import {css, styled} from "styled-components";
import {useLoadingContext} from "app/employee/LoadingContext";

interface IProps {
  question: ISyllabusQuestion;
}

const highlightStyle = css`
  background-color: #fffadb;
`;

const HighlightableIonRadio = styled(IonRadio)<{$highlight: boolean}>`
  ${({$highlight}) => $highlight && highlightStyle}
`;

export const Answers = ({question}: DeepReadonly<IProps>) => {
  const {startLoading, finishLoading, showExceptionError, loading} = useLoadingContext();
  const {highlightAnswers} = useAdminStore((state) => state.features);

  const onAnswerChange = async (event: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail>) => {
    const saveAnswerArg: ISaveAnswer = {questionId: question.id, answerId: event.detail.value};
    try {
      startLoading();
      await saveAnswer(saveAnswerArg);
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  };

  return (
    <IonRadioGroup onIonChange={onAnswerChange} value={question?.employeeAnswer?.answerId}>
      {question.answers.map((answer) => {
        return (
          <IonItem className="ion-no-padding" key={answer.id}>
            <HighlightableIonRadio
              $highlight={highlightAnswers && answer.correct}
              disabled={loading}
              justify="start"
              labelPlacement="end"
              value={answer.id}
            >
              {answer.content}
            </HighlightableIonRadio>
          </IonItem>
        );
      })}
    </IonRadioGroup>
  );
};
