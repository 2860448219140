import {useEffect} from "react";
import {useNotifications} from "app/employee/notification/useNotifications";
import {usePollVisible} from "app/components/hooks/usePollVisible";

const refreshDurationMs = 1000 * 60 * 5;

export const useNotificationsPoll = () => {
  const {fetch, refetch} = useNotifications();

  const {isPageVisible} = usePollVisible(
    refetch,
    refreshDurationMs,
    "Setting up periodic refresh of employee notifications",
    "Stop periodic refresh of employee notifications",
    "Refreshing employee notifications"
  );

  useEffect(() => {
    if (isPageVisible) {
      fetch({});
    }
  }, [fetch, isPageVisible]);
};
