import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonViewWillEnter,
  useIonViewWillLeave
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {Trans, useTranslation} from "react-i18next";
import {useQuery} from "app/employee/controlCard/hook/useQuery";
import {
  GetManagerBroadcastDocument,
  IGetManagerBroadcastQuery,
  IManagerBroadcastId,
  MarkAsReadManagerBroadcastDocument
} from "app/gql/graphqlSchema";
import {LoadingLoadable} from "app/employee/Loading";
import {isNil} from "app/utils/stdlib";
import {
  useSafePathParams,
  useSafeQueryParams,
  zBroadcastDeliveryId,
  zBroadcastIdParams
} from "app/components/hooks/useSafeParams";
import {useEmployeeClientId} from "app/employee/state/bootstrapEmployee/selectors";
import {RichTextView} from "app/components/admin/rte/RichTextView";
import {checkmarkCircleOutline, closeOutline} from "ionicons/icons";
import {useBackNavigation} from "app/employee/hooks/useBackNavigation";
import {linkEmplNotifications} from "app/employee/core/employeeLinks";
import {query} from "app/gql/client";
import {useLoadingContext} from "app/employee/LoadingContext";
import {IonCardFooter} from "app/employee/controlCard/ControlCardUI";
import {Timestamp} from "app/employee/Timestamp";
import {markAsReadInAppNotification} from "app/employee/notification/utils";
import {useEffect} from "react";

const stateMapper = (n: IGetManagerBroadcastQuery) => n.getManagerBroadcast;
const useBroadcast = () => useQuery(GetManagerBroadcastDocument, stateMapper);

const markAsRead = (managerBroadcastId: IManagerBroadcastId) =>
  query(MarkAsReadManagerBroadcastDocument, {input: managerBroadcastId});

export const Broadcast = () => {
  const goBack = useBackNavigation(linkEmplNotifications());
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();

  const {t} = useTranslation();
  const {broadcastId} = useSafePathParams(zBroadcastIdParams);
  const {deliveryId} = useSafeQueryParams(zBroadcastDeliveryId);
  const clientId = useEmployeeClientId();
  const {data: broadcast, fetch, refetch} = useBroadcast();
  const [presentAlert, dismissAlert] = useIonAlert();

  useIonViewWillEnter(() => {
    fetch({
      input: {
        broadcastId,
        clientId
      }
    });
  }, [broadcastId, clientId, fetch]);

  useEffect(
    () => () => {
      dismissAlert();
    },
    [dismissAlert]
  );

  useIonViewWillLeave(() => {
    dismissAlert();
  }, [dismissAlert]);

  const handleMarkAsRead = async () => {
    try {
      startLoading();
      const promises: Promise<unknown>[] = [markAsRead({broadcastId, clientId})];
      if (!isNil(deliveryId)) {
        promises.push(markAsReadInAppNotification(deliveryId));
      }
      await Promise.all(promises);
      goBack();
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  };

  const handleMarkAsReadButton = async () => {
    await presentAlert({
      header: t("broadcast.mark-as-read-confirm.title"),
      buttons: [
        {text: t("g.no"), role: "cancel"},
        {text: t("g.yes"), handler: handleMarkAsRead}
      ]
    });
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{broadcast?.value?.subject}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <LoadingLoadable loadable={broadcast} />
        <IonRefresher onIonRefresh={refetch} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>
        {!isNil(broadcast?.value) && (
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{broadcast?.value?.subject}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <RichTextView html={broadcast.value?.htmlContent} />
            </IonCardContent>
            <IonCardFooter>
              <em>
                <Trans
                  components={{
                    Name: <strong>{broadcast.value?.creator.name}</strong>,
                    SentAt: <Timestamp ts={broadcast.value?.sentAt} />
                  }}
                  i18nKey="broadcast.from"
                  t={t}
                />
              </em>
            </IonCardFooter>
          </IonCard>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton color="primary" fill="outline" onClick={goBack}>
              <IonIcon icon={closeOutline} slot="start" />
              {t("g.close")}
            </IonButton>
            {}
            <IonButton color="primary" fill="solid" onClick={handleMarkAsReadButton}>
              <IonIcon icon={checkmarkCircleOutline} slot="start" />
              {t("broadcast.mark-as-read")}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
