import {useTranslation} from "react-i18next";
import {IOrganizerDocumentFragment, OrganizerDocumentState} from "app/gql/graphqlSchema";
import {IonBadge} from "@ionic/react";
import {UnreachableCaseError} from "ts-essentials";

export const StateBadge = ({document}: {document: IOrganizerDocumentFragment}) => {
  const {t} = useTranslation();
  switch (document.state) {
    case OrganizerDocumentState.Pending:
      return <IonBadge color="warning">{t("organizer.state.pending", {defaultValue: "В изчакване"})}</IonBadge>;
    case OrganizerDocumentState.Complete:
      return <IonBadge color="success">{t("organizer.state.complete", {defaultValue: "Завършен"})}</IonBadge>;
    default:
      throw new UnreachableCaseError(document.state);
  }
};
