import {IGraphQlDateTime, MaybeNil} from "app/utils/types";
import {DateTime} from "luxon";
import {isNil} from "app/utils/stdlib";
import {useTranslation} from "react-i18next";
import {ToRelativeUnit} from "luxon/src/datetime";

interface Props {
  ts: MaybeNil<IGraphQlDateTime>;
}

const getRelativeUnit = (ts: DateTime): ToRelativeUnit | undefined => {
  const now = DateTime.now();
  let diff = ts.diff(now, "minutes").minutes;
  if (diff < 0) {
    diff = -diff;
  }
  if (diff < 60) {
    return "minutes";
  } else if (diff < 24 * 60) {
    return "hours";
  } else if (diff < 7 * 24 * 60) {
    return "days";
  } else {
    return undefined;
  }
};

export const RelativeTimestamp = ({ts}: Props) => {
  const {i18n} = useTranslation();

  if (isNil(ts)) {
    return null;
  } else {
    const luxonTs = DateTime.fromISO(ts);
    const relTs = luxonTs.toRelativeCalendar({locale: i18n.language, unit: getRelativeUnit(luxonTs)});
    return <>{relTs}</>;
  }
};
