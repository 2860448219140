import {IonIcon, IonItem, IonLabel} from "@ionic/react";
import {trashOutline} from "ionicons/icons";
import {IOrganizerDocumentFragment} from "app/gql/graphqlSchema";
import {useDocumentDelete} from "app/employee/organizer/document/useDocumentDelete";

interface IProps {
  document: IOrganizerDocumentFragment;
  onBack: () => void | Promise<void>;
}

export const DeletableDocumentItem = ({document, onBack}: IProps) => {
  const handleOnDelete = useDocumentDelete(document, onBack);

  return (
    <IonItem button lines="full" onClick={handleOnDelete}>
      <IonIcon color="danger" icon={trashOutline} slot="start" />
      <IonLabel>{document.name}</IonLabel>
    </IonItem>
  );
};
