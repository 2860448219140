import {ElementOf} from "ts-essentials";
import {
  CreateSupplierDocument,
  GetSupplierDocument,
  ICreateSupplierInput,
  ISearchSuppliersQuery,
  ISupplierSearchPredicateInput,
  IUpdateSupplierInput,
  SearchSuppliersDocument,
  Sort,
  UpdateSupplierDocument
} from "app/gql/graphqlSchema";
import {IPageable} from "app/state/common/pageable";
import {ApiState, ILoadable, loadedOk, LOADING_STATE_NA} from "app/state/common/loadable";
import {createWithEqualityFn as zustandCreate} from "zustand/traditional";
import {immer} from "zustand/middleware/immer";
import {searchWrapper} from "app/state/searchWrapper";
import {query} from "app/gql/client";
import {getCurrentClientId} from "app/state/currentClient/mutations";
import {ID} from "app/utils/types";

export type ISearchResultSupplier = ElementOf<ISearchSuppliersQuery["searchSuppliers"]["results"]>;

export interface ISupplierState extends IPageable<ISearchResultSupplier, ISupplierSearchPredicateInput> {
  savingState: ApiState;
  supplier: ILoadable<ISearchResultSupplier>;
}

const initialState: ISupplierState = {
  list: LOADING_STATE_NA,
  predicate: {
    clientId: "",
    term: "",
    page: {
      number: 1,
      size: 10,
      order: [
        {
          name: "createdAt",
          sort: Sort.Desc
        }
      ]
    }
  },
  savingState: ApiState.NA,
  supplier: LOADING_STATE_NA
};

export const useSupplierStore = zustandCreate(
  immer(() => initialState),
  Object.is
);
export const reset = () => useSupplierStore.setState(initialState, true);

export const search = async () => {
  return searchWrapper(
    useSupplierStore,
    async (predicate) =>
      (
        await query(SearchSuppliersDocument, {
          input: {
            ...predicate,
            clientId: getCurrentClientId()
          }
        })
      ).searchSuppliers
  );
};

export const createSupplier = async (input: ICreateSupplierInput, signal?: AbortSignal) => {
  try {
    useSupplierStore.setState((state) => {
      state.savingState = ApiState.LOADING;
    });
    const resp = await query(CreateSupplierDocument, {input, signal});
    useSupplierStore.setState((state) => {
      state.savingState = ApiState.OK;
    });
    return resp.createSupplier;
  } catch (e) {
    useSupplierStore.setState((state) => {
      state.savingState = ApiState.ERROR;
    });
    throw e;
  }
};

export const updateSupplier = async (input: IUpdateSupplierInput, signal?: AbortSignal) => {
  try {
    useSupplierStore.setState((state) => {
      state.savingState = ApiState.LOADING;
    });
    const resp = await query(UpdateSupplierDocument, {input, signal});
    useSupplierStore.setState((state) => {
      state.savingState = ApiState.OK;
    });
    return resp.updateSupplier;
  } catch (e) {
    useSupplierStore.setState((state) => {
      state.savingState = ApiState.ERROR;
    });
    throw e;
  }
};

export const getSupplier = async (supplierId: ID, signal?: AbortSignal) => {
  try {
    useSupplierStore.setState((state) => {
      state.supplier.loadingState = ApiState.LOADING;
    });
    const resp = await query(GetSupplierDocument, {
      id: {
        clientId: getCurrentClientId(),
        supplierId
      },
      signal
    });
    useSupplierStore.setState((state) => {
      state.supplier = loadedOk(resp.getSupplier);
    });
  } catch (e) {
    useSupplierStore.setState((state) => {
      state.supplier.loadingState = ApiState.ERROR;
    });
    throw e;
  }
};

export const init = async () => {
  reset();
  await search();
};
