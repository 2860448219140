import {archiveIncomingDocument, ISearchResultIncomingDocument} from "app/employee/state/incdoc/mutations";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  useIonActionSheet,
  useIonAlert
} from "@ionic/react";
import {DateDisplay} from "app/employee/DateDisplay";
import {AttachmentCollection} from "app/employee/att/AttachmentCollection";
import {Trans, useTranslation} from "react-i18next";
import {
  closeOutline,
  ellipsisVerticalCircleOutline,
  lockClosedOutline,
  pencilOutline,
  trashOutline
} from "ionicons/icons";
import {useCallback} from "react";
import {DocumentNotes} from "app/employee/incdoc/DocumentNotes";
import {FlexColumn} from "app/employee/flexUtils";
import {EditContainer} from "app/employee/incdoc/EditContainer";
import {usePermissions} from "app/employee/hooks/usePermissions";
import {luxonToShortDate, parseAnyDate} from "app/utils/dates";

interface IContextMenuProps {
  onDelete: () => void | Promise<void>;
  onEdit: () => void | Promise<void>;
}

const useContextMenu = ({onDelete, onEdit}: IContextMenuProps) => {
  const {t} = useTranslation();
  const [presentActionSheet] = useIonActionSheet();
  const [presentConfirmation] = useIonAlert();
  const showOptions = useCallback(
    async () =>
      presentActionSheet({
        header: t("incdoc.action.title", {defaultValue: "Действия"}),

        buttons: [
          {
            text: t("incdoc.action.edit", {defaultValue: "Редактирай"}),
            icon: pencilOutline,
            handler: onEdit
          },
          {
            text: t("incdoc.action.delete", {defaultValue: "Изтрий"}),
            role: "destructive",
            icon: trashOutline,
            handler: async () => {
              await presentConfirmation({
                header: t("incdoc.action.confirm-delete.title", {
                  defaultValue: "Сигурни ли сте че искате да изтриете този документ?"
                }),
                buttons: [
                  {
                    text: t("g.cancel"),
                    role: "cancel"
                  },
                  {
                    text: t("g.delete", {defaultValue: "Изтрий"}),
                    role: "destructive",
                    handler: onDelete
                  }
                ]
              });
            }
          },
          {
            text: t("g.close"),
            icon: closeOutline,
            role: "cancel"
          }
        ]
      }),
    [onDelete, onEdit, presentActionSheet, presentConfirmation, t]
  );

  return {showOptions};
};

interface IProps {
  document: ISearchResultIncomingDocument;
  refresh: () => void | Promise<void>;
  editDocument: (document: ISearchResultIncomingDocument) => void | Promise<void>;
}

export const DocumentCard = ({document, refresh, editDocument}: IProps) => {
  const {t} = useTranslation();
  const {showOptions} = useContextMenu({
    onDelete: async () => {
      await archiveIncomingDocument(document.id);
      await refresh();
    },
    onEdit: () => {
      editDocument(document);
    }
  });

  const {canEditIncDoc} = usePermissions();

  const supplierName = document.supplier.name;
  const locationName = document.location?.name;
  const creatorName = document.creator.name;
  const createdAt = document.createdAt;
  const canEdit = canEditIncDoc(document);
  const name = `${document.documentNumber} от ${luxonToShortDate(parseAnyDate(document.documentDate))}`;
  return (
    <IonCard className="ion-no-margin">
      <EditContainer>
        <IonButton disabled={!canEdit} fill="clear" onClick={showOptions}>
          <IonIcon icon={canEdit ? ellipsisVerticalCircleOutline : lockClosedOutline} slot="icon-only" />
        </IonButton>
      </EditContainer>
      <IonCardHeader>
        <IonCardTitle>
          {t("incdoc.doc-no", {defaultValue: "№{{docNum}}", docNum: document.documentNumber})}
        </IonCardTitle>
        <IonCardSubtitle>
          <Trans i18nKey="incdoc.doc-subtitle" t={t}>
            От дата{" "}
            <strong>
              <DateDisplay dt={document.documentDate} />
            </strong>
            , доставчик <strong>{{supplierName}}</strong>, за обект <strong>{{locationName}}</strong>, създаден от{" "}
            <strong>{{creatorName}}</strong> на <DateDisplay dt={createdAt} />
          </Trans>
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <FlexColumn>
          <AttachmentCollection
            attCollection={document.attachmentCollection}
            canUpload={canEdit}
            name={name}
            onUploadCompleted={refresh}
          />
          <DocumentNotes notes={document.notes} />
        </FlexColumn>
      </IonCardContent>
    </IonCard>
  );
};
