import {ButtonAction} from "app/employee/login/Button";
import {Fragment} from "react";
import {CSSProp, styled} from "styled-components";

const DigitText = styled.div<{$disabled?: boolean; $filledIn?: boolean}>(({$disabled, $filledIn}) => {
  let conditionalStyles: CSSProp;
  if ($disabled) {
    conditionalStyles = {
      backgroundColor: "var(--fst-disabled-background-color)",
      color: "var(--fst-disabled-text-color)"
    };
  } else {
    if ($filledIn) {
      conditionalStyles = {
        color: "var(--fst-background-color)",
        backgroundColor: "var(--ion-text-color)"
      };
    } else {
      conditionalStyles = {
        // backgroundColor: theme.semanticColors.inputBackground
      };
    }
  }

  return {
    fontSize: "1.5rem",
    width: "2rem",
    height: "2rem",
    padding: "1.5rem 0",
    display: "flex",
    justifyContent: "center",
    // alignContent: "center",
    alignItems: "center",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "0.5rem",
    // padding: "0.2rem",
    borderColor: "var(--fst-border-color)",
    color: "var(--ion-text-color)",
    backgroundColor: "var(--fst-background-color)",
    userSelect: "none",
    ...conditionalStyles
  };
});

const Divider = styled.div`
  font-size: 1.5rem;
  height: 2rem;
  display: flex;
  padding: 1.5rem 0;
  justify-content: center;
  align-items: center;
  color: var(--ion-text-color);
  user-select: none;
`;

export type ButtonInput = Exclude<ButtonAction, "back">;
type ButtonInputValue = ButtonInput | "";

interface IProps {
  value: ButtonInput[];
  maxSize: number;
  disabled?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.2rem;
  justify-content: center;
`;

export const DigitInput = ({value, maxSize, disabled}: IProps) => {
  const paddedValue: ButtonInputValue[] = [...value];
  if (paddedValue.length < maxSize) {
    for (let i = paddedValue.length; i < maxSize; i++) {
      paddedValue.push("");
    }
  }
  return (
    <Container>
      {paddedValue.map((v, i) => {
        const divider: JSX.Element | null = i > 0 && i % 3 === 0 ? <Divider>-</Divider> : null;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={i}>
            {divider}
            <DigitText $disabled={disabled} $filledIn={v !== ""}>
              {v}
            </DigitText>
          </Fragment>
        );
      })}
    </Container>
  );
};
