import {
  redirectTo,
  RedirectType,
  refreshSyllabus,
  updateCurrentVideoProgress
} from "app/employee/state/syllabus/mutations";
import {IWatchCurrent, VideoPlayer} from "app/employee/video/VideoPlayer";
import {Duration} from "luxon";
import {isNotNil} from "app/utils/stdlib";
import {useCurrentAssignment} from "app/employee/state/syllabus/selectors";
import {noop} from "app/utils/types";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonRouter
} from "@ionic/react";
import {MenuButton} from "app/employee/menu/MenuButton";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {DURATION_ZERO, parseDurationOptional} from "app/utils/dates";
import {FlexColumnCenter} from "app/employee/flexUtils";
import {homeOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {linkEmplCourseHome} from "app/employee/core/employeeLinks";

const updateProgress = async (progress: Duration) => {
  await updateCurrentVideoProgress({
    progress: progress.toISO()
  });
};

export const WatchCurrentVideo = () => {
  const {t} = useTranslation();
  const inProgress = useCurrentAssignment();
  const ionRouter = useIonRouter();
  if (isNotNil(inProgress)) {
    const watchedLengthAsDuration = parseDurationOptional(inProgress.lesson.watchedLength);
    const playerControl: IWatchCurrent = {
      type: "current",
      updateProgress: inProgress.lesson.watchCompleted ? noop : updateProgress,
      fromTime: inProgress.lesson.watchCompleted ? DURATION_ZERO : watchedLengthAsDuration,
      watchedLength: watchedLengthAsDuration,
      handleReturn: () => {
        redirectTo(RedirectType.TEST);
        ionRouter.push(linkEmplCourseHome(), "back", "push");
      }
    };
    const videoLesson = inProgress.lesson.videoLesson;
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <IonTitle>{videoLesson.title}</IonTitle>
            <MenuButton />
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRefresher onIonRefresh={refreshSyllabus} slot="fixed">
            <IonRefresherContent />
          </IonRefresher>
          <VideoPlayer playerControl={playerControl} videoLesson={videoLesson} />
        </IonContent>
      </>
    );
  } else {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <IonTitle>{t("syllabus.completed.completed-course")}</IonTitle>
            <MenuButton />
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <h1 className="ion-text-center">{t("syllabus.completed.completed-course")}</h1>
          <FlexColumnCenter>
            <IonButton color="primary" routerDirection="back" routerLink={linkEmplCourseHome()}>
              <IonIcon icon={homeOutline} slot="start" />
              {t("g.back-to-home")}
            </IonButton>
          </FlexColumnCenter>
        </IonContent>
      </>
    );
  }
};
