import {styled} from "styled-components";

export const Table = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  margin: 0.5rem;
`;

export const CustomPanel = styled.div`
  border: 1px solid var(--fst-border-color);
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  margin: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const SummaryAdditionalRow = styled.div<{$color?: "completed" | "incomplete" | "not-applicable"}>`
  border-top: 1px solid var(--fst-border-color);
  padding: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
  ${({$color}) => $color && `background-color: var(--ion-color-${$color});`}
`;

export const Attachments = styled.div`
  overflow-x: auto;
  margin: 0.25rem 0;
`;
