import {styled} from "styled-components";

export const EditContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
`;
