import BigNumber from "bignumber.js";
import {includes, MaybeNil} from "app/utils/types";
import {useImmer} from "use-immer";
import {ReactNode, useEffect, useMemo, useRef} from "react";
import {styled} from "styled-components";
import {IonButton, IonIcon, IonText} from "@ionic/react";
import {backspaceOutline, enterOutline} from "ionicons/icons";
import {DEFAULT_ROUNDING_MODE, isNil} from "app/utils/stdlib";
import {DisplayNumberRange} from "app/employee/controlCard/display/DisplayNumberRange";
import {IControlCardNumberRangeFragment} from "app/gql/graphqlSchema";
import {rangeCheckFactory, rangeValidator} from "app/utils/range";
import {zBigNumber} from "app/utils/validator";
import {useTranslation} from "react-i18next";
import {valueToPresentation} from "app/employee/rhf/number";
import {useOnEvent} from "@fluentui/react-hooks";
import {useDocument} from "@fluentui/react";
import {freeze} from "immer";
import {UnreachableCaseError} from "ts-essentials";

interface IProps {
  value: MaybeNil<BigNumber>;
  precision: number;
  unit?: MaybeNil<string>;
  greenZoneRange?: MaybeNil<IControlCardNumberRangeFragment>;
  boundaryRange?: MaybeNil<IControlCardNumberRangeFragment>;
  onChange?: (newValue: BigNumber | null) => void | Promise<void>;
  title?: MaybeNil<ReactNode>;
}

const Key = styled(IonButton).attrs({shape: "round", size: "large"})`
  font-weight: bold;
`;

type KeypadDigit =
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "."
  | "-"
  | "backspace"
  | "enter"
  | "clear";
const allowedNumbers = freeze(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"] satisfies KeypadDigit[]);
const allowedKeys = freeze<KeypadDigit[]>([...allowedNumbers, ".", "-"]);

const Title = styled(IonText)`
  font-weight: bold;
  margin-top: 0.5rem;
  word-break: break-word;
  text-align: center;
`;

const ValidationErrorMessage = styled(IonText).attrs({color: "danger"})`
  word-break: break-word;
  text-align: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
`;

const Divider = styled.div`
  grid-column: span 3;
  height: 1rem;
  margin-top: -1rem;
  margin-bottom: -1rem;
  border-bottom: 1px solid var(--fst-border-color);
`;

const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const CurrentValue = styled(IonText)`
  font-size: 2rem;
  font-weight: bold;
  height: 4rem;
  font-variant-numeric: tabular-nums;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;
  border: 1px solid var(--fst-border-color);
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  overflow: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  &.in-range {
    background-color: #e8ffe7;
  }

  &.out-of-range {
    background-color: #ffe7e7;
  }
`;

export const NumberTypePad = ({value, precision, unit, greenZoneRange, onChange, title, boundaryRange}: IProps) => {
  const {t} = useTranslation();
  const initialValueRef = useRef(false);
  const [digits, setDigits] = useImmer<string[]>([]);
  const checkGreenZoneRange = useMemo(() => rangeCheckFactory(greenZoneRange), [greenZoneRange]);
  const checkBoundaryRange = useMemo(() => rangeValidator(boundaryRange), [boundaryRange]);

  useEffect(() => {
    if (value) {
      setDigits(value.toFormat(precision, DEFAULT_ROUNDING_MODE, {decimalSeparator: "."}).split(""));
    } else {
      setDigits([]);
    }
    initialValueRef.current = true;
  }, [precision, setDigits, value]);

  const [isValid, bnValue] = useMemo(() => {
    if (digits.length === 0) {
      return [true, null];
    }
    const parseResult = zBigNumber.safeParse(digits.join(""));
    if (parseResult.success) {
      const bnValue = parseResult.data;
      return [true, bnValue];
    } else {
      return [false, null];
    }
  }, [digits]);

  const boundaryRangeCheckResult = useMemo(() => checkBoundaryRange(bnValue), [bnValue, checkBoundaryRange]);
  const submitDisabled = !isValid || boundaryRangeCheckResult.maxExceeded || boundaryRangeCheckResult.minExceeded;
  const showDecimalDot = precision > 0;
  const showNegative = isNil(boundaryRangeCheckResult.minValue) || boundaryRangeCheckResult.minValue.isNegative();

  const handleKeyClick = async (digit: KeypadDigit) => {
    if (digit === "enter") {
      if (isValid && !submitDisabled) {
        await onChange?.(bnValue);
      }
      return;
    }
    if (initialValueRef.current && digit !== "backspace") {
      setDigits([]);
    }
    initialValueRef.current = false;

    setDigits((draft) => {
      if (digit === "-") {
        if (showNegative) {
          if (!draft.includes("-")) {
            draft.unshift("-");
          } else {
            draft.shift();
          }
        }
      } else if (digit === "backspace") {
        draft.pop();
      } else if (digit === "clear") {
        draft.splice(0, draft.length);
      } else {
        if (digit === ".") {
          if (showDecimalDot) {
            if (!draft.includes(".")) {
              if (draft.length === 0 || (draft.length === 1 && draft[0] === "-")) {
                draft.push("0");
              }
              draft.push(".");
            } else if (draft[draft.length - 1] === ".") {
              draft.pop();
            }
          }
        } else if (includes(allowedNumbers, digit)) {
          draft.push(digit);
        } else {
          throw new UnreachableCaseError(digit);
        }
      }
    });
  };

  const typeNumber: Document["onkeyup"] = async (e) => {
    const {code, key} = e;
    if (code === "Enter") {
      await handleKeyClick("enter");
    } else if (code === "Backspace" || code === "ArrowLeft") {
      await handleKeyClick("backspace");
    } else if (code === "Delete") {
      await handleKeyClick("clear");
    } else if (code === "Space" || code === "Comma") {
      await handleKeyClick(".");
    } else if (includes(allowedKeys, key)) {
      await handleKeyClick(key);
    }
  };
  const document = useDocument();
  useOnEvent(document, "keyup", typeNumber);

  const currentValueClassName = useMemo((): string | undefined => {
    if (isNil(bnValue)) {
      return undefined;
    } else {
      const greenZoneStatus = checkGreenZoneRange(bnValue);
      if (isNil(greenZoneStatus)) {
        return undefined;
      } else {
        return greenZoneStatus ? "in-range" : "out-of-range";
      }
    }
  }, [checkGreenZoneRange, bnValue]);

  const boundaryErrorMessage = useMemo(() => {
    const {minValue, minExceeded, maxValue, maxExceeded} = boundaryRangeCheckResult;
    if (minExceeded && !isNil(minValue)) {
      return t("control-card.number-range.min-exceeded", {
        minValue: valueToPresentation(minValue, unit, precision)
      });
    } else if (maxExceeded && !isNil(maxValue)) {
      return t("control-card.number-range.max-exceeded", {
        maxValue: valueToPresentation(maxValue, unit, precision)
      });
    } else {
      return null;
    }
  }, [boundaryRangeCheckResult, precision, t, unit]);

  return (
    <Container>
      <Title>
        {!isNil(title) && title}{" "}
        {!isNil(greenZoneRange) && (
          <>
            (<DisplayNumberRange range={greenZoneRange} unit={unit} />)
          </>
        )}
      </Title>
      {boundaryErrorMessage && <ValidationErrorMessage>{boundaryErrorMessage}</ValidationErrorMessage>}
      <CurrentValue className={currentValueClassName}>
        {digits.join("")} {unit}
      </CurrentValue>
      <Grid>
        <Key color="secondary" onClick={() => handleKeyClick("1")}>
          1
        </Key>
        <Key color="secondary" onClick={() => handleKeyClick("2")}>
          2
        </Key>
        <Key color="secondary" onClick={() => handleKeyClick("3")}>
          3
        </Key>
        <Key color="secondary" onClick={() => handleKeyClick("4")}>
          4
        </Key>
        <Key color="secondary" onClick={() => handleKeyClick("5")}>
          5
        </Key>
        <Key color="secondary" onClick={() => handleKeyClick("6")}>
          6
        </Key>
        <Key color="secondary" onClick={() => handleKeyClick("7")}>
          7
        </Key>
        <Key color="secondary" onClick={() => handleKeyClick("8")}>
          8
        </Key>
        <Key color="secondary" onClick={() => handleKeyClick("9")}>
          9
        </Key>
        {showNegative ? (
          <Key color="secondary" fill="outline" onClick={() => handleKeyClick("-")}>
            &mdash;
          </Key>
        ) : (
          <div />
        )}
        <Key color="secondary" onClick={() => handleKeyClick("0")}>
          0
        </Key>
        {showDecimalDot ? (
          <Key color="secondary" fill="outline" onClick={() => handleKeyClick(".")}>
            .
          </Key>
        ) : (
          <div />
        )}
        <Divider />
        <Key fill="outline" onClick={() => handleKeyClick("clear")}>
          C
        </Key>
        <Key disabled={submitDisabled} onClick={() => handleKeyClick("enter")}>
          <IonIcon icon={enterOutline} />
        </Key>
        <Key fill="outline" onClick={() => handleKeyClick("backspace")}>
          <IonIcon icon={backspaceOutline} />
        </Key>
      </Grid>
    </Container>
  );
};
