import {selectMaybeLoaded} from "app/state/common/loadable";
import {
  ISyllabusInProgressVideoLesson,
  ISyllabusQuestion,
  ISyllabusTermVideoLesson,
  useSyllabusStore
} from "app/employee/state/syllabus/mutations";
import {ID} from "app/utils/types";
import {IVideoLessonBaseFragment} from "app/gql/graphqlSchema";
import {assertNotNil, isNotNil} from "app/utils/stdlib";
import {completedVideoLessonPredicate} from "app/employee/syllabus/utils";
import {getInProgressVideoLesson} from "app/employee/state/syllabus/inProgressVideoLessons";
import {shallow} from "zustand/shallow";

export const useSyllabusTerms = () => {
  return useSyllabusStore((state) => selectMaybeLoaded(state.syllabus)?.termProgress.terms ?? []);
};

export const useSyllabusVideoLessons = <T extends ISyllabusTermVideoLesson>(
  predicate: (vl: ISyllabusTermVideoLesson) => vl is T
): T[] => {
  return useSyllabusTerms()
    .flatMap((t) => t.termPlans.flatMap((tp) => tp.lessons))
    .filter(predicate);
};

export const useTestResultState = () => {
  const currentAssignment = useCurrentAssignment();

  return {
    canDisplayTestResult:
      isNotNil(currentAssignment) &&
      currentAssignment.lesson.testProgress.totalCorrectAnswers <=
        currentAssignment.lesson.testProgress.totalEmployeeAnswers
  };
};

export const useTestState = () => {
  const currentAssignment = useCurrentAssignment();

  return {
    canDisplayTestResult: isNotNil(currentAssignment) && currentAssignment.lesson.watchCompleted
  };
};

export const useCompletedTest = () => {
  return useCurrentAssignment()?.lesson?.testResult;
};

export const useSyllabusLoadingState = () => {
  return useSyllabusStore(
    (state) => ({
      loadingState: state.syllabus.loadingState,
      lastRefreshMs: state.lastRefreshMs,
      appError: state.syllabus.appError
    }),
    shallow
  );
};

export const usePastAssignment = (assignmentId?: string) => {
  const completedVideoLessons = useSyllabusVideoLessons(completedVideoLessonPredicate);
  if (isNotNil(assignmentId)) {
    return completedVideoLessons.find((s) => s.assignmentId === assignmentId);
  } else {
    return undefined;
  }
};

export const useCurrentAssignment = () => {
  const terms = useSyllabusTerms();
  const inProgress = getInProgressVideoLesson(terms);
  if (isNotNil(inProgress)) {
    return inProgress;
  } else {
    return undefined;
  }
};

export interface IProgress {
  index: number;
  total: number;
  questionNumber: number;
}

export interface ITestProgress {
  available: true;
  prev?: ISyllabusQuestion;
  current: ISyllabusQuestion;
  next?: ISyllabusQuestion;
  progress: IProgress;
  videoLesson: IVideoLessonBaseFragment;
}

export type ISelectCurrentAssignmentQuestionReturn = {available: false} | ITestProgress;

export const testPage = (
  termPlanVideoLesson: ISyllabusInProgressVideoLesson | undefined,
  questionId: ID | undefined
): ISelectCurrentAssignmentQuestionReturn => {
  if (isNotNil(termPlanVideoLesson)) {
    const videoLesson = termPlanVideoLesson.videoLesson;
    const allQuestions = videoLesson?.test.questions ?? [];
    const questions = allQuestions.filter((q) => !q.employeeAnswer?.correct);
    if (questions.length === 0) {
      return {
        available: false
      };
    }
    assertNotNil(videoLesson);
    let maybeCurrentQuestionIndex: number;
    if (isNotNil(questionId) && questionId !== "") {
      maybeCurrentQuestionIndex = questions.findIndex((q) => q.id === questionId);
    } else {
      maybeCurrentQuestionIndex = 0;
    }

    if (maybeCurrentQuestionIndex < 0) {
      return {
        available: false
      };
    }
    const currentIndex = maybeCurrentQuestionIndex;
    const nextIndex = maybeCurrentQuestionIndex + 1;
    const prevIndex = maybeCurrentQuestionIndex - 1;
    const current = questions[currentIndex];
    const questionNumber = allQuestions.findIndex((q) => q.id === current.id);
    return {
      available: true,
      prev: prevIndex >= 0 ? questions[prevIndex] : undefined,
      current,
      next: nextIndex < questions.length ? questions[nextIndex] : undefined,
      progress: {
        questionNumber,
        index: currentIndex,
        total: questions.length
      },
      videoLesson
    };
  } else {
    return {
      available: false
    };
  }
};

export const useCurrentAssignmentQuestion = (questionId?: string) => {
  const currentAssignment = useCurrentAssignment();
  return testPage(currentAssignment?.lesson, questionId);
};

export interface IFailedQuestion {
  question: ISyllabusQuestion;
  overallIndex: number;
}

export const useTestQuestions = () => {
  const currentAssignment = useCurrentAssignment();
  if (currentAssignment?.lesson) {
    return currentAssignment?.lesson.videoLesson.test.questions;
  } else {
    return [];
  }
};

export const useIncorrectQuestions = () => {
  const questions = useTestQuestions();
  return questions.reduce<IFailedQuestion[]>((acc, question, index) => {
    if (!question.employeeAnswer?.correct) {
      acc.push({
        question,
        overallIndex: index
      });
    }
    return acc;
  }, []);
};

export const hasVideoAnswer = (q: ISyllabusQuestion) => isNotNil(q.answerStartPoint) && isNotNil(q.answerEndPoint);

export const mustWatchAgain = (q: ISyllabusQuestion) =>
  hasVideoAnswer(q) && (!q.employeeAnswer || (!q.employeeAnswer.correct && q.employeeAnswer.watchCount < 1));

export const useIsWatchedAllIncorrectQuestions = () => {
  const questions = useTestQuestions();
  return questions.every((q) => !mustWatchAgain(q));
};
