import {FlexRight, FlexRow} from "app/employee/flexUtils";
import {UnifiedAttachment} from "app/employee/att/UnifiedAttachment";
import {useTranslation} from "react-i18next";
import {
  IUnifiedAttachment,
  IUseAttachmentCollectionEditorReturn
} from "app/components/hooks/useAttachmentCollectionEditor";
import {styled} from "styled-components";
import {UploadButton} from "app/employee/att/UploadButton";

interface IProps {
  showFileUpload: IUseAttachmentCollectionEditorReturn["showFileUpload"];
  unifiedAttachments: IUnifiedAttachment[];
  disabled?: boolean;
}

const FlexList = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`;

const FlexListItem = styled.div`
  min-width: 75%;
`;

export const AttachmentCollectionEdit = ({showFileUpload, unifiedAttachments, disabled}: IProps) => {
  const {t} = useTranslation();
  return (
    <>
      <FlexRow>
        <div>
          {t("g.uploaded-files", {defaultValue: "Качени файлове: {{count}}", count: unifiedAttachments.length})}
        </div>
        <FlexRight>
          <UploadButton disabled={disabled} showFileUpload={showFileUpload} unifiedAttachments={unifiedAttachments} />
        </FlexRight>
      </FlexRow>
      <FlexList>
        {unifiedAttachments.map((unifiedAttachment) => (
          <FlexListItem key={unifiedAttachment.key}>
            <UnifiedAttachment disabled={disabled} unifiedAttachment={unifiedAttachment} />
          </FlexListItem>
        ))}
      </FlexList>
    </>
  );
};
