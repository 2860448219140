import {TFunction} from "i18next";
import {FILE_STATES} from "@rpldy/uploady";
import {IonBadge, IonButton, IonCard, IonCardContent, IonIcon, IonImg, IonProgressBar} from "@ionic/react";
import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import documentPlaceholder from "app/employee/att/document-placeholder.png";
import {FlexColumn, FlexColumnCenter} from "app/employee/flexUtils";
import {humanFileSize} from "app/utils/stdlib";
import {closeOutline} from "ionicons/icons";
import {IUnifiedAttachment} from "app/components/hooks/useAttachmentCollectionEditor";

const stateToBadge = (t: TFunction, state: FILE_STATES) => {
  switch (state) {
    case FILE_STATES.ADDED:
    case FILE_STATES.PENDING:
      return <IonBadge color="light">{t("incdoc.upload.pending", {defaultValue: "В изчакване"})}</IonBadge>;
    case FILE_STATES.UPLOADING:
      return <IonBadge color="primary">{t("incdoc.upload.uploading", {defaultValue: "Качва се"})}</IonBadge>;
    case FILE_STATES.FINISHED:
      return <IonBadge color="success">{t("incdoc.upload.finished", {defaultValue: "Качен"})}</IonBadge>;
    case FILE_STATES.CANCELLED:
    case FILE_STATES.ERROR:
    case FILE_STATES.ABORTED:
      return <IonBadge color="danger">{t("incdoc.upload.error", {defaultValue: "Грешка при качване"})}</IonBadge>;
    default:
      return null;
  }
};
export const UnifiedAttachment = ({
  unifiedAttachment,
  disabled
}: {
  unifiedAttachment: IUnifiedAttachment;
  disabled?: boolean;
}) => {
  const prevState = useRef(unifiedAttachment.state);
  const cardRef = useRef<HTMLIonCardElement | null>(null);
  useEffect(() => {
    if (prevState.current !== FILE_STATES.UPLOADING && unifiedAttachment.state === FILE_STATES.UPLOADING) {
      cardRef.current?.scrollIntoView({behavior: "smooth"});
    }
    prevState.current = unifiedAttachment.state;
  }, [unifiedAttachment.state]);

  const {t} = useTranslation();
  return (
    <IonCard ref={cardRef}>
      <IonImg src={unifiedAttachment.thumbnailUrl ?? unifiedAttachment.fullSizeUrl ?? documentPlaceholder} />
      <IonProgressBar type="determinate" value={unifiedAttachment.completed} />
      <IonCardContent>
        <FlexColumn>
          <div>{stateToBadge(t, unifiedAttachment.state)}</div>
          <div>
            {t("g.doc-file-name", {defaultValue: "Име на файла"})}: <strong>{unifiedAttachment.name}</strong>
          </div>
          <div>
            {t("g.doc-file-size", {defaultValue: "Размер"})}: <strong>{humanFileSize(unifiedAttachment.size)}</strong>
          </div>
        </FlexColumn>
      </IonCardContent>
      <FlexColumnCenter>
        <IonButton disabled={disabled} fill="clear" onClick={unifiedAttachment.handleRemove}>
          <IonIcon icon={closeOutline} slot="start" />
          {t("g.remove")}
        </IonButton>
      </FlexColumnCenter>
    </IonCard>
  );
};
