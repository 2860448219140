import {forwardRef, PropsWithChildren, useCallback, useImperativeHandle, useRef, useState} from "react";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {useAttachmentCollectionEditor} from "app/components/hooks/useAttachmentCollectionEditor";
import {AddEditOrgDoc} from "app/employee/organizer/modal/AddEditOrgDoc";
import {IOrganizerDocumentFragment, IOrganizerFragment} from "app/gql/graphqlSchema";
import {ID} from "app/utils/types";

export interface IOrgDocUploaderHandle {
  showFileUpload(defaults?: Partial<{folderId: ID}>): void;
  editDocument(document: IOrganizerDocumentFragment): Promise<void>;
}

interface IProps {
  onCompleted?: () => void;
  organizer: IOrganizerFragment;
}

export const OrgDocUploader = forwardRef<IOrgDocUploaderHandle, PropsWithChildren<IProps>>(
  ({onCompleted, organizer}, ref) => {
    const modal = useRef<HTMLIonModalElement>(null);
    const [editDocument, setEditDocument] = useState<IOrganizerDocumentFragment | null>(null);
    const [defaults, setDefaults] = useState<Partial<{folderId: ID}> | null>(null);

    const {showFileUpload, unifiedAttachments, uploadProgress, updateAttachments, cleanup} =
      useAttachmentCollectionEditor(editDocument?.attachmentCollection, () => modal.current?.present());

    const dismissDialog = useCallback(async () => {
      cleanup();
      setEditDocument(null);
      setDefaults(null);

      await modal.current?.dismiss();
      onCompleted?.();
    }, [cleanup, onCompleted, setEditDocument]);

    useImperativeHandle(
      ref,
      () => ({
        showFileUpload: (defaults) => {
          setDefaults(defaults ?? null);
          showFileUpload();
        },
        editDocument: async (document) => {
          setEditDocument(document);
          setDefaults(null);
          await modal.current?.present();
        }
      }),
      [setEditDocument, showFileUpload]
    );

    return (
      <FstIonModal onIonModalDidDismiss={dismissDialog} ref={modal}>
        <AddEditOrgDoc
          defaults={defaults}
          document={editDocument}
          organizer={organizer}
          showFileUpload={showFileUpload}
          unifiedAttachments={unifiedAttachments}
          updateAttachments={updateAttachments}
          uploadProgress={uploadProgress}
        />
      </FstIonModal>
    );
  }
);

OrgDocUploader.displayName = "DocumentUploader";
