import {PropsWithChildren} from "react";
import {styled} from "styled-components";
import {IOptionalLinkProps, OptionalLink} from "app/employee/syllabus/OptionalLink";

interface IProps extends IOptionalLinkProps {
  overlay: JSX.Element;
  dark?: boolean;
}

const Container = styled.div`
  position: relative;
`;

const Overlay = styled.div<{$dark?: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, ${(props) => (props.$dark ? 0.5 : 0.2)});
  color: rgba(255, 255, 255, 0.85);
  > {
    ion-icon {
      filter: drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.2)) drop-shadow(-1px 0px 1px rgba(0, 0, 0, 0.2))
        drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.2));
    }

    a {
      color: rgba(255, 255, 255, 0.85);
    }
  }
`;

export const ImageOverlay = ({children, overlay, ...linkProps}: PropsWithChildren<IProps>) => {
  return (
    <Container>
      {children}
      {}
      <OptionalLink {...linkProps}>
        <Overlay $dark={linkProps.disabled}>{overlay}</Overlay>
      </OptionalLink>
    </Container>
  );
};
