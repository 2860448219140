import {styled} from "styled-components";
import {IonCard} from "@ionic/react";

const NotesWrapper = styled(IonCard)`
  padding: 0.5rem;
  margin: 0;
  white-space: pre-wrap;
  background-color: #fcfcfc;
`;

export const DocumentNotes = ({notes}: {notes: string}) => {
  if (notes === "") {
    return null;
  } else {
    return <NotesWrapper>{notes}</NotesWrapper>;
  }
};
