import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {useTranslation} from "react-i18next";
import {checkmarkCircleOutline, closeOutline} from "ionicons/icons";
import {linkEmplOrganizer} from "app/employee/core/employeeLinks";
import {useEffect} from "react";
import {isNil} from "app/utils/stdlib";
import {useDismiss} from "app/employee/incdoc/useDismiss";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {RhfIonInput} from "app/employee/rhf/RhfIonInput";
import {parseErrorResult} from "app/utils/parseErrorResult";
import {RhfRequiredLabel} from "app/employee/incdoc/RequiredLabel";
import {useLoadingContext} from "app/employee/LoadingContext";
import {zNormalizedString} from "app/utils/validator";
import {t} from "i18next";
import {query} from "app/gql/client";
import {
  CreateOrganizerFolderDocument,
  IOrganizerFolderFragment,
  IOrganizerId,
  UpdateOrganizerFolderDocument
} from "app/gql/graphqlSchema";
import {MaybeNil} from "app/utils/types";
import {ListHeaderErrorMessage} from "app/employee/rhf/GlobalErrorMessage";

const formSchema = z.object({
  name: zNormalizedString.pipe(z.string().min(1, t("g.required")))
});

type IFormData = z.infer<typeof formSchema>;

interface IProps {
  organizerId: IOrganizerId;
  folder?: MaybeNil<IOrganizerFolderFragment>;
  defaults?: Partial<IOrganizerFolderFragment>;
}

export const AddEditFolder = ({defaults, folder, organizerId}: IProps) => {
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();
  const {t} = useTranslation();

  const backHandler = useDismiss(linkEmplOrganizer());

  const {
    control,
    handleSubmit,
    watch,
    formState: {isSubmitting, errors},
    setError,
    setValue,
    reset
  } = useForm<IFormData>({
    defaultValues: {
      name: defaults?.name ?? ""
    },
    resolver: zodResolver(formSchema)
  });

  useEffect(() => {
    if (folder) {
      setValue("name", folder.name);
    } else {
      reset();
    }
  }, [setValue, folder, reset]);

  const onSaveHandler = async (form: IFormData) => {
    startLoading();
    try {
      if (folder) {
        const updateResult = await query(UpdateOrganizerFolderDocument, {
          input: {name: form.name, folderId: folder.id}
        });
        if (updateResult.updateOrganizerFolder.__typename === "ErrorResult") {
          parseErrorResult(updateResult.updateOrganizerFolder, setError);
        } else {
          await backHandler(updateResult.updateOrganizerFolder.folder);
        }
      } else {
        const createResult = await query(CreateOrganizerFolderDocument, {input: {name: form.name, organizerId}});
        if (createResult.createOrganizerFolder.__typename === "ErrorResult") {
          parseErrorResult(createResult.createOrganizerFolder, setError);
        } else {
          await backHandler(createResult.createOrganizerFolder.folder);
        }
      }
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  };

  const title = isNil(folder)
    ? t("organizer.folder.add-title", {defaultValue: "Добавяне на папка"})
    : t("organizer.folder.edit-title", {defaultValue: "Редактиране на папка"});

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{title}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <ListHeaderErrorMessage errors={errors} />
          <IonItem>
            <RhfIonInput
              inputMode="text"
              labelPlacement="stacked"
              placeholder={t("organizer.folder.name-placeholder", {defaultValue: "Въведете името на папката"})}
              required
              rhf={{control, name: "name"}}
            >
              <div slot="label">
                {t("organizer.folder.name", {defaultValue: "Име"})}{" "}
                <RhfRequiredLabel errors={errors} field="name" watch={watch} />
              </div>
            </RhfIonInput>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton color="primary" fill="outline" onClick={() => backHandler(null)}>
              <IonIcon icon={closeOutline} slot="start" />
              {t("g.cancel", {defaultValue: "Откажи"})}
            </IonButton>
            <IonButton color="primary" disabled={isSubmitting} fill="solid" onClick={handleSubmit(onSaveHandler)}>
              <IonIcon icon={checkmarkCircleOutline} slot="start" />
              {t("g.save", {defaultValue: "Запази"})}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
