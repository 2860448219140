import {ILocation} from "app/gql/graphqlSchema";
import {isNotBlank} from "app/utils/stdlib";

export const getLocationText = (location: Pick<ILocation, "name" | "address">) => {
  let text = location.name;
  if (isNotBlank(location.address)) {
    text += ` (${location.address})`;
  }
  return text;
};
