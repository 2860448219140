import {hofForwardRef, noop, RhfProps, RhfRef} from "app/utils/types";
import {FieldPath, FieldValues, useController} from "react-hook-form";
import {ForwardedRef, RefCallback, useCallback, useImperativeHandle, useRef} from "react";
import {assignRef, defaultScrollIntoView} from "app/utils/stdlib";
import {FstIonSelect, IFstIonSelectProps} from "app/employee/core/FstIonSelect";

const extraClassNames = "ion-invalid ion-touched";

const reportValidity = () => true;

export const RhfFstIonSelect = hofForwardRef(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    {
      rhf,
      className,
      disabled,
      ...rest
    }: Omit<IFstIonSelectProps, "value" | "onIonChange"> & RhfProps<TFieldValues, TName>,
    ref: ForwardedRef<HTMLIonSelectElement>
  ) => {
    const {
      field: {onChange, ref: fieldRef, disabled: rhfDisabled, ...restField},
      fieldState: {error},
      formState: {isSubmitting}
    } = useController(rhf);

    const classNames = [];
    if (className) {
      classNames.push(className);
    }

    if (error) {
      classNames.push(extraClassNames);
    }

    const rhfRef = useRef<RhfRef | null>(null);
    const inputRef: RefCallback<HTMLIonSelectElement> = useCallback(
      (element) => {
        assignRef(ref, element);
        rhfRef.current = {
          focus: () => element?.scrollIntoView(defaultScrollIntoView),
          select: () => noop,
          setCustomValidity: noop,
          reportValidity
        };
      },
      [ref]
    );
    useImperativeHandle(fieldRef, () => rhfRef.current);

    return (
      <FstIonSelect
        ref={inputRef}
        {...rest}
        {...restField}
        className={classNames.join(" ")}
        disabled={isSubmitting || disabled || rhfDisabled}
        onIonChange={(event) => onChange(event.detail.value)}
      />
    );
  }
);
