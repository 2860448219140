import {Trans, useTranslation} from "react-i18next";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  useIonAlert,
  useIonRouter,
  useIonViewWillLeave
} from "@ionic/react";
import {assertNotNil, isNil} from "app/utils/stdlib";
import {linkEmplControlCardInstance} from "app/employee/core/employeeLinks";
import {addOutline, cloudOutline, filterOutline, reloadOutline} from "ionicons/icons";
import {Fragment, useMemo, useRef} from "react";
import {
  CompleteStatus,
  IControlCardInstanceFullFragment,
  IControlCardTemplateDeckFragment,
  ILocationControlCardDeckFragment
} from "app/gql/graphqlSchema";
import {DateDisplay} from "app/employee/DateDisplay";
import {RelativeDateDisplay} from "app/employee/RelativeDateDisplay";
import {styled} from "styled-components";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {InstantiateTemplate} from "app/employee/controlCard/location/InstantiateTemplate";
import {RecurringFilter} from "app/employee/controlCard/location/RecurringFilter";
import {useLocationDeckFilterContext} from "app/employee/controlCard/location/useLocationDeckFilter";
import {dateApiToLuxon, dateLuxonToApi} from "app/utils/dates";
import {cardCrossedIcon} from "app/utils/icons/ionicFixedIcons";
import {IIonItem} from "app/utils/types";
import {usePermissions} from "app/employee/hooks/usePermissions";
import {DateTime} from "luxon";

const DateLabel = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-right: 1rem;
  gap: 0.5rem;
  align-items: center;
  font-size: 1rem;

  & > :last-child {
    font-size: 0.8rem;
  }
`;

const useFilterCount = () => {
  const {filter} = useLocationDeckFilterContext();
  assertNotNil(filter);

  return useMemo(() => {
    if (isNil(filter)) {
      return 0;
    } else {
      let filterCount = 0;
      if (!isNil(filter.recurringDateRange.start)) {
        filterCount++;
      }
      if (!isNil(filter.recurringDateRange.end)) {
        filterCount++;
      }
      if (!isNil(filter.query)) {
        filterCount++;
      }
      if (!isNil(filter.recurringTemplateIds) && filter.recurringTemplateIds.length > 0) {
        filterCount++;
      }
      if (filter.completeStatus !== CompleteStatus.All) {
        filterCount++;
      }
      return filterCount;
    }
  }, [filter]);
};

const LoadMoreButton = styled.div`
  display: flex;
  justify-content: center;
`;

interface IProps {
  deck: ILocationControlCardDeckFragment;
}

export const RecurringLocationControlCards = ({deck}: IProps) => {
  const {t} = useTranslation();
  const {canEditControlCardLocation} = usePermissions();
  const {filter, setFilter} = useLocationDeckFilterContext();
  assertNotNil(filter);
  const {locationId} = filter;

  const [presentAlert, dismissAlert] = useIonAlert();
  useIonViewWillLeave(() => {
    dismissAlert();
  }, [dismissAlert]);

  const instantiateRef = useRef<HTMLIonModalElement | null>(null);
  const filterRef = useRef<HTMLIonModalElement | null>(null);

  const {push} = useIonRouter();
  const {byDateDeck, recurringTemplates} = deck;

  const templates = useMemo(() => {
    const templates: IControlCardTemplateDeckFragment[] = [];
    for (const {template} of recurringTemplates) {
      if (!isNil(template)) {
        templates.push(template);
      }
    }
    return templates;
  }, [recurringTemplates]);

  const instantiateTemplate = async () => {
    await instantiateRef.current?.present();
  };

  const openFilter = async () => {
    await filterRef.current?.present();
  };

  const handleCreateTemplate = (newInstance: IControlCardInstanceFullFragment) => {
    push(linkEmplControlCardInstance(newInstance.id.instanceId), "forward");
  };

  const handleApplyFilter = () => filterRef.current?.dismiss();

  const count = useFilterCount();

  const loadMoreDate = useMemo(() => {
    if (isNil(filter.recurringDateRange.start)) {
      return null;
    } else {
      const currentStart = dateApiToLuxon(filter.recurringDateRange.start);
      return dateLuxonToApi(currentStart.minus({month: 1}));
    }
  }, [filter.recurringDateRange.start]);

  const handleLoadMore = () => {
    setFilter((draft) => {
      assertNotNil(draft);
      draft.recurringDateRange.start = loadMoreDate;
    });
  };

  const canShowCreateButton = canEditControlCardLocation(locationId, dateLuxonToApi(DateTime.now()));
  return (
    <>
      <IonList>
        {canShowCreateButton && (
          <IonItem color="highlight" lines="full">
            <IonLabel>{t("control-card.location.recurring.create.title")}</IonLabel>
            <IonButton onClick={instantiateTemplate} slot="end">
              <IonIcon icon={addOutline} slot="start" />
              <IonLabel>{t("control-card.location.create-new")}</IonLabel>
            </IonButton>
          </IonItem>
        )}
        <IonItem color="highlight" lines="full">
          <IonLabel>{t("control-card.location.recurring.filter.label")}</IonLabel>

          <IonButton onClick={openFilter} slot="end">
            <IonIcon icon={filterOutline} slot="start" />
            {t("g.filter-button", {count})}
          </IonButton>
        </IonItem>
        {byDateDeck.length === 0 ? (
          <IonListHeader>
            <IonLabel>{t("control-card.location.no-control-cards")}</IonLabel>
          </IonListHeader>
        ) : (
          <IonListHeader>
            <IonLabel>{t("control-card.location.title")}</IonLabel>
          </IonListHeader>
        )}

        {byDateDeck.map(({date, cards: deckCards}) => (
          <Fragment key={date}>
            <IonListHeader>
              <IonLabel>
                <DateLabel>
                  <span>
                    <DateDisplay dt={date} />
                  </span>
                  <span>
                    <RelativeDateDisplay dt={date} />
                  </span>
                </DateLabel>
              </IonLabel>
            </IonListHeader>
            {deckCards.map((deckCard) => {
              const {instance, template} = deckCard;
              if (isNil(instance)) {
                return null;
              }

              const isArchived = isNil(template);
              let itemProps: IIonItem;

              if (!isNil(instance.notApplicableBy)) {
                itemProps = {
                  onClick: async () => {
                    await presentAlert({
                      header: t("control-card.location.not-applicable.header"),
                      message: t("control-card.location.not-applicable.message", {
                        notApplicableBy: instance.notApplicableBy?.name,
                        notApplicableReason: instance.notApplicableReason
                      }),
                      buttons: [{role: "cancel", text: t("g.close")}]
                    });
                  },
                  color: "not-applicable",
                  button: true,
                  detail: true,
                  detailIcon: cardCrossedIcon
                };
              } else if (!isNil(instance.completedBy)) {
                itemProps = {
                  routerLink: linkEmplControlCardInstance(instance.instanceId.instanceId),
                  routerDirection: "forward",
                  detail: true,
                  color: "completed"
                };
              } else {
                itemProps = {
                  routerLink: linkEmplControlCardInstance(instance.instanceId.instanceId),
                  routerDirection: "forward",
                  detail: true,
                  color: "incomplete"
                };
              }

              return (
                <IonItem key={instance.instanceId.instanceId} lines="full" {...itemProps}>
                  <IonLabel>{instance.name}</IonLabel>
                  {isArchived && <IonIcon icon={cloudOutline} slot="end" />}
                </IonItem>
              );
            })}
          </Fragment>
        ))}

        {!isNil(loadMoreDate) && (
          <>
            <IonListHeader>
              <IonLabel>
                <DateLabel>
                  <span>
                    <DateDisplay dt={filter.recurringDateRange.start} />
                  </span>
                  <span>
                    <RelativeDateDisplay dt={filter.recurringDateRange.start} />
                  </span>
                </DateLabel>
              </IonLabel>
            </IonListHeader>
            <IonItem lines="none">
              <IonLabel>
                <LoadMoreButton>
                  <IonButton onClick={handleLoadMore}>
                    <IonIcon icon={reloadOutline} slot="start" />
                    <Trans
                      components={{
                        StartDate: <DateDisplay dt={filter.recurringDateRange.start} />
                      }}
                      i18nKey="control-card.location.recurring.load-more"
                      t={t}
                    />
                  </IonButton>
                </LoadMoreButton>
              </IonLabel>
            </IonItem>
          </>
        )}
      </IonList>
      <FstIonModal ref={instantiateRef}>
        <InstantiateTemplate locationId={locationId} onCreate={handleCreateTemplate} templates={templates} />
      </FstIonModal>
      <FstIonModal ref={filterRef}>
        <RecurringFilter onApply={handleApplyFilter} templates={templates} />
      </FstIonModal>
    </>
  );
};
