import {IOrganizerDocumentFragment, OrganizerDocumentState} from "app/gql/graphqlSchema";
import {IonIcon} from "@ionic/react";
import {checkmarkCircleOutline, warningOutline} from "ionicons/icons";

export const DocumentIcon = ({document}: {document: IOrganizerDocumentFragment}) => {
  if (document.expired || document.expiresSoon || document.state !== OrganizerDocumentState.Complete) {
    const color = document.expired ? "danger" : "warning";
    return <IonIcon color={color} icon={warningOutline} />;
  } else {
    return <IonIcon color="success" icon={checkmarkCircleOutline} />;
  }
};
