import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {Trans, useTranslation} from "react-i18next";
import {INotificationFragment} from "app/gql/graphqlSchema";
import {LoadingLoadable} from "app/employee/Loading";
import {ComponentProps, ReactNode, useCallback, useMemo} from "react";
import {isNil} from "app/utils/stdlib";
import {css, styled} from "styled-components";
import {Timestamp} from "app/employee/Timestamp";
import {DateTime} from "luxon";
import {DateDisplay} from "app/employee/DateDisplay";
import {linkEmplBroadcast, linkEmplOrganizer} from "app/employee/core/employeeLinks";
import {parseAnyDate} from "app/utils/dates";
import {markAsReadInAppNotificationAndReload} from "app/employee/notification/utils";
import {Updater} from "use-immer";
import {TitleRow} from "app/employee/incdoc/TitleRow";
import {useNotifications} from "app/employee/notification/useNotifications";

const DisplayNotificationContent = ({notification}: {notification: INotificationFragment}) => {
  return <>{notification.content.text}</>;
};

const unreadCss = css`
  font-weight: bold;
`;

const NotificationIonItem = styled(IonItem)<{$unread?: boolean}>`
  ${({$unread}) => $unread && unreadCss}
`;

const ReadAt = styled.p`
  && {
    margin-top: 0.5rem;
  }
`;

type INotificationIonItem = ComponentProps<typeof NotificationIonItem>;

const NotificationItem = ({
  notification,
  setNotifications
}: {
  notification: INotificationFragment;
  setNotifications: Updater<INotificationFragment[]>;
}) => {
  const {t} = useTranslation();
  const isUnread = isNil(notification.readAt);
  const handleMarkAsRead = useCallback(async () => {
    if (isUnread) {
      const result = await markAsReadInAppNotificationAndReload(notification.deliveryId);
      const notifications = result.markInAppNotificationAsRead.inAppNotifications;
      setNotifications(notifications);
    }
  }, [isUnread, notification.deliveryId, setNotifications]);

  const itemProps: INotificationIonItem = {
    $unread: isUnread
  };

  switch (notification.__typename) {
    case "ManagerBroadcastInAppNotification":
      itemProps.detail = true;
      itemProps.routerLink = linkEmplBroadcast(notification.managerBroadcastId.broadcastId, notification.deliveryId);
      break;
    case "TextInAppNotification":
      itemProps.onClick = handleMarkAsRead;
      break;
    case "OrgDocInAppNotification":
      itemProps.routerLink = linkEmplOrganizer(notification.orgDoc.location.id.locationId);
      itemProps.routerDirection = "root";
      itemProps.onClick = handleMarkAsRead;
      break;
  }
  return (
    <NotificationIonItem {...itemProps}>
      <IonLabel>
        <DisplayNotificationContent notification={notification} />
        {!isUnread && (
          <ReadAt>
            <Trans
              components={{
                ReadAtTime: <Timestamp ts={notification.readAt} />
              }}
              i18nKey="notifications.read-at"
              t={t}
            />
          </ReadAt>
        )}
      </IonLabel>
    </NotificationIonItem>
  );
};

export const Notifications = () => {
  const {t} = useTranslation();
  const {data: notifications, fetch, refetch, setData} = useNotifications();

  const unreadCount = useMemo(() => {
    return notifications.value?.filter((n) => isNil(n.readAt)).length;
  }, [notifications.value]);

  useIonViewWillEnter(() => {
    fetch({});
  }, [fetch]);

  let lastSentAt: DateTime | null = null;

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{t("notifications.title", {count: unreadCount ?? 0})}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <LoadingLoadable loadable={notifications} />
        <IonRefresher onIonRefresh={refetch} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>

        {!isNil(notifications.value) && notifications.value.length === 0 && (
          <TitleRow className="ion-padding-horizontal">
            <h1>{t("notifications.no-notifications")}</h1>
          </TitleRow>
        )}
        <IonList>
          {notifications.value?.map((n) => {
            let dateComponent: ReactNode | null = null;
            const sentAtLuxon = parseAnyDate(n.sentAt);
            if (isNil(lastSentAt) || !sentAtLuxon.hasSame(lastSentAt, "day")) {
              dateComponent = (
                <IonListHeader key={n.sentAt}>
                  <IonLabel>
                    <DateDisplay dt={sentAtLuxon} />
                  </IonLabel>
                </IonListHeader>
              );
            }
            lastSentAt = sentAtLuxon;

            return [dateComponent, <NotificationItem key={n.deliveryId} notification={n} setNotifications={setData} />];
          })}
        </IonList>
      </IonContent>
    </>
  );
};
