import {IonButton, IonIcon, IonLabel, IonProgressBar} from "@ionic/react";
import {arrowBackCircleOutline, arrowForwardCircleOutline} from "ionicons/icons";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {linkEmplControlCardInstance} from "app/employee/core/employeeLinks";
import {useTranslation} from "react-i18next";
import {FlexRow} from "app/employee/flexUtils";
import {styled} from "styled-components";
import {IIonButton} from "app/utils/types";

const RecordPaging = styled.div`
  margin-left: auto;
`;

export const ControlCardRowNavigation = () => {
  const {t} = useTranslation();
  const {instance, rowId} = useControlCardInstanceContext();

  const rowIndex = instance.rows.findIndex((r) => r.rowId === rowId);
  let prevLinkProps: IIonButton;
  if (rowIndex > 0) {
    prevLinkProps = {
      routerLink: linkEmplControlCardInstance(instance.id.instanceId, instance.rows[rowIndex - 1].rowId),
      routerDirection: "forward"
    };
  } else {
    prevLinkProps = {disabled: true};
  }
  let nextLinkProps: IIonButton;
  if (rowIndex < instance.rows.length - 1) {
    nextLinkProps = {
      routerLink: linkEmplControlCardInstance(instance.id.instanceId, instance.rows[rowIndex + 1].rowId),
      routerDirection: "forward"
    };
  } else {
    nextLinkProps = {disabled: true};
  }

  return (
    <div className="ion-margin-horizontal">
      <FlexRow>
        <div>
          <IonLabel>{t("control-card.breadcrumbs.row", {current: rowIndex + 1, total: instance.rows.length})}</IonLabel>
          <IonProgressBar value={(rowIndex + 1) / instance.rows.length} />
        </div>
        <RecordPaging>
          <IonButton fill="outline" {...prevLinkProps}>
            <IonIcon icon={arrowBackCircleOutline} slot="icon-only" />
          </IonButton>
          <IonButton fill="outline" {...nextLinkProps}>
            <IonIcon icon={arrowForwardCircleOutline} slot="icon-only" />
          </IonButton>
        </RecordPaging>
      </FlexRow>
    </div>
  );
};
