import {FieldErrors, FieldValues} from "react-hook-form";
import {isNil} from "app/utils/stdlib";
import {IonText} from "@ionic/react";
import {styled} from "styled-components";
import {useTranslation} from "react-i18next";
import {MaybeNil} from "app/utils/types";
import {IApplicationError} from "app/gql/handleGraphqlErrors";
import {getErrorMessage} from "app/utils/parseErrorResult";

const Wrapper = styled.div`
  margin-bottom: 1rem;
  font-size: 1rem;
`;

export const DisplayRootError = <T extends FieldValues>({errors}: {errors: FieldErrors<T>}) => {
  if (!isNil(errors.root)) {
    return <DisplayErrorMessage message={errors.root.message} />;
  }
  return null;
};

export const DisplayAppError = ({appError}: {appError: MaybeNil<IApplicationError>}) => {
  if (!isNil(appError)) {
    const message = getErrorMessage(appError);
    return <DisplayErrorMessage message={message} />;
  }
  return null;
};

const DisplayErrorMessage = ({message}: {message: MaybeNil<string>}) => {
  const {t} = useTranslation();

  return (
    <IonText color="danger">
      <Wrapper>{t("control-card.error.saving-error", {message})}</Wrapper>
    </IonText>
  );
};
