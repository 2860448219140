import {createContext, forwardRef, PropsWithChildren, useCallback, useContext, useMemo} from "react";
import {IonModal, useIonViewWillLeave} from "@ionic/react";
import {useBoolean} from "@fluentui/react-hooks";
import {IIonModal} from "app/utils/types";

interface IModalContext {
  isOpen: boolean;
  dismiss: HTMLIonModalElement["dismiss"];
}

const ModalContext = createContext<IModalContext | null>(null);

export const useModalContext = () => useContext(ModalContext);

export const FstIonModal = forwardRef<HTMLIonModalElement, PropsWithChildren<Omit<IIonModal, "isOpen">>>(
  ({children, onIonModalDidDismiss, onIonModalWillPresent, ...modalProps}, modalRef) => {
    const [isOpen, {setFalse: hideModal, setTrue: showModal}] = useBoolean(false);

    const refValue = useMemo(
      () => ({
        isOpen,
        dismiss: (data?: unknown, role?: string) =>
          (typeof modalRef === "object" ? modalRef?.current?.dismiss(data, role) : null) ?? Promise.resolve(true)
      }),
      [modalRef, isOpen]
    );

    const onIonModalDidDismissInner: NonNullable<IIonModal["onIonModalDidDismiss"]> = useCallback(
      (event) => {
        hideModal();
        onIonModalDidDismiss?.(event);
      },
      [hideModal, onIonModalDidDismiss]
    );

    const onIonModalWillPresentInner: NonNullable<IIonModal["onIonModalWillPresent"]> = useCallback(
      (event) => {
        showModal();
        onIonModalWillPresent?.(event);
      },
      [showModal, onIonModalWillPresent]
    );

    useIonViewWillLeave(() => {
      refValue.dismiss();
    }, [refValue]);

    return (
      <IonModal
        isOpen={isOpen}
        onIonModalDidDismiss={onIonModalDidDismissInner}
        onIonModalWillPresent={onIonModalWillPresentInner}
        ref={modalRef}
        {...modalProps}
      >
        <ModalContext.Provider value={refValue}>{children}</ModalContext.Provider>
      </IonModal>
    );
  }
);

FstIonModal.displayName = "FstIonModal";
