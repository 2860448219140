import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
  IonRadio,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {ID, MaybeNil} from "app/utils/types";
import {useCallback, useEffect, useRef, useState} from "react";
import {checkmarkCircleOutline, closeOutline} from "ionicons/icons";
import {assertNotNil, fractionToPercent, isNil} from "app/utils/stdlib";
import {styled} from "styled-components";
import {RhfRequiredLabel} from "app/employee/incdoc/RequiredLabel";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {useDismiss} from "app/employee/incdoc/useDismiss";
import {linkEmplOrganizer} from "app/employee/core/employeeLinks";
import {MenuButton} from "app/employee/menu/MenuButton";
import {IUseAttachmentCollectionEditorReturn} from "app/components/hooks/useAttachmentCollectionEditor";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {RhfFstIonDatetimeButton} from "app/employee/rhf/RhfFstIonDatetimeButton";
import {RhfIonTextarea} from "app/employee/rhf/RhfIonTextarea";
import {useLoadingContext} from "app/employee/LoadingContext";
import {
  CreateOrganizerDocumentDocument,
  GetEmployeeOrganizerDocument,
  IOrganizerDocumentFragment,
  IOrganizerFolderFragment,
  IOrganizerFolderId,
  IOrganizerFragment,
  OrganizerDocumentState,
  UpdateOrganizerDocumentDocument
} from "app/gql/graphqlSchema";
import {query} from "app/gql/client";
import {parseErrorResult} from "app/utils/parseErrorResult";
import {RhfIonInput} from "app/employee/rhf/RhfIonInput";
import {RhfTypeaheadSelect} from "app/employee/rhf/RhfTypeaheadSelect";
import {RelativeDateDisplay} from "app/employee/RelativeDateDisplay";
import {IonIconButton} from "app/employee/IonIconButton";
import {RhfIonRadioGroup} from "app/employee/rhf/RhfIonRadioGroup";
import {FlexRight, FlexRow} from "app/employee/flexUtils";
import {zBigNumber, zLocalDate, zNormalizedString, zToNull} from "app/utils/validator";
import {dateApiToLuxon, parseDuration} from "app/utils/dates";
import BigNumber from "bignumber.js";
import {DateTime, Duration} from "luxon";
import {AttachmentCollectionEdit} from "app/employee/att/AttachmentCollectionEdit";
import {UploadButton} from "app/employee/att/UploadButton";
import {ErrorMessage} from "app/employee/incdoc/supplier/ErrorMessage";
import {ListHeaderErrorMessage} from "app/employee/rhf/GlobalErrorMessage";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {AddEditFolder} from "app/employee/organizer/modal/AddEditFolder";
import {IonModalCustomEvent} from "@ionic/core/dist/types/components";
import {OverlayEventDetail} from "@ionic/react/dist/types/components/react-component-lib/interfaces";

const LinkButton = styled("button").attrs({type: "button"})`
  color: var(--ion-color-primary);
  background-color: transparent;

  &:disabled {
    color: var(--fst-disabled-text-color);
  }
`;

const formSchema = z.object({
  folderId: z.string().min(1, "Изберете папка"),
  name: zNormalizedString.pipe(z.string().min(1, "Името е задължително")),
  notifyBeforeExpiry: zToNull.or(zBigNumber),
  validFrom: zToNull.or(zLocalDate),
  validUntil: zToNull.or(zLocalDate),
  state: z.nativeEnum(OrganizerDocumentState),
  notes: zNormalizedString
});

type IFormData = z.infer<typeof formSchema>;

const durationToDays = (duration: MaybeNil<string>) => {
  if (isNil(duration)) {
    return null;
  }
  const durationParsed = parseDuration(duration);
  return Math.round(durationParsed.as("days"));
};

const daysToDuration = (days: MaybeNil<BigNumber>) => {
  if (isNil(days)) {
    return null;
  }
  return Duration.fromObject({days: days.toNumber()}).normalize().toISO();
};

interface IProps
  extends Pick<
    IUseAttachmentCollectionEditorReturn,
    "updateAttachments" | "unifiedAttachments" | "uploadProgress" | "showFileUpload"
  > {
  document: MaybeNil<IOrganizerDocumentFragment>;
  organizer: IOrganizerFragment;
  defaults: MaybeNil<Partial<{folderId: ID}>>;
}

export const AddEditOrgDoc = ({
  unifiedAttachments,
  showFileUpload,
  uploadProgress,
  document,
  organizer,
  updateAttachments,
  defaults
}: IProps) => {
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();
  const {t} = useTranslation();
  const backHandler = useDismiss(linkEmplOrganizer());

  const [folders, setFolders] = useState(organizer.folders);
  const defaultFolderId = defaults?.folderId ?? (folders.length > 0 ? folders[0].id.folderId : null);
  const folderRef = useRef<HTMLIonModalElement | null>(null);
  const [folderDefaults, setFolderDefaults] = useState<Partial<IOrganizerFolderFragment> | undefined>(undefined);

  const {
    watch,
    handleSubmit,
    control,
    setValue,
    formState: {errors, isSubmitting},
    setError,
    reset
  } = useForm<IFormData>({
    defaultValues: {
      folderId: document?.id?.folderId ?? defaultFolderId ?? "",
      name: document?.name ?? "",
      notifyBeforeExpiry: durationToDays(document?.notifyBeforeExpiry),
      validFrom: document?.validFrom,
      validUntil: document?.validUntil,
      state: document?.state ?? OrganizerDocumentState.Pending,
      notes: document?.notes ?? ""
    },
    resolver: zodResolver(formSchema)
  });

  const onSubmit = async (data: IFormData) => {
    const folderId: IOrganizerFolderId = {
      folderId: data.folderId,
      organizerId: organizer.id.organizerId
    };

    startLoading();
    try {
      const {notifyBeforeExpiry, ...rest} = data;
      const notifyBeforeExpiryDuration = daysToDuration(notifyBeforeExpiry);
      if (document) {
        const updateResult = await query(UpdateOrganizerDocumentDocument, {
          input: {
            ...rest,
            notifyBeforeExpiry: notifyBeforeExpiryDuration,
            id: document.id,
            folderId
          }
        });
        if (updateResult.updateOrganizerDocument.__typename === "OrganizerDocumentSuccessResult") {
          await updateAttachments(document.attachmentCollection.id);
        } else {
          parseErrorResult(updateResult.updateOrganizerDocument, setError);
        }
      } else {
        const createResult = await query(CreateOrganizerDocumentDocument, {
          input: {
            ...rest,
            notifyBeforeExpiry: notifyBeforeExpiryDuration,
            folderId
          }
        });
        if (createResult.createOrganizerDocument.__typename === "OrganizerDocumentSuccessResult") {
          await updateAttachments(createResult.createOrganizerDocument.document.attachmentCollection.id);
        } else {
          parseErrorResult(createResult.createOrganizerDocument, setError);
        }
      }
      await backHandler();
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  };

  const saveDisabled = !uploadProgress.completed || isSubmitting;

  const title = document
    ? t("organizer.edit-document-title", {defaultValue: "Редактиране на документ"})
    : t("organizer.add-document-title", {defaultValue: "Добавяне на документ"});

  const nameInputRef = useRef<HTMLIonInputElement>(null);
  const notifyBeforeExpiryRef = useRef<HTMLIonInputElement>(null);

  const validUntil = watch("validUntil");
  const validFrom = watch("validFrom");

  const setQuickValidUntil = (months: number) => {
    let validFromLuxon: DateTime;
    if (isNil(validFrom) || validFrom === "") {
      validFromLuxon = DateTime.now();
    } else {
      validFromLuxon = dateApiToLuxon(validFrom);
    }
    setValue("validUntil", validFromLuxon.plus({month: months}).toISODate());
  };

  const allowNotifyBeforeExpiry = !isNil(validUntil);

  useEffect(() => {
    reset();
  }, [document, reset]);

  const handleNewFolder = useCallback(
    async (e: IonModalCustomEvent<OverlayEventDetail<MaybeNil<IOrganizerFolderFragment>>>) => {
      const result = await query(GetEmployeeOrganizerDocument, {locationId: organizer.id.locationId});
      assertNotNil(result.getOrganizer);
      setFolders(result.getOrganizer.folders);
      if (!isNil(e.detail.data)) {
        const folderId = e.detail.data.id.folderId;
        setValue("folderId", folderId, {shouldValidate: true});
      }
    },
    [organizer.id.locationId, setValue]
  );

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{title}</IonTitle>
          <MenuButton />
        </IonToolbar>
        <IonProgressBar type="determinate" value={uploadProgress.completed ? 1 : uploadProgress.progress} />
      </IonHeader>
      <IonContent>
        <FstIonModal onIonModalDidDismiss={handleNewFolder} ref={folderRef}>
          <AddEditFolder defaults={folderDefaults} organizerId={organizer.id} />
        </FstIonModal>
        <IonList lines="full">
          <ListHeaderErrorMessage errors={errors} />
          <RhfTypeaheadSelect
            onAddNew={(searchText) => {
              setFolderDefaults({name: searchText});
              folderRef?.current?.present();
            }}
            options={folders.map((folder) => {
              return {
                key: folder.id.folderId,
                value: folder.id.folderId,
                text: folder.name
              };
            })}
            required
            rhf={{control, name: "folderId"}}
            textExtractor={(f) => f.text}
            title={t("organizer.form.folder", {defaultValue: "Папка"})}
          />
          <IonItem>
            <RhfIonInput
              labelPlacement="stacked"
              onSubmitValue={async () => {
                const input = await nameInputRef.current?.getInputElement();
                input?.blur();
              }}
              placeholder={t("organizer.form.name-placeholder", {
                defaultValue: "Име на документа, например Разрешително за..."
              })}
              ref={nameInputRef}
              rhf={{control, name: "name"}}
            >
              <div slot="label">
                {t("organizer.form.name", {defaultValue: "Име"})}{" "}
                <RhfRequiredLabel errors={errors} field="name" watch={watch} />
              </div>
            </RhfIonInput>
          </IonItem>
          <IonItem>
            <IonLabel>
              <FlexRow>
                <div>
                  {t("organizer.form.valid-from", {defaultValue: "Дата на издаване"})}{" "}
                  {!isNil(validFrom) && (
                    <p>
                      <RelativeDateDisplay dt={validFrom} />
                    </p>
                  )}
                </div>
                <FlexRight>
                  {!isNil(validFrom) && (
                    <IonIconButton fill="outline" icon={closeOutline} onClick={() => setValue("validFrom", null)} />
                  )}

                  <RhfFstIonDatetimeButton multiple={false} presentation="date" rhf={{control, name: "validFrom"}}>
                    <IonLabel slot="title">
                      {t("organizer.form.valid-from", {defaultValue: "Дата на издаване"})}
                    </IonLabel>
                  </RhfFstIonDatetimeButton>
                </FlexRight>
              </FlexRow>
              <p>
                {t("organizer.form.valid-from-hint", {
                  defaultValue: "Не попълвайте ако няма дата на издаване"
                })}
              </p>
              {!isNil(errors.validFrom) && (
                <p>
                  <ErrorMessage>{errors.validFrom.message}</ErrorMessage>
                </p>
              )}
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <FlexRow>
                <div>
                  {t("organizer.form.valid-until", {defaultValue: "Изтича на"})}{" "}
                  {!isNil(validUntil) && (
                    <p>
                      <RelativeDateDisplay dt={validUntil} />
                    </p>
                  )}
                </div>
                <FlexRight>
                  {!isNil(validUntil) && (
                    <IonIconButton fill="outline" icon={closeOutline} onClick={() => setValue("validUntil", null)} />
                  )}

                  <RhfFstIonDatetimeButton
                    multiple={false}
                    presentation="date"
                    rhf={{control, name: "validUntil"}}
                    slot="end"
                  >
                    <IonLabel slot="title">{t("organizer.form.valid-until", {defaultValue: "Изтича на"})}</IonLabel>
                  </RhfFstIonDatetimeButton>
                </FlexRight>
              </FlexRow>
              <p>
                {t("organizer.form.valid-until-hint", {
                  defaultValue: "Не попълвайте ако няма срок"
                })}
              </p>
              {!isNil(errors.validUntil) && (
                <p>
                  <ErrorMessage>{errors.validUntil.message}</ErrorMessage>
                </p>
              )}
              <p>
                {t("organizer.form.valid-until-quick-hint", {
                  defaultValue: "Бързи срокове"
                })}
                :{" "}
                <LinkButton disabled={isSubmitting} onClick={() => setQuickValidUntil(1)}>
                  {t("organizer.form.valid-until-quick-hint-1-month", {
                    defaultValue: "1 месец"
                  })}
                </LinkButton>
                ,{" "}
                <LinkButton disabled={isSubmitting} onClick={() => setQuickValidUntil(3)}>
                  {t("organizer.form.valid-until-quick-hint-3-months", {
                    defaultValue: "3 месеца"
                  })}
                </LinkButton>
                ,{" "}
                <LinkButton disabled={isSubmitting} onClick={() => setQuickValidUntil(6)}>
                  {t("organizer.form.valid-until-quick-hint-6-months", {
                    defaultValue: "6 месеца"
                  })}
                </LinkButton>
                ,{" "}
                <LinkButton disabled={isSubmitting} onClick={() => setQuickValidUntil(12)}>
                  {t("organizer.form.valid-until-quick-hint-1-year", {
                    defaultValue: "1 година"
                  })}
                </LinkButton>
                ,{" "}
                <LinkButton disabled={isSubmitting} onClick={() => setQuickValidUntil(24)}>
                  {t("organizer.form.valid-until-quick-hint-2-years", {
                    defaultValue: "2 години"
                  })}
                </LinkButton>
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <RhfIonInput
                disabled={!allowNotifyBeforeExpiry}
                inputMode="decimal"
                labelPlacement="stacked"
                onSubmitValue={async () => {
                  const input = await notifyBeforeExpiryRef.current?.getInputElement();
                  input?.blur();
                }}
                placeholder={t("organizer.form.notify-before-expiry-placeholder", {
                  defaultValue: "Без напомняне"
                })}
                ref={notifyBeforeExpiryRef}
                rhf={{control, name: "notifyBeforeExpiry"}}
                unit={t("organizer.form.notify-before-expiry-unit", {
                  defaultValue: "дни по-рано"
                })}
              >
                <div slot="label">
                  {t("organizer.form.notify-before-expiry", {defaultValue: "Изпрати ми напомяне"})}
                </div>
              </RhfIonInput>

              <p>
                {!allowNotifyBeforeExpiry && (
                  <>
                    {t("organizer.form.notify-before-expiry-hint-2", {
                      defaultValue: 'Изберете "Изтича на" за да добавите напомняне.'
                    })}{" "}
                  </>
                )}
                {t("organizer.form.notify-before-expiry-hint-1", {
                  defaultValue: "Ще изпратим предупреждаващо известие преди да изтече документа."
                })}
              </p>
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel>
              <AttachmentCollectionEdit
                disabled={isSubmitting}
                showFileUpload={() => showFileUpload()}
                unifiedAttachments={unifiedAttachments}
              />
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel>
              <p>{t("organizer.form.state", {defaultValue: "Състояние"})}</p>

              <RhfIonRadioGroup rhf={{control, name: "state"}}>
                <FlexRow>
                  <IonRadio
                    disabled={isSubmitting}
                    justify="start"
                    labelPlacement="end"
                    value={OrganizerDocumentState.Pending}
                  >
                    {t("organizer.state.pending", {defaultValue: "В изчакване"})}
                  </IonRadio>
                  <IonRadio
                    disabled={isSubmitting}
                    justify="start"
                    labelPlacement="end"
                    value={OrganizerDocumentState.Complete}
                  >
                    {t("organizer.state.complete", {defaultValue: "Завършен"})}
                  </IonRadio>
                </FlexRow>
              </RhfIonRadioGroup>
              {!isNil(errors.state) && (
                <p>
                  <ErrorMessage>{errors.state.message}</ErrorMessage>
                </p>
              )}
            </IonLabel>
          </IonItem>
          <IonItem>
            <RhfIonTextarea
              label={t("organizer.form.notes", {defaultValue: "Бележки"})}
              labelPlacement="stacked"
              placeholder={t("organizer.form.notes-placeholder", {
                defaultValue: "Допълнителни бележки (ако има нужда)"
              })}
              rhf={{control, name: "notes"}}
            />
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton color="primary" fill="outline" onClick={() => backHandler()}>
              <IonIcon icon={closeOutline} slot="start" />
              {t("g.cancel", {defaultValue: "Откажи"})}
            </IonButton>
            <UploadButton
              color="secondary"
              disabled={isSubmitting}
              fill="solid"
              showFileUpload={showFileUpload}
              unifiedAttachments={unifiedAttachments}
            />
            <IonButton color="primary" disabled={saveDisabled} fill="solid" onClick={handleSubmit(onSubmit)}>
              <IonIcon icon={checkmarkCircleOutline} slot="start" />
              {!uploadProgress.completed
                ? t("g.uploading", {
                    defaultValue: "Качване ({{uploadProgress}})",
                    uploadProgress: fractionToPercent(uploadProgress.progress)
                  })
                : t("g.save", {defaultValue: "Запази"})}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
