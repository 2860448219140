import {IColumnFragment, IControlCardValue, IValueInstanceFragment} from "app/gql/graphqlSchema";
import {
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonImg,
  useIonAlert
} from "@ionic/react";
import {isNil, isNotBlank} from "app/utils/stdlib";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {useSaveControlCardValue} from "app/employee/controlCard/hook/useSaveControlCardValue";
import {IonCardFooter} from "app/employee/controlCard/ControlCardUI";
import {checkmarkCircleOutline, closeCircleOutline, closeOutline} from "ionicons/icons";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {EMPTY_VALUE} from "app/employee/controlCard/types";
import {useCallback, useEffect, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {zToNull} from "app/utils/validator";
import {FlexColumnCenter} from "app/employee/flexUtils";
import {Color} from "@ionic/core";
import {EditContainer} from "app/employee/incdoc/EditContainer";
import {SavingProgress} from "app/employee/controlCard/input/SavingProgress";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {SignaturePad} from "app/employee/controlCard/input/SignaturePad";
import {Deferred} from "app/utils/deferred";
import {transformToApplicationError} from "app/gql/handleGraphqlErrors";
import {setApplicationError} from "app/utils/parseErrorResult";
import {DisplayRootError} from "app/employee/controlCard/input/DisplayRootError";
import {DisplayValueInstanceError} from "app/employee/controlCard/input/DisplayValueInstanceError";
import {useHasControlCardValueError} from "app/employee/controlCard/hook/useHasControlCardValueError";
import {InputIonCard} from "app/employee/controlCard/input/InputIonCard";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardSignatureColumn"};
  valueInstance: IValueInstanceFragment;
  disabled?: boolean;
}

const formSchema = z.object({
  value: zToNull.or(z.string())
});

export type IFormData = z.infer<typeof formSchema>;

export const InputSignature = ({column, valueInstance, disabled}: IProps) => {
  const {t} = useTranslation();
  const {isComplete, canEdit} = useControlCardInstanceContext();
  const hasError = useHasControlCardValueError(valueInstance);

  const {saveValue} = useSaveControlCardValue(valueInstance);
  const signatureModalRef = useRef<HTMLIonModalElement | null>(null);

  const localDisabled = isComplete || !!disabled || !canEdit;

  const persistedValue = valueInstance.value;

  let value: string | null = null;
  if (persistedValue.__typename === "ControlCardSignatureValue") {
    value = persistedValue.signature;
  }

  const {
    formState: {isDirty, isSubmitting, errors},
    handleSubmit,
    reset,
    watch,
    setValue,
    setError
  } = useForm<IFormData>({
    values: {
      value
    },
    resolver: zodResolver(formSchema)
  });

  const handleSave = useMemo(
    () =>
      handleSubmit(async (data) => {
        try {
          let value: IControlCardValue;
          if (isNil(data.value)) {
            value = EMPTY_VALUE;
          } else {
            value = {__typename: "ControlCardSignatureValue", signature: data.value};
          }

          await saveValue(value);
        } catch (e) {
          const appError = transformToApplicationError(e);
          setApplicationError(appError, setError);
        }
      }),
    [handleSubmit, saveValue, setError]
  );

  const handleClear = () => reset();

  useEffect(() => {
    if (isComplete) {
      reset();
    }
  }, [isComplete, reset]);

  const watchedValue = watch("value");
  const color: Color = isNil(watchedValue) ? "warning" : "primary";

  useEffect(() => {
    const subscription = watch((_, {name, type}) => {
      if (name === "value" && type === "change") {
        handleSave();
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSave, watch]);

  const setNewSignature = useCallback(
    async (data: string) => {
      try {
        signatureModalRef.current?.dismiss();
        setValue("value", data, {
          shouldValidate: true,
          shouldDirty: true
        });
        await handleSave();
      } catch (e) {
        const appError = transformToApplicationError(e);
        setApplicationError(appError, setError);
      }
    },
    [handleSave, setError, setValue]
  );

  const [presentAlert] = useIonAlert();

  const confirmRemoveSignature = useCallback(async () => {
    const deferred = new Deferred<boolean>();
    await presentAlert({
      header: t("control-card.row.action.confirm-remove-signature.title"),
      buttons: [
        {text: t("g.cancel"), handler: () => deferred.resolve(false), role: "cancel"},
        {
          text: t("g.remove"),
          role: "destructive",
          handler: () => deferred.resolve(true)
        }
      ]
    });

    return deferred.promise;
  }, [presentAlert, t]);

  const setNow = async () => {
    if (await confirmRemoveSignature()) {
      setValue("value", null, {shouldValidate: true, shouldDirty: true});
      await handleSave();
    }
  };

  const onClick = !localDisabled ? async () => await signatureModalRef.current?.present() : undefined;

  const nothingToClear = isNil(watchedValue);
  return (
    <>
      <InputIonCard $hasError={hasError} data-scroll-to-value-instance-id={valueInstance.id}>
        <EditContainer>
          <IonButton disabled={localDisabled || nothingToClear} fill="clear" onClick={setNow}>
            <IonIcon icon={closeCircleOutline} slot="icon-only" />
          </IonButton>
        </EditContainer>
        <IonCardHeader>
          <IonCardTitle>{column.title}</IonCardTitle>
          {isNotBlank(column.description) && <IonCardSubtitle>{column.description}</IonCardSubtitle>}
        </IonCardHeader>
        <IonCardContent>
          <DisplayRootError errors={errors} />
          <FlexColumnCenter>
            {!isNil(watchedValue) && <IonImg onClick={onClick} src={watchedValue} />}
            <IonButton
              color={color}
              disabled={localDisabled}
              onClick={onClick}
              size={isNil(watchedValue) ? "large" : "default"}
            >
              {t("control-card.action.signature")}
            </IonButton>
            <DisplayValueInstanceError column={column} disabled={localDisabled} valueInstance={valueInstance} />
          </FlexColumnCenter>
        </IonCardContent>
        {isDirty && !isSubmitting && (
          <IonCardFooter>
            <IonButton color="primary" fill="outline" onClick={handleClear} size="small">
              <IonIcon icon={closeOutline} slot="start" />
              {t("g.cancel")}
            </IonButton>
            <IonButton color="primary" onClick={handleSave} size="small">
              <IonIcon icon={checkmarkCircleOutline} slot="start" />
              {t("g.save")}
            </IonButton>
          </IonCardFooter>
        )}
        <SavingProgress isSubmitting={isSubmitting} />{" "}
      </InputIonCard>
      <FstIonModal ref={signatureModalRef}>
        <SignaturePad onSubmit={setNewSignature} title={column.title} />
      </FstIonModal>
    </>
  );
};
