import {IPlayerControl} from "app/employee/video/VideoPlayer";
import {useIonAlert, useIonViewWillLeave} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {forwardRef, useCallback, useImperativeHandle} from "react";

interface IProps {
  startControl: IPlayerControl;
  playAgain: () => unknown;
  continueWatching: () => unknown;
  isEnded: boolean;
}

export interface IModalHandle {
  show: () => Promise<void>;
  hide: () => Promise<void>;
}

export const VideoPlayerModal = forwardRef<IModalHandle, IProps>(
  ({startControl, playAgain, continueWatching, isEnded}: IProps, componentRef) => {
    const [presentAlert, dismissAlert] = useIonAlert();
    const {t} = useTranslation();

    useIonViewWillLeave(() => {
      dismissAlert();
    });

    const showAlert = useCallback(async () => {
      switch (startControl.type) {
        case "segment": {
          await presentAlert({
            header: t("player.modal.segment.header", {defaultValue: "Този сегмент съдържаше отговорът на въпроса"}),
            message: t("player.modal.segment.sub-header", {defaultValue: "Изберете една от следните опции"}),
            buttons: [
              {
                text: t("player.modal.segment.watch-again", {defaultValue: "Гледай отново"}),
                handler: () => {
                  playAgain();
                }
              },
              {
                text: t("player.modal.segment.back-to-results", {defaultValue: "Обратно към резултатите"}),
                role: "cancel",
                handler: () => {
                  startControl.handleReturn();
                }
              }
            ]
          });
          break;
        }
        case "current": {
          if (isEnded) {
            await presentAlert({
              header: t("player.modal.current.ended-header", {defaultValue: "Вие приключихте с тази тема"}),
              buttons: [
                {
                  text: t("player.modal.current.go-to-test", {defaultValue: "Преминете към теста"}),
                  role: "cancel",
                  handler: () => {
                    startControl.handleReturn();
                  }
                }
              ]
            });
          } else {
            await presentAlert({
              header:
                t("player.modal.current.continue-header", {defaultValue: 'За да продължите, натиснете "Продължи"'}) ??
                undefined,
              buttons: [
                {
                  text: t("player.modal.current.continue-button", {defaultValue: "Продължи"}),
                  role: "cancel",
                  handler: () => {
                    continueWatching();
                  }
                }
              ]
            });
          }
          break;
        }
        case "again": {
          await presentAlert({
            header: t("player.modal.again.header", {defaultValue: "Темата завърши"}),
            message: t("player.modal.again.sub-header", {defaultValue: "Изберете една от следните опции"}),
            buttons: [
              {
                text: t("player.modal.again.watch-again", {defaultValue: "Гледай отново"}),
                handler: () => {
                  playAgain();
                }
              },
              {
                text: t("player.modal.segment.back-to-home", {defaultValue: "Към началния екран"}),
                role: "cancel",
                handler: () => {
                  startControl.handleReturn();
                }
              }
            ]
          });
          break;
        }
      }
    }, [continueWatching, isEnded, playAgain, presentAlert, startControl, t]);

    useImperativeHandle(
      componentRef,
      () => ({
        show: showAlert,
        hide: dismissAlert
      }),
      [dismissAlert, showAlert]
    );

    return null;
  }
);

VideoPlayerModal.displayName = "VideoPlayerModal";
