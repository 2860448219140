import {hasVideoAnswer, IFailedQuestion, mustWatchAgain} from "app/employee/state/syllabus/selectors";
import {watchQuestion} from "app/employee/state/syllabus/mutations";
import {FailedQuestionAnswer} from "app/employee/test/result/FailedQuestionAnswer";
import {IonButton, IonCol, IonIcon, IonRow, useIonRouter} from "@ionic/react";
import {playCircle, refresh} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {linkEmplWatchVideoAnswerSegment} from "app/employee/core/employeeLinks";

interface IProps {
  failedQuestion: IFailedQuestion;
  showAnswer?: boolean;
}

export const FailedQuestion = ({failedQuestion, showAnswer}: IProps) => {
  const ionRouter = useIonRouter();
  const question = failedQuestion.question;
  const mustWatchThisQuestion = mustWatchAgain(question);
  const isHasVideoAnswer = hasVideoAnswer(question);
  const {t} = useTranslation();
  const playSnippet = async () => {
    await watchQuestion({questionId: question.id});
    console.log(linkEmplWatchVideoAnswerSegment(question.id));
    ionRouter.push(linkEmplWatchVideoAnswerSegment(question.id));
  };

  return (
    <>
      <IonRow>
        <IonCol>
          <strong>{t("test.question-no", {questionNo: failedQuestion.overallIndex + 1})}</strong>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>{question.content}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          {showAnswer && <FailedQuestionAnswer question={failedQuestion} />}
          {!showAnswer &&
            isHasVideoAnswer &&
            (mustWatchThisQuestion ? (
              <IonButton onClick={playSnippet} size="small">
                <IonIcon icon={playCircle} slot="start" />
                {t("test.check-the-answer-in-segment", {defaultValue: "Виж отговора в откъс от темата"})}
              </IonButton>
            ) : (
              <IonButton fill="outline" onClick={playSnippet} size="small">
                <IonIcon icon={refresh} slot="start" />
                {t("test.refresh-the-answer-in-segment", {defaultValue: "Припомни си отговора в откъс от темата"})}
              </IonButton>
            ))}
        </IonCol>
      </IonRow>
    </>
  );
};
