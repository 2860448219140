import {
  GetEmployeeOrganizerDocument,
  IEmployeeLocationFragment,
  ILocationId,
  IOrganizerFragment
} from "app/gql/graphqlSchema";
import {query} from "app/gql/client";
import {updateBootstrapEmployee} from "app/employee/state/bootstrapEmployee/mutations";
import {RefresherEventDetail, SelectChangeEventDetail, useIonRouter, useIonViewWillEnter} from "@ionic/react";
import {usePermissions} from "app/employee/hooks/usePermissions";
import {useEmployeeLocations} from "app/employee/state/bootstrapEmployee/selectors";
import {useImmer} from "use-immer";
import {ApiState, ILoadable, loadedOk, LOADING_STATE_NA, loadingFailed} from "app/state/common/loadable";
import {useCallback, useMemo} from "react";
import {isNil} from "app/utils/stdlib";
import {IonSelectCustomEvent} from "@ionic/core/dist/types/components";
import {linkEmplOrganizer} from "app/employee/core/employeeLinks";
import {useSafePathParams} from "app/components/hooks/useSafeParams";
import {z} from "zod";

const loadOrganizer = async (location: IEmployeeLocationFragment) => {
  const result = await query(GetEmployeeOrganizerDocument, {locationId: location.id});
  updateBootstrapEmployee(result.refreshEmployee);
  return result.getOrganizer;
};

const locationIdParams = z.object({
  locationId: z.string().optional()
});

export const useOrganizer = () => {
  const {locationId: paramLocationId} = useSafePathParams(locationIdParams);
  const ionRouter = useIonRouter();

  const {canViewOrganizerLocation} = usePermissions();

  const locations = useEmployeeLocations().filter((l) => canViewOrganizerLocation(l.id));
  const location = useMemo(() => {
    return (
      locations.find((l) => l.id.locationId === paramLocationId) ?? (locations.length > 0 ? locations[0] : undefined)
    );
  }, [locations, paramLocationId]);

  const [organizerLoadable, setOrganizer] = useImmer<ILoadable<IOrganizerFragment>>(LOADING_STATE_NA);

  const refreshOrganizer = useCallback(
    async (event?: CustomEvent<RefresherEventDetail>) => {
      try {
        if (isNil(location)) {
          setOrganizer((draft) => {
            draft.loadingState = ApiState.NA;
          });
        } else {
          setOrganizer((draft) => {
            draft.loadingState = ApiState.LOADING;
          });
          try {
            const result = await loadOrganizer(location);
            setOrganizer(() => loadedOk(result));
          } catch (e) {
            setOrganizer((draft) => {
              loadingFailed(draft, e);
            });
          }
        }
      } finally {
        event?.detail.complete();
      }
    },
    [location, setOrganizer]
  );

  useIonViewWillEnter(() => {
    refreshOrganizer();
  }, [refreshOrganizer]);

  const handleLocationChange = (e: IonSelectCustomEvent<SelectChangeEventDetail<ILocationId>>) => {
    const locationId = e.detail.value;
    ionRouter.push(linkEmplOrganizer(locationId.locationId), "root", "push");
  };

  return {organizerLoadable, handleLocationChange, refreshOrganizer, locations, location};
};
