import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonAlert
} from "@ionic/react";
import {MenuButton} from "app/employee/menu/MenuButton";
import {RhfIonSearchbar} from "app/employee/rhf/RhfIonSearchbar";
import {checkmarkCircle, checkmarkCircleOutline, closeOutline, createOutline, trashBinOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useQuery} from "app/employee/controlCard/hook/useQuery";
import {
  ControlCardComboBoxOptionsDocument,
  ControlCardDeleteComboBoxOptionDocument,
  ControlCardSaveComboBoxOptionDocument,
  ControlCardUpdateComboBoxOptionDocument,
  IControlCardComboBoxOptionsQuery
} from "app/gql/graphqlSchema";
import {useFuzzySearch} from "app/employee/controlCard/hook/useFuzzySearch";
import {LoadingLoadable} from "app/employee/Loading";
import {ReactNode, useCallback, useEffect, useMemo, useRef} from "react";
import {query} from "app/gql/client";
import {ID, IIonItem, MaybeNil} from "app/utils/types";
import {isEqual, isNil, isNotBlank, isNotNil} from "app/utils/stdlib";
import {useModalContext} from "app/employee/incdoc/FstIonModal";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {CheckedLabel} from "app/employee/incdoc/supplier/TypeaheadSelect";
import {Deferred} from "app/utils/deferred";

const searchSchema = z.object({
  searchValue: z.string().trim().min(1, "g.required")
});

type ISearchData = z.infer<typeof searchSchema>;

const formSchema = z.object({
  selectedValues: z.array(z.string()).min(1, "g.required")
});

type IFormData = z.infer<typeof formSchema>;

interface IProps {
  title?: ReactNode;
  values: MaybeNil<MaybeNil<string>[]>;
  onSelect?: (values: string[]) => void | Promise<void>;
  listKey: string;
  multiselect?: boolean;
}

const addOption = async (listKey: string, value: string) => {
  const resp = await query(ControlCardSaveComboBoxOptionDocument, {input: {listKey, value}});
  return resp.controlCardSaveComboBoxOption;
};

const updateOption = async (listKey: string, optionId: ID, newValue: string) => {
  await query(ControlCardUpdateComboBoxOptionDocument, {input: {listKey, optionId, newValue}});
};

const deleteOption = async (listKey: string, optionId: ID) => {
  await query(ControlCardDeleteComboBoxOptionDocument, {input: {listKey, optionId}});
};

type IItem = IControlCardComboBoxOptionsQuery["controlCardComboBoxOptions"][number];
const textExtractor = (item: IItem) => item.value;

const useEditComboBoxOption = (listKey: string) => {
  const {t} = useTranslation();

  const editSchema = useMemo(() => z.object({value: z.string().trim().min(1, t("g.required"))}), [t]);

  const [presentEditOption] = useIonAlert();
  const [presentConfirmation] = useIonAlert();

  const edit = useCallback(
    async (item: IItem) => {
      const deferred = new Deferred<unknown>();

      await presentEditOption({
        header: t("g.combo-box.edit-header"),
        inputs: [
          {
            name: "value",
            type: "text",
            value: item.value
          }
        ],
        buttons: [
          {
            text: t("g.cancel"),
            role: "cancel",
            handler: () => deferred.resolve(null)
          },
          {
            text: t("g.delete"),
            role: "destructive",
            handler: async () => {
              await presentConfirmation({
                header: t("g.combo-box.delete-title"),
                buttons: [
                  {
                    text: t("g.cancel"),
                    role: "cancel",
                    handler: () => deferred.resolve(null)
                  },
                  {
                    text: t("g.delete"),
                    role: "destructive",
                    handler: async () => {
                      try {
                        await deleteOption(listKey, item.id);
                      } finally {
                        deferred.resolve(null);
                      }
                    }
                  }
                ]
              });
            }
          },
          {
            text: t("g.save"),
            handler: async (handlerValue) => {
              try {
                const parsedValue = editSchema.safeParse(handlerValue);
                if (parsedValue.success) {
                  await updateOption(listKey, item.id, parsedValue.data.value);
                }
              } finally {
                deferred.resolve(null);
              }
            }
          }
        ]
      });

      return deferred.promise;
    },
    [editSchema, listKey, presentConfirmation, presentEditOption, t]
  );

  return {editItem: edit};
};

export const ComboBox = ({listKey, title, onSelect, values, multiselect}: IProps) => {
  const modalContext = useModalContext();
  const {t} = useTranslation();
  const {editItem} = useEditComboBoxOption(listKey);
  const {data, refetch, fetch, setData} = useQuery(ControlCardComboBoxOptionsDocument);
  const items = data?.value?.controlCardComboBoxOptions ?? [];

  const defaultSearchValue = multiselect ? "" : values?.[0] ?? "";
  const {
    control: searchControl,
    watch: searchWatch,
    handleSubmit: searchHandleSubmit,
    formState: {isSubmitting: isSearchSubmitting, isValid: isSearchValid},
    reset: searchReset,
    setValue: setSearchValue
  } = useForm<ISearchData>({
    resolver: zodResolver(searchSchema),
    defaultValues: {
      searchValue: defaultSearchValue
    }
  });

  const {
    watch,
    handleSubmit,
    formState: {isSubmitting, isValid},
    reset,
    setValue
  } = useForm<IFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      selectedValues: values?.filter(isNotNil) ?? []
    }
  });

  const addNewOption = async (data: ISearchData) => {
    const newOption = await addOption(listKey, data.searchValue);
    setData((draft) => {
      draft.controlCardComboBoxOptions.unshift(newOption);
    });
    toggleSelectedValue(newOption.value);
    if (!multiselect) {
      onSelect?.([newOption.value]);
    } else {
      setSearchValue("searchValue", "", {shouldValidate: true});
    }
  };

  const selectSelectedValues = async (data: IFormData) => {
    await onSelect?.(data.selectedValues);
  };

  const toggleSelectedValue = (value: string) => {
    const index = selectedValues.findIndex((v) => isEqual(v, value));
    if (index === -1) {
      setValue("selectedValues", [value, ...selectedValues], {shouldValidate: true});
    } else {
      setValue("selectedValues", [...selectedValues.slice(0, index), ...selectedValues.slice(index + 1)], {
        shouldValidate: true
      });
    }
  };

  const searchValue = searchWatch("searchValue");
  const exactMatch = items.some((item) => item.value === searchValue);
  const {filteredItems} = useFuzzySearch(searchValue, items, textExtractor);
  const listRef = useRef<HTMLIonListElement>(null);

  const selectedValues = watch("selectedValues");

  useEffect(() => {
    reset();
    searchReset();
    fetch({input: {listKey}});
  }, [fetch, listKey, reset, searchReset]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{title}</IonTitle>
          <MenuButton />
        </IonToolbar>
        <IonToolbar>
          <RhfIonSearchbar
            debounce={200}
            placeholder={t("g.combo-box.placeholder")}
            rhf={{control: searchControl, name: "searchValue"}}
          />
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <LoadingLoadable loadable={data} />

        <IonRefresher onIonRefresh={refetch} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>
        {!exactMatch && (
          <IonList>
            <IonListHeader>{t("g.add-new")}</IonListHeader>
            <IonItem
              button
              color="highlight"
              detail
              disabled={!isSearchValid || isSubmitting || isSearchSubmitting}
              onClick={searchHandleSubmit(addNewOption)}
            >
              <IonLabel>{isSearchValid ? searchValue : t("g.combo-box.hint")}</IonLabel>
            </IonItem>
          </IonList>
        )}
        <IonList ref={listRef}>
          <IonListHeader>
            {isNotBlank(searchValue)
              ? t("g.search-results-term", {
                  term: searchValue,
                  count: filteredItems.length
                })
              : t("g.search-results-no-term", {
                  term: searchValue,
                  count: filteredItems.length
                })}
          </IonListHeader>
          {filteredItems.map((filteredItem) => {
            const {item, highlighted} = filteredItem;

            let checkmark: ReactNode | null;
            let itemProps: IIonItem;
            let isSelected = selectedValues.some((v) => isEqual(v, item.value));
            if (multiselect) {
              itemProps = {
                detail: false,
                onClick: () => toggleSelectedValue(item.value)
              };
              checkmark = null;
            } else {
              itemProps = {
                detail: true,
                onClick: () => onSelect?.([item.value])
              };
              checkmark = isSelected ? <IonIcon color="success" icon={checkmarkCircle} slot="end" /> : null;
              isSelected = false;
            }

            return (
              <IonItemSliding key={item.id}>
                <IonItem button color={isSelected ? "primary" : undefined} {...itemProps} disabled={isSubmitting}>
                  <CheckedLabel $checked={isSelected} className="ion-text-wrap">
                    {highlighted}
                  </CheckedLabel>
                  {checkmark}
                </IonItem>
                <IonItemOptions side="start">
                  <IonItemOption
                    onClick={async () => {
                      await editItem(item);
                      await refetch();
                      await listRef.current?.closeSlidingItems();
                    }}
                  >
                    <IonIcon icon={createOutline} slot="icon-only" />
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            );
          })}
        </IonList>
      </IonContent>
      {multiselect && (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="primary">
              {!isNil(modalContext) && (
                <IonButton color="primary" fill="outline" onClick={() => modalContext?.dismiss()}>
                  <IonIcon icon={closeOutline} slot="start" />
                  {t("g.cancel")}
                </IonButton>
              )}

              <IonButton
                color="primary"
                disabled={selectedValues.length === 0 || isSubmitting}
                fill="outline"
                onClick={() => setValue("selectedValues", [], {shouldValidate: true})}
              >
                <IonIcon icon={trashBinOutline} slot="start" />
                {t("g.clear")}
              </IonButton>

              <IonButton
                color="primary"
                disabled={isSubmitting || !isValid}
                fill="solid"
                onClick={handleSubmit(selectSelectedValues)}
              >
                <IonIcon icon={checkmarkCircleOutline} slot="start" />
                {t("g.add-count", {count: selectedValues.length})}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      )}
    </>
  );
};
