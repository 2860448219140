import {
  IControlCardInstanceRemoveOverrideValidationResultInput,
  IValueInstanceFragment,
  RemoveOverrideControlCardValidationResultDocument
} from "app/gql/graphqlSchema";
import {query} from "app/gql/client";
import {useCallback} from "react";
import {useLoadingContext} from "app/employee/LoadingContext";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";

const removeOverrideControlCardValidationResultApi = async (
  input: IControlCardInstanceRemoveOverrideValidationResultInput
) => {
  const result = await query(RemoveOverrideControlCardValidationResultDocument, {input});
  return result.controlCardInstanceRemoveOverrideValidationResult;
};

export const useRemoveOverrideControlCardValidationResult = (valueInstance: Pick<IValueInstanceFragment, "id">) => {
  const {instance, updateValue} = useControlCardInstanceContext();

  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();

  const removeOverrideValidationResult = useCallback(async () => {
    try {
      startLoading();
      const result = await removeOverrideControlCardValidationResultApi({
        instanceId: instance.id,
        valueIds: [valueInstance.id]
      });
      const updatedValues = result.values;
      for (const updatedValue of updatedValues) {
        updateValue(updatedValue);
      }
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  }, [finishLoading, instance.id, showExceptionError, startLoading, updateValue, valueInstance]);

  return {removeOverrideValidationResult};
};
