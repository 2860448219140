import {IFailedQuestion} from "app/employee/state/syllabus/selectors";
import {DeepReadonly} from "ts-essentials";
import {styled} from "styled-components";
import {checkmarkCircle, closeCircle} from "ionicons/icons";
import {IonIcon} from "@ionic/react";
import {useTranslation} from "react-i18next";

const Grid = styled.div`
  display: grid;
  grid-template-columns: [icon] auto [label] auto [text] 1fr;
  grid-template-rows: auto auto;
  grid-row-gap: 0.5em;
  grid-column-gap: 0.5em;
`;

const GridCell = styled.div<{$bold?: boolean; $right?: boolean}>(({$bold, $right}) => ({
  fontWeight: $bold ? "bold" : undefined,
  justifySelf: $right ? "end" : undefined
}));

interface IProps {
  question: IFailedQuestion;
}

export const FailedQuestionAnswer = ({question}: DeepReadonly<IProps>) => {
  const {t} = useTranslation();
  const employeeAnswer = question.question.employeeAnswer;
  const wrongAnswer = question.question.answers.find((a) => a.id === employeeAnswer?.answerId);
  const correctAnswer = question.question.answers.find((a) => a.correct);
  const noAnswerText = t("test.no-answer", {defaultValue: "Няма отговор"});
  return (
    <Grid>
      <GridCell $bold>• {t("test.correct-answer", {defaultValue: "Верен отговор"})}: </GridCell>
      <GridCell>
        <IonIcon color="success" icon={checkmarkCircle} />
      </GridCell>
      <GridCell $bold>{correctAnswer ? correctAnswer.content : noAnswerText}</GridCell>
      <GridCell>• {t("test.your-answer", {defaultValue: "Вашият отговор"})}:</GridCell>
      <GridCell>
        <IonIcon color="danger" icon={closeCircle} />
      </GridCell>
      <GridCell>{wrongAnswer ? wrongAnswer.content : noAnswerText}</GridCell>
    </Grid>
  );
};
