import {createContext, useContext, useEffect, useMemo, useRef, useState} from "react";
import {assertNotNil, isEqual, isNil} from "app/utils/stdlib";
import {useSafePathParams, zOptionalLocationIdParams} from "app/components/hooks/useSafeParams";
import {usePermissions} from "app/employee/hooks/usePermissions";
import {useEmployeeLocations} from "app/employee/state/bootstrapEmployee/selectors";
import {useImmer} from "use-immer";
import {
  ILocationControlCardFilter,
  useLocationControlCardGlobalFilter
} from "app/employee/controlCard/location/useLocationControlCardGlobalFilter";
import {useIonViewDidEnter, useIonViewWillEnter, useIonViewWillLeave} from "@ionic/react";
import {DateTime} from "luxon";
import {CompleteStatus, ControlCardCategory, ILocationId} from "app/gql/graphqlSchema";
import {dateLuxonToApi} from "app/utils/dates";

export const useDeepMemo = <TKey, TValue>(memoFn: () => TValue, key: TKey): TValue => {
  const ref = useRef<{key: TKey; value: TValue}>();

  if (!ref.current || !isEqual(key, ref.current.key)) {
    ref.current = {key, value: memoFn()};
  }

  return ref.current.value;
};

const useCurrentEmployeeLocations = () => {
  const {locationId: paramLocationId} = useSafePathParams(zOptionalLocationIdParams);
  const {canViewControlCardLocation} = usePermissions();
  const employeeLocations = useEmployeeLocations();

  const locations = useMemo(
    () => employeeLocations.filter((l) => canViewControlCardLocation(l.id)),
    [canViewControlCardLocation, employeeLocations]
  );

  const location = useDeepMemo(
    () =>
      locations.find((l) => l.id.locationId === paramLocationId) ?? (locations.length > 0 ? locations[0] : undefined),
    [locations, paramLocationId]
  );

  return {location, locations};
};

export const getDefaultRecurringRange = () => {
  const now = DateTime.now();
  return {
    start: dateLuxonToApi(now.minus({month: 1})),
    end: dateLuxonToApi(now)
  };
};

const createDefaultFilter = (locationId: ILocationId): ILocationControlCardFilter => {
  const defaultRecurringRange = getDefaultRecurringRange();
  return {
    locationId,
    category: ControlCardCategory.Daily,
    dailyDate: defaultRecurringRange.end,
    query: null,
    completeStatus: CompleteStatus.All,
    recurringDateRange: defaultRecurringRange,
    recurringTemplateIds: []
  };
};

const getGlobalFilter = useLocationControlCardGlobalFilter.getState;
const setGlobalFilter = useLocationControlCardGlobalFilter.setState;

export const useLocationDeckFilter = () => {
  const [pageVisible, setPageVisible] = useState(false);
  const {location, locations} = useCurrentEmployeeLocations();

  const [filter, setFilter] = useImmer<ILocationControlCardFilter | null>(null);

  useIonViewWillEnter(() => {
    if (isNil(location)) {
      setFilter(null);
      return;
    }
    const globalFilter = getGlobalFilter().filter;
    if (isNil(globalFilter)) {
      setFilter(createDefaultFilter(location.id));
    } else {
      setFilter(() => {
        if (!isEqual(globalFilter.locationId, location.id)) {
          return {
            ...globalFilter,
            locationId: location.id,
            recurringTemplateIds: []
          };
        } else {
          return {
            ...globalFilter,
            locationId: location.id
          };
        }
      });
    }
  }, [location, setFilter]);

  useIonViewDidEnter(() => {
    setPageVisible(true);
  }, []);

  useIonViewWillLeave(() => {
    setPageVisible(false);
    setGlobalFilter((draft) => {
      draft.filter = filter;
    });
  }, [filter]);

  useEffect(() => {
    if (pageVisible) {
      setFilter((draft) => {
        if (isNil(location)) {
          return null;
        } else {
          if (!isNil(draft) && !isEqual(draft.locationId, location.id)) {
            draft.locationId = location.id;
            draft.recurringTemplateIds = [];
          }
        }
      });
    }
  }, [location, pageVisible, setFilter]);

  return useMemo(() => ({filter, locations, setFilter, pageVisible}), [locations, pageVisible, setFilter, filter]);
};

export const LocationDeckFilterContext = createContext<ReturnType<typeof useLocationDeckFilter> | null>(null);

export const useLocationDeckFilterContext = () => {
  const context = useContext(LocationDeckFilterContext);
  assertNotNil(context);
  return context;
};
