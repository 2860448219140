import {IonLabel, IonListHeader} from "@ionic/react";
import {FieldErrors, FieldValues} from "react-hook-form";
import {isNil} from "app/utils/stdlib";

export const ListHeaderErrorMessage = <FV extends FieldValues>({errors}: {errors: FieldErrors<FV>}) => {
  if (!isNil(errors.root)) {
    return (
      <IonListHeader color="danger">
        <IonLabel>{errors.root.message}</IonLabel>
      </IonListHeader>
    );
  } else {
    return null;
  }
};
