import {IonButton, IonIcon, IonLabel} from "@ionic/react";
import {cameraOutline} from "ionicons/icons";
import {IIonButton} from "app/utils/types";
import {
  IUnifiedAttachment,
  IUseAttachmentCollectionEditorReturn
} from "app/components/hooks/useAttachmentCollectionEditor";
import {useTranslation} from "react-i18next";
import {useCallback} from "react";

type IProps = Omit<IIonButton, "onClick"> & {
  showFileUpload: IUseAttachmentCollectionEditorReturn["showFileUpload"];
  unifiedAttachments: IUnifiedAttachment[];
};

export const UploadButton = ({showFileUpload, unifiedAttachments, ...props}: IProps) => {
  const {t} = useTranslation();
  const clickHandler = useCallback(() => {
    showFileUpload();
  }, [showFileUpload]);
  return (
    <IonButton {...props} onClick={clickHandler}>
      <IonIcon icon={cameraOutline} slot="start" />
      <IonLabel>{unifiedAttachments.length > 0 ? t("g.upload-more") : t("g.upload")}</IonLabel>
    </IonButton>
  );
};
