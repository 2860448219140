import {INotificationFragment} from "app/gql/graphqlSchema";
import {create as zustandCreate} from "zustand";
import {immer} from "zustand/middleware/immer";
import {ILoadable, LOADING_STATE_NA} from "app/state/common/loadable";
import {Updater} from "use-immer";

export interface IEmployeeNotificationState {
  notifications: ILoadable<INotificationFragment[]>;
  setNotifications: Updater<ILoadable<INotificationFragment[]>>;
}

export const useEmployeeNotificationState = zustandCreate(
  immer<IEmployeeNotificationState>((setState) => ({
    notifications: LOADING_STATE_NA,
    setNotifications: (arg) =>
      setState((state) => {
        if (typeof arg === "function") {
          arg(state.notifications);
        } else {
          state.notifications = arg;
        }
      })
  }))
);
