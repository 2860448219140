import {IColumnFragment, IValueFragment} from "app/gql/graphqlSchema";
import {EmptyValue} from "app/employee/controlCard/display/EmptyValue";
import {InvalidValue} from "app/employee/controlCard/display/InvalidValue";
import {DateDisplay} from "app/employee/DateDisplay";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardDateColumn"};
  value: IValueFragment;
}
export const DisplayDate = ({value}: IProps) => {
  let result: JSX.Element;
  if (value.__typename === "ControlCardDateValue") {
    result = <DateDisplay dt={value.date} />;
  } else if (value.__typename === "ControlCardEmptyValue") {
    result = <EmptyValue />;
  } else {
    result = <InvalidValue />;
  }

  return result;
};
