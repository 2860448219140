import {useTranslation} from "react-i18next";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {MenuButton} from "app/employee/menu/MenuButton";
import {DisplayControlCardInstance} from "app/employee/controlCard/DisplayControlCardInstance";
import {ListPadding} from "app/employee/incdoc/ListPadding";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {arrowBackOutline} from "ionicons/icons";
import {linkEmplControlCardInstance, linkEmplControlCardLocation} from "app/employee/core/employeeLinks";
import {isNil} from "app/utils/stdlib";

export const ControlCardInstance = () => {
  const {t} = useTranslation();
  const {refresh, instance} = useControlCardInstanceContext();
  const {rowId} = useControlCardInstanceContext();
  let backLink: string;
  if (!isNil(rowId)) {
    backLink = linkEmplControlCardInstance(instance.id.instanceId);
  } else {
    backLink = linkEmplControlCardLocation(instance.location.id.locationId);
  }
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" routerDirection="back" routerLink={backLink}>
              <IonIcon icon={arrowBackOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>{t("control-card.title", {defaultValue: "Контролни карти"})}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher onIonRefresh={refresh} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>
        <DisplayControlCardInstance />
        <ListPadding />
      </IonContent>
    </>
  );
};
