import {hofForwardRef, IIonButton, IIonDatetime, MaybeNil} from "app/utils/types";
import {IonButton, IonDatetime, IonPopover} from "@ionic/react";
import {PropsWithoutRef, ReactNode, Ref, useCallback, useRef} from "react";
import {useTranslation} from "react-i18next";
import {DateDisplay} from "app/employee/DateDisplay";
import {styled} from "styled-components";
import {DateTime} from "luxon";
import {API_DATE_FORMAT, API_TIME_FORMAT, dateApiToLuxon, dateLuxonToApi, timeApiToLuxon} from "app/utils/dates";
import {IInitialValueFragment} from "app/gql/graphqlSchema";
import {isNil} from "app/utils/stdlib";
import {UnreachableCaseError} from "ts-essentials";

const DatePopover = styled(IonPopover)`
  --width: auto;
`;

const getAppCenter = () => {
  const br = document.querySelector("ion-app")?.getBoundingClientRect();
  if (br) {
    return [br.width / 2, br.height / 2];
  } else {
    return [0, 0];
  }
};

export interface IFstIonDatetimeButton extends PropsWithoutRef<IIonDatetime> {
  slot?: "start" | "end";
  buttonSize?: IIonButton["size"];
  buttonColor?: IIonButton["color"];
  initialValue?: MaybeNil<IInitialValueFragment>;
}

const PICKER_FORMAT = "HH:mm";

const nowTime = (initialValue: MaybeNil<IInitialValueFragment>) => {
  if (!isNil(initialValue)) {
    switch (initialValue.__typename) {
      case "ControlCardInitialFixedValue":
        if (initialValue.value.__typename === "ControlCardTimeValue") {
          return timeApiToLuxon(initialValue.value.time).toFormat(PICKER_FORMAT);
        } else {
          break;
        }
      default:
        throw new UnreachableCaseError(initialValue.__typename);
    }
  }

  return timeApiToLuxon(DateTime.now()).toFormat(PICKER_FORMAT);
};

const nowDate = (initialValue: MaybeNil<IInitialValueFragment>) => {
  if (!isNil(initialValue)) {
    switch (initialValue.__typename) {
      case "ControlCardInitialFixedValue":
        if (initialValue.value.__typename === "ControlCardDateValue") {
          return dateLuxonToApi(dateApiToLuxon(initialValue.value.date));
        } else {
          break;
        }
      default:
        throw new UnreachableCaseError(initialValue.__typename);
    }
  }
  return dateLuxonToApi(DateTime.now());
};

const minTime = DateTime.now().minus({years: 5}).toISO();
const maxTime = DateTime.now().plus({years: 5}).toISO();

export const FstIonDatetimeButton = hofForwardRef(
  (
    {slot, presentation, disabled, value, buttonSize, buttonColor, initialValue, ...props}: IFstIonDatetimeButton,
    ref: Ref<HTMLIonButtonElement>
  ) => {
    const {t, i18n} = useTranslation();
    const modalRef = useRef<HTMLIonPopoverElement>(null);
    let displayValue: ReactNode | null = null;
    let pickerValue: string;
    if (typeof value === "string") {
      switch (presentation) {
        case "date": {
          displayValue = <DateDisplay dt={value} />;
          const date = dateApiToLuxon(value);
          pickerValue = date.toFormat(API_DATE_FORMAT);
          break;
        }
        case "time": {
          const date = timeApiToLuxon(value);
          displayValue = date.toFormat(PICKER_FORMAT, {locale: i18n.language});
          pickerValue = date.toFormat(API_TIME_FORMAT);
          break;
        }
        default: {
          throw new Error(`Unsupported presentation: ${presentation}`);
        }
      }
    } else {
      switch (presentation) {
        case "date": {
          displayValue = t("g.select-date");
          pickerValue = nowDate(initialValue);
          break;
        }
        case "time": {
          displayValue = t("g.select-time");
          pickerValue = nowTime(initialValue);
          break;
        }
        default: {
          throw new Error(`Unsupport presentation: ${presentation}`);
        }
      }
    }

    const handleClick = useCallback(() => {
      const [x] = getAppCenter();

      return modalRef.current?.present(
        new MouseEvent("click", {
          clientX: x,
          clientY: 16
        })
      );
    }, []);
    return (
      <>
        <IonButton
          color={buttonColor}
          disabled={disabled}
          onClick={handleClick}
          ref={ref}
          size={buttonSize}
          slot={slot}
        >
          {displayValue}
        </IonButton>
        <DatePopover alignment="center" keepContentsMounted ref={modalRef} reference="event" side="bottom" size="auto">
          <IonDatetime
            cancelText={t("g.cancel")}
            clearText={t("g.clear")}
            disabled={disabled}
            doneText={t("g.ok")}
            firstDayOfWeek={1}
            locale={i18n.language}
            max={maxTime}
            min={minTime}
            presentation={presentation}
            showClearButton
            showDefaultButtons
            value={pickerValue}
            {...props}
          />
        </DatePopover>
      </>
    );
  }
);
