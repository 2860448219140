import {FieldPath, FieldValues, useController} from "react-hook-form";
import {hofForwardRef, IIonInput, MaybeNil, noop, RhfProps, RhfRef} from "app/utils/types";
import {IonChip, IonInput} from "@ionic/react";
import {ForwardedRef, RefCallback, useCallback, useImperativeHandle, useMemo, useRef} from "react";
import {assignRef, isNotBlank} from "app/utils/stdlib";
import {useRunOnPressEnter} from "app/components/hooks/useRunOnPressEnter";

const extraClassNames = "ion-invalid ion-touched";

const reportValidity = () => true;

interface RhfIonInputProps extends Omit<IIonInput, "enterkeyhint" | "errorText" | "onIonInput" | "onKeyDown"> {
  unit?: MaybeNil<string>;
  onSubmitValue?: MaybeNil<() => void>;
}

export const RhfIonInput = hofForwardRef(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    {
      rhf,
      className,
      disabled,
      unit,
      onSubmitValue,
      children,
      ...rest
    }: RhfIonInputProps & RhfProps<TFieldValues, TName>,
    ref: ForwardedRef<HTMLIonInputElement>
  ) => {
    const {
      field: {onChange, ref: fieldRef, disabled: rhfDisabled, ...restField},
      fieldState: {error},
      formState: {isSubmitting}
    } = useController(rhf);

    const classNames = useMemo(() => {
      const classNames = [];
      if (className) {
        classNames.push(className);
      }

      if (error) {
        classNames.push(extraClassNames);
      }
      return classNames;
    }, [className, error]);

    const submitOnEnter = useRunOnPressEnter(onSubmitValue);

    const rhfRef = useRef<RhfRef | null>(null);
    const inputRef: RefCallback<HTMLIonInputElement> = useCallback(
      (element) => {
        assignRef(ref, element);
        rhfRef.current = {
          focus: () => element?.setFocus(),
          select: () => element?.getInputElement?.()?.then((e) => e?.select()),
          setCustomValidity: noop,
          reportValidity
        };
      },
      [ref]
    );
    useImperativeHandle(fieldRef, () => rhfRef.current);

    return (
      <IonInput
        ref={inputRef}
        {...rest}
        {...restField}
        className={classNames.join(" ")}
        disabled={isSubmitting || disabled || rhfDisabled}
        enterkeyhint="enter"
        errorText={error?.message}
        onIonInput={(event) => onChange(event.detail.value)}
        onKeyDown={submitOnEnter}
      >
        {isNotBlank(unit) && <IonChip slot="end">{unit}</IonChip>}
        {children}
      </IonInput>
    );
  }
);
