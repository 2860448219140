import {IColumnFragment, IControlCardRefValue, IValueInstanceFragment} from "app/gql/graphqlSchema";
import {
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail
} from "@ionic/react";
import {isEqual, isNil, isNotBlank} from "app/utils/stdlib";
import {useSaveControlCardValue} from "app/employee/controlCard/hook/useSaveControlCardValue";
import {IonSelectCustomEvent} from "@ionic/core/dist/types/components";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {useTranslation} from "react-i18next";
import {FlexColumn} from "app/employee/flexUtils";
import {SavingProgress} from "app/employee/controlCard/input/SavingProgress";
import {IApplicationError, transformToApplicationError} from "app/gql/handleGraphqlErrors";
import {useMemo, useState} from "react";
import {DisplayAppError} from "app/employee/controlCard/input/DisplayRootError";
import {refMapping} from "app/employee/controlCard/refUtils";
import {DisplayValueInstanceError} from "app/employee/controlCard/input/DisplayValueInstanceError";
import {useHasControlCardValueError} from "app/employee/controlCard/hook/useHasControlCardValueError";
import {InputIonCard} from "app/employee/controlCard/input/InputIonCard";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardEmployeeRefColumn"};
  valueInstance: IValueInstanceFragment;
  disabled?: boolean;
}

type IOption = {id: string; name: string; disabled: boolean};

export const InputEmployeeRef = ({column, valueInstance, disabled}: IProps) => {
  const {t} = useTranslation();
  const {instance, isComplete, canEdit} = useControlCardInstanceContext();
  const hasError = useHasControlCardValueError(valueInstance);
  const [appError, setAppError] = useState<IApplicationError | null>(null);
  const localDisabled = isComplete || !canEdit || !!disabled;

  const {saveValue, saving} = useSaveControlCardValue(valueInstance);
  let selectedId: string | null = null;
  const persistedValue = valueInstance.value;

  if (persistedValue.__typename === "ControlCardRefValue") {
    selectedId = persistedValue.id;
  }

  const options = useMemo((): IOption[] => {
    const columnRefOptions = instance.columnRefOptions.find((c) => isEqual(c.column.key, column.key));
    if (!isNil(columnRefOptions)) {
      const {filter} = refMapping[columnRefOptions.__typename];
      const refOptions = columnRefOptions.options;
      return refOptions
        .filter((option) => filter(option) || isEqual(option.ref.id, selectedId))
        .map((option) => {
          const {name, disabled} = option.employee;
          return {id: option.ref.id, name, disabled};
        });
    } else {
      return [];
    }
  }, [column.key, instance.columnRefOptions, selectedId]);

  const handleOnChange = async (e: IonSelectCustomEvent<SelectChangeEventDetail<typeof selectedId>>) => {
    try {
      setAppError(null);
      const v = e.detail.value;
      if (!isNil(v)) {
        const value: IControlCardRefValue = {
          __typename: "ControlCardRefValue",
          id: v
        };
        await saveValue(value);
      }
    } catch (e) {
      const appError = transformToApplicationError(e);
      setAppError(appError);
    }
  };

  return (
    <InputIonCard $hasError={hasError} data-scroll-to-value-instance-id={valueInstance.id}>
      <IonCardHeader>
        <IonCardTitle>{column.title}</IonCardTitle>
        {isNotBlank(column.description) && <IonCardSubtitle>{column.description}</IonCardSubtitle>}
      </IonCardHeader>
      <IonCardContent>
        <DisplayAppError appError={appError} />
        <FlexColumn>
          <IonSelect
            cancelText={t("g.cancel")}
            compareWith={isEqual}
            disabled={localDisabled}
            label={column.title}
            okText={t("g.save")}
            onIonChange={handleOnChange}
            value={selectedId}
          >
            {options.map((option) => {
              return (
                <IonSelectOption key={option.id} value={option.id}>
                  {option.name}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </FlexColumn>
        <DisplayValueInstanceError column={column} disabled={localDisabled} valueInstance={valueInstance} />
      </IonCardContent>
      <SavingProgress isSubmitting={saving} />
    </InputIonCard>
  );
};
