import {IonItem, IonLabel} from "@ionic/react";
import {FlexColumn} from "app/employee/flexUtils";
import {AttachmentCollection} from "app/employee/att/AttachmentCollection";
import {StateBadge} from "app/employee/organizer/document/StateBadge";
import {Validity} from "app/employee/organizer/document/Validity";
import {ExpirationBadge} from "app/employee/organizer/document/ExpirationBadge";
import {IOrganizerDocumentFragment} from "app/gql/graphqlSchema";
import {Attachments} from "app/employee/organizer/OrganizerUI";
import {DocumentReminderItem} from "app/employee/organizer/document/DocumentReminderItem";

interface IProps {
  document: IOrganizerDocumentFragment;
  onUploadCompleted?: () => void | Promise<void>;
  canEdit: boolean;
}

export const DocumentItem = ({document, onUploadCompleted, canEdit}: IProps) => {
  return (
    <>
      <IonItem lines="full">
        <IonLabel>
          <Validity document={document} /> <ExpirationBadge document={document} /> <StateBadge document={document} />
        </IonLabel>
      </IonItem>
      <DocumentReminderItem document={document} />
      <IonItem lines="full">
        <IonLabel>
          <Attachments>
            <FlexColumn>
              <AttachmentCollection
                attCollection={document.attachmentCollection}
                canUpload={canEdit}
                name={document.name}
                onUploadCompleted={onUploadCompleted}
              />
            </FlexColumn>
          </Attachments>
        </IonLabel>
      </IonItem>
      {document.notes !== "" && (
        <IonItem className="ion-text-wrap" lines="full">
          <p>{document.notes}</p>
        </IonItem>
      )}
    </>
  );
};
