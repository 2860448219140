import {AddEditFolder} from "app/employee/organizer/modal/AddEditFolder";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {IonButton, IonFab, IonIcon, IonLabel} from "@ionic/react";
import {ellipsisVerticalCircleOutline} from "ionicons/icons";
import {ArchiveOrganizerFolderDocument, IOrganizerFolderFragment, IOrganizerFragment} from "app/gql/graphqlSchema";
import {useTranslation} from "react-i18next";
import {forwardRef, useCallback, useImperativeHandle, useRef} from "react";
import {Uploady} from "@rpldy/uploady";
import {getUploadyDestination, query} from "app/gql/client";
import {IOrgDocUploaderHandle, OrgDocUploader} from "app/employee/organizer/OrgDocUploader";
import {useFolderContextMenu} from "app/employee/organizer/folder/useFolderContextMenu";
import {useLoadingContext} from "app/employee/LoadingContext";

interface IProps {
  organizer: IOrganizerFragment;
  folder: IOrganizerFolderFragment;
  onRefresh: () => void | Promise<void>;
  onBack: () => void | Promise<void>;
}

export interface IFolderFabHandle {
  showFileUpload: () => void;
}

const archiveOrgDocFolder = async (folder: IOrganizerFolderFragment) => {
  return await query(ArchiveOrganizerFolderDocument, {input: folder.id});
};

export const FolderFab = forwardRef<IFolderFabHandle, IProps>(({organizer, folder, onRefresh, onBack}, ref) => {
  const {t} = useTranslation();
  const folderRef = useRef<HTMLIonModalElement>(null);
  const uploaderRef = useRef<IOrgDocUploaderHandle>(null);
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();

  const handleDismiss = useCallback(() => {
    onRefresh();
  }, [onRefresh]);

  const onEdit = useCallback(() => {
    folderRef.current?.present();
  }, []);

  const onUploadDocument = useCallback(() => {
    uploaderRef.current?.showFileUpload({folderId: folder.id.folderId});
  }, [folder.id.folderId]);

  const onDelete = useCallback(async () => {
    startLoading();
    try {
      await archiveOrgDocFolder(folder);
      await onBack();
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  }, [finishLoading, folder, onBack, showExceptionError, startLoading]);

  const {showOptions} = useFolderContextMenu({onEdit, onUploadDocument, onDelete, name: folder.name});

  useImperativeHandle(
    ref,
    () => ({
      showFileUpload: onUploadDocument
    }),
    [onUploadDocument]
  );

  return (
    <>
      <FstIonModal onIonModalDidDismiss={handleDismiss} ref={folderRef}>
        <AddEditFolder folder={folder} organizerId={organizer.id} />
      </FstIonModal>
      <Uploady concurrent destination={getUploadyDestination()} maxConcurrent={2} method="POST" multiple>
        <OrgDocUploader onCompleted={onRefresh} organizer={organizer} ref={uploaderRef} />
      </Uploady>
      <IonFab horizontal="end" slot="fixed" vertical="bottom">
        <IonButton onClick={showOptions} shape="round">
          <IonIcon icon={ellipsisVerticalCircleOutline} slot="end" />
          <IonLabel>{t("g.action")}</IonLabel>
        </IonButton>
      </IonFab>
    </>
  );
});

FolderFab.displayName = "FolderFab";
