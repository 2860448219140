import {useRef} from "react";
import {useVideoStreams} from "app/employee/state/bootstrapEmployee/selectors";
import {useBoolean, useForceUpdate, useOnEvent} from "@fluentui/react-hooks";
import {Duration} from "luxon";
import {MaybeNil} from "app/utils/types";
import {IVideoLesson} from "app/gql/graphqlSchema";
import {IModalHandle, VideoPlayerModal} from "app/employee/video/VideoPlayerModal";
import {useProgressCallback} from "app/employee/video/useProgressCallback";
import {useChapters} from "app/employee/hooks/useChapters";
import {assertNotNil, isNil, isNotNil} from "app/utils/stdlib";
import {useAdminStore} from "app/state/admin";
import {styled} from "styled-components";
import {IShakaHandle, ShakaPlayer} from "app/employee/video/ShakaPlayer";
import {useVideoDialog} from "app/employee/video/useVideoDialog";
import {DURATION_ZERO} from "app/utils/dates";
import {useIonViewWillLeave} from "@ionic/react";

export interface IWatchCurrent {
  type: "current";
  fromTime: MaybeNil<Duration>;
  watchedLength: MaybeNil<Duration>;
  updateProgress: (currentTime: Duration) => void;
  handleReturn: () => unknown;
}

export interface IWatchSegment {
  type: "segment";
  fromTime: Duration;
  toTime: Duration;
  handleReturn: () => unknown;
}

export interface IWatchAgain {
  type: "again";
  handleReturn: () => unknown;
}

export type IPlayerControl = IWatchCurrent | IWatchSegment | IWatchAgain;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  height: 100%;
  gap: 1rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const TitleHeading = styled.div`
  background-color: #f3f2f1;
  font-weight: 500;
  padding: 0.5rem;
  text-align: center;
`;

interface IProps {
  videoLesson: Pick<IVideoLesson, "streamingLinks" | "chapters" | "title">;
  playerControl: IPlayerControl;
}

const getWatchedLength = (playerControl: IWatchAgain | IWatchCurrent | IWatchSegment) => {
  if (playerControl.type === "current") {
    return playerControl.watchedLength ?? Duration.fromMillis(0);
  } else {
    return undefined;
  }
};

const getEndAt = (playerControl: IWatchAgain | IWatchCurrent | IWatchSegment) => {
  if (playerControl.type === "segment") {
    return playerControl.toTime;
  } else {
    return undefined;
  }
};

const getStartFrom = (playerControl: IWatchCurrent | IWatchSegment | IWatchAgain) => {
  if (playerControl.type === "current" || playerControl.type === "segment") {
    return playerControl.fromTime;
  } else {
    return undefined;
  }
};

export const VideoPlayer = ({videoLesson, playerControl}: IProps) => {
  const forceUpdate = useForceUpdate();
  const [isEnded, {setTrue: setEnded, setFalse: beginAgain}] = useBoolean(false);
  const videoStreams = useVideoStreams(videoLesson.streamingLinks);
  const {disableChapters} = useAdminStore((state) => state.features);
  assertNotNil(videoStreams);

  const playerRef = useRef<IShakaHandle | null>(null);
  const modalRef = useRef<IModalHandle | null>(null);
  const {openModal, closeModal} = useVideoDialog(playerRef, modalRef);
  const mediaElement = playerRef.current?.mediaElement();

  const {checkPauseAt} = useChapters(videoLesson.chapters, playerControl);

  const startFrom = getStartFrom(playerControl);
  const endAt = getEndAt(playerControl);
  const watchedLength = getWatchedLength(playerControl);

  const playAgain = async () => {
    if (isNotNil(startFrom)) {
      playerRef.current?.setCurrentTime(startFrom);
    } else {
      playerRef.current?.setCurrentTime(DURATION_ZERO);
    }
    await closeModal();
    beginAgain();
    await mediaElement?.play();
  };

  const continueWatching = async () => {
    await closeModal();
    await mediaElement?.play();
  };

  const {updateProgressThrottled, updateProgress} = useProgressCallback(playerRef, playerControl);

  useOnEvent(mediaElement, "pause", updateProgress);

  useOnEvent(mediaElement, "ended", async () => {
    updateProgress();
    setEnded();
    await openModal();
  });

  useOnEvent(mediaElement, "timeupdate", async () => {
    if (!mediaElement || !playerRef.current) {
      return;
    }

    updateProgressThrottled();
    if (checkPauseAt.current) {
      const currentTime = playerRef.current.currentTime();
      if (currentTime) {
        const pauseAt = checkPauseAt.current(currentTime);
        if (isNotNil(pauseAt)) {
          if (!disableChapters) {
            console.log(
              `Pause at chapter at (${pauseAt.toFormat("hh:mm:ss.SSS")}), current time ${currentTime.toFormat(
                "hh:mm:ss.SSS"
              )}`
            );

            mediaElement.pause();
            playerRef.current.setCurrentTime(pauseAt);

            await openModal();
          }
        }
      }
    }
  });

  useOnEvent(mediaElement, "timeupdate", async () => {
    if (!mediaElement || !playerRef.current) {
      return;
    }

    const currentTime = playerRef.current.currentTime();
    if (!isNil(currentTime) && !isNil(endAt) && currentTime >= endAt) {
      if (!mediaElement.paused) {
        console.log(
          `Pause at end at (${endAt.toFormat("hh:mm:ss.SSS")}), current time ${currentTime.toFormat("hh:mm:ss.SSS")}`
        );

        mediaElement.pause();
        playerRef.current.setCurrentTime(endAt);

        await openModal();
      }
    }
  });

  useIonViewWillLeave(() => {
    closeModal();
  }, [closeModal]);

  return (
    <Container>
      <TitleWrapper>
        <TitleHeading>{videoLesson.title}</TitleHeading>
      </TitleWrapper>
      <ShakaPlayer
        onReady={forceUpdate}
        ref={playerRef}
        startFrom={startFrom}
        streams={videoStreams}
        watchedLength={watchedLength}
      />
      <VideoPlayerModal
        continueWatching={continueWatching}
        isEnded={isEnded}
        playAgain={playAgain}
        ref={modalRef}
        startControl={playerControl}
      />
      <div />
    </Container>
  );
};
