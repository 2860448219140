import {isNil, isNotNil} from "app/utils/stdlib";
import {IPage} from "app/gql/graphqlSchema";
import {IonButton, IonIcon} from "@ionic/react";
import {arrowBackCircleOutline, arrowForwardCircleOutline} from "ionicons/icons";
import {styled} from "styled-components";
import {useTranslation} from "react-i18next";

export type IPageFunc = (itemCount: number) => string;

interface IProps {
  page?: IPage;
  setPage: (page: number) => void;
  pageFunc: IPageFunc;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  justify-content: end;
`;

const PageLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`;

export const Pagination = ({page, setPage, pageFunc}: IProps) => {
  const {t} = useTranslation();
  if (isNil(page)) {
    return null;
  }

  const prevPage = page.previous;
  const prevButton = (
    <IonButton
      disabled={isNil(prevPage)}
      fill="outline"
      onClick={isNotNil(prevPage) ? () => setPage(prevPage) : undefined}
    >
      <IonIcon icon={arrowBackCircleOutline} slot="icon-only" />
    </IonButton>
  );

  const nextPage = page.next;
  const nextButton = (
    <IonButton
      disabled={isNil(nextPage)}
      fill="outline"
      onClick={isNotNil(nextPage) ? () => setPage(nextPage) : undefined}
    >
      <IonIcon icon={arrowForwardCircleOutline} slot="icon-only" />
    </IonButton>
  );

  const countLabel = pageFunc(page.itemCount);
  const pageNumberLabel = t("g.pagination.page", {
    defaultValue: "Страница {{pageNumber}} от {{pageCount}}",
    pageNumber: page.number,
    pageCount: page.count
  });

  return (
    <Container>
      <PageLabel>
        <strong>{countLabel}</strong>
        <div>{pageNumberLabel}</div>
      </PageLabel>
      {prevButton}
      {nextButton}
    </Container>
  );
};
