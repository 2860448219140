import {selectMaybeLoaded} from "app/state/common/loadable";
import {Feature, IStreamingLinks} from "app/gql/graphqlSchema";
import {updateDefaultLocation, useBootstrapEmployeeStore} from "app/employee/state/bootstrapEmployee/mutations";
import {useCallback, useMemo} from "react";
import {assertNotNil} from "app/utils/stdlib";

export const useEmployeeBootstrapResult = () => {
  return useBootstrapEmployeeStore((state) => selectMaybeLoaded(state.bootstrapResult));
};

export const useEmployeeBuildInfo = () => {
  const boostrapResult = useEmployeeBootstrapResult();
  return boostrapResult?.buildInfo;
};

export const useVideoStreams = (streamingLinks: IStreamingLinks) => {
  const boostrapResult = useEmployeeBootstrapResult();
  if (boostrapResult?.__typename === "BootstrapEmployee") {
    return {
      mpd: boostrapResult.vodUrlPrefix + streamingLinks.dash,
      hls: boostrapResult.vodUrlPrefix + streamingLinks.hls,
      poster: boostrapResult.vodUrlPrefix + streamingLinks.thumbnail
    };
  }
  return;
};

export const useEnabledFeatures = () => {
  const boostrapResult = useEmployeeBootstrapResult();
  const features = useMemo(
    () => (boostrapResult?.__typename === "BootstrapEmployee" ? boostrapResult.employee.client.features : []),
    [boostrapResult]
  );

  return useCallback(
    (feature: Feature) => {
      return features.includes(feature);
    },
    [features]
  );
};

export const useDefaultLocationId = () => {
  const defaultLocationId = useBootstrapEmployeeStore((state) => state.defaultLocationId);
  return [defaultLocationId, updateDefaultLocation] as const;
};

/**
 * TODO: reload locations
 */
export const useEmployeeLocations = () => {
  const boostrapResult = useEmployeeBootstrapResult();
  return useMemo(
    () => (boostrapResult?.__typename === "BootstrapEmployee" ? boostrapResult.employee.locations : []),
    [boostrapResult]
  );
};

export const useEmployeeClientId = () => {
  const boostrapResult = useEmployeeBootstrapResult();
  const clientId = useMemo(
    () => (boostrapResult?.__typename === "BootstrapEmployee" ? boostrapResult.employee.client.id : undefined),
    [boostrapResult]
  );
  assertNotNil(clientId);
  return clientId;
};
