import {IAnyDate, MaybeNil} from "app/utils/types";
import {parseAnyDate} from "app/utils/dates";
import {isNil} from "app/utils/stdlib";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {DateTime} from "luxon";
import {ToRelativeUnit} from "luxon/src/datetime";

interface Props {
  dt: MaybeNil<IAnyDate>;
}

const pickUnit = (date: DateTime): ToRelativeUnit => {
  const {weeks: relWeeks} = DateTime.now().diff(date, "weeks");
  const weeks = Math.abs(relWeeks);
  if (weeks > 52) {
    return "years";
  } else if (weeks > 4) {
    return "months";
  } else if (weeks > 2) {
    return "weeks";
  } else {
    return "days";
  }
};

export const RelativeDateDisplay = ({dt}: Props) => {
  const {i18n} = useTranslation();

  const relTs = useMemo(() => {
    if (isNil(dt)) {
      return null;
    }
    const luxonTs = parseAnyDate(dt);
    return luxonTs.toRelativeCalendar({locale: i18n.language, unit: pickUnit(luxonTs)});
  }, [dt, i18n.language]);

  if (isNil(relTs)) {
    return null;
  } else {
    return <>{relTs}</>;
  }
};
