import {IChoiceOptionFragment, IValueFragment} from "app/gql/graphqlSchema";
import {EmptyValue} from "app/employee/controlCard/display/EmptyValue";
import {isEqual, isNil} from "app/utils/stdlib";
import {InvalidValue} from "app/employee/controlCard/display/InvalidValue";

interface IProps {
  value: IValueFragment;
  options: IChoiceOptionFragment[];
}

export const DisplayOption = ({value, options}: IProps) => {
  if (value.__typename === "ControlCardEmptyValue") {
    return <EmptyValue />;
  } else if (
    value.__typename === "ControlCardSingleChoiceValue" ||
    value.__typename === "ControlCardChoiceWithCommentValue"
  ) {
    const optionKey = value.optionKey;
    const selectedOption = options.find((option) => isEqual(option.key, optionKey));
    if (isNil(selectedOption)) {
      return <InvalidValue />;
    } else {
      if (
        value.__typename === "ControlCardSingleChoiceValue" &&
        selectedOption.__typename === "ControlCardSingleChoiceOption"
      ) {
        return <span>{selectedOption.text}</span>;
      } else if (
        value.__typename === "ControlCardChoiceWithCommentValue" &&
        selectedOption.__typename === "ControlCardChoiceWithCommentOption"
      ) {
        return (
          <span>
            {selectedOption.text} ({selectedOption.commentLabel}: {value.comment})
          </span>
        );
      } else {
        return <InvalidValue />;
      }
    }
  } else {
    return <InvalidValue />;
  }
};
