import {
  IonAccordion,
  IonAccordionGroup,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  useIonModal
} from "@ionic/react";
import {language, logOutOutline, settingsOutline} from "ionicons/icons";
import {Trans, useTranslation} from "react-i18next";
import {useAdminStore} from "app/state/admin";
import {AdminMenu} from "app/employee/menu/AdminMenu";
import {useEmployeeBuildInfo} from "app/employee/state/bootstrapEmployee/selectors";
import {styled} from "styled-components";
import {Timestamp} from "app/employee/Timestamp";
import {isNil} from "app/utils/stdlib";
import {LanguageSelect} from "app/employee/menu/LanguageSelect";
import {IBootstrapResult, logoutEmployeeAndBootstrap} from "app/employee/state/bootstrapEmployee/mutations";
import {MaybeNil} from "app/utils/types";
import {menuController} from "@ionic/core/components";
import {useCallback} from "react";

const Copyright = styled.div`
  font-size: 0.8rem;
`;

const BuildInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  font-size: 0.6rem;
  gap: 0.2rem;
  margin: 0 auto;
`;

const BuildInfoLocation = styled.div`
  grid-column: 2 / -1;
`;

export const EmployeeMenuFooter = ({bootstrapResult}: {bootstrapResult: MaybeNil<IBootstrapResult>}) => {
  const buildInfo = useEmployeeBuildInfo();
  const testMode = useAdminStore((state) => state.testMode);
  const {t} = useTranslation();
  const [presentAdminMenu, dismissAdminMenu] = useIonModal(AdminMenu, {
    onDismiss: () => dismissAdminMenu()
  });

  const clientVersion = import.meta.env.VITE_BUILD_VERSION;
  const clientBuildTime = import.meta.env.VITE_BUILD_TIMESTAMP;

  const isLoggedIn = bootstrapResult?.__typename === "BootstrapEmployee";
  const privacyPolicy = t("g.privacy-policy-url", {defaultValue: "/legal/privacy-policy/latest"});

  const logout = useCallback(async () => {
    await logoutEmployeeAndBootstrap();
    await menuController.close();
  }, []);

  return (
    <IonFooter>
      <IonList>
        <IonItem>
          <IonIcon icon={language} slot="start" />
          <LanguageSelect />
        </IonItem>

        {isLoggedIn && (
          <IonItem button onClick={logout}>
            <IonIcon icon={logOutOutline} slot="start" />
            <Trans i18nKey="menu.logout" t={t} />
          </IonItem>
        )}

        {testMode && (
          <IonItem button onClick={() => presentAdminMenu()} slot="end">
            <IonIcon icon={settingsOutline} slot="start" />
            <Trans i18nKey="menu.test-mode" t={t} />
          </IonItem>
        )}
        <IonAccordionGroup>
          <IonAccordion value="1">
            <IonItem slot="header">
              <IonLabel className="ion-text-wrap">
                <Copyright>
                  <Trans i18nKey="g.copyright-and-privacy-policy" t={t}>
                    © 2025 Еф Ес Академи ООД ⋅{" "}
                    <a href={privacyPolicy} rel="noreferrer" target="_blank">
                      Политика за лични данни
                    </a>
                  </Trans>
                </Copyright>
              </IonLabel>
            </IonItem>
            <IonItem slot="content">
              <BuildInfo>
                <span>Клиент:</span>
                <span>{clientVersion}</span>
                <span>
                  <Timestamp ts={clientBuildTime} />
                </span>
                {!isNil(buildInfo) && (
                  <>
                    <span>Сървър:</span>
                    <span>{buildInfo.version}</span>
                    <span>
                      <Timestamp ts={buildInfo.buildTime} />
                    </span>
                  </>
                )}
                <span>Адрес:</span>
                <BuildInfoLocation>{window.location.host}</BuildInfoLocation>
              </BuildInfo>
            </IonItem>
          </IonAccordion>
        </IonAccordionGroup>
      </IonList>
    </IonFooter>
  );
};
