import {styled} from "styled-components";

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FlexColumnCenter = styled(FlexColumn)`
  align-items: center;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const FlexRight = styled.div`
  margin-left: auto;
`;

export const FlexCellFull = styled.div`
  flex: 0 1 auto;
`;
