import {useDocument} from "@fluentui/react";
import {Button, ButtonAction} from "app/employee/login/Button";
import {ButtonInput, DigitInput} from "app/employee/login/DigitInput";
import {useImmer} from "use-immer";
import {useOnEvent} from "@fluentui/react-hooks";
import {KeyboardEvent} from "react";
import {includes} from "app/utils/types";
import {styled} from "styled-components";
import {IonButton, IonIcon} from "@ionic/react";
import {backspaceOutline, logInOutline} from "ionicons/icons";
import {Trans, useTranslation} from "react-i18next";

const MAX_LENGTH = 9;

interface IProps {
  onSubmit: (value: string) => unknown;
  disabled?: boolean;
}

const KeypadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

const KeyPadGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-items: center;
  gap: 1rem;
`;

const LoginWrapper = styled.div`
  gap: 0.5rem;
  align-self: center;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 300px;
`;

const PrivacyPolicyText = styled.div`
  padding: 0 0.5rem;
  font-size: 0.8rem;
  text-align: center;
  user-select: none;
`;

const BackSpaceIcon = styled(IonIcon).attrs({icon: backspaceOutline})`
  margin-top: 0.2rem;
`;

const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"] as const;

export const Keypad = ({onSubmit, disabled}: IProps) => {
  const {t} = useTranslation();
  const [accessCode, setAccessCode] = useImmer<ButtonInput[]>([]);
  const isIncomplete = accessCode.length < MAX_LENGTH;
  const isComplete = !isIncomplete;
  const canDelete = accessCode.length > 0;
  const privacyPolicy = t("g.privacy-policy-url", {defaultValue: "/legal/privacy-policy/latest"});

  const loginHandler = () => {
    onSubmit(accessCode.join(""));
  };

  const buttonClickHandler = (action: ButtonAction) => {
    if (action === "back") {
      if (accessCode.length > 0) {
        setAccessCode((draft) => {
          draft.splice(accessCode.length - 1, 1);
        });
      }
    } else {
      if (isIncomplete) {
        setAccessCode((draft) => {
          draft.push(action);
        });
      }
    }
  };

  const document = useDocument();

  const typeNumber = (e: Event) => {
    const {code, key} = e as unknown as KeyboardEvent;
    if (includes(numbers, key)) {
      buttonClickHandler(key);
    } else if (code === "Enter") {
      loginHandler();
    } else if (code === "Backspace" || code === "Delete" || code === "ArrowLeft") {
      buttonClickHandler("back");
    }
  };

  useOnEvent(document, "keyup", typeNumber);

  const loginDisabled = isIncomplete || disabled;

  return (
    <KeypadWrapper>
      <DigitInput disabled={disabled} maxSize={MAX_LENGTH} value={accessCode} />
      <KeyPadGrid>
        <Button action="1" disabled={isComplete || disabled} onClick={buttonClickHandler} />
        <Button action="2" disabled={isComplete || disabled} onClick={buttonClickHandler} />
        <Button action="3" disabled={isComplete || disabled} onClick={buttonClickHandler} />
        <Button action="4" disabled={isComplete || disabled} onClick={buttonClickHandler} />
        <Button action="5" disabled={isComplete || disabled} onClick={buttonClickHandler} />
        <Button action="6" disabled={isComplete || disabled} onClick={buttonClickHandler} />
        <Button action="7" disabled={isComplete || disabled} onClick={buttonClickHandler} />
        <Button action="8" disabled={isComplete || disabled} onClick={buttonClickHandler} />
        <Button action="9" disabled={isComplete || disabled} onClick={buttonClickHandler} />
        <div />
        <Button action="0" disabled={isComplete || disabled} onClick={buttonClickHandler} />
        <Button
          action="back"
          disabled={!canDelete || disabled}
          onClick={buttonClickHandler}
          value={<BackSpaceIcon />}
        />
      </KeyPadGrid>
      <LoginWrapper>
        <IonButton disabled={loginDisabled} onClick={loginHandler} size="large">
          <IonIcon icon={logInOutline} slot="end" />
          {t("login.login-button", {defaultValue: "Вход"})}
        </IonButton>
        <PrivacyPolicyText>
          <Trans i18nKey="login.privacy-policy" t={t}>
            С натискането на бутона &quot;Вход&quot; се съглаявате с{" "}
            <a href={privacyPolicy} rel="noreferrer" target="_blank">
              политиката за лични данни
            </a>
          </Trans>
        </PrivacyPolicyText>
      </LoginWrapper>
    </KeypadWrapper>
  );
};
