import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
  useIonToast
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {useTranslation} from "react-i18next";
import {checkmarkCircleOutline, closeOutline} from "ionicons/icons";
import {selectMaybeLoaded} from "app/state/common/loadable";
import {getCurrentClientId} from "app/state/currentClient/mutations";
import {linkEmplSupplierHome} from "app/employee/core/employeeLinks";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {
  createSupplier,
  getSupplier,
  ISearchResultSupplier,
  updateSupplier,
  useSupplierStore
} from "app/employee/state/supplier/mutations";
import {reset} from "app/state/boForm/mutations";
import {isNil} from "app/utils/stdlib";
import {IOperationResult, MaybeNil} from "app/utils/types";
import {useDismiss} from "app/employee/incdoc/useDismiss";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {RhfIonInput} from "app/employee/rhf/RhfIonInput";
import {RhfIonTextarea} from "app/employee/rhf/RhfIonTextarea";
import {parseErrorResult} from "app/utils/parseErrorResult";
import {RhfRequiredLabel} from "app/employee/incdoc/RequiredLabel";
import {useLoadingContext} from "app/employee/LoadingContext";
import {zNormalizedString} from "app/utils/validator";
import {t} from "i18next";

const formSchema = z.object({
  name: zNormalizedString.pipe(z.string().min(1, t("g.required"))),
  eik: zNormalizedString,
  notes: zNormalizedString
});

type IFormData = z.infer<typeof formSchema>;

export const AddEditSupplier = ({defaults}: {defaults?: Partial<ISearchResultSupplier>}) => {
  const {startLoading, finishLoading} = useLoadingContext();

  const {t} = useTranslation();
  const {supplierId} = useParams<{supplierId?: string}>();
  const supplier = useSupplierStore((state) => selectMaybeLoaded(state.supplier));
  const backHandler = useDismiss(linkEmplSupplierHome());
  const [presentToast] = useIonToast();

  const {
    control,
    handleSubmit,
    watch,
    formState: {isSubmitting, errors},
    setError,
    setValue,
    reset: resetForm
  } = useForm<IFormData>({
    defaultValues: {
      name: defaults?.name ?? "",
      eik: defaults?.eik ?? "",
      notes: defaults?.notes ?? ""
    },
    resolver: zodResolver(formSchema)
  });

  useEffect(() => {
    if (supplier) {
      setValue("name", supplier.name);
      setValue("eik", supplier.eik);
      setValue("notes", supplier.notes);
    } else {
      resetForm();
    }
  }, [resetForm, setValue, supplier]);

  const onSaveHandler = async (form: IFormData) => {
    startLoading();
    try {
      let operationResult: MaybeNil<IOperationResult<ISearchResultSupplier>>;
      if (isNil(supplierId)) {
        const clientId = getCurrentClientId();
        const result = await createSupplier({
          name: form.name,
          clientId,
          eik: form.eik,
          notes: form.notes
        });
        if (result.__typename === "ErrorResult") {
          operationResult = {
            success: false,
            message: t("incdoc.supplier.add-supplier-error", {defaultValue: "Грешка при добавяне на доставчик"}),
            errors: result.errors
          };
        } else {
          operationResult = {
            success: true,
            message: t("incdoc.supplier.add-supplier-success", {defaultValue: "Успешно добавяне на доставчик"}),
            data: result.supplier
          };
        }
      } else if (!isNil(supplier)) {
        const result = await updateSupplier({
          name: form.name,
          supplierId: supplier.id,
          eik: form.eik,
          notes: form.notes
        });
        if (result.__typename === "ErrorResult") {
          operationResult = {
            message: t("incdoc.supplier.edit-supplier-error", {defaultValue: "Грешка при промяна на доставчик"}),
            success: false,
            errors: result.errors
          };
        } else {
          operationResult = {
            success: true,
            message: t("incdoc.supplier.edit-supplier-success", {defaultValue: "Успешна промяна на доставчик"}),
            data: result.supplier
          };
        }
      }
      if (operationResult) {
        await presentToast({
          position: "top",
          message: operationResult.message,
          color: operationResult.success ? "success" : "danger",
          duration: 2000
        });
        if (operationResult.success) {
          await backHandler(operationResult.data);
        } else {
          parseErrorResult(operationResult, setError);
        }
      }
    } finally {
      finishLoading();
    }
  };

  useEffect(() => {
    if (!isNil(supplierId)) {
      const abortController = new AbortController();
      getSupplier(supplierId, abortController.signal);
      return () => {
        abortController.abort();
        reset();
      };
    }
  }, [supplierId]);

  const title = isNil(supplier)
    ? t("incdoc.supplier.add-title", {defaultValue: "Добавяне на доставчик"})
    : t("incdoc.supplier.edit-title", {defaultValue: "Редактиране на доставчик"});

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{title}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <RhfIonInput
              inputMode="text"
              labelPlacement="stacked"
              placeholder={t("incdoc.supplier.name-placeholder", {defaultValue: "Въведете името на доставчика"})}
              required
              rhf={{control, name: "name"}}
            >
              <div slot="label">
                {t("incdoc.supplier.name", {defaultValue: "Име"})}{" "}
                <RhfRequiredLabel errors={errors} field="name" watch={watch} />
              </div>
            </RhfIonInput>
          </IonItem>
          <IonItem>
            <RhfIonInput
              inputMode="text"
              labelPlacement="stacked"
              placeholder={t("incdoc.supplier.eik-placeholder", {defaultValue: "Въведете ЕИК на доставчика"})}
              rhf={{control, name: "eik"}}
            >
              <div slot="label">{t("incdoc.supplier.eik", {defaultValue: "ЕИК"})}</div>
            </RhfIonInput>
          </IonItem>
          <IonItem>
            <RhfIonTextarea
              labelPlacement="stacked"
              placeholder={t("incdoc.supplier.notes-placeholder", {
                defaultValue: "Допълнителни бележки (ако има нужда)"
              })}
              rhf={{control, name: "notes"}}
            >
              <div slot="label">{t("incdoc.supplier.notes", {defaultValue: "Бележки"})}</div>
            </RhfIonTextarea>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton color="primary" fill="outline" onClick={() => backHandler(null)}>
              <IonIcon icon={closeOutline} slot="start" />
              {t("g.cancel", {defaultValue: "Откажи"})}
            </IonButton>
            <IonButton color="primary" disabled={isSubmitting} fill="solid" onClick={handleSubmit(onSaveHandler)}>
              <IonIcon icon={checkmarkCircleOutline} slot="start" />
              {t("g.save", {defaultValue: "Запази"})}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
