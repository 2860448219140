import {PropsWithChildren} from "react";
import {Feature} from "app/gql/graphqlSchema";
import {useEnabledFeatures} from "app/employee/state/bootstrapEmployee/selectors";
import {NotFoundPage} from "app/employee/page/NotFoundPage";

interface IProps {
  feature: Feature;
}

export const RoutingGuard = ({feature, children}: PropsWithChildren<IProps>) => {
  const checkFeature = useEnabledFeatures();
  const featureEnabled = checkFeature(feature);

  if (featureEnabled) {
    return <>{children}</>;
  } else {
    return <NotFoundPage />;
  }
};
