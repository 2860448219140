import {assertNotNil, isEqual, isNil} from "app/utils/stdlib";
import {createContext, ReactNode, useCallback, useContext, useMemo} from "react";
import {useSafePathParams, zControlCardInstance} from "app/components/hooks/useSafeParams";
import {useEmployeeClientId} from "app/employee/state/bootstrapEmployee/selectors";
import {
  IColumnFragment,
  IControlCardInstanceFullFragment,
  IControlCardInstanceId,
  IControlCardInstanceUpdatedValueFragment
} from "app/gql/graphqlSchema";
import {useControlCardInstance} from "app/employee/controlCard/hook/useControlCardInstance";
import {ID, MaybeNil} from "app/utils/types";
import {useIonViewWillEnter} from "@ionic/react";
import {IRefetch} from "app/employee/controlCard/hook/useQuery";
import {ApiState} from "app/state/common/loadable";
import {LoadingLoadable} from "app/employee/Loading";
import {usePermissions} from "app/employee/hooks/usePermissions";
import {useRemount} from "app/employee/controlCard/hook/useRemount";
import {Updater} from "use-immer";

interface IControlCardInstanceContext {
  loadingState: ApiState;
  setInstance: Updater<IControlCardInstanceFullFragment>;
  instance: IControlCardInstanceFullFragment;
  rowId: MaybeNil<ID>;
  primaryColumn: IColumnFragment;
  isComplete: boolean;
  isRowComplete: (rowId: MaybeNil<ID>) => boolean;
  updateValue: (newValue: IControlCardInstanceUpdatedValueFragment) => void;
  refresh: IRefetch;
  canEdit: boolean;
  isTransposedCard: boolean;
}

const Context = createContext<IControlCardInstanceContext | null>(null);

export const ControlCardInstanceContext = ({children}: {children: ReactNode}) => {
  const {canEditControlCardLocation} = usePermissions();
  const {instanceId, rowId} = useSafePathParams(zControlCardInstance);
  const remountKey = useRemount();
  const clientId = useEmployeeClientId();

  const controlCardInstanceId = useMemo((): IControlCardInstanceId => ({instanceId, clientId}), [instanceId, clientId]);

  const {setData, refetch, fetch, data} = useControlCardInstance();
  const instance = data.value;
  const isRowComplete = useCallback(
    (rowId: MaybeNil<ID>) =>
      !isNil(rowId) && (instance?.rows.filter((r) => rowId === r.rowId).every((r) => !isNil(r.completedBy)) ?? false),
    [instance?.rows]
  );

  const isComplete = useMemo(
    () => !isNil(instance?.completedAt) || isRowComplete(rowId),
    [instance?.completedAt, isRowComplete, rowId]
  );

  const updateValue = useCallback(
    (newValue: IControlCardInstanceUpdatedValueFragment) => {
      setData((draft) => {
        for (const row of draft.rows) {
          for (const valueInstance of row.columns) {
            if (valueInstance.id === newValue.valueId) {
              valueInstance.value = newValue.value;
              valueInstance.validationResult = newValue.validationResult;
              valueInstance.overriddenValidationResult = newValue.overriddenValidationResult ?? null;
              break;
            }
          }
        }
      });
    },
    [setData]
  );

  const contextValue = useMemo((): IControlCardInstanceContext | null => {
    if (!isNil(instance)) {
      const definition = instance.definition;
      const primaryColumnKey = definition.primaryColumnKey;
      const primaryColumn = definition.columns.find((c) => isEqual(c.key, primaryColumnKey));
      assertNotNil(primaryColumn);
      const isTransposedCard =
        !definition.dynamicRows &&
        instance.rows.length === 1 &&
        primaryColumn.__typename === "ControlCardTransposedColumn";

      const canEdit = canEditControlCardLocation(instance.location.id, instance.date);
      return {
        refresh: refetch,
        rowId,
        primaryColumn,
        instance,
        isComplete,
        isRowComplete,
        setInstance: setData,
        loadingState: data.loadingState,
        updateValue,
        canEdit,
        isTransposedCard
      };
    } else {
      return null;
    }
  }, [
    canEditControlCardLocation,
    instance,
    data.loadingState,
    isComplete,
    isRowComplete,
    refetch,
    rowId,
    setData,
    updateValue
  ]);

  useIonViewWillEnter(() => {
    fetch({id: controlCardInstanceId});
  }, [fetch, controlCardInstanceId]);

  return (
    <>
      <LoadingLoadable loadable={data} />
      {!isNil(contextValue) && (
        <Context.Provider key={remountKey} value={contextValue}>
          {children}
        </Context.Provider>
      )}
    </>
  );
};

export const useControlCardInstanceContext = () => {
  const context = useContext(Context);
  assertNotNil(context);
  return context;
};
