import {IColumnFragment, IValueFragment} from "app/gql/graphqlSchema";
import {PrimaryRowRefValue} from "app/employee/controlCard/primaryKey/PrimaryRowRefValue";
import {assertNotNil, isEqual} from "app/utils/stdlib";
import {ISelectedValue} from "app/employee/controlCard/primaryKey/types";
import {useMemo} from "react";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardEmployeeRefColumn"};
  setValues: (value: IValueFragment[]) => void | Promise<void>;
}

export const PrimaryRowEmployeeRefValue = ({column: primaryColumn, setValues}: IProps) => {
  const {instance} = useControlCardInstanceContext();

  const {selectedValues, refOption} = useMemo(() => {
    const refOption = instance.columnRefOptions.find((c) => isEqual(c.column.key, primaryColumn.key));
    assertNotNil(refOption);

    const selectedValues: ISelectedValue[] = [];
    for (const row of instance.rows) {
      const column = row.columns.find((c) => isEqual(c.columnKey, primaryColumn.key));
      if (column?.value.__typename === "ControlCardRefValue") {
        selectedValues.push({rowId: row.rowId, value: column.value});
      }
    }

    return {refOption, selectedValues};
  }, [instance.columnRefOptions, instance.rows, primaryColumn.key]);

  return (
    <PrimaryRowRefValue
      preselectedValues={selectedValues}
      refOption={refOption}
      setValues={setValues}
      title={primaryColumn.title}
    />
  );
};
