import {IValueInstanceFragment} from "app/gql/graphqlSchema";
import {isNil} from "app/utils/stdlib";

export const useHasControlCardValueError = (valueInstance: IValueInstanceFragment) => {
  return (
    valueInstance.validationResult.__typename === "ErrorValidationResult" &&
    !valueInstance.validationResult.initial &&
    isNil(valueInstance.overriddenValidationResult)
  );
};
