import {ISyllabusTermPlan} from "app/employee/state/syllabus/mutations";
import {FlexColumn} from "app/employee/flexUtils";
import {LessonRow} from "app/employee/syllabus/LessonRow";
import {PlanBadge} from "app/employee/syllabus/PlanBadge";
import {PlanPeriodAndDeadline} from "app/employee/syllabus/PlanPeriodAndDeadline";
import {fractionToPercent, scoreToPercent} from "app/utils/stdlib";
import {completedVideoLessonPredicate} from "app/employee/syllabus/utils";
import {IonProgressBar} from "@ionic/react";
import {Trans, useTranslation} from "react-i18next";
import {DateDisplay} from "app/employee/DateDisplay";

const VideoLessonProgress = ({termPlan}: {termPlan: ISyllabusTermPlan}) => {
  const {t} = useTranslation();
  const lessons = termPlan.lessons;
  const totalLessonsCount = lessons.length;
  if (termPlan.completed || totalLessonsCount === 0) {
    return null;
  }
  const completedVideoLessons = lessons.filter(completedVideoLessonPredicate);
  const completedLessonsCount = completedVideoLessons.length;
  if (completedLessonsCount === 0) {
    return null;
  }

  const progress = completedLessonsCount / totalLessonsCount;
  const progressPercent = fractionToPercent(progress);
  const averageScorePercent = scoreToPercent(termPlan.averageScore);
  return (
    <>
      <IonProgressBar type="determinate" value={progress} />
      <div>
        {t("syllabus.in-progress.course-progress", {
          completedLessonsCount,
          totalLessonsCount,
          progressPercent,
          averageScorePercent,
          defaultValue:
            "Завършени {{completedLessonsCount}} от {{totalLessonsCount}} урока ({{progressPercent}}) със среден успех {{averageScorePercent}}"
        })}
      </div>
    </>
  );
};

const PlanSummary = ({termPlan}: {termPlan: ISyllabusTermPlan}) => {
  const {t} = useTranslation();
  const plan = termPlan.clientPlan;
  const scorePercent = scoreToPercent(termPlan.averageScore);
  return (
    <>
      <div>
        <PlanBadge plan={plan} /> <PlanPeriodAndDeadline termPlan={termPlan} />
      </div>
      {termPlan.completed && (
        <em>
          <Trans i18nKey="syllabus.completed.completed-at" scorePercent={scorePercent} t={t}>
            Завършено на{" "}
            <strong>
              <DateDisplay dt={termPlan.completedAt} />
            </strong>{" "}
            със среден успех <strong>{{scorePercent}}</strong>
          </Trans>
        </em>
      )}
    </>
  );
};

interface IDetailedProgressPlanProps {
  termPlan: ISyllabusTermPlan;
}

const DetailedProgressPlan = ({termPlan}: IDetailedProgressPlanProps) => {
  return (
    <FlexColumn>
      <PlanSummary termPlan={termPlan} />
      <VideoLessonProgress termPlan={termPlan} />
      {termPlan.lessons.map((lesson) => (
        <LessonRow key={lesson.videoLesson.id} lesson={lesson} />
      ))}
    </FlexColumn>
  );
};

interface IProps {
  termPlans: ISyllabusTermPlan[];
}

export const DetailedProgress = ({termPlans}: IProps) => {
  return (
    <FlexColumn>
      {termPlans.map((plan) => (
        <DetailedProgressPlan key={plan.clientPlan.id.planId} termPlan={plan} />
      ))}
      {/*{term.adhocVideoLessons.length > 0 && (*/}
      {/*  <Stack>*/}
      {/*    <Stack.Item>*/}
      {/*      <Text variant="mediumPlus">Допълнителни видео уроци</Text>*/}
      {/*    </Stack.Item>*/}
      {/*    <Stack.Item>*/}
      {/*      {term.adhocVideoLessons.map((lesson) => (*/}
      {/*        <LessonDetails key={lesson.videoLesson.id} lesson={lesson} />*/}
      {/*      ))}*/}
      {/*    </Stack.Item>*/}
      {/*  </Stack>*/}
      {/*)}*/}
    </FlexColumn>
  );
};
