import uFuzzy from "@leeoniya/ufuzzy";
import {MaybeNil} from "app/utils/types";
import {Fragment, ReactNode, useMemo} from "react";

// Unicode / universal (50%-75% slower)
const uf = new uFuzzy({
  unicode: true,
  interSplit: "[^\\p{L}\\d']+",
  intraSplit: "\\p{Ll}\\p{Lu}",
  intraBound: "\\p{L}\\d|\\d\\p{L}|\\p{Ll}\\p{Lu}",
  intraChars: "[\\p{L}\\d']",
  intraContr: "'\\p{L}{1,2}\\b",
  /**
   * intraMode: 1 allows for a single error in each term of the search phrase, where an error is one of:
   * substitution (replacement), transposition (swap), insertion (addition), or deletion (omission).
   * The search strings with errors below can return matches containing "example". What is actually matched will
   * depend on additional fuzziness settings. In contrast to the previous mode, searching for "example"
   * will never match "extra maple".
   */
  intraMode: 1
});

interface IFilteredItem<T> {
  item: T;
  highlighted: ReactNode;
}

const mark = (part: string, matched: boolean) => (matched ? <mark>{part}</mark> : part);
const append = (accum: ReactNode[], part: ReactNode) => {
  accum.push(part);
  return accum;
};

export const useFuzzySearch = <T,>(
  term: MaybeNil<string>,
  items: T[],
  textExtractor: (item: T) => string,
  filter?: (item: T) => boolean
): {
  filteredItems: IFilteredItem<T>[];
} => {
  const filteredItems = useMemo(() => {
    let filteredItems: IFilteredItem<T>[] = [];

    const prefilteredItems = filter ? items.filter(filter) : items;

    const haystack = prefilteredItems.map(textExtractor);
    const needle = term ?? "";
    // pre-filter
    const [idxs, info, order] = uf.search(haystack, needle);

    // idxs can be null when the needle is non-searchable (has no alpha-numeric chars)
    if (idxs != null && idxs.length > 0) {
      if (info && order) {
        // render post-filtered & ordered matches
        for (let i = 0; i < order.length; i++) {
          const infoIdx = order[i];
          const itemIndex = info.idx[infoIdx];

          const item = prefilteredItems[itemIndex];

          const highlighted = uFuzzy
            .highlight<ReactNode[], ReactNode>(haystack[itemIndex], info.ranges[infoIdx], mark, [], append)
            // eslint-disable-next-line react/no-array-index-key
            .map((item, i) => <Fragment key={i}>{item}</Fragment>);
          filteredItems.push({
            item,
            highlighted
          });
        }
      } else {
        // render pre-filtered but unordered matches
        for (let i = 0; i < idxs.length; i++) {
          const itemIndex = idxs[i];
          const item = prefilteredItems[itemIndex];
          filteredItems.push({item, highlighted: haystack[itemIndex]});
        }
      }
    } else {
      if (needle === "") {
        filteredItems = prefilteredItems.map((item) => ({item, highlighted: textExtractor(item)}));
        filteredItems.sort((a, b) => textExtractor(a.item).localeCompare(textExtractor(b.item)));
      }
    }

    return filteredItems;
  }, [filter, items, term, textExtractor]);

  return {filteredItems} as const;
};
