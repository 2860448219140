import {RegularRow} from "app/employee/controlCard/RegularRow";
import {PrimaryColumnRows} from "app/employee/controlCard/PrimaryColumnRows";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {isNil} from "app/utils/stdlib";

export const DisplayControlCardInstance = () => {
  const {rowId} = useControlCardInstanceContext();
  if (!isNil(rowId)) {
    return <RegularRow rowId={rowId} />;
  } else {
    return <PrimaryColumnRows />;
  }
};
