import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {Ref, useRef, useState} from "react";
import {isEqual, isNil} from "app/utils/stdlib";
import {hofForwardRef, IIonSelect, MaybeNil} from "app/utils/types";
import {MenuButton} from "app/employee/menu/MenuButton";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {useTranslation} from "react-i18next";
import {styled} from "styled-components";
import {addCircleOutline, checkmarkCircle} from "ionicons/icons";
import {RequiredLabel} from "app/employee/incdoc/RequiredLabel";
import {ErrorMessage} from "app/employee/incdoc/supplier/ErrorMessage";
import {useFuzzySearch} from "app/employee/controlCard/hook/useFuzzySearch";

export interface IOption<Value> {
  key: string;
  value: Value;
  text: string;
  component?: JSX.Element;
}

export interface ITypeaheadSelectProps<Value, Option extends IOption<Value>> extends Pick<IIonSelect, "disabled"> {
  title: JSX.Element | string;
  options: Option[];
  value: MaybeNil<IOption<Value>["value"]>;
  textExtractor: (option: Option) => string;
  onConfirm: (option: MaybeNil<Option>) => void;
  onAddNew?: MaybeNil<(searchText: string) => void>;
  required?: boolean;
  errorMessage?: string;
}

const AddNewItem = styled(IonItem)`
  --background: var(--fst-highlight-color);
  ion-label {
    font-weight: bold;
  }
`;

export const CheckedLabel = styled(IonLabel)<{$checked: boolean}>`
  ${({$checked}) => $checked && `font-weight: bold;`}
`;

export const textExtractor = <Value, Option extends IOption<Value>>(option: Option) => option.text;

export const TypeaheadSelect = hofForwardRef(
  <Value, Option extends IOption<Value> = IOption<Value>>(
    {
      title,
      options,
      value,
      onConfirm,
      textExtractor,
      onAddNew,
      required,
      disabled,
      errorMessage
    }: ITypeaheadSelectProps<Value, Option>,
    ref: Ref<HTMLIonItemElement>
  ) => {
    const {t} = useTranslation();

    const [searchValue, setSearchValue] = useState("");
    const modal = useRef<HTMLIonModalElement>(null);
    const {filteredItems} = useFuzzySearch(searchValue, options, textExtractor);

    const selectedValue = options.find((o) => isEqual(o.value, value));

    const handleConfirm = async (item: Option) => {
      onConfirm(item ?? null);
      await modal.current?.dismiss();
    };

    const selectedValueComp = selectedValue?.component ?? selectedValue?.text;
    const label = (
      <>
        {title} {required && isNil(errorMessage) ? <RequiredLabel value={selectedValue} /> : null}
      </>
    );

    const selectedItemRef = useRef<HTMLIonItemElement | null>(null);
    const handleModalDidPresent = () => {
      setTimeout(() => {
        selectedItemRef.current?.scrollIntoView({behavior: "smooth"});
      }, 50);
    };

    return (
      <>
        <IonItem button detail disabled={disabled} onClick={() => modal.current?.present()} ref={ref}>
          <IonLabel className="ion-text-wrap">
            {isNil(selectedValue) ? (
              label
            ) : (
              <>
                <p>{label}</p> {selectedValueComp}
              </>
            )}
            {errorMessage ? (
              <p>
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </p>
            ) : null}
          </IonLabel>
        </IonItem>
        <FstIonModal onIonModalDidPresent={handleModalDidPresent} ref={modal}>
          <IonHeader>
            <IonToolbar>
              <HomeBackButton />
              <IonTitle>{title}</IonTitle>
              <MenuButton />
            </IonToolbar>
            <IonToolbar>
              <IonSearchbar
                debounce={200}
                onIonInput={(e) => setSearchValue(e.detail.value ?? "")}
                placeholder={t("g.search", {defaultValue: "Търсене"})}
                value={searchValue}
              />
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonList lines="full">
              {filteredItems.map((filteredItem) => {
                const {item, highlighted} = filteredItem;
                const isChecked = isEqual(item.value, value);
                return (
                  <IonItem
                    button
                    color={isChecked ? "primary" : undefined}
                    key={item.key}
                    onClick={() => handleConfirm(item)}
                    ref={isChecked ? selectedItemRef : undefined}
                  >
                    <CheckedLabel $checked={isChecked} className="ion-text-wrap">
                      {item.component ?? highlighted}
                    </CheckedLabel>
                    {isChecked && <IonIcon icon={checkmarkCircle} slot="end" />}
                  </IonItem>
                );
              })}
              {onAddNew && (
                <AddNewItem
                  button
                  onClick={() => {
                    modal.current?.dismiss();
                    onAddNew(searchValue);
                  }}
                >
                  <IonLabel>{t("g.add-new", {defaultValue: "Добави нов"})}</IonLabel>
                  <IonIcon icon={addCircleOutline} slot="end" />
                </AddNewItem>
              )}
            </IonList>
          </IonContent>
        </FstIonModal>
      </>
    );
  }
);
