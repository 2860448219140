import {IOrganizerDocumentFragment} from "app/gql/graphqlSchema";
import {isNil} from "app/utils/stdlib";
import {IonIcon, IonItem, IonLabel} from "@ionic/react";
import DurationDisplay from "app/employee/DurationDisplay";
import {FlexCellFull, FlexRow} from "app/employee/flexUtils";
import {notificationsOffOutline, notificationsOutline} from "ionicons/icons";
import {Trans, useTranslation} from "react-i18next";

export const DocumentReminderItem = ({document}: {document: IOrganizerDocumentFragment}) => {
  const {t} = useTranslation();
  if (!isNil(document.notifyBeforeExpiry)) {
    return (
      <IonItem lines="full">
        <IonLabel>
          <FlexRow>
            <FlexCellFull>
              <IonIcon icon={notificationsOutline} />
            </FlexCellFull>
            <div>
              <Trans
                components={{
                  ReminderDuration: (
                    <strong>
                      <DurationDisplay duration={document.notifyBeforeExpiry} />
                    </strong>
                  )
                }}
                defaultValue="Напомняне <ReminderDuration/> по-рано"
                i18nKey="organizer.notify-before-expiry-label"
                t={t}
              />
            </div>
          </FlexRow>
        </IonLabel>
      </IonItem>
    );
  } else if (!isNil(document.validUntil)) {
    return (
      <IonItem lines="full">
        <IonLabel>
          <FlexRow>
            <FlexCellFull>
              <IonIcon icon={notificationsOffOutline} />
            </FlexCellFull>
            <div>{t("organizer.no-reminder-label", {defaultValue: "Без напомняне"})}</div>
          </FlexRow>
        </IonLabel>
      </IonItem>
    );
  } else {
    return null;
  }
};
