import {fixSvgForIonIcon} from "app/utils/stdlib";
import _cardCrossed from "app/utils/icons/card-crossed.svg";
import _lockContinue from "app/utils/icons/lock-continue.svg";
import _one23Icon from "bootstrap-icons/icons/123.svg";
import _viberIcon from "app/utils/icons/viber.svg";

/**
 * Workaround for the issue with the Vite inlined SVG icons in the IonIcon component
 * https://github.com/vitejs/vite/blob/0c0aeaeb3f12d2cdc3c47557da209416c8d48fb7/packages/vite/src/node/plugins/asset.ts#L441-L454
 * https://github.com/ionic-team/ionicons/blob/de8b11e8927cb2d4630ecf73015ee46a75910e41/src/components/icon/validate.ts#L52
 */

export const cardCrossedIcon = fixSvgForIonIcon(_cardCrossed);
export const lockContinueIcon = fixSvgForIonIcon(_lockContinue);
export const one23Icon = fixSvgForIonIcon(_one23Icon);
export const viberIcon = fixSvgForIonIcon(_viberIcon);
