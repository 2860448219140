import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {filterOutline, trashBinOutline} from "ionicons/icons";
import {useRef} from "react";
import {useTranslation} from "react-i18next";
import {shallow} from "zustand/shallow";
import {isNil} from "app/utils/stdlib";
import {useSupplierStore} from "app/employee/state/supplier/mutations";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {MenuButton} from "app/employee/menu/MenuButton";
import {z} from "zod";
import {RhfIonInput} from "app/employee/rhf/RhfIonInput";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";

interface IProps {
  refreshSuppliers: () => Promise<void>;
}

const formSchema = z.object({
  term: z.string().trim().nullish()
});

type IFormData = z.infer<typeof formSchema>;

const FilterIcon = ({predicate}: {predicate: IFormData}) => {
  let filtersCount = 0;
  if (!isNil(predicate.term) && predicate.term !== "") {
    filtersCount++;
  }
  if (filtersCount > 0) {
    return (
      <>
        {filtersCount} <IonIcon icon={filterOutline} slot="end" />
      </>
    );
  } else {
    return <IonIcon icon={filterOutline} slot="icon-only" />;
  }
};

export const SupplierFilter = ({refreshSuppliers}: IProps) => {
  const {t} = useTranslation();
  const modal = useRef<HTMLIonModalElement | null>(null);

  const {predicate} = useSupplierStore(
    (state) => ({
      predicate: state.predicate
    }),
    shallow
  );

  const showModal = () => modal.current?.present();
  const hideModal = () => modal.current?.dismiss();

  const applyFilter = async (formData: IFormData) => {
    useSupplierStore.setState((state) => {
      state.predicate = {
        ...predicate,
        ...formData
      };
    });
    await hideModal();
    await refreshSuppliers();
  };

  const clearFilter = async () => {
    useSupplierStore.setState((state) => {
      state.predicate.term = null;
    });
    await hideModal();
    await refreshSuppliers();
  };

  const {control, handleSubmit, watch} = useForm<IFormData>({
    values: predicate,
    resolver: zodResolver(formSchema)
  });

  const title = t("incdoc.supplier.filter.title", {defaultValue: "Филтрирай доставчиците"});
  const submitForm = handleSubmit(applyFilter);

  return (
    <>
      <IonButton fill="clear" onClick={showModal}>
        <FilterIcon predicate={watch()} />
      </IonButton>
      <FstIonModal ref={modal}>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <IonTitle>{title}</IonTitle>
            <MenuButton />
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>
            <IonItem>
              <RhfIonInput
                clearInput
                label={t("incdoc.filter.keyword", {defaultValue: "Текст"})}
                labelPlacement="floating"
                onSubmitValue={submitForm}
                rhf={{control, name: "term"}}
              />
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="primary">
              <IonButton color="primary" fill="outline" onClick={clearFilter}>
                <IonIcon icon={trashBinOutline} slot="start" />
                {t("g.clear", {defaultValue: "Изчисти"})}
              </IonButton>
              <IonButton color="primary" fill="solid" onClick={submitForm}>
                <IonIcon icon={filterOutline} slot="start" />
                {t("g.filter", {defaultValue: "Приложи филтъра"})}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </FstIonModal>
    </>
  );
};
