import {IPlayerControl, VideoPlayer} from "app/employee/video/VideoPlayer";
import {useParams} from "react-router-dom";
import {usePastAssignment} from "app/employee/state/syllabus/selectors";
import {isNotNil} from "app/utils/stdlib";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonRouter
} from "@ionic/react";
import {MenuButton} from "app/employee/menu/MenuButton";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {linkEmplCourseHome} from "app/employee/core/employeeLinks";
import {refreshSyllabus} from "app/employee/state/syllabus/mutations";
import {useTranslation} from "react-i18next";
import {FlexColumnCenter} from "app/employee/flexUtils";
import {fullRefresh} from "app/employee/core/fullRefresh";
import {homeOutline} from "ionicons/icons";

export const WatchPastVideo = () => {
  const {t} = useTranslation();
  const {assignmentId} = useParams<{assignmentId?: string}>();
  const {push} = useIonRouter();
  const assignment = usePastAssignment(assignmentId);

  const videoLesson = assignment?.videoLesson;
  if (isNotNil(videoLesson)) {
    const startControl: IPlayerControl = {
      type: "again",
      handleReturn: () => {
        push(linkEmplCourseHome(), "back", "pop");
      }
    };
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <IonTitle>{videoLesson.title}</IonTitle>
            <MenuButton />
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRefresher onIonRefresh={refreshSyllabus} slot="fixed">
            <IonRefresherContent />
          </IonRefresher>
          <VideoPlayer playerControl={startControl} videoLesson={videoLesson} />
        </IonContent>
      </>
    );
  } else {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <IonTitle>{t("syllabus.completed.no-such-topic", {defaultValue: "Няма такъв изминал урок"})}</IonTitle>
            <MenuButton />
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <FlexColumnCenter>
            <h1>{t("syllabus.completed.no-such-topic", {defaultValue: "Няма такъв изминал урок"})}</h1>
            <IonButton color="primary" onClick={fullRefresh}>
              <IonIcon icon={homeOutline} slot="start" />
              {t("g.back-to-home")}
            </IonButton>
          </FlexColumnCenter>
        </IonContent>
      </>
    );
  }
};
