import {IColumnFragment, IValueFragment, IValueInstanceFragment} from "app/gql/graphqlSchema";
import {useSaveControlCardValue} from "app/employee/controlCard/hook/useSaveControlCardValue";
import {EMPTY_VALUE} from "app/employee/controlCard/types";
import {useCallback} from "react";
import {freeze} from "immer";
import {isNil} from "app/utils/stdlib";
import {useLoadingContext} from "app/employee/LoadingContext";

const getConstantValue = (value: IValueFragment) => {
  if (value.__typename === "ControlCardExpressionValue") {
    return value.result;
  } else {
    return value;
  }
};

const computedValue: IValueFragment = freeze({__typename: "ControlCardExpressionValue", result: EMPTY_VALUE});

export const useExpressionValue = (column: IColumnFragment, valueInstance: IValueInstanceFragment) => {
  const {showExceptionError} = useLoadingContext();
  const {saveValue, saving} = useSaveControlCardValue(valueInstance);
  const singleValue = valueInstance.value;
  const constantValue = getConstantValue(singleValue);

  const showRestoreExpression = !isNil(column.expression) && singleValue.__typename !== "ControlCardExpressionValue";

  const convertToComputed = useCallback(async () => {
    try {
      await saveValue(computedValue);
    } catch (e) {
      await showExceptionError(e);
    }
  }, [saveValue, showExceptionError]);

  return {persistedValue: constantValue, convertToComputed, saving, showRestoreExpression, saveValue} as const;
};
