import {ISyllabusTerm} from "app/employee/state/syllabus/mutations";
import {DetailedProgress} from "app/employee/syllabus/DetailedProgress";
import {useTranslation} from "react-i18next";

const getPendingPlans = (terms: ISyllabusTerm[]) =>
  terms.flatMap((term) => term.termPlans).filter((termPlan) => !termPlan.completed);

interface IProps {
  terms: ISyllabusTerm[];
}
export const SyllabusPendingTermPlan = ({terms}: IProps) => {
  const pendingPlans = getPendingPlans(terms);
  const {t} = useTranslation();
  if (pendingPlans.length > 0) {
    return <DetailedProgress termPlans={pendingPlans} />;
  } else {
    return <h4>{t("syllabus.pending.no-pending-course", {defaultValue: "Няма текущо обучение"})}</h4>;
  }
};
