import {completeCurrentTest, redirectTo, RedirectType} from "app/employee/state/syllabus/mutations";
import {IonButton, IonContent, IonHeader, IonIcon, IonTitle, IonToolbar, useIonRouter} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {FlexColumnCenter} from "app/employee/flexUtils";
import {arrowForward} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {linkEmplCourseHome} from "app/employee/core/employeeLinks";
import {MenuButton} from "app/employee/menu/MenuButton";

export const NoTest = () => {
  const {t} = useTranslation();
  const ionRouter = useIonRouter();

  const completeLesson = async () => {
    await completeCurrentTest();
    redirectTo(RedirectType.NEXT_TOPIC);
    ionRouter.push(linkEmplCourseHome(), "back", "push");
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{t("test.title")}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h1 className="ion-text-center">🎉 {t("test.no-test-message", {defaultValue: "Към тази тема няма тест"})}</h1>
        <FlexColumnCenter>
          <IonButton color="primary" fill="solid" onClick={completeLesson}>
            <IonIcon icon={arrowForward} slot="end" />
            {t("test.continue-to-next", {defaultValue: "Продължете към следващата тема"})}
          </IonButton>
        </FlexColumnCenter>
      </IonContent>
    </>
  );
};
