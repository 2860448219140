import {useBoolean} from "@fluentui/react-hooks";
import {loginEmployeeAndBootstrap} from "app/employee/state/bootstrapEmployee/mutations";
import {Keypad} from "app/employee/login/Keypad";
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonLoading, useIonToast} from "@ionic/react";
import {Trans, useTranslation} from "react-i18next";
import {LanguageSelect} from "app/employee/menu/LanguageSelect";
import {MenuButton} from "app/employee/menu/MenuButton";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";

export const Login = () => {
  const [loggingIn, {setTrue: loginInProgress, setFalse: loginDone}] = useBoolean(false);
  const [presentToast, dismissToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();
  const {t} = useTranslation();

  const loginEmployee = async (accessCode: string) => {
    loginInProgress();
    await dismissToast();
    await presentLoading({message: t("g.loading")});
    try {
      const errors = await loginEmployeeAndBootstrap({accessCode});
      if (errors) {
        await presentToast({
          message: t("login.failed", {
            defaultValue: "Неуспешен вход. Проверете дали сте въвели правилно кодът за достъп"
          }),
          color: "danger",
          duration: 5000
        });
      } else {
        await presentToast({
          message: t("login.success", {
            defaultValue: "Успешен вход!"
          }),
          color: "success",
          duration: 2000
        });
      }
    } finally {
      await dismissLoading();
      loginDone();
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>
            <Trans i18nKey="login.welcome" t={t}>
              Добре дошли в FSAcademy!
            </Trans>
          </IonTitle>
          <MenuButton>
            <LanguageSelect />
          </MenuButton>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal">
        <h1 className="ion-text-center">
          <Trans i18nKey="login.employee-login" t={t}>
            Вход за служители
          </Trans>
        </h1>
        <h4 className="ion-text-center">
          <Trans i18nKey="login.enter-your-access-code" t={t} />
        </h4>
        <Keypad disabled={loggingIn} onSubmit={loginEmployee} />
      </IonContent>
    </IonPage>
  );
};
