import {
  ControlCardCategory,
  GetLocationControlCardDeckDocument,
  IGetLocationControlCardDeckQuery,
  ILocationControlCardDeckFragment
} from "app/gql/graphqlSchema";
import {updateBootstrapEmployee} from "app/employee/state/bootstrapEmployee/mutations";
import {useTranslation} from "react-i18next";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  SegmentChangeEventDetail,
  SelectChangeEventDetail,
  useIonRouter
} from "@ionic/react";
import {useEffect} from "react";
import {assertNotNil, isNil} from "app/utils/stdlib";
import {IonSegmentCustomEvent, IonSelectCustomEvent} from "@ionic/core/dist/types/components";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {LoadingLoadable} from "app/employee/Loading";
import {FlexColumn} from "app/employee/flexUtils";
import {FstIonSelect} from "app/employee/core/FstIonSelect";
import {getLocationText} from "app/employee/utils";
import {TitleRow} from "app/employee/incdoc/TitleRow";
import {DisplayLocationControlCards} from "app/employee/controlCard/location/DisplayLocationControlCards";
import {alarmOutline, libraryOutline} from "ionicons/icons";
import {zControlCardCategory} from "app/employee/controlCard/location/useLocationControlCardGlobalFilter";
import {useQuery} from "app/employee/controlCard/hook/useQuery";
import {zLocationId} from "app/utils/validator";
import {
  LocationDeckFilterContext,
  useLocationDeckFilter,
  useLocationDeckFilterContext
} from "app/employee/controlCard/location/useLocationDeckFilter";
import {linkEmplControlCardLocation} from "app/employee/core/employeeLinks";
import {IncompleteInstanceCount} from "app/employee/controlCard/location/IncompleteInstanceCount";
import {ListPadding} from "app/employee/incdoc/ListPadding";
import {pageRefresh} from "app/employee/core/fullRefresh";

const stateMapper = (result: IGetLocationControlCardDeckQuery) => {
  updateBootstrapEmployee(result.refreshEmployee);
  return result.getLocationControlCardDeckV2;
};

const useLocationsInstances = () => useQuery(GetLocationControlCardDeckDocument, stateMapper);

const ControlCardsForLocation = ({deck}: {deck: ILocationControlCardDeckFragment}) => {
  const {t} = useTranslation();
  const ionRouter = useIonRouter();

  const {filter, locations, setFilter} = useLocationDeckFilterContext();
  assertNotNil(filter);

  const handleLocationChange = (e: IonSelectCustomEvent<SelectChangeEventDetail<unknown>>) => {
    const locationId = zLocationId.parse(e.detail.value);
    ionRouter.push(linkEmplControlCardLocation(locationId.locationId), "root", "push");
  };

  const handleChangeCategory = (e: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
    const newCategory = zControlCardCategory.parse(e.detail.value);
    setFilter((state) => {
      assertNotNil(state);
      state.category = newCategory;
    });
  };

  const {category, locationId} = filter;

  return (
    <FlexColumn>
      <IonList>
        <IonItem color="highlight" lines="full">
          <FstIonSelect
            className="ion-text-wrap"
            label={t("control-card.location.label", {defaultValue: "Обект"})}
            onIonChange={handleLocationChange}
            value={locationId}
          >
            {locations.map((l) => (
              <IonSelectOption key={l.id.locationId} value={l.id}>
                {getLocationText(l)}
              </IonSelectOption>
            ))}
          </FstIonSelect>
        </IonItem>
        <IonItem lines="none">
          <IonSegment onIonChange={handleChangeCategory} value={category}>
            <IonSegmentButton layout="icon-top" value={ControlCardCategory.Daily}>
              <IonIcon icon={alarmOutline} />
              <IonLabel>
                {t("control-card.type.daily")}{" "}
                {!isNil(deck) && <IncompleteInstanceCount category={ControlCardCategory.Daily} stats={deck.stats} />}
              </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton layout="icon-top" value={ControlCardCategory.Recurring}>
              <IonIcon icon={libraryOutline} />
              <IonLabel>
                {t("control-card.type.recurring")}{" "}
                {!isNil(deck) && (
                  <IncompleteInstanceCount category={ControlCardCategory.Recurring} stats={deck.stats} />
                )}
              </IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonItem>
      </IonList>
      <DisplayLocationControlCards deck={deck} />
      <ListPadding />
    </FlexColumn>
  );
};

export const LocationControlCards = () => {
  const {t} = useTranslation();
  const locationDeckFilter = useLocationDeckFilter();
  const {filter, pageVisible, locations} = locationDeckFilter;
  const {data, refetch, fetch} = useLocationsInstances();

  useEffect(() => {
    if (pageVisible && !isNil(filter)) {
      fetch({input: filter});
    }
  }, [fetch, filter, pageVisible]);

  const hasAnyAccess = locations.length > 0;

  const handleRefresh = hasAnyAccess ? refetch : pageRefresh;

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{t("control-card.title", {defaultValue: "Контролни карти"})}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <LoadingLoadable loadable={data} />
        <IonRefresher onIonRefresh={handleRefresh} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>

        {!hasAnyAccess ? (
          <TitleRow className="ion-padding-horizontal">
            <h1>
              {t("control-card.no-locations", {
                defaultValue: "Нямате достъп до контролните карти на нито един обект"
              })}
            </h1>
          </TitleRow>
        ) : !isNil(data.value) && !isNil(filter) ? (
          <LocationDeckFilterContext.Provider value={locationDeckFilter}>
            <ControlCardsForLocation deck={data.value} />
          </LocationDeckFilterContext.Provider>
        ) : null}
      </IonContent>
    </>
  );
};
