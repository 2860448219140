import {ReactNode, RefCallback, useCallback, useRef, useState} from "react";
import SignaturePadClass from "signature_pad";
import {isNil} from "app/utils/stdlib";
import {IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {styled} from "styled-components";
import useResizeObserver, {ObservedSize, ResizeHandler} from "use-resize-observer";
import {checkmarkCircleOutline, closeOutline, trashBinOutline} from "ionicons/icons";
import {useModalContext} from "app/employee/incdoc/FstIonModal";
import {useTranslation} from "react-i18next";
import {trimCanvas} from "app/utils/trimCanvas";

const padding = 10;
const canvasColor = "#fff4bd";

interface IProps {
  title?: ReactNode;
  onSubmit: (data: string) => void;
}

const PadWrapper = styled.div`
  background-color: ${canvasColor};
  margin: ${padding}px;
`;

export const SignaturePad = ({title, onSubmit}: IProps) => {
  const modalContext = useModalContext();
  const {t} = useTranslation();
  const signaturePadRef = useRef<{pad: SignaturePadClass; canvas: HTMLCanvasElement} | null>(null);
  const [size, setSize] = useState<ObservedSize>({width: 0, height: 0});

  const {width, height} = size;

  const canvasRef: RefCallback<HTMLCanvasElement> = useCallback((canvas) => {
    if (!isNil(canvas) && isNil(signaturePadRef.current)) {
      // https://stackoverflow.com/a/74136040/848569
      canvas.getContext("2d", {willReadFrequently: true});
      signaturePadRef.current = {
        pad: new SignaturePadClass(canvas),
        canvas
      };
    }
  }, []);

  const handleSubmit = useCallback(() => {
    const signaturePad = signaturePadRef.current;

    if (!isNil(signaturePad)) {
      const pad = signaturePad.pad;
      trimCanvas(signaturePad.canvas); // trim the canvas (remove white space around the signature
      const jpg05 = pad.toDataURL("image/jpeg", 0.5); // save image as JPEG with 0.5 image quality
      onSubmit(jpg05);
    }
  }, [onSubmit]);

  const onResize: ResizeHandler = useCallback((size) => {
    setSize(size);
    const signaturePad = signaturePadRef.current;
    if (signaturePad) {
      signaturePad.pad.clear();
    }
  }, []);

  const handleClear = () => {
    const signaturePad = signaturePadRef.current;
    if (signaturePad) {
      signaturePad.pad.clear();
    }
  };

  const {ref} = useResizeObserver({onResize});

  const canvasWidth = !isNil(width) ? width - padding * 2 : 0;
  const canvasHeight = !isNil(height) ? height - padding * 2 : 0;

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{title}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent ref={ref}>
        <PadWrapper style={{width: canvasWidth, height: canvasHeight}}>
          <canvas height={canvasHeight} ref={canvasRef} width={canvasWidth} />
        </PadWrapper>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="primary">
            {!isNil(modalContext) && (
              <IonButton color="primary" fill="outline" onClick={() => modalContext?.dismiss()}>
                <IonIcon icon={closeOutline} slot="start" />
                {t("g.cancel")}
              </IonButton>
            )}

            <IonButton color="primary" fill="outline" onClick={handleClear}>
              <IonIcon icon={trashBinOutline} slot="start" />
              {t("g.clear")}
            </IonButton>

            <IonButton color="primary" fill="solid" onClick={handleSubmit}>
              <IonIcon icon={checkmarkCircleOutline} slot="start" />
              {t("g.save")}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
