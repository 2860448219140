import {useWindow} from "@fluentui/react";
import {toggleFeature, useAdminStore} from "app/state/admin";
import {query} from "app/gql/client";
import {LoginGenerateTokenDocument} from "app/gql/graphqlSchema";
import {useCallback, useState} from "react";
import QRCode from "react-qr-code";
import {styled} from "styled-components";
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar
} from "@ionic/react";
import {IModalProps} from "app/employee/useTypedIonModal";
import {closeOutline, rocketOutline} from "ionicons/icons";
import {Trans, useTranslation} from "react-i18next";

const TokenWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  height: 300px;

  ion-spinner {
    width: 64px;
    height: 64px;
  }
`;

const GenerateLoginToken = () => {
  const [loginToken, setLoginToken] = useState<string | null>(null);
  const window = useWindow();

  const generateLoginToken = useCallback(async () => {
    const result = await query(LoginGenerateTokenDocument, {
      returnUrl: window?.location.href.replace(window.location.origin, "") ?? "/"
    });
    setLoginToken(`${window?.location.origin}/i/teleport/${result.loginGenerateToken}`);
  }, [window?.location.href, window?.location.origin]);

  return (
    <TokenWrapper>
      {loginToken ? (
        <a href={loginToken} rel="noreferrer" target="_blank">
          <QRCode size={256} value={loginToken} />
        </a>
      ) : (
        <IonButton onClick={generateLoginToken} size="large">
          <IonIcon icon={rocketOutline} slot="icon-only" />
        </IonButton>
      )}
    </TokenWrapper>
  );
};

export const AdminMenu = ({onDismiss}: IModalProps<void>) => {
  const features = useAdminStore((state) => state.features);
  const {t} = useTranslation();

  const toggleFeatureMenu = (feature: keyof typeof features) => () => {
    toggleFeature(feature);
    return null;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <Trans i18nKey="admin.title" t={t}>
              Тестов режим
            </Trans>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss(undefined, "confirm")}>
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonToggle checked={features.showVideoStats} onIonChange={toggleFeatureMenu("showVideoStats")}>
              <Trans i18nKey="admin.video-stats" t={t}>
                Видео статистика
              </Trans>
            </IonToggle>
          </IonItem>
          <IonItem>
            <IonToggle checked={features.allowSeeking} onIonChange={toggleFeatureMenu("allowSeeking")}>
              <Trans i18nKey="admin.fast-forward" t={t}>
                Превъртане напред
              </Trans>
            </IonToggle>
          </IonItem>
          <IonItem>
            <IonToggle checked={features.disableChapters} onIonChange={toggleFeatureMenu("disableChapters")}>
              <Trans i18nKey="admin.no-segment-pause" t={t}>
                Не спирай на сегментите на видеото
              </Trans>
            </IonToggle>
          </IonItem>
          <IonItem>
            <IonToggle checked={features.highlightAnswers} onIonChange={toggleFeatureMenu("highlightAnswers")}>
              <Trans i18nKey="admin.show-correct-answers" t={t}>
                Покажи верните отговори
              </Trans>
            </IonToggle>
          </IonItem>
          <IonAccordionGroup>
            <IonAccordion value="1">
              <IonItem slot="header">
                <IonLabel>
                  <Trans i18nKey="admin.transfer-to-new-device" t={t}>
                    Прехвърляне на друго устройство
                  </Trans>
                </IonLabel>
              </IonItem>
              <IonItem slot="content">
                <GenerateLoginToken />
              </IonItem>
            </IonAccordion>
          </IonAccordionGroup>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
