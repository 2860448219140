import {IProgress} from "app/employee/state/syllabus/selectors";
import {ISyllabusQuestion} from "app/employee/state/syllabus/mutations";
import {Answers} from "app/employee/test/Answers";
import {DeepReadonly} from "ts-essentials";
import {fractionToPercent} from "app/utils/stdlib";
import {IonBadge, IonCol, IonGrid, IonRow} from "@ionic/react";
import {Trans} from "react-i18next";

interface IProps {
  question: ISyllabusQuestion;
  progress: IProgress;
}

export const Question = ({question, progress}: DeepReadonly<IProps>) => {
  const currentIndex = progress.index + 1;
  const percentFraction = currentIndex / progress.total;
  const percent = fractionToPercent(percentFraction);
  const questionNo = progress.questionNumber + 1;
  return (
    <IonGrid>
      <IonRow className="ion-justify-content-between">
        <IonCol className="ion-flex ion-align-items-center" size="auto">
          <span>
            <Trans i18nKey="test.question-no" questionNo={questionNo}>
              Въпрос № {{questionNo}}
            </Trans>
          </span>
        </IonCol>
        <IonCol size="auto">
          <IonBadge color="primary">
            {currentIndex} / {progress.total} ({percent})
          </IonBadge>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <strong>{question.content}</strong>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Answers question={question} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
