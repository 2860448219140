import {ISyllabusTermVideoLesson} from "app/employee/state/syllabus/mutations";
import {useTranslation} from "react-i18next";
import {parseDuration} from "app/utils/dates";
import {IonBadge} from "@ionic/react";

interface IProps {
  lesson: ISyllabusTermVideoLesson;
}

export const VideoLessonTitleBadge = ({lesson}: IProps) => {
  const {t} = useTranslation();
  const lessonDuration = parseDuration(lesson.videoLesson.length);
  const durationHuman = lessonDuration.toFormat("m");
  const message = t("player.lesson-duration", {minutes: durationHuman, defaultValue: "{{minutes}} минути"});

  return <IonBadge color="primary">{message}</IonBadge>;
};
