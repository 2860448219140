import {IControlCardInstanceFullFragment} from "app/gql/graphqlSchema";

export type IRefOption = IControlCardInstanceFullFragment["columnRefOptions"][number];
type PossibleRefOptions = IRefOption["__typename"];

interface IMapping<T> {
  textExtractor: (item: T) => string;
  filter: (item: T) => boolean;
}

type IRefOptionType<T extends IRefOption, Key extends PossibleRefOptions> = Extract<
  T,
  {__typename: Key}
>["options"][number];

export type IRefOptionsMapping<T extends IRefOption> = {
  [Key in PossibleRefOptions]: IMapping<IRefOptionType<T, Key>>;
};

export const refMapping: IRefOptionsMapping<IRefOption> = {
  EmployeeColumnRefOptions: {
    textExtractor: (item) => item.employee.name,
    filter: (item) => !item.employee.disabled
  }
};
