import {hofForwardRef, IIonButton, MaybeNil, noop, RhfProps, RhfRef} from "app/utils/types";
import {FieldPath, FieldValues, useController} from "react-hook-form";
import {ForwardedRef, RefCallback, useCallback, useImperativeHandle, useMemo, useRef} from "react";
import {
  IControlCardClosedNumberRange,
  IControlCardDiscreteNumberInputMethod,
  IControlCardIconSetFragment,
  IControlCardNumberRangeFragment,
  IInitialValueFragment
} from "app/gql/graphqlSchema";
import {IonButton, IonIcon, IonText, useIonPicker} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {PickerColumnOption} from "@ionic/core/dist/types/components/picker/picker-interface";
import {z} from "zod";
import {assignRef, DEFAULT_ROUNDING_MODE, isNil, isNotBlank} from "app/utils/stdlib";
import {Color} from "@ionic/core";
import {styled} from "styled-components";
import BigNumber from "bignumber.js";
import {toBigNumber, zBigNumber} from "app/utils/validator";
import {getDefaultInitialValue, getInitialValue, useInputIcons, valueToPresentation} from "app/employee/rhf/number";
import {rangeCheckFactory} from "app/utils/range";

export interface INumberPickerInputProps extends Pick<IIonButton, "disabled" | "size"> {
  inputMethod: IControlCardDiscreteNumberInputMethod;
  unit: MaybeNil<string>;
  initialValue: MaybeNil<IInitialValueFragment>;
  iconSet: IControlCardIconSetFragment;
  greenZoneRange: MaybeNil<IControlCardNumberRangeFragment>;
  onSubmitValue?: MaybeNil<() => void>;
}

interface INumberColumnOption extends PickerColumnOption {
  value: BigNumber;
}

const reportValidity = () => true;

const pickerValueSchema = z.object({
  num: z.object({
    value: zBigNumber
  })
});

const Wrapper = styled.div`
  display: flex;
`;

const getValueSelectedIndex = (
  options: INumberColumnOption[],
  value: BigNumber,
  unit: MaybeNil<string>,
  precision: number
) => {
  let selectedIndex = options.findIndex((option) => option.value.isEqualTo(value));
  if (selectedIndex >= 0) {
    return selectedIndex;
  }
  options.push(createOption(value, unit, precision));
  options.sort((a, b) => a.value.comparedTo(b.value));
  selectedIndex = options.findIndex((option) => option.value.isEqualTo(value));
  return selectedIndex >= 0 ? selectedIndex : 0;
};

const findSelectedIndex = (
  options: INumberColumnOption[],
  value: BigNumber | null,
  unit: MaybeNil<string>,
  precision: number,
  start: BigNumber,
  end: BigNumber,
  initialValue: MaybeNil<IInitialValueFragment>
) => {
  if (!isNil(value)) {
    return getValueSelectedIndex(options, value, unit, precision);
  } else {
    let parsedInitialValue = getInitialValue(initialValue);
    if (isNil(parsedInitialValue)) {
      parsedInitialValue = getDefaultInitialValue(start, end);
    }
    return getValueSelectedIndex(options, parsedInitialValue, unit, precision);
  }
};

const getSelectionRange = (range: IControlCardClosedNumberRange): [BigNumber, BigNumber] => {
  return [toBigNumber(range.start), toBigNumber(range.end)];
};

const getDeltaValue = (currentValue: BigNumber | null, initialValue: BigNumber, delta: BigNumber) => {
  let deltaValue: BigNumber;
  if (isNil(currentValue)) {
    deltaValue = initialValue;
  } else {
    deltaValue = currentValue.plus(delta);
  }
  return deltaValue;
};

const createOption = (value: BigNumber, unit: MaybeNil<string>, precision: number): INumberColumnOption => ({
  text: valueToPresentation(value, unit, precision),
  value
});

export const RhfNumberPickerInput = hofForwardRef(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    {
      rhf,
      size,
      inputMethod,
      initialValue,
      unit,
      disabled,
      greenZoneRange,
      iconSet,
      onSubmitValue
    }: INumberPickerInputProps & RhfProps<TFieldValues, TName>,
    ref: ForwardedRef<HTMLIonButtonElement>
  ) => {
    const {t} = useTranslation();
    const {
      field: {onChange, ref: fieldRef, value, disabled: rhfDisabled, ...restField},
      fieldState: {error},
      formState: {isSubmitting}
    } = useController(rhf);

    const bnValue = useMemo(() => (!isNil(value) ? toBigNumber(value) : null), [value]);

    const {actionText, discreteRange, step} = inputMethod;
    const bnStep = useMemo(() => toBigNumber(step), [step]);
    const checkGreenZoneRange = useMemo(() => rangeCheckFactory(greenZoneRange), [greenZoneRange]);
    const precision = bnStep.decimalPlaces() ?? 0;

    const rhfRef = useRef<RhfRef | null>(null);
    const inputRef: RefCallback<HTMLIonButtonElement> = (element) => {
      assignRef(ref, element);

      rhfRef.current = {
        focus: () => element?.focus(),
        select: () => element?.focus(),
        setCustomValidity: noop,
        reportValidity
      };
    };
    useImperativeHandle(fieldRef, () => rhfRef.current);

    const [present] = useIonPicker();

    const {options, selectedIndex} = useMemo(() => {
      const [start, end] = getSelectionRange(discreteRange);

      const options: INumberColumnOption[] = [];
      for (let i = start; i.isLessThanOrEqualTo(end); i = i.plus(bnStep)) {
        options.push(createOption(i, unit, precision));
      }

      const selectedIndex = findSelectedIndex(options, bnValue, unit, precision, start, end, initialValue);
      return {options, bnValue, selectedIndex};
    }, [discreteRange, bnValue, unit, precision, initialValue, bnStep]);

    const onChangeBigNum = useCallback(
      (value: BigNumber) => {
        onChange(value.toFixed(precision, DEFAULT_ROUNDING_MODE));
        onSubmitValue?.();
      },
      [onChange, precision, onSubmitValue]
    );

    const openPicker = useCallback(async () => {
      await present({
        columns: [
          {
            name: "num",
            options,
            selectedIndex
          }
        ],
        buttons: [
          {
            text: t("g.cancel"),
            role: "cancel"
          },
          {
            text: t("g.ok"),
            handler: (value) => {
              const parseResult = pickerValueSchema.parse(value);
              onChangeBigNum(parseResult.num.value);
            }
          }
        ]
      });
    }, [onChangeBigNum, options, present, selectedIndex, t]);

    const presentationValue = useMemo(() => {
      if (isNil(bnValue)) {
        return isNotBlank(actionText) ? actionText : t("g.select-value");
      } else {
        return valueToPresentation(bnValue, unit, precision);
      }
    }, [actionText, bnValue, precision, t, unit]);

    const color = useMemo((): Color => {
      if (isNil(bnValue)) {
        return "warning";
      } else {
        const greenZoneStatus = checkGreenZoneRange(bnValue);
        if (isNil(greenZoneStatus)) {
          return "primary";
        } else {
          return greenZoneStatus ? "success" : "danger";
        }
      }
    }, [checkGreenZoneRange, bnValue]);

    const decrementHandler = useCallback(
      () => onChangeBigNum(getDeltaValue(bnValue, options[selectedIndex].value, bnStep.negated())),
      [bnStep, bnValue, onChangeBigNum, options, selectedIndex]
    );
    const incrementHandler = useCallback(
      () => onChangeBigNum(getDeltaValue(bnValue, options[selectedIndex].value, bnStep)),
      [bnStep, bnValue, onChangeBigNum, options, selectedIndex]
    );

    const {incrementIcon, decrementIcon} = useInputIcons(iconSet);

    return (
      <>
        <Wrapper>
          <IonButton
            color={decrementIcon.color}
            disabled={isNil(selectedIndex) || rhfDisabled || disabled || isSubmitting}
            fill="clear"
            onClick={decrementHandler}
          >
            <IonIcon icon={decrementIcon.icon} slot="icon-only" />
          </IonButton>
          <IonButton
            {...restField}
            color={color}
            disabled={rhfDisabled || disabled || isSubmitting}
            onClick={openPicker}
            ref={inputRef}
            size={size}
          >
            {presentationValue}
          </IonButton>
          <IonButton
            color={incrementIcon.color}
            disabled={isNil(selectedIndex) || rhfDisabled || disabled || isSubmitting}
            fill="clear"
            onClick={incrementHandler}
          >
            <IonIcon icon={incrementIcon.icon} slot="icon-only" />
          </IonButton>
        </Wrapper>
        {!isNil(error) && <IonText color="danger">{error.message}</IonText>}
      </>
    );
  }
);
