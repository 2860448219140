import {PropsWithChildren} from "react";
import {useAccountDisabled} from "app/state/autoupdate/mutations";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToolbar
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {FlexColumnCenter} from "app/employee/flexUtils";
import {logOutOutline} from "ionicons/icons";
import {logoutEmployeeAndBootstrap} from "app/employee/state/bootstrapEmployee/mutations";
import {fullRefresh} from "app/employee/core/fullRefresh";
import {useTranslation} from "react-i18next";

export const DisabledGuard = ({children}: PropsWithChildren<object>) => {
  const isDisabled = useAccountDisabled();
  const {t} = useTranslation();
  const logout = async () => {
    await logoutEmployeeAndBootstrap();
    fullRefresh();
  };

  if (isDisabled) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <MenuButton />
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding ion-text-center">
          <IonRefresher onIonRefresh={fullRefresh} slot="fixed">
            <IonRefresherContent />
          </IonRefresher>
          <FlexColumnCenter>
            <h1>{t("g.user-disable.title")}</h1>
            <IonButton onClick={logout}>
              <IonIcon icon={logOutOutline} slot="start" />
              {t("g.user-disable.action")}
            </IonButton>
          </FlexColumnCenter>
        </IonContent>
      </IonPage>
    );
  } else {
    return <>{children}</>;
  }
};
