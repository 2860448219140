import {useEnabledFeatures} from "app/employee/state/bootstrapEmployee/selectors";
import {Feature} from "app/gql/graphqlSchema";
import {
  linkEmplControlCardLocation,
  linkEmplCourseHome,
  linkEmplIncDocHome,
  linkEmplOrganizer
} from "app/employee/core/employeeLinks";

export const useDefaultPage = () => {
  const checkFeature = useEnabledFeatures();
  const incDocEnabled = checkFeature(Feature.IncomingControl);
  const courseEnabled = checkFeature(Feature.Course);
  const organizerEnabled = checkFeature(Feature.Organizer);
  const controlCardEnabled = checkFeature(Feature.ControlCard);

  if (courseEnabled) {
    return linkEmplCourseHome();
  } else if (incDocEnabled) {
    return linkEmplIncDocHome();
  } else if (organizerEnabled) {
    return linkEmplOrganizer();
  } else if (controlCardEnabled) {
    return linkEmplControlCardLocation();
  } else {
    return null;
  }
};
