import {IColumnFragment, IControlCardValue, IValueInstanceFragment} from "app/gql/graphqlSchema";
import {IonButton, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {IonCardFooter} from "app/employee/controlCard/ControlCardUI";
import {checkmarkCircleOutline, closeOutline, refreshCircleOutline} from "ionicons/icons";
import {useForm} from "react-hook-form";
import {DEFAULT_ROUNDING_MODE, isNil, isNotBlank, normalizeToNull} from "app/utils/stdlib";
import {EMPTY_VALUE} from "app/employee/controlCard/types";
import {FlexColumnCenter} from "app/employee/flexUtils";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {useEffect, useMemo} from "react";
import {SavingProgress} from "app/employee/controlCard/input/SavingProgress";
import {useExpressionValue} from "app/employee/controlCard/hook/useExpressionValue";
import {EditContainer} from "app/employee/incdoc/EditContainer";
import {DisplayRootError} from "app/employee/controlCard/input/DisplayRootError";
import {transformToApplicationError} from "app/gql/handleGraphqlErrors";
import {setApplicationError} from "app/utils/parseErrorResult";
import {DisplayNumberRange} from "app/employee/controlCard/display/DisplayNumberRange";
import {RhfNumberPickerInput} from "app/employee/rhf/RhfNumberPickerInput";
import {toBigNumber, zBigNumber} from "app/utils/validator";
import {rangeValidator} from "app/utils/range";
import {valueToPresentation} from "app/employee/rhf/number";
import {RhfNumberTypePadInput} from "app/employee/rhf/RhfNumberTypePadInput";
import {DisplayValueInstanceError} from "app/employee/controlCard/input/DisplayValueInstanceError";
import {useHasControlCardValueError} from "app/employee/controlCard/hook/useHasControlCardValueError";
import {InputIonCard} from "app/employee/controlCard/input/InputIonCard";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardNumberColumn"};
  valueInstance: IValueInstanceFragment;
  disabled?: boolean;
}

export type IFormData = {
  value: string | null;
};

export const InputNumber = ({column, valueInstance, disabled}: IProps) => {
  const {t} = useTranslation();
  const {isComplete, canEdit} = useControlCardInstanceContext();

  const {persistedValue, convertToComputed, showRestoreExpression, saving, saveValue} = useExpressionValue(
    column,
    valueInstance
  );
  const hasError = useHasControlCardValueError(valueInstance);

  let value: string | null = null;
  if (persistedValue.__typename === "ControlCardNumberValue") {
    value = persistedValue.number;
  }

  const isDiscrete = column.inputMethod.__typename === "ControlCardDiscreteNumberInputMethod";

  const {
    control,
    formState: {isDirty, isSubmitting, isValid: formIsValid, disabled: formDisabled, errors},
    handleSubmit,
    reset,
    setError
  } = useForm<IFormData>({
    values: {
      value
    },
    resolver: (data) => {
      const inputNumStr = normalizeToNull(data.value);
      if (isNil(inputNumStr)) {
        return {
          values: {value: null},
          errors: {}
        };
      } else {
        const parsedNum = zBigNumber.safeParse(inputNumStr);
        if (!parsedNum.success) {
          return {
            values: {},
            errors: {value: {type: "invalid", message: "Невалидно число"}}
          };
        }
        const parsedNumValue = parsedNum.data;
        const {minValue, minExceeded, maxValue, maxExceeded} = boundaryRangeValidator(parsedNumValue);
        if (minExceeded && !isNil(minValue)) {
          return {
            values: {},
            errors: {
              value: {
                type: "min",
                message: t("control-card.number-range.min-exceeded", {
                  minValue: valueToPresentation(minValue, unit, precision)
                })
              }
            }
          };
        } else if (maxExceeded && !isNil(maxValue)) {
          return {
            values: {},
            errors: {
              value: {
                type: "max",
                message: t("control-card.number-range.max-exceeded", {
                  maxValue: valueToPresentation(maxValue, unit, precision)
                })
              }
            }
          };
        }

        return {
          values: {value: parsedNumValue.toFixed(precision, DEFAULT_ROUNDING_MODE)},
          errors: {}
        };
      }
    },
    mode: "onChange",
    disabled: isComplete || !canEdit || !!disabled
  });

  const boundaryRangeValidator = useMemo(() => rangeValidator(column.boundaryRange), [column.boundaryRange]);
  const unit = column.unit;
  const bnStep = useMemo(() => toBigNumber(column.inputMethod.step), [column.inputMethod.step]);
  const precision = bnStep.decimalPlaces() ?? 0;

  const handleSave = useMemo(
    () =>
      handleSubmit(
        async (data) => {
          try {
            let value: IControlCardValue;
            if (isNil(data.value)) {
              value = EMPTY_VALUE;
            } else {
              value = {
                __typename: "ControlCardNumberValue",
                number: data.value
              };
            }

            await saveValue(value);
            reset();
          } catch (e) {
            const appError = transformToApplicationError(e);
            setApplicationError(appError, setError);
          }
        },
        (fieldErrors) => {
          if (!isNil(fieldErrors.value) && isDiscrete) {
            reset(undefined, {keepErrors: true});
          }
        }
      ),
    [handleSubmit, isDiscrete, reset, saveValue, setError]
  );

  const handleClear = () => reset();

  useEffect(() => {
    if (isComplete) {
      reset();
    }
  }, [isComplete, reset]);

  return (
    <InputIonCard $hasError={hasError} data-scroll-to-value-instance-id={valueInstance.id}>
      <EditContainer>
        {showRestoreExpression && (
          <IonButton disabled={formDisabled} fill="clear" onClick={convertToComputed}>
            <IonIcon icon={refreshCircleOutline} slot="icon-only" />
          </IonButton>
        )}
      </EditContainer>
      <IonCardHeader>
        <IonCardTitle>{column.title}</IonCardTitle>
        {isNotBlank(column.description) && <IonCardSubtitle>{column.description}</IonCardSubtitle>}
      </IonCardHeader>
      <IonCardContent>
        <FlexColumnCenter>
          {!isNil(column.greenZoneRange) && (
            <div>
              <strong>{t("control-card.number-range.text")}</strong>:{" "}
              <DisplayNumberRange range={column.greenZoneRange} unit={column.unit} />
            </div>
          )}
          <DisplayRootError errors={errors} />
          {column.inputMethod.__typename === "ControlCardDiscreteNumberInputMethod" && (
            <RhfNumberPickerInput
              greenZoneRange={column.greenZoneRange}
              iconSet={column.iconSet}
              initialValue={column.initialValue}
              inputMethod={column.inputMethod}
              onSubmitValue={handleSave}
              rhf={{control, name: "value"}}
              size="large"
              unit={column.unit}
            />
          )}
          {column.inputMethod.__typename === "ControlCardFreeNumberInputMethod" && (
            <RhfNumberTypePadInput
              boundaryRange={column.boundaryRange}
              greenZoneRange={column.greenZoneRange}
              iconSet={column.iconSet}
              initialValue={column.initialValue}
              inputMethod={column.inputMethod}
              onSubmitValue={handleSave}
              rhf={{control, name: "value"}}
              size="large"
              title={column.title}
              unit={column.unit}
            />
          )}
          <DisplayValueInstanceError column={column} disabled={formDisabled} valueInstance={valueInstance} />
        </FlexColumnCenter>
      </IonCardContent>
      {isDirty && !isSubmitting && (
        <IonCardFooter>
          <IonButton color="primary" fill="outline" onClick={handleClear} size="small">
            <IonIcon icon={closeOutline} slot="start" />
            {t("g.cancel")}
          </IonButton>
          <IonButton color="primary" disabled={formDisabled && !formIsValid} onClick={handleSave} size="small">
            <IonIcon icon={checkmarkCircleOutline} slot="start" />
            {t("g.save")}
          </IonButton>
        </IonCardFooter>
      )}
      <SavingProgress isSubmitting={saving} />
    </InputIonCard>
  );
};
