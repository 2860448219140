import {linkEmplControlCardAddPrimaryRow, linkEmplControlCardInstance} from "app/employee/core/employeeLinks";
import {IonButton, IonFab, IonIcon, IonItem, IonLabel, IonList, IonListHeader} from "@ionic/react";
import {assertNotNil, isEqual, isNil} from "app/utils/stdlib";
import {DisplayValueInstance} from "app/employee/controlCard/DisplayValueInstance";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {Trans, useTranslation} from "react-i18next";
import {InstanceActionButton} from "app/employee/controlCard/InstanceActionButton";
import {
  ControlCardInstanceSummary,
  SummaryAdditionalGridRow
} from "app/employee/controlCard/ControlCardInstanceSummary";
import {TransposedRow} from "app/employee/controlCard/RegularRow";
import {ControlCardEditDisabledBanner} from "app/employee/controlCard/ControlCardEditDisabledBanner";
import {addOutline, checkmarkCircle, closeOutline, lockOpenOutline, trashOutline} from "ionicons/icons";
import {useBoolean} from "@fluentui/react-hooks";
import {IIonItem} from "app/utils/types";
import {
  DeleteControlCardRows,
  DeleteControlCardRowsHandle
} from "app/employee/controlCard/actions/DeleteControlCardRows";
import {createRef, useRef} from "react";
import {IonRowButton} from "app/employee/IonRowButton";
import {useUncompleteControlCard} from "app/employee/controlCard/actions/useUncompleteControlCard";
import {useCompleteControlCard} from "app/employee/controlCard/actions/useCompleteControlCard";
import {TimeDisplay} from "app/employee/TimeDisplay";
import {RelativeTimestamp} from "app/employee/RelativeTimestamp";

const PrimaryColumnSummary = () => {
  const {instance, canEdit, isTransposedCard} = useControlCardInstanceContext();
  const {uncompleteControlCard} = useUncompleteControlCard();
  const {completeControlCard} = useCompleteControlCard();
  const {t} = useTranslation();
  return (
    <ControlCardInstanceSummary>
      {!isNil(instance.notApplicableBy) ? (
        <SummaryAdditionalGridRow $color="not-applicable">
          {t("control-card.status.not-applicable", {
            notApplicableBy: instance.notApplicableBy.name,
            notApplicableReason: instance.notApplicableReason
          })}
        </SummaryAdditionalGridRow>
      ) : !isNil(instance.completedBy) ? (
        <SummaryAdditionalGridRow $color="completed">
          <span>
            <Trans
              components={{
                Name: <strong>{instance.completedBy.name}</strong>
              }}
              i18nKey="control-card.status.completed"
              t={t}
            />
          </span>
          <span>
            <IonRowButton disabled={!canEdit} onClick={uncompleteControlCard}>
              <IonIcon icon={lockOpenOutline} slot="start" />
              {t("control-card.row.action.uncomplete")}
            </IonRowButton>
          </span>
        </SummaryAdditionalGridRow>
      ) : (
        <SummaryAdditionalGridRow $color="incomplete">
          <span>
            <strong>{t("control-card.status.not-completed")}</strong>
            {!isNil(instance.dueTo) ? (
              <>
                ,{" "}
                <Trans
                  components={{
                    Time: (
                      <strong>
                        <TimeDisplay time={instance.dueTo} />
                      </strong>
                    ),
                    RelTime: (
                      <>
                        {instance.isPastDue ? (
                          <>
                            {" "}
                            (<RelativeTimestamp ts={instance.dueTo} />)
                          </>
                        ) : null}
                      </>
                    )
                  }}
                  i18nKey="control-card.due-to"
                  t={t}
                />
              </>
            ) : null}
          </span>
          {!isTransposedCard && (
            <span>
              <IonRowButton disabled={!canEdit} onClick={() => completeControlCard()}>
                <IonIcon icon={checkmarkCircle} slot="start" />
                {t("g.complete")}
              </IonRowButton>
            </span>
          )}
        </SummaryAdditionalGridRow>
      )}
    </ControlCardInstanceSummary>
  );
};

export const PrimaryColumnRows = () => {
  const {isTransposedCard, instance, canEdit} = useControlCardInstanceContext();
  const isNotApplicable = !isNil(instance.notApplicableBy);
  return (
    <>
      <IonFab horizontal="end" slot="fixed" vertical="bottom">
        <InstanceActionButton />
      </IonFab>

      <PrimaryColumnSummary />

      {!canEdit && <ControlCardEditDisabledBanner />}

      {isNotApplicable ? null : isTransposedCard ? <TransposedRow rowId={instance.rows[0].rowId} /> : <PrimaryColumn />}
    </>
  );
};

const PrimaryColumn = () => {
  const {t} = useTranslation();
  const deleteRowsRef = useRef<DeleteControlCardRowsHandle | null>(null);

  const {instance, primaryColumn, isRowComplete, isComplete, canEdit, setInstance} = useControlCardInstanceContext();

  const rows = instance.rows;
  const canChangeRows = instance.definition.dynamicRows && canEdit;

  const [deleteMode, {toggle: toggleDelete}] = useBoolean(false);

  const toolbar = rows.length > 0 && !isComplete && (
    <IonItem button={false} color="highlight" lines="full">
      <IonButton disabled={!canEdit} fill="outline" onClick={toggleDelete} slot="start">
        <IonIcon icon={deleteMode ? closeOutline : trashOutline} slot="start" />
        {deleteMode ? t("g.cancel") : t("g.delete")}
      </IonButton>
      <IonButton
        disabled={!canEdit}
        routerDirection="forward"
        routerLink={linkEmplControlCardAddPrimaryRow(instance.id.instanceId)}
        slot="end"
      >
        <IonIcon icon={addOutline} slot="start" />
        {t("g.add")}
      </IonButton>
    </IonItem>
  );

  const addNewButton = !(rows.length > 0) && (
    <IonItem lines="full">
      <IonLabel>
        <IonButton
          disabled={isComplete || !canEdit}
          expand="block"
          routerDirection="forward"
          routerLink={linkEmplControlCardAddPrimaryRow(instance.id.instanceId)}
          size="default"
        >
          <IonIcon icon={addOutline} slot="start" />
          {t("g.add")}
        </IonButton>
      </IonLabel>
    </IonItem>
  );

  return (
    <>
      <IonList>
        <IonListHeader lines="full">
          <IonLabel>
            {t("control-card.primary-column-title", {
              count: rows.length,
              title: primaryColumn.title
            })}
          </IonLabel>
        </IonListHeader>
        {toolbar}
        {addNewButton}
        {rows.map((row) => {
          const value = row.columns.find((c) => isEqual(c.columnKey, primaryColumn.key));
          assertNotNil(value);
          const link = linkEmplControlCardInstance(instance.id.instanceId, row.rowId);
          const rowComplete = isRowComplete(row.rowId);
          const color = isComplete || rowComplete ? "completed" : "incomplete";

          const labelRef = createRef<HTMLIonLabelElement>();
          const handleDelete = async () => {
            const result = await deleteRowsRef.current?.doDelete(instance.id, [row.rowId], labelRef.current?.innerText);
            if (!isNil(result)) {
              setInstance(result);
            }
          };

          const itemProps: IIonItem = deleteMode
            ? {detail: false, onClick: handleDelete, button: true, lines: "full"}
            : {routerLink: link, routerDirection: "forward", detail: true, lines: "full", button: true};

          return (
            <IonItem color={color} key={row.rowId} {...itemProps}>
              {deleteMode && <IonIcon color="danger" icon={trashOutline} slot="start" />}
              <IonLabel ref={labelRef}>
                <DisplayValueInstance column={primaryColumn} valueInstance={value} />
              </IonLabel>
            </IonItem>
          );
        })}
      </IonList>
      {canChangeRows && <DeleteControlCardRows ref={deleteRowsRef} />}
    </>
  );
};
