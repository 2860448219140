import {usePageVisibility} from "app/components/hooks/usePageVisibility";
import {useSetInterval} from "@fluentui/react-hooks";
import {useEffect} from "react";

export const usePollVisible = (
  callback: () => unknown,
  periodMs: number,
  startMessage?: string,
  stopMessage?: string,
  pollMessage?: string
) => {
  const isPageVisible = usePageVisibility();
  const {setInterval, clearInterval} = useSetInterval();

  useEffect(() => {
    if (isPageVisible) {
      console.log(`📮 ${startMessage ?? "starting polling"}, every ${periodMs} ms`);
      const timerId = setInterval(async () => {
        console.log(`📮 ${pollMessage ?? "executing callback"}...`);
        await callback();
        console.log(`📮 ${pollMessage ?? "executing callback"} - Done!`);
      }, periodMs);

      return () => {
        console.log(`📮 ${stopMessage ?? "stopping polling"}`);
        clearInterval(timerId);
      };
    }
  }, [callback, clearInterval, isPageVisible, periodMs, pollMessage, setInterval, startMessage, stopMessage]);

  return {isPageVisible};
};
