import {useTranslation} from "react-i18next";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {assertNotNil, isNil} from "app/utils/stdlib";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  useIonActionSheet,
  useIonRouter
} from "@ionic/react";
import {
  arrowBackOutline,
  closeOutline,
  ellipsisVerticalCircleOutline,
  informationCircleOutline,
  lockOpenOutline,
  trashOutline
} from "ionicons/icons";
import {useCallback, useRef} from "react";
import {styled} from "styled-components";
import {ActionSheetButton} from "@ionic/core/dist/types/components/action-sheet/action-sheet-interface";
import {linkEmplControlCardInstance} from "app/employee/core/employeeLinks";
import {useBoolean} from "@fluentui/react-hooks";
import {
  DeleteControlCardRows,
  DeleteControlCardRowsHandle
} from "app/employee/controlCard/actions/DeleteControlCardRows";
import {lockContinueIcon} from "app/utils/icons/ionicFixedIcons";
import {useCompleteControlCardRow} from "app/employee/controlCard/actions/useCompleteControlCardRow";
import {useUncompleteControlCardRow} from "app/employee/controlCard/actions/useUncompleteControlCardRow";

const AutoHeightModal = styled(IonModal)`
  --height: auto;
`;

const ModalWrapper = styled.div`
  padding: 1rem 0;
`;

interface IContextMenuProps {
  canDelete: boolean;
  onDelete: () => void | Promise<void>;
  onComplete: () => void | Promise<void>;
  onUncomplete: () => void | Promise<void>;
  onMoreInfo: () => void | Promise<void>;
  onBack: () => void | Promise<void>;
  completed: boolean;
  canEdit: boolean;
}

const useContextMenu = ({
  completed,
  onDelete,
  onComplete,
  onUncomplete,
  onMoreInfo,
  canDelete,
  canEdit,
  onBack
}: IContextMenuProps) => {
  const {t} = useTranslation();
  const [presentActionSheet] = useIonActionSheet();

  const showOptions = useCallback(async () => {
    const completeOption: ActionSheetButton = {
      text: t("control-card.row.action.complete"),
      icon: lockContinueIcon,
      handler: onComplete
    };
    const moreInfoOption: ActionSheetButton = {
      text: t("control-card.row.action.more-info", {defaultValue: "Допълнителна информация"}),
      icon: informationCircleOutline,
      handler: onMoreInfo
    };
    const uncompleteOption: ActionSheetButton = {
      text: t("control-card.row.action.uncomplete"),
      icon: lockOpenOutline,
      handler: onUncomplete
    };
    const deleteOption: ActionSheetButton = {
      text: t("control-card.row.action.delete"),
      role: "destructive",
      icon: trashOutline,
      handler: onDelete
    };
    const buttons: ActionSheetButton[] = [];
    if (completed) {
      if (canEdit) {
        buttons.push(uncompleteOption);
      }
      buttons.push(moreInfoOption);
    } else {
      if (canEdit) {
        buttons.push(completeOption);
      }
      if (canDelete) {
        buttons.push(deleteOption);
      }
    }
    return presentActionSheet({
      header: t("control-card.row.action.title"),
      buttons: [
        ...buttons,
        {
          text: t("g.back"),
          icon: arrowBackOutline,
          handler: onBack
        },
        {
          text: t("g.close"),
          icon: closeOutline,
          role: "cancel"
        }
      ]
    });
  }, [canDelete, canEdit, completed, onBack, onComplete, onDelete, onMoreInfo, onUncomplete, presentActionSheet, t]);

  return {showOptions};
};

export const RowActionButton = () => {
  const {instance, setInstance, rowId, canEdit} = useControlCardInstanceContext();
  assertNotNil(rowId);
  const deleteRowsRef = useRef<DeleteControlCardRowsHandle | null>(null);
  const {completeRowAndContinue} = useCompleteControlCardRow(rowId);
  const {uncompleteRow} = useUncompleteControlCardRow(rowId);

  const {t} = useTranslation();
  const {push} = useIonRouter();

  assertNotNil(rowId);
  const row = instance.rows.find((r) => rowId === r.rowId);
  assertNotNil(row);
  const [isMoreInfoOpen, {setTrue: showMoreInfo, setFalse: closeMoreInfo}] = useBoolean(false);

  const removeRow = useCallback(async () => {
    const result = await deleteRowsRef.current?.doDelete(instance.id, [rowId]);
    if (!isNil(result)) {
      setInstance(result);

      push(linkEmplControlCardInstance(instance.id.instanceId), "back");
    }
  }, [instance.id, push, rowId, setInstance]);

  const canDelete = canEdit && instance.definition.dynamicRows;
  const backHandler = useCallback(
    () => push(linkEmplControlCardInstance(instance.id.instanceId), "back"),
    [instance.id.instanceId, push]
  );
  const {showOptions} = useContextMenu({
    completed: !isNil(row.completedBy),
    onDelete: removeRow,
    onComplete: completeRowAndContinue,
    onUncomplete: uncompleteRow,
    onBack: backHandler,
    onMoreInfo: showMoreInfo,
    canDelete,
    canEdit
  });

  return (
    <>
      {canDelete && <DeleteControlCardRows ref={deleteRowsRef} />}
      <IonButton onClick={showOptions} shape="round">
        <IonIcon icon={ellipsisVerticalCircleOutline} slot="end" />
        <IonLabel>{t("g.action", {defaultValue: "Действия"})}</IonLabel>
      </IonButton>
      {!isNil(row.completedBy) && isMoreInfoOpen && (
        <AutoHeightModal
          breakpoints={[0, 1]}
          initialBreakpoint={1}
          isOpen={isMoreInfoOpen}
          onIonModalDidDismiss={closeMoreInfo}
        >
          <ModalWrapper>
            <IonList>
              <IonListHeader>
                {t("control-card.row.more-info.title", {defaultValue: "Информация за този запис"})}
              </IonListHeader>
              <IonItem lines="full">
                <IonLabel slot="start">{t("control-card.row.completed-by", {defaultValue: "Приключен от"})}</IonLabel>
                <IonLabel>{row.completedBy.name}</IonLabel>
              </IonItem>
            </IonList>
          </ModalWrapper>
        </AutoHeightModal>
      )}
    </>
  );
};
