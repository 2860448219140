import {useTranslation} from "react-i18next";
import {useIonActionSheet} from "@ionic/react";
import {useCallback} from "react";
import {closeOutline, pencilOutline, trashOutline} from "ionicons/icons";

interface IContextMenuProps {
  onDelete: () => void | Promise<void>;
  onEdit: () => void | Promise<void>;
  name: string;
}

export const useDocumentContextMenu = ({onDelete, onEdit, name}: IContextMenuProps) => {
  const {t} = useTranslation();

  const [presentActionSheet] = useIonActionSheet();
  const showOptions = useCallback(
    async () =>
      presentActionSheet({
        header: name,

        buttons: [
          {
            text: t("organizer.action.edit", {defaultValue: "Редактирай"}),
            icon: pencilOutline,
            handler: onEdit
          },
          {
            text: t("g.delete"),
            role: "destructive",
            icon: trashOutline,
            handler: async () => onDelete()
          },
          {
            text: t("g.close"),
            icon: closeOutline,
            role: "cancel"
          }
        ]
      }),
    [name, onDelete, onEdit, presentActionSheet, t]
  );

  return {showOptions};
};
