import {IColumnFragment, IValueFragment} from "app/gql/graphqlSchema";
import {assertCondition, assertNotNil, isEqual} from "app/utils/stdlib";
import {InvalidValue} from "app/employee/controlCard/display/InvalidValue";
import {EmptyValue} from "app/employee/controlCard/display/EmptyValue";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {DisplayEmployeeRefOptionValue} from "app/employee/controlCard/display/DisplayEmployeeRefOptionValue";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardEmployeeRefColumn"};
  value: IValueFragment;
}

export const DisplayEmployeeRef = ({column, value}: IProps) => {
  const {instance} = useControlCardInstanceContext();

  let result: JSX.Element;
  const selected = value;
  if (selected.__typename === "ControlCardRefValue") {
    const refOptions = instance.columnRefOptions.find((c) => isEqual(c.column.key, column.key));
    assertCondition(refOptions?.__typename === "EmployeeColumnRefOptions");
    assertNotNil(refOptions);
    const selectedRefOption = refOptions.options.find((o) => isEqual(selected, o.ref));
    result = <DisplayEmployeeRefOptionValue value={selectedRefOption} />;
  } else if (selected.__typename === "ControlCardEmptyValue") {
    result = <EmptyValue />;
  } else {
    result = <InvalidValue />;
  }

  return result;
};
