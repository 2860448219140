import {IonButton, IonContent, IonFab, IonIcon, IonRefresher, IonRefresherContent} from "@ionic/react";
import {ListPadding} from "app/employee/incdoc/ListPadding";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {ControlCardInstance as ConroCardInstancePrintView} from "app/components/print/view/controlcard/ControlCardInstance";
import {useCallback, useEffect, useMemo} from "react";
import {BackButtonEvent} from "@ionic/core/dist/types/utils/hardware-back-button";
import {NonPrintable} from "app/components/print/view/printStyles";
import {closeOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";

export const ControlCardPrintInstance = () => {
  const {t} = useTranslation();
  const {refresh, instance} = useControlCardInstanceContext();

  const listener = useCallback((ev: Event) => {
    const castEv = ev as BackButtonEvent;
    castEv.detail.register(10, (processNextHandler) => {
      if (window.opener) {
        window.close();
        return;
      }
      processNextHandler();
    });
  }, []);

  const closeCallback = useMemo(() => {
    if (window.opener) {
      return () => window.close();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("ionBackButton", listener);
    return () => document.removeEventListener("ionBackButton", listener);
  }, [listener]);

  return (
    <IonContent className="ion-padding">
      <NonPrintable>
        {closeCallback && (
          <IonFab horizontal="end" slot="fixed" vertical="bottom">
            <IonButton color="primary" onClick={closeCallback} shape="round">
              <IonIcon icon={closeOutline} slot="start" />
              {t("g.close")}
            </IonButton>
          </IonFab>
        )}
      </NonPrintable>
      <IonRefresher onIonRefresh={refresh} slot="fixed">
        <IonRefresherContent />
      </IonRefresher>
      <ConroCardInstancePrintView instance={instance} />
      <ListPadding />
    </IonContent>
  );
};
