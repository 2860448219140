import {ISyllabusTermVideoLesson} from "app/employee/state/syllabus/mutations";
import {IGraphQlDuration, MaybeNil} from "app/utils/types";
import {isNil} from "app/utils/stdlib";
import {linkEmplWatchCurrentVideo, linkEmplWatchPastVideo} from "app/employee/core/employeeLinks";
import {UnreachableCaseError} from "ts-essentials";

export const getVideoStats = (termLesson: ISyllabusTermVideoLesson) => {
  switch (termLesson.__typename) {
    case "TermPlanInProgressVideoLesson": {
      let thumbnailLength: MaybeNil<IGraphQlDuration>;
      if (!termLesson.watchCompleted && !isNil(termLesson.watchedLength)) {
        thumbnailLength = termLesson.watchedLength;
      } else {
        thumbnailLength = termLesson.videoLesson.thumbnail;
      }
      return {
        showProgress: true,
        watchCompleted: termLesson.watchCompleted,
        watchProgress: !isNil(termLesson.watchProgress) ? Number(termLesson.watchProgress) : null,
        thumbnailLength,
        canPlay: true,
        playLink: linkEmplWatchCurrentVideo()
      };
    }
    case "TermPlanCompletedVideoLesson":
      return {
        showProgress: false,
        watchCompleted: true,
        watchProgress: 1,
        thumbnailLength: termLesson.videoLesson.thumbnail,
        canPlay: true,
        playLink: linkEmplWatchPastVideo(termLesson.assignmentId)
      };
    case "TermPlanPendingVideoLesson":
      return {
        showProgress: false,
        watchCompleted: false,
        watchProgress: 0,
        canPlay: false,
        playLink: null,
        thumbnailLength: termLesson.videoLesson.thumbnail
      };
    default:
      throw new UnreachableCaseError(termLesson);
  }
};
