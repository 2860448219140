import {
  CreateControlCardInstanceDocument,
  IControlCardInstanceFullFragment,
  IControlCardTemplate,
  ILocationId
} from "app/gql/graphqlSchema";
import {useLoadingContext} from "app/employee/LoadingContext";
import {useTranslation} from "react-i18next";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRadio,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {RhfFstIonDatetimeButton} from "app/employee/rhf/RhfFstIonDatetimeButton";
import {z} from "zod";
import {zControlCardTemplateId, zLocalDate} from "app/utils/validator";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {DateTime} from "luxon";
import {dateLuxonToApi} from "app/utils/dates";
import {addOutline, closeOutline} from "ionicons/icons";
import {isNil} from "app/utils/stdlib";
import {useModalContext} from "app/employee/incdoc/FstIonModal";
import {query} from "app/gql/client";
import {RelativeDateDisplay} from "app/employee/RelativeDateDisplay";
import {RhfIonRadioGroup} from "app/employee/rhf/RhfIonRadioGroup";
import {usePermissions} from "app/employee/hooks/usePermissions";

type IAvailableTemplate = Pick<IControlCardTemplate, "id" | "name">;

interface IProps {
  locationId: ILocationId;
  templates: IAvailableTemplate[];
  onCreate: (newInstance: IControlCardInstanceFullFragment) => void;
}

const formSchema = z.object({
  date: zLocalDate,
  templateId: zControlCardTemplateId
});

export type IFormData = z.infer<typeof formSchema>;

export const InstantiateTemplate = ({locationId, templates, onCreate}: IProps) => {
  const modalContext = useModalContext();
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();
  const {canEditControlCardLocation} = usePermissions();

  const {t} = useTranslation();

  const {
    control,
    formState: {isValid, isSubmitting, errors},
    handleSubmit,
    watch,
    setError
  } = useForm<IFormData>({
    defaultValues: {
      date: dateLuxonToApi(DateTime.now())
    },
    resolver: zodResolver(formSchema)
  });

  const createTemplateInstance = async (data: IFormData) => {
    if (!canEditControlCardLocation(locationId, data.date)) {
      setError("date", {message: t("control-card.location.recurring.create.no-permission")});
      return;
    }
    startLoading();
    try {
      const {date, templateId} = data;
      const result = await query(CreateControlCardInstanceDocument, {
        input: {
          templateId,
          locationId,
          sourceInstanceId: null,
          date
        }
      });
      onCreate(result.createControlCardInstance);
    } catch (e) {
      await showExceptionError(e);
      return null;
    } finally {
      finishLoading();
    }
  };

  const maxTime = DateTime.now().toISODate();
  const date = watch("date");

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{t("control-card.location.recurring.create.title")}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          <IonItem lines="full">
            <IonLabel>
              {t("control-card.location.recurring.create.date")}
              {!isNil(date) && (
                <p>
                  <RelativeDateDisplay dt={date} />
                </p>
              )}
              {!isNil(errors.date) && (
                <p>
                  <IonText color="danger">{errors.date.message}</IonText>
                </p>
              )}
            </IonLabel>
            <RhfFstIonDatetimeButton
              max={maxTime}
              multiple={false}
              presentation="date"
              rhf={{control, name: "date"}}
              showClearButton={false}
              slot="end"
            >
              <IonLabel slot="title">{t("control-card.location.recurring.create.date")}</IonLabel>
            </RhfFstIonDatetimeButton>
          </IonItem>
          <IonListHeader>
            <IonLabel>{t("control-card.location.recurring.create.template")}</IonLabel>
          </IonListHeader>
          <IonItem lines="full">
            <RhfIonRadioGroup rhf={{control, name: "templateId"}}>
              {templates.map((template) => (
                <IonRadio justify="start" key={template.id.templateId} labelPlacement="end" value={template.id}>
                  {template.name}
                </IonRadio>
              ))}
            </RhfIonRadioGroup>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="primary">
            {!isNil(modalContext) && (
              <IonButton color="primary" disabled={isSubmitting} fill="outline" onClick={() => modalContext?.dismiss()}>
                <IonIcon icon={closeOutline} slot="start" />
                {t("g.cancel")}
              </IonButton>
            )}
            <IonButton
              color="primary"
              disabled={isSubmitting || !isValid}
              fill="solid"
              onClick={handleSubmit(createTemplateInstance)}
            >
              <IonIcon icon={addOutline} slot="start" />
              {t("control-card.location.create-new")}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
