import {assertNotNil, isEqual, isNil} from "app/utils/stdlib";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {ControlCardRowNavigation} from "app/employee/controlCard/ControlCardRowNavigation";
import {DisplayInput} from "app/employee/controlCard/DisplayInput";
import {ID} from "app/utils/types";
import {IonButton, IonFab, IonIcon} from "@ionic/react";
import {RowActionButton} from "app/employee/controlCard/RowActionButton";
import {ControlCardInstanceHeaderFooter} from "app/employee/controlCard/ControlCardInstanceHeaderFooter";
import {FlexColumnCenter} from "app/employee/flexUtils";
import {useTranslation} from "react-i18next";
import {
  ControlCardInstanceSummary,
  SummaryAdditionalGridRow,
  SummaryAdditionalRow
} from "app/employee/controlCard/ControlCardInstanceSummary";
import {IControlCardRowInstanceFragment} from "app/gql/graphqlSchema";
import {checkmarkCircle, lockOpenOutline} from "ionicons/icons";
import {IonRowButton} from "app/employee/IonRowButton";
import {useUncompleteControlCardRow} from "app/employee/controlCard/actions/useUncompleteControlCardRow";
import {useCompleteControlCardRow} from "app/employee/controlCard/actions/useCompleteControlCardRow";
import {useCompleteControlCard} from "app/employee/controlCard/actions/useCompleteControlCard";
import {useUncompleteControlCard} from "app/employee/controlCard/actions/useUncompleteControlCard";
import {DisplayValueInstance} from "app/employee/controlCard/DisplayValueInstance";

interface IProps {
  rowId: ID;
}

export const PrimaryColumnValue = ({row}: {row: IControlCardRowInstanceFragment}) => {
  const {primaryColumn} = useControlCardInstanceContext();
  const rowColumns = row.columns;
  const valueInstance = rowColumns.find((c) => isEqual(c.columnKey, primaryColumn.key));
  assertNotNil(valueInstance);
  return <DisplayValueInstance column={primaryColumn} valueInstance={valueInstance} />;
};

export const RegularRowSummary = ({
  row,
  rowCompleted,
  uncompleteRow
}: {
  row: IControlCardRowInstanceFragment;
  rowCompleted: boolean;
  uncompleteRow: () => void;
}) => {
  const {primaryColumn, canEdit} = useControlCardInstanceContext();
  const {t} = useTranslation();
  const content = (
    <span>
      {primaryColumn.title}:{" "}
      <strong>
        <PrimaryColumnValue row={row} />
      </strong>
    </span>
  );
  return (
    <ControlCardInstanceSummary>
      {rowCompleted ? (
        <SummaryAdditionalGridRow $color="completed">
          {content}
          <div>
            <IonRowButton disabled={!canEdit} onClick={uncompleteRow}>
              <IonIcon icon={lockOpenOutline} slot="start" />
              {t("control-card.row.action.uncomplete")}
            </IonRowButton>
          </div>
        </SummaryAdditionalGridRow>
      ) : (
        <SummaryAdditionalRow $color="incomplete">{content}</SummaryAdditionalRow>
      )}
    </ControlCardInstanceSummary>
  );
};

export const RegularRow = ({rowId}: IProps) => {
  const {instance, primaryColumn, isComplete, isRowComplete, canEdit} = useControlCardInstanceContext();
  const {completeRowAndContinue} = useCompleteControlCardRow(rowId);
  const {uncompleteRow} = useUncompleteControlCardRow(rowId);
  const {t} = useTranslation();

  const row = instance.rows.find((r) => rowId === r.rowId);
  // Workaround for Ionic keeping the view in DOM and causing re-renders
  if (isNil(row)) {
    return null;
  }

  const rowCompleted = isComplete || isRowComplete(row.rowId);
  const rowColumns = row.columns;
  const definitionColumns = instance.definition.columns;
  const isNotApplicable = !isNil(instance.notApplicableBy);

  return (
    <>
      <RegularRowSummary row={row} rowCompleted={rowCompleted} uncompleteRow={uncompleteRow} />

      {!isNotApplicable && (
        <>
          <ControlCardRowNavigation />
          <IonFab horizontal="end" slot="fixed" vertical="bottom">
            <RowActionButton />
          </IonFab>
          <ControlCardInstanceHeaderFooter headerOrFooter="header" />
          {definitionColumns.map((column) => {
            const isPrimaryColumn = isEqual(column.key, primaryColumn.key);
            if (isPrimaryColumn) {
              if (instance.definition.lockPrimaryColumn) {
                return null;
              }
            }
            const valueInstances = rowColumns.filter((c) => isEqual(c.columnKey, column.key));
            if (valueInstances.length === 1) {
              return <DisplayInput column={column} key={column.key.value} valueInstance={valueInstances[0]} />;
            } else {
              return null;
            }
          })}
          <ControlCardInstanceHeaderFooter headerOrFooter="footer" />
          <FlexColumnCenter className="ion-margin">
            <div>
              {rowCompleted ? (
                <IonButton disabled={!canEdit} onClick={uncompleteRow}>
                  <IonIcon icon={lockOpenOutline} slot="start" />
                  {t("control-card.row.action.uncomplete")} <PrimaryColumnValue row={row} />
                </IonButton>
              ) : (
                <IonButton disabled={!canEdit} onClick={completeRowAndContinue}>
                  <IonIcon icon={checkmarkCircle} slot="start" />
                  {t("control-card.row.action.complete")} <PrimaryColumnValue row={row} />
                </IonButton>
              )}
            </div>
          </FlexColumnCenter>
        </>
      )}
    </>
  );
};

export const TransposedRow = ({rowId}: {rowId: ID}) => {
  const {instance, primaryColumn, isComplete, canEdit} = useControlCardInstanceContext();
  const {t} = useTranslation();
  const {completeControlCard} = useCompleteControlCard();
  const {uncompleteControlCard} = useUncompleteControlCard();
  const row = instance.rows.find((r) => rowId === r.rowId);
  // Workaround for Ionic keeping the view in DOM and causing re-renders
  if (isNil(row)) {
    return null;
  }

  const rowColumns = row.columns;
  const columns = instance.definition.columns;

  return (
    <>
      <ControlCardInstanceHeaderFooter headerOrFooter="header" />
      {columns.map((column) => {
        const isPrimaryColumn = isEqual(column.key, primaryColumn.key);
        const disabled = isPrimaryColumn && instance.definition.lockPrimaryColumn;
        const valueInstances = rowColumns.filter((c) => isEqual(c.columnKey, column.key));
        if (valueInstances.length === 1) {
          return (
            <DisplayInput
              column={column}
              disabled={disabled}
              key={column.key.value}
              valueInstance={valueInstances[0]}
            />
          );
        } else {
          return null;
        }
      })}
      <ControlCardInstanceHeaderFooter headerOrFooter="footer" />
      <FlexColumnCenter className="ion-margin">
        <div>
          {isComplete ? (
            <IonButton disabled={!canEdit} onClick={uncompleteControlCard}>
              <IonIcon icon={lockOpenOutline} slot="start" />
              {t("control-card.action.uncomplete")}
            </IonButton>
          ) : (
            <IonButton disabled={!canEdit} onClick={() => completeControlCard()}>
              <IonIcon icon={checkmarkCircle} slot="start" />
              {t("control-card.action.complete")}
            </IonButton>
          )}
        </div>
      </FlexColumnCenter>
    </>
  );
};
