import {FieldPath, FieldValues, useController} from "react-hook-form";
import {hofForwardRef, IIonRadioGroup, noop, RhfProps, RhfRef} from "app/utils/types";
import {IonRadioGroup} from "@ionic/react";
import {ForwardedRef, RefCallback, useImperativeHandle, useRef} from "react";
import {assignRef, isEqual} from "app/utils/stdlib";

const reportValidity = () => true;

export const RhfIonRadioGroup = hofForwardRef(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    {
      rhf,
      ...rest
    }: Omit<IIonRadioGroup, "compareWith" | "name" | "onBlur" | "onIonChange" | "value"> &
      RhfProps<TFieldValues, TName>,
    ref: ForwardedRef<HTMLIonRadioGroupElement>
  ) => {
    const {
      field: {onChange, ref: fieldRef, value, name, onBlur}
    } = useController(rhf);

    const rhfRef = useRef<RhfRef | null>(null);
    const inputRef: RefCallback<HTMLIonRadioGroupElement> = (element) => {
      assignRef(ref, element);
      rhfRef.current = {
        focus: () => element?.focus(),
        select: () => element?.scrollIntoView(),
        setCustomValidity: noop,
        reportValidity
      };
    };
    useImperativeHandle(fieldRef, () => rhfRef.current);

    return (
      <IonRadioGroup
        {...rest}
        compareWith={isEqual}
        name={name}
        onBlur={onBlur}
        onIonChange={(event) => {
          if (event.target.tagName.toLowerCase() !== "ion-radio-group") {
            console.debug("Ignoring event from non-radio-group element", event.target.tagName);
            return;
          }
          onChange(event.detail.value);
        }}
        ref={inputRef}
        value={value}
      />
    );
  }
);
