import {completeCurrentTest, ISyllabusInProgressVideoLesson} from "app/employee/state/syllabus/mutations";
import {IonButton, IonIcon, useIonRouter} from "@ionic/react";
import {isNotNil} from "app/utils/stdlib";
import {mustWatchAgain} from "app/employee/state/syllabus/selectors";
import {arrowForward, helpCircleOutline, listOutline, playCircle} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {
  linkEmplCurrentTest,
  linkEmplCurrentTestResult,
  linkEmplWatchCurrentVideo
} from "app/employee/core/employeeLinks";

interface ICurrentVideoButtonsProps {
  lesson: ISyllabusInProgressVideoLesson;
}

export const CurrentVideoButtons = ({lesson}: ICurrentVideoButtonsProps) => {
  const {t} = useTranslation();
  const ionRouter = useIonRouter();

  if (!lesson.watchCompleted) {
    return (
      <IonButton color="primary" routerDirection="forward" routerLink={linkEmplWatchCurrentVideo()}>
        <IonIcon icon={playCircle} slot="start" />
        {t("player.watch-lesson", {defaultValue: "Гледай темата"})}
      </IonButton>
    );
  }

  const isPassed = lesson.testResult.passed;
  if (isPassed) {
    const completeLesson = async () => {
      await completeCurrentTest();
      ionRouter.push(linkEmplWatchCurrentVideo(), "forward", "push");
    };

    return (
      <IonButton color="primary" onClick={completeLesson}>
        <IonIcon icon={arrowForward} slot="end" />
        {t("test.continue-to-next")}
      </IonButton>
    );
  }

  const questions = lesson.videoLesson.test.questions;
  const hasAnswersToAllQuestions = questions.every((q) => isNotNil(q.employeeAnswer));
  const isWatchedAllIncorrectQuestions = questions.every((q) => !mustWatchAgain(q));

  if (!hasAnswersToAllQuestions || isWatchedAllIncorrectQuestions) {
    const label = hasAnswersToAllQuestions
      ? t("test.take-test-again")
      : t("test.take-test", {defaultValue: "Решете теста"});

    return (
      <IonButton color="primary" routerLink={linkEmplCurrentTest()}>
        <IonIcon icon={helpCircleOutline} slot="start" />
        {label}
      </IonButton>
    );
  } else {
    return (
      <IonButton routerLink={linkEmplCurrentTestResult()}>
        <IonIcon icon={listOutline} slot="start" />
        {t("test.view-results", {defaultValue: "Вижте резултата от теста"})}
      </IonButton>
    );
  }
};
