import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonRouter
} from "@ionic/react";
import {MenuButton} from "app/employee/menu/MenuButton";
import {LoadingLoadable} from "app/employee/Loading";
import {useTranslation} from "react-i18next";
import {useOrganizer} from "../useOrganizer";
import {arrowBackOutline} from "ionicons/icons";
import {linkEmplOrganizer, linkEmplOrganizerFolder} from "app/employee/core/employeeLinks";
import {isNil} from "app/utils/stdlib";
import {z} from "zod";
import {useSafePathParams} from "app/components/hooks/useSafeParams";
import {IEmployeeLocationFragment, IOrganizerFragment} from "app/gql/graphqlSchema";
import {MaybeNil} from "app/utils/types";
import {useCallback} from "react";
import {DocumentContent} from "app/employee/organizer/document/DocumentContent";

const findDocumentAndFolder = (organizer: MaybeNil<IOrganizerFragment>, documentId: string) => {
  if (isNil(organizer)) {
    return null;
  }
  for (const folder of organizer.folders) {
    const document = folder.documents.find((d) => d.id.documentId === documentId);
    if (document) {
      return {document, folder};
    }
  }
};

type IFindDocumentAndFolderReturn = ReturnType<typeof findDocumentAndFolder>;

const getBackLink = (
  location: MaybeNil<IEmployeeLocationFragment>,
  documentAndFolder: IFindDocumentAndFolderReturn
) => {
  if (isNil(location)) {
    return linkEmplOrganizer();
  }
  if (isNil(documentAndFolder)) {
    return linkEmplOrganizer(location.id.locationId);
  }
  return linkEmplOrganizerFolder(location.id.locationId, documentAndFolder.folder.id.folderId);
};

const documentParams = z.object({
  documentId: z.string()
});

export const DocumentPage = () => {
  const {t} = useTranslation();
  const {organizerLoadable, refreshOrganizer, location} = useOrganizer();
  const {documentId: documentIdParam} = useSafePathParams(documentParams);
  const ionRouter = useIonRouter();

  const organizer = organizerLoadable.value;
  const documentAndFolder = findDocumentAndFolder(organizer, documentIdParam);
  const backLink = getBackLink(location, documentAndFolder);

  const handleGoBack = useCallback(() => {
    ionRouter.push(backLink, "back", "pop");
  }, [backLink, ionRouter]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" routerDirection="back" routerLink={backLink}>
              <IonIcon icon={arrowBackOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>{t("organizer.title", {defaultValue: "Органайзер"})}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <LoadingLoadable loadable={organizerLoadable} />
        <IonRefresher onIonRefresh={refreshOrganizer} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>
        {!isNil(location) && !isNil(organizer) && !isNil(documentAndFolder) && (
          <DocumentContent
            document={documentAndFolder.document}
            folder={documentAndFolder.folder}
            location={location}
            onBack={handleGoBack}
            onRefresh={refreshOrganizer}
            organizer={organizer}
          />
        )}
      </IonContent>
    </>
  );
};
