import {
  CreateControlCardInstanceDocument,
  IControlCardInstanceFullFragment,
  IControlCardInstanceId,
  IControlCardInstanceSummaryFragment,
  IControlCardTemplateId,
  ILocationId
} from "app/gql/graphqlSchema";
import {forwardRef, useCallback, useEffect, useImperativeHandle} from "react";
import {MaybeNil} from "app/utils/types";
import {useLoadingContext} from "app/employee/LoadingContext";
import {isNil} from "app/utils/stdlib";
import {useIonAlert, useIonViewWillLeave} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {z} from "zod";
import {query} from "app/gql/client";
import {DateTime} from "luxon";
import {Deferred} from "app/utils/deferred";

interface IProps {}

type ISourceInstance = Pick<IControlCardInstanceSummaryFragment, "name" | "instanceId">;

export interface IInstantiateControlCardHandle {
  doInstance: (
    templateId: IControlCardTemplateId,
    templateName: string,
    locationId: ILocationId,
    sourceInstance: MaybeNil<ISourceInstance>,
    date: DateTime
  ) => Promise<IControlCardInstanceFullFragment | null>;
}

enum ConfirmOutcome {
  CANCEL = 1,
  COPY,
  EMPTY
}

const zConfirmOutcome = z
  .nativeEnum(ConfirmOutcome)
  .nullish()
  .transform((v) => v ?? ConfirmOutcome.CANCEL);

export const InstantiateControlCard = forwardRef<IInstantiateControlCardHandle, IProps>((props, componentRef) => {
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();
  const [presentAlert, dismissAlert] = useIonAlert();
  const {t} = useTranslation();

  const confirmUseSourceInstance = useCallback(
    async (sourceInstance: ISourceInstance) => {
      const deferred = new Deferred<ConfirmOutcome>();
      await presentAlert({
        header: t("control-card.instantiate.header", {name: sourceInstance.name}),
        message: t("control-card.instantiate.message", {name: sourceInstance.name}),
        inputs: [
          {
            label: t("control-card.instantiate.option-create-copy"),
            type: "radio",
            value: ConfirmOutcome.COPY
          },
          {
            label: t("control-card.instantiate.option-create-empty"),
            type: "radio",
            value: ConfirmOutcome.EMPTY
          }
        ],
        buttons: [
          {text: t("g.cancel"), handler: () => deferred.resolve(ConfirmOutcome.CANCEL), role: "cancel"},
          {
            text: t("control-card.location.create-new"),
            handler: (value) => deferred.resolve(zConfirmOutcome.parse(value))
          }
        ]
      });
      return deferred.promise;
    },
    [presentAlert, t]
  );

  useEffect(
    () => () => {
      dismissAlert();
    },
    [dismissAlert]
  );

  useIonViewWillLeave(() => {
    dismissAlert();
  }, [dismissAlert]);

  const confirmNewInstance = useCallback(
    async (templateName: string) => {
      const deferred = new Deferred<ConfirmOutcome>();
      await presentAlert({
        header: t("control-card.instantiate.header", {name: templateName}),
        message: t("control-card.instantiate.confirmation-message", {name: templateName}),
        buttons: [
          {text: t("g.cancel"), handler: () => deferred.resolve(ConfirmOutcome.CANCEL), role: "cancel"},
          {
            text: t("control-card.location.create-new"),
            handler: () => deferred.resolve(ConfirmOutcome.EMPTY)
          }
        ]
      });
      return deferred.promise;
    },
    [presentAlert, t]
  );

  useImperativeHandle(
    componentRef,
    () => ({
      doInstance: async (templateId, templateName, locationId, sourceInstance, date: DateTime) => {
        try {
          let sourceInstanceId: IControlCardInstanceId | null = null;
          if (!isNil(sourceInstance)) {
            const confirmOutcome = await confirmUseSourceInstance(sourceInstance);
            if (confirmOutcome === ConfirmOutcome.CANCEL) {
              return null;
            }
            if (confirmOutcome === ConfirmOutcome.COPY) {
              sourceInstanceId = sourceInstance.instanceId;
            }
          } else {
            const confirmOutcome = await confirmNewInstance(templateName);
            if (confirmOutcome === ConfirmOutcome.CANCEL) {
              return null;
            }
          }
          startLoading();
          const result = await query(CreateControlCardInstanceDocument, {
            input: {
              templateId,
              locationId,
              sourceInstanceId,
              date: date.toISODate()
            }
          });

          return result.createControlCardInstance;
        } catch (e) {
          await showExceptionError(e);
          return null;
        } finally {
          finishLoading();
        }
      }
    }),
    [confirmNewInstance, confirmUseSourceInstance, finishLoading, showExceptionError, startLoading]
  );

  return null;
});

InstantiateControlCard.displayName = "InstantiateControlCard";
