import {Trans, useTranslation} from "react-i18next";
import {supportedLanguages} from "app/employee/i18n";
import {IonSelectCustomEvent} from "@ionic/core/dist/types/components";
import {IonSelect, IonSelectOption, SelectChangeEventDetail} from "@ionic/react";
import {z} from "zod";
import {customZodI18nMap} from "app/utils/zodErrorMap";

export const LanguageSelect = () => {
  const {t, i18n} = useTranslation();
  const currentLanguage = supportedLanguages.find((lang) => lang.code === i18n.language);

  const languageChangeHandler = async (event: IonSelectCustomEvent<SelectChangeEventDetail>) => {
    console.log("Changing language to", event.detail.value);
    await i18n.changeLanguage(event.detail.value);
    z.setErrorMap(customZodI18nMap);
  };

  return (
    <IonSelect
      cancelText={t("g.cancel")}
      okText={t("g.ok")}
      onIonChange={languageChangeHandler}
      value={currentLanguage?.code}
    >
      <Trans i18nKey="menu.language" t={t} />
      {supportedLanguages.map((lang) => (
        <IonSelectOption key={lang.code} value={lang.code}>
          {lang.flag} {lang.name}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
};
