import {FieldPath, FieldValues, PathValue, useController} from "react-hook-form";
import {IOption, ITypeaheadSelectProps, TypeaheadSelect} from "app/employee/incdoc/supplier/TypeaheadSelect";
import {hofForwardRef, noop, RhfProps, RhfRef} from "app/utils/types";
import {ForwardedRef, RefCallback, useImperativeHandle, useRef} from "react";
import {assignRef, defaultScrollIntoView} from "app/utils/stdlib";

const reportValidity = () => true;

export const RhfTypeaheadSelect = hofForwardRef(
  <
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>,
    Value extends PathValue<TFieldValues, TName>,
    Option extends IOption<Value>
  >(
    {
      rhf,
      disabled,
      ...rest
    }: Omit<ITypeaheadSelectProps<Value, Option>, "onConfirm" | "value"> & RhfProps<TFieldValues, TName>,
    ref: ForwardedRef<HTMLIonItemElement>
  ) => {
    const {
      field: {onChange, value, disabled: rhfDisabled, ref: fieldRef},
      formState: {isSubmitting},
      fieldState: {error}
    } = useController(rhf);

    const rhfRef = useRef<RhfRef | null>(null);
    const inputRef: RefCallback<HTMLIonItemElement> = (element) => {
      assignRef(ref, element);
      rhfRef.current = {
        focus: () => element?.scrollIntoView(defaultScrollIntoView),
        select: noop,
        setCustomValidity: noop,
        reportValidity
      };
    };
    useImperativeHandle(fieldRef, () => rhfRef.current);

    return (
      <TypeaheadSelect
        ref={inputRef}
        {...rest}
        disabled={isSubmitting || disabled || rhfDisabled}
        errorMessage={error?.message}
        onConfirm={(option) => onChange(option?.value)}
        value={value}
      />
    );
  }
);
