import {EmployeeNotificationsDocument, IEmployeeNotificationsQuery} from "app/gql/graphqlSchema";
import {useEmployeeNotificationState} from "app/employee/state/notification/mutations";
import {useShallow} from "zustand/react/shallow";
import {useQueryExternalState} from "app/employee/controlCard/hook/useQuery";

const stateMapper = (n: IEmployeeNotificationsQuery) => {
  return n.getNotifications.inAppNotifications;
};

export const useNotifications = () => {
  const [state, updateState] = useEmployeeNotificationState(
    useShallow((state) => [state.notifications, state.setNotifications])
  );
  return useQueryExternalState(EmployeeNotificationsDocument, stateMapper, [state, updateState]);
};
