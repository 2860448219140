/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "swiper/css/bundle";

import "app/employee/ion-customize.scss";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {bootstrap, useBootstrapEmployeeStore} from "app/employee/state/bootstrapEmployee/mutations";
import {useEffect} from "react";
import {ApiState, selectMaybeLoaded} from "app/state/common/loadable";
import {Login} from "app/employee/login/Login";
import {closeOutline, homeOutline} from "ionicons/icons";
import {IonReactRouter} from "@ionic/react-router";
import {EmployeeMenuFooter} from "app/employee/menu/EmployeeMenuFooter";
import {Routing} from "app/employee/Routing";
import {ContentSpinner} from "app/employee/ContentSpinner";
import {LoggedInMenuItems} from "app/employee/menu/LoggedInMenuItems";
import {Trans, useTranslation} from "react-i18next";

import {linkEmplAppHome} from "app/employee/core/employeeLinks";
import {AppContext, updateAppContext} from "app/gql/appContext";
import {DisabledGuard} from "app/employee/core/DisabledGuard";
import {LoadingProvider} from "app/employee/LoadingContext";

export const EmployeeApp = () => {
  const {t} = useTranslation();
  const bootstrapResult = useBootstrapEmployeeStore((state) => state.bootstrapResult);

  const maybeBootstrapResult = selectMaybeLoaded(bootstrapResult);

  useEffect(() => {
    updateAppContext(AppContext.EMPLOYEE);
    bootstrap();
  }, []);

  let content: JSX.Element | null;
  const loadingState = bootstrapResult.loadingState;
  if (loadingState === ApiState.OK) {
    const showLogin = bootstrapResult.value.__typename === "Public";
    content = showLogin ? <Login /> : <Routing />;
  } else {
    content = null;
  }

  return (
    <IonReactRouter>
      <IonMenu contentId="main-content" side="end">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonButton routerDirection="root" routerLink={linkEmplAppHome()}>
                  <IonIcon icon={homeOutline} slot="icon-only" />
                </IonButton>
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>
              <Trans i18nKey="g.app-name" t={t}>
                FSAcademy.bg
              </Trans>
            </IonTitle>
            <IonMenuButton aria-label="Close main menu" slot="end">
              <IonIcon icon={closeOutline} />
            </IonMenuButton>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <LoggedInMenuItems bootstrapResult={maybeBootstrapResult} />
        </IonContent>
        <EmployeeMenuFooter bootstrapResult={maybeBootstrapResult} />
      </IonMenu>
      <IonContent id="main-content">
        <DisabledGuard>
          <ContentSpinner loadingState={loadingState}>
            <LoadingProvider>{content}</LoadingProvider>
          </ContentSpinner>
        </DisabledGuard>
      </IonContent>
    </IonReactRouter>
  );
};
