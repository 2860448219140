import {IonIcon, IonLabel, useIonLoading, useIonViewWillLeave} from "@ionic/react";
import {cameraOutline} from "ionicons/icons";
import {ID, IIonButton} from "app/utils/types";
import {useTranslation} from "react-i18next";
import {AddAttachmentDocument, IAttachmentCollectionFragment, IAttachmentCollectionId} from "app/gql/graphqlSchema";
import {getUploadyDestination, query} from "app/gql/client";
import {
  Batch,
  FILE_STATES,
  Uploady,
  useAbortAll,
  useBatchAddListener,
  useBatchFinishListener,
  useUploady
} from "@rpldy/uploady";
import {useRef} from "react";
import PQueue from "p-queue";
import {IonRowButton} from "app/employee/IonRowButton";
import {zUploadResponse} from "app/utils/validator";

type IProps = Omit<IIonButton, "onClick"> & {
  attCollection: IAttachmentCollectionFragment;
  canUpload: boolean;
  onUploadCompleted?: () => void | Promise<void>;
};

const addAttachments = async (collectionId: IAttachmentCollectionId, stageUploadIds: ID[], signal?: AbortSignal) => {
  const batchPromises = stageUploadIds.map((stagedUploadId) =>
    query(AddAttachmentDocument, {
      input: {
        collectionId,
        stagedUploadId
      },
      signal
    }).then((result) => result.attach)
  );
  const batchResult = await Promise.allSettled(batchPromises);
  for (const result of batchResult) {
    if (result.status === "fulfilled") {
      const attachment = result.value;
      if (attachment.__typename === "AttachmentSuccessResult") {
        console.log("Attachment added", attachment.attachment);
      } else {
        console.error("Attachment failed", attachment.errors);
      }
    } else {
      console.error("Attachment rejected", result.reason);
    }
  }
};

const collectStageUploadIds = (batch: Batch) => {
  const stagedUploadIds: ID[] = [];
  for (const item of batch.items) {
    if (item.state === FILE_STATES.FINISHED) {
      const uploadResponse = zUploadResponse.safeParse(item.uploadResponse);
      if (uploadResponse.success) {
        for (const responseData of uploadResponse.data.data) {
          stagedUploadIds.push(responseData.id.toString());
        }
      } else {
        console.error("Failed to parse upload response", uploadResponse.error);
      }
    }
  }
  return stagedUploadIds;
};

export const AddAttachment = ({canUpload, ...props}: IProps) => {
  if (!canUpload) {
    return null;
  } else {
    return (
      <Uploady concurrent destination={getUploadyDestination()} maxConcurrent={2} method="POST" multiple>
        <AddAttachmentInner {...props} />
      </Uploady>
    );
  }
};

const AddAttachmentInner = ({attCollection, onUploadCompleted, ...props}: Omit<IProps, "canUpload">) => {
  const {t} = useTranslation();
  const [presentLoading, dismissLoading] = useIonLoading();
  const {showFileUpload} = useUploady();

  const queueRef = useRef(
    new PQueue({concurrency: 1}).on("error", (err) => {
      console.error("loadingQueue error", err);
    })
  );

  useBatchAddListener(() => {
    queueRef.current.add(() =>
      presentLoading({
        backdropDismiss: false,
        message: t("g.loading")
      })
    );
  });

  useBatchFinishListener((batch) => {
    console.log("Finish", batch);
    queueRef.current.add(async () => {
      const stagedUploadIds = collectStageUploadIds(batch);
      await addAttachments(attCollection.id, stagedUploadIds);
      await dismissLoading();
      await onUploadCompleted?.();
    });
  });

  const abortAll = useAbortAll();

  useIonViewWillLeave(() => {
    abortAll();
    dismissLoading();
  }, [abortAll, dismissLoading]);

  const clickHandler = () => {
    showFileUpload();
  };

  return (
    <IonRowButton {...props} onClick={clickHandler}>
      <IonIcon icon={cameraOutline} slot="start" />
      <IonLabel>{attCollection.attachments.length > 0 ? t("g.upload-more") : t("g.upload")}</IonLabel>
    </IonRowButton>
  );
};
