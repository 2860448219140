import {MaybeNil} from "app/utils/types";
import {isNil} from "app/utils/stdlib";
import {InvalidValue} from "app/employee/controlCard/display/InvalidValue";
import {IRefOption} from "app/employee/controlCard/refUtils";

type IEmployeeRefOptions = IRefOption & {__typename: "EmployeeColumnRefOptions"};
type IEmployeeRefOptionValue = IEmployeeRefOptions["options"][number];

export const DisplayEmployeeRefOptionValue = ({value}: {value: MaybeNil<IEmployeeRefOptionValue>}) => {
  if (isNil(value)) {
    return <InvalidValue />;
  } else {
    const {name} = value.employee;
    return <>{name}</>;
  }
};
