import {IIonButton, IIonIcon} from "app/utils/types";
import {closeOutline} from "ionicons/icons";
import {IonButton, IonIcon} from "@ionic/react";
import {styled} from "styled-components";

const StyledIonButton = styled(IonButton)`
  --padding-start: 0;
  --padding-end: 0;
`;

type IProps = Omit<IIonButton, "children"> & Pick<IIonIcon, "icon">;
export const IonIconButton = (props: IProps) => {
  return (
    <StyledIonButton {...props}>
      <IonIcon icon={closeOutline} slot="icon-only" />
    </StyledIonButton>
  );
};
