import {ControlCardCategory, ICardDeckStatsFragment} from "app/gql/graphqlSchema";
import {useMemo} from "react";
import {isNil} from "app/utils/stdlib";
import {IonBadge} from "@ionic/react";

interface IProps {
  stats: ICardDeckStatsFragment[];
  category: ControlCardCategory;
}

export const IncompleteInstanceCount = ({stats, category}: IProps) => {
  const count = useMemo(
    () => stats.find((s) => s.category === category)?.rangeStats.incompleteCount,
    [stats, category]
  );

  if (isNil(count) || count === 0) {
    return null;
  }
  return <IonBadge>{count}</IonBadge>;
};
