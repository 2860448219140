import {useTranslation} from "react-i18next";
import {IonBadge} from "@ionic/react";
import {IOrganizerDocumentFragment} from "app/gql/graphqlSchema";

export const ExpirationBadge = ({document}: {document: IOrganizerDocumentFragment}) => {
  const {t} = useTranslation();

  if (document.expired) {
    return <IonBadge color="danger">{t("organizer.validity.expired", {defaultValue: "Изтекъл"})}</IonBadge>;
  } else if (document.expiresSoon) {
    return <IonBadge color="warning">{t("organizer.validity.expires-soon", {defaultValue: "Изтича скоро"})}</IonBadge>;
  } else {
    return null;
  }
};
