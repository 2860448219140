import {useTranslation} from "react-i18next";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  useIonAlert,
  useIonRouter,
  useIonViewWillLeave
} from "@ionic/react";
import {assertNotNil, isNil} from "app/utils/stdlib";
import {linkEmplControlCardInstance} from "app/employee/core/employeeLinks";
import {DateTime} from "luxon";
import {
  IInstantiateControlCardHandle,
  InstantiateControlCard
} from "app/employee/controlCard/actions/InstantiateControlCard";
import {useMemo, useRef} from "react";
import {addOutline} from "ionicons/icons";
import {dateApiToLuxon, ionDateTimeParser} from "app/utils/dates";
import {IControlCardTemplateDeckFragment, IDeckControlCardFragment} from "app/gql/graphqlSchema";
import {
  getInstanceOrTemplate,
  getKey,
  useActiveTemplatesControlCards,
  useArchivedControlCards
} from "app/employee/controlCard/location/utils";
import {FstIonDatetimeButton} from "app/employee/core/FstIonDatetimeButton";
import {useLocationDeckFilterContext} from "app/employee/controlCard/location/useLocationDeckFilter";
import {RelativeDateDisplay} from "app/employee/RelativeDateDisplay";
import {IIonItem} from "app/utils/types";
import {ArchivedControlCards} from "app/employee/controlCard/location/ArchivedControlCards";
import {cardCrossedIcon} from "app/utils/icons/ionicFixedIcons";
import {usePermissions} from "app/employee/hooks/usePermissions";

export const DailyLocationControlCards = ({dailyDeck}: {dailyDeck: IDeckControlCardFragment[]}) => {
  const {t} = useTranslation();
  const {filter, setFilter} = useLocationDeckFilterContext();
  assertNotNil(filter);
  const {locationId, dailyDate} = filter;
  const {canEditControlCardLocation} = usePermissions();

  const canCreateControlCard = useMemo(
    () => canEditControlCardLocation(locationId, dailyDate),
    [canEditControlCardLocation, dailyDate, locationId]
  );

  const instantiateRef = useRef<IInstantiateControlCardHandle>(null);
  const [presentAlert, dismissAlert] = useIonAlert();
  useIonViewWillLeave(() => {
    dismissAlert();
  }, [dismissAlert]);

  const {push} = useIonRouter();

  const createNewInstance = async (template: IControlCardTemplateDeckFragment, date: DateTime) => {
    const newInstance = await instantiateRef.current?.doInstance(template.id, template.name, locationId, null, date);
    if (!isNil(newInstance)) {
      push(linkEmplControlCardInstance(newInstance.id.instanceId), "forward");
    }
  };

  const handleDailyDateChange = (date: string | null) => {
    if (isNil(date)) {
      return;
    }
    setFilter((draft) => {
      assertNotNil(draft);
      draft.dailyDate = date;
    });
  };

  const maxTime = DateTime.now().toISODate();
  const dateLuxon = useMemo(() => dateApiToLuxon(dailyDate), [dailyDate]);

  const color = useMemo(() => {
    const maxTimeLuxon = dateApiToLuxon(maxTime);
    if (dateLuxon.hasSame(maxTimeLuxon, "day")) {
      return "primary";
    } else {
      const days = Math.abs(maxTimeLuxon.diff(dateLuxon, "days").days);
      if (days <= 7) {
        return "warning";
      } else {
        return "danger";
      }
    }
  }, [maxTime, dateLuxon]);

  const archivedControlCards = useArchivedControlCards(dailyDeck);
  const activeTemplatesControlCards = useActiveTemplatesControlCards(dailyDeck);

  return (
    <>
      <IonList>
        <IonItem color="highlight">
          <IonLabel>
            {t("control-card.location.date")}
            {!isNil(dateLuxon) && (
              <p>
                <RelativeDateDisplay dt={dateLuxon} />
              </p>
            )}
          </IonLabel>
          <FstIonDatetimeButton
            buttonColor={color}
            max={maxTime}
            onIonChange={(event) => handleDailyDateChange(ionDateTimeParser("date", event.detail.value))}
            presentation="date"
            showClearButton={false}
            showDefaultTimeLabel
            slot="end"
            value={dailyDate}
          />
        </IonItem>
        {activeTemplatesControlCards.length === 0 ? (
          <IonListHeader>
            <IonLabel>{t("control-card.location.no-control-cards")}</IonLabel>
          </IonListHeader>
        ) : (
          <IonListHeader>
            <IonLabel>{t("control-card.location.title")}</IonLabel>
          </IonListHeader>
        )}
        {activeTemplatesControlCards.map((deckControlCard) => {
          const {instance, template} = deckControlCard;
          const instanceOrTemplate = getInstanceOrTemplate(deckControlCard);
          const key = getKey(deckControlCard);

          let itemProps: IIonItem = {};
          let showCreate = false;

          if (!isNil(instance)) {
            if (!isNil(instance.notApplicableBy)) {
              itemProps = {
                onClick: async () => {
                  await presentAlert({
                    header: t("control-card.location.not-applicable.header"),
                    message: t("control-card.location.not-applicable.message", {
                      notApplicableBy: instance.notApplicableBy?.name,
                      notApplicableReason: instance.notApplicableReason
                    }),
                    buttons: [{role: "cancel", text: t("g.close")}]
                  });
                },
                color: "not-applicable",
                button: true,
                detail: true,
                detailIcon: cardCrossedIcon
              };
            } else if (!isNil(instance.completedBy)) {
              itemProps = {
                routerLink: linkEmplControlCardInstance(instance.instanceId.instanceId),
                routerDirection: "forward",
                detail: true,
                color: "completed"
              };
            } else {
              itemProps = {
                routerLink: linkEmplControlCardInstance(instance.instanceId.instanceId),
                routerDirection: "forward",
                detail: true,
                color: "incomplete"
              };
            }
          } else if (!isNil(template)) {
            itemProps = {};
            showCreate = true;
          } else {
            return null;
          }

          return (
            <IonItem key={key} lines="full" {...itemProps}>
              <IonLabel>{instanceOrTemplate.name}</IonLabel>
              {showCreate && !isNil(template) && (
                <IonButton
                  disabled={!canCreateControlCard}
                  onClick={() => createNewInstance(template, dateLuxon)}
                  slot="end"
                >
                  <IonIcon icon={addOutline} slot="start" />
                  {t("control-card.location.create-new")}
                </IonButton>
              )}
            </IonItem>
          );
        })}
      </IonList>
      <ArchivedControlCards instances={archivedControlCards} />
      <InstantiateControlCard ref={instantiateRef} />
    </>
  );
};
