import {useCompletedTest} from "app/employee/state/syllabus/selectors";
import {assertNotNil} from "app/utils/stdlib";
import {Trans, useTranslation} from "react-i18next";

interface IProps {
  questionsCount: number;
}

export const FailedTestBanner = ({questionsCount}: IProps) => {
  const completedTest = useCompletedTest();
  const {t} = useTranslation();
  assertNotNil(completedTest);

  const currentScorePercent = Math.round(parseFloat(completedTest.currentScorePercent));
  return (
    <>
      <h1 className="ion-text-center">
        <Trans currentScorePercent={currentScorePercent} i18nKey="test.your-result-is" t={t}>
          Вашият резултат е {{currentScorePercent}}%
        </Trans>
      </h1>
      <h4 className="ion-text-center">
        <Trans i18nKey="test.not-sufficient-title" t={t}>
          Той не е достатъчен за да преминете към следващата тема.
        </Trans>
      </h4>
      <p>
        <Trans i18nKey="test.failed-answers-paragraph" questionsCount={questionsCount} t={t}>
          Имате {{questionsCount}} грешни отговора на следните въпроси:
        </Trans>
      </p>
    </>
  );
};
