import {IonBadge, IonIcon, IonItem, IonLabel, IonList, IonListHeader} from "@ionic/react";
import {IEmployeeLocationFragment, IOrganizerFragment} from "app/gql/graphqlSchema";
import {addOutline, folderOutline} from "ionicons/icons";
import {usePermissions} from "app/employee/hooks/usePermissions";
import {useTranslation} from "react-i18next";
import {ListPadding} from "app/employee/incdoc/ListPadding";
import {FlexCellFull, FlexRight, FlexRow} from "app/employee/flexUtils";
import {linkEmplOrganizerFolder} from "app/employee/core/employeeLinks";
import {FolderListFab, IFolderListFabHandle} from "app/employee/organizer/folderList/FolderListFab";
import {FolderIcon} from "app/employee/organizer/folderList/FolderIcon";
import {useRef} from "react";
import {IonRowButton} from "app/employee/IonRowButton";

interface IProps {
  organizer: IOrganizerFragment;
  location: IEmployeeLocationFragment;
  onRefresh: () => void | Promise<void>;
}

export const FolderListContent = ({organizer, location, onRefresh}: IProps) => {
  const {t} = useTranslation();

  const {canEditOrganizerLocation} = usePermissions();
  const canEditOrganizer = canEditOrganizerLocation(organizer.id.locationId);
  const folders = organizer.folders;
  const folderListFabRef = useRef<IFolderListFabHandle>(null);

  const handleAddFolder = () => {
    folderListFabRef.current?.addFolder();
  };

  return (
    <>
      <IonList>
        <IonListHeader lines="full" style={{paddingInlineEnd: "1rem"}}>
          <IonLabel>
            <FlexRow>
              {t("organizer.folder-list.title", {defaultValue: "Папки"})}
              <span>
                <IonBadge color="primary">{folders.length}</IonBadge>
              </span>
              {canEditOrganizer && folders.length > 0 && (
                <FlexRight>
                  <IonRowButton fill="solid" onClick={handleAddFolder} slot="end">
                    <IonIcon icon={addOutline} slot="start" />
                    {t("organizer.folder-list.add")}
                  </IonRowButton>
                </FlexRight>
              )}
            </FlexRow>
          </IonLabel>
        </IonListHeader>
        {folders.map((folder) => {
          return (
            <IonItem
              detail
              key={folder.id.folderId}
              lines="full"
              routerLink={linkEmplOrganizerFolder(location.id.locationId, folder.id.folderId)}
            >
              <IonLabel>
                <FlexRow>
                  <FlexCellFull>
                    <IonIcon icon={folderOutline} />
                  </FlexCellFull>
                  <span>{folder.name}</span>
                </FlexRow>
              </IonLabel>

              <FlexRow slot="end">
                <FlexCellFull>
                  <FolderIcon folder={folder} />
                </FlexCellFull>
                <IonBadge color="primary">{folder.documents.length}</IonBadge>
              </FlexRow>
            </IonItem>
          );
        })}
      </IonList>
      <ListPadding />
      {canEditOrganizer && <FolderListFab onRefresh={onRefresh} organizer={organizer} ref={folderListFabRef} />}
    </>
  );
};
