import {useTranslation} from "react-i18next";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {isNil} from "app/utils/stdlib";
import {IonButton, IonIcon, IonLabel, useIonActionSheet, useIonRouter} from "@ionic/react";
import {
  arrowBackOutline,
  checkmarkCircle,
  closeOutline,
  ellipsisVerticalCircleOutline,
  eyeOutline,
  lockOpenOutline
} from "ionicons/icons";
import {useCallback} from "react";
import {ActionSheetButton} from "@ionic/core/dist/types/components/action-sheet/action-sheet-interface";
import {linkEmplControlCardLocation, linkEmplPrintControlCardInstance} from "app/employee/core/employeeLinks";
import {useCompleteControlCard} from "app/employee/controlCard/actions/useCompleteControlCard";
import {useUncompleteControlCard} from "app/employee/controlCard/actions/useUncompleteControlCard";

interface IContextMenuProps {
  onComplete: () => void | Promise<void>;
  onUncomplete: () => void | Promise<void>;
  onPrint: () => void | Promise<void>;
  onBack: () => void | Promise<void>;
  completed: boolean;
  canEdit: boolean;
}

const useContextMenu = ({completed, onComplete, onUncomplete, canEdit, onPrint, onBack}: IContextMenuProps) => {
  const {t} = useTranslation();
  const [presentActionSheet] = useIonActionSheet();

  const showOptions = useCallback(async () => {
    const completeOption: ActionSheetButton = {
      text: t("control-card.action.complete"),
      icon: checkmarkCircle,
      handler: onComplete
    };
    const uncompleteOption: ActionSheetButton = {
      text: t("control-card.action.uncomplete"),
      icon: lockOpenOutline,
      handler: onUncomplete
    };

    const printOption: ActionSheetButton = {
      text: t("control-card.action.view-print"),
      icon: eyeOutline,
      handler: onPrint
    };
    const buttons = [];
    buttons.push(printOption);
    if (canEdit) {
      if (completed) {
        buttons.push(uncompleteOption);
      } else {
        buttons.push(completeOption);
      }
    }
    return presentActionSheet({
      header: t("control-card.action.title", {defaultValue: "Действия за тази контролна карта"}),
      buttons: [
        ...buttons,
        {
          text: t("g.back"),
          icon: arrowBackOutline,
          handler: onBack
        },
        {
          text: t("g.close"),
          icon: closeOutline,
          role: "cancel"
        }
      ]
    });
  }, [canEdit, completed, onBack, onComplete, onPrint, onUncomplete, presentActionSheet, t]);

  return {showOptions};
};

export const InstanceActionButton = () => {
  const {t} = useTranslation();

  const {push} = useIonRouter();
  const {instance, canEdit} = useControlCardInstanceContext();
  const {completeControlCard} = useCompleteControlCard();
  const {uncompleteControlCard} = useUncompleteControlCard();

  const backHandler = useCallback(() => {
    push(linkEmplControlCardLocation(instance.location.id.locationId), "back");
  }, [instance.location.id.locationId, push]);

  const print = useCallback(() => {
    window.open(linkEmplPrintControlCardInstance(instance.id.instanceId), "_blank");
  }, [instance.id.instanceId]);

  const completed = !isNil(instance.completedBy);
  const {showOptions} = useContextMenu({
    completed,
    onComplete: completeControlCard,
    onUncomplete: uncompleteControlCard,
    onPrint: print,
    onBack: backHandler,
    canEdit
  });

  return (
    <IonButton onClick={showOptions} shape="round">
      <IonIcon icon={ellipsisVerticalCircleOutline} slot="end" />
      <IonLabel>{t("g.action", {defaultValue: "Действия"})}</IonLabel>
    </IonButton>
  );
};
