import {useIonAlert} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {ArchiveOrganizerDocumentDocument, IOrganizerDocumentFragment} from "app/gql/graphqlSchema";
import {query} from "app/gql/client";
import {useCallback} from "react";
import {useLoadingContext} from "app/employee/LoadingContext";

const archiveOrgDocDocument = async (document: IOrganizerDocumentFragment) => {
  return await query(ArchiveOrganizerDocumentDocument, {input: document.id});
};

export const useDocumentDelete = (document: IOrganizerDocumentFragment, onBack: () => void | Promise<void>) => {
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();

  const {t} = useTranslation();
  const [presentConfirmation] = useIonAlert();

  const onDelete = useCallback(async () => {
    startLoading();
    try {
      await archiveOrgDocDocument(document);
      await onBack?.();
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  }, [document, finishLoading, onBack, showExceptionError, startLoading]);

  const deleteDocument = useCallback(async () => {
    await presentConfirmation({
      header: t("organizer.action.confirm-delete.title", {
        defaultValue: "Изтрий {{name}}?",
        name: document.name
      }),
      buttons: [
        {
          text: t("g.cancel"),
          role: "cancel"
        },
        {
          text: t("g.delete", {defaultValue: "Изтрий"}),
          role: "destructive",
          handler: onDelete
        }
      ]
    });
  }, [document.name, onDelete, presentConfirmation, t]);

  return deleteDocument;
};
