import {useBootstrapEmployeeStore} from "app/employee/state/bootstrapEmployee/mutations";
import {selectMaybeLoaded} from "app/state/common/loadable";
import {shallow} from "zustand/shallow";
import {assertCondition, isEqual, isNil} from "app/utils/stdlib";
import {useCallback} from "react";
import {ILocationId, IUserFragment} from "app/gql/graphqlSchema";
import {DateTime} from "luxon";
import {dateApiToLuxon, parseDuration} from "app/utils/dates";
import {IGraphQlDate, IGraphQlDateTime, IGraphQlDuration, MaybeNil} from "app/utils/types";

export const usePermissions = () => {
  const state = useBootstrapEmployeeStore((state) => {
    const result = selectMaybeLoaded(state.bootstrapResult);
    assertCondition(result?.__typename === "BootstrapEmployee");
    return {
      permissions: result.employee.permissions,
      employeeId: result.employee.id
    };
  }, shallow);

  const canAddIncDocAny = useCallback(() => {
    return state.permissions.some((p) => p.__typename === "AddIncDoc");
  }, [state.permissions]);

  const canAddIncDocLocation = useCallback(
    (locationId: ILocationId) => {
      return state.permissions.some((p) => p.__typename === "AddIncDoc" && isEqual(p.locationId, locationId));
    },
    [state.permissions]
  );

  const canViewIncDocLocation = useCallback(
    (locationId: ILocationId) => {
      return state.permissions.some((p) => p.__typename === "ViewIncDoc" && isEqual(p.locationId, locationId));
    },
    [state.permissions]
  );

  const canEditIncDoc = useCallback(
    (incDoc: {location?: {id: ILocationId} | null; creator: IUserFragment; createdAt: IGraphQlDateTime}) => {
      const isOwnDocument =
        incDoc.creator.__typename === "Employee" && isEqual(state.employeeId, incDoc.creator.employeeId);

      let permission: {timeLimit?: MaybeNil<IGraphQlDuration>} | undefined;
      if (isOwnDocument) {
        permission = state.permissions.find(
          (p): p is typeof p & {__typename: "EditOwnIncDoc"} =>
            p.__typename === "EditOwnIncDoc" && isEqual(p.locationId, incDoc.location?.id)
        );
      } else {
        permission = state.permissions.find(
          (p): p is typeof p & {__typename: "EditOtherIncDoc"} =>
            p.__typename === "EditOtherIncDoc" && isEqual(p.locationId, incDoc.location?.id)
        );
      }
      if (permission) {
        if (!isNil(permission.timeLimit)) {
          const createdAt = DateTime.fromISO(incDoc.createdAt);
          const timeLimit = parseDuration(permission.timeLimit);
          return createdAt.plus(timeLimit) > DateTime.now();
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    [state.employeeId, state.permissions]
  );

  const canViewOrganizerLocation = useCallback(
    (locationId: ILocationId) => {
      return state.permissions.some((p) => p.__typename === "ViewOrganizer" && isEqual(p.locationId, locationId));
    },
    [state.permissions]
  );

  const canEditOrganizerLocation = useCallback(
    (locationId: ILocationId) => {
      return state.permissions.some((p) => p.__typename === "EditOrganizer" && isEqual(p.locationId, locationId));
    },
    [state.permissions]
  );

  const canViewControlCardLocation = useCallback(
    (locationId: ILocationId) => {
      return state.permissions.some((p) => p.__typename === "ViewControlCard" && isEqual(p.locationId, locationId));
    },
    [state.permissions]
  );

  const canEditControlCardLocation = useCallback(
    (locationId: ILocationId, controlCardDate: IGraphQlDate) => {
      const p = state.permissions.find(
        (p): p is typeof p & {__typename: "EditControlCard"} =>
          p.__typename === "EditControlCard" && isEqual(p.locationId, locationId)
      );
      if (isNil(p)) {
        return false;
      }
      if (isNil(p.timeLimit)) {
        return true;
      } else {
        return dateApiToLuxon(controlCardDate).plus(parseDuration(p.timeLimit)).diffNow("days").days >= 0;
      }
    },
    [state.permissions]
  );

  return {
    canAddIncDocAny,
    canAddIncDocLocation,
    canEditIncDoc,
    canViewIncDocLocation,
    canViewOrganizerLocation,
    canEditOrganizerLocation,
    canViewControlCardLocation,
    canEditControlCardLocation
  };
};
