import {IColumnFragment, IValueInstanceFragment} from "app/gql/graphqlSchema";
import {DisplayValue} from "app/employee/controlCard/DisplayValue";

interface IProps {
  column: IColumnFragment;
  valueInstance: IValueInstanceFragment;
}

export const DisplayValueInstance = ({valueInstance, column}: IProps) => {
  return <DisplayValue column={column} value={valueInstance.value} />;
};
