import {styled} from "styled-components";

export const LinkButton = styled.button`
  background-color: transparent;
  color: var(--ion-color-primary);
  text-decoration: underline;
  text-underline-offset: 15%;
  font-size: inherit;

  &:focus {
    outline-offset: 0.4rem;
    outline: var(--ion-color-primary) solid 1px;
    text-decoration: none;
  }

  &:disabled {
    color: var(--fst-disabled-text-color);
  }
`;
