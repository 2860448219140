import {IColumnFragment, IValueInstanceFragment} from "app/gql/graphqlSchema";
import {IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle} from "@ionic/react";
import {isNotBlank} from "app/utils/stdlib";
import {DisplayValueInstance} from "app/employee/controlCard/DisplayValueInstance";
import {DisplayValueInstanceError} from "app/employee/controlCard/input/DisplayValueInstanceError";
import {useHasControlCardValueError} from "app/employee/controlCard/hook/useHasControlCardValueError";
import {InputIonCard} from "app/employee/controlCard/input/InputIonCard";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";

interface IProps {
  column: {__typename?: "ControlCardLabelColumn"} & IColumnFragment;
  valueInstance: IValueInstanceFragment;
  disabled?: boolean;
}

export const InputLabel = ({column, valueInstance, disabled}: IProps) => {
  const hasError = useHasControlCardValueError(valueInstance);
  const {isComplete, canEdit} = useControlCardInstanceContext();

  const localDisabled = isComplete || !canEdit || !!disabled;

  return (
    <InputIonCard $hasError={hasError} data-scroll-to-value-instance-id={valueInstance.id}>
      <IonCardHeader>
        <IonCardTitle>{column.title}</IonCardTitle>
        {isNotBlank(column.description) && <IonCardSubtitle>{column.description}</IonCardSubtitle>}
      </IonCardHeader>
      <IonCardContent>
        <DisplayValueInstance column={column} valueInstance={valueInstance} />
        <DisplayValueInstanceError column={column} disabled={localDisabled} valueInstance={valueInstance} />
      </IonCardContent>
    </InputIonCard>
  );
};
