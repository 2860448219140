import {Link, LinkProps} from "react-router-dom";
import {isNil} from "app/utils/stdlib";
import {PartialProps} from "app/utils/types";
import {PropsWithChildren} from "react";

export interface IOptionalLinkProps extends PartialProps<LinkProps, "to"> {
  disabled?: boolean;
}

export const OptionalLink = ({children, disabled, to, ...rest}: PropsWithChildren<IOptionalLinkProps>) => {
  if (!isNil(to) && !disabled) {
    return (
      <Link to={to} {...rest}>
        {children}
      </Link>
    );
  } else {
    return <>{children}</>;
  }
};
