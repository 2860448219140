import {ControlCardInstanceUncompleteDocument, IControlCardInstanceId} from "app/gql/graphqlSchema";
import {query} from "app/gql/client";
import {useCallback} from "react";
import {useLoadingContext} from "app/employee/LoadingContext";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";

const uncompleteControlCardApi = async (instanceId: IControlCardInstanceId) => {
  const result = await query(ControlCardInstanceUncompleteDocument, {input: instanceId});
  return result.controlCardInstanceUncomplete;
};

export const useUncompleteControlCard = () => {
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();

  const {instance, setInstance} = useControlCardInstanceContext();

  const uncompleteControlCard = useCallback(async () => {
    try {
      startLoading();
      const newInstance = await uncompleteControlCardApi(instance.id);
      setInstance(newInstance);
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  }, [finishLoading, instance.id, setInstance, showExceptionError, startLoading]);

  return {uncompleteControlCard};
};
