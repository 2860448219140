import {IPlayerControl, VideoPlayer} from "app/employee/video/VideoPlayer";
import {useParams} from "react-router-dom";
import {Duration} from "luxon";
import {useCurrentAssignment, useCurrentAssignmentQuestion} from "app/employee/state/syllabus/selectors";
import {assertCondition, assertNotNil} from "app/utils/stdlib";
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonRouter
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {linkEmplCurrentTestResult} from "app/employee/core/employeeLinks";
import {refreshSyllabus} from "app/employee/state/syllabus/mutations";

export const WatchVideoAnswerSegment = () => {
  const {questionId} = useParams<{questionId?: string}>();
  const {push} = useIonRouter();
  const questionReturn = useCurrentAssignmentQuestion(questionId);
  assertCondition(questionReturn.available);
  const currentAssignment = useCurrentAssignment();
  const videoLesson = currentAssignment?.lesson?.videoLesson;
  assertNotNil(videoLesson);

  const question = questionReturn.current;
  assertNotNil(question);
  assertNotNil(question.answerStartPoint);
  assertNotNil(question.answerEndPoint);

  const startControl: IPlayerControl = {
    type: "segment",
    fromTime: Duration.fromISO(question.answerStartPoint),
    toTime: Duration.fromISO(question.answerEndPoint),
    handleReturn: () => push(linkEmplCurrentTestResult(), "back", "pop")
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{videoLesson.title}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher onIonRefresh={refreshSyllabus} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>
        <VideoPlayer playerControl={startControl} videoLesson={videoLesson} />
      </IonContent>
    </>
  );
};
