import {UnreachableCaseError} from "ts-essentials";
import {IColumnFragment} from "app/gql/graphqlSchema";
import {useTranslation} from "react-i18next";

type IRange = (IColumnFragment & {__typename: "ControlCardTemperatureColumn"})["range"];

export const DisplayTemperatureRange = ({range}: {range: IRange}) => {
  const {t} = useTranslation();
  switch (range.__typename) {
    case "ControlCardClosedTemperatureRange":
      return <>{t("control-card.range.closed", {start: range.start, end: range.end})}</>;
    case "ControlCardOpenStartTemperatureRange":
      return <>{t("control-card.range.open-start", {end: range.end})}</>;
    case "ControlCardOpenEndTemperatureRange":
      return <>{t("control-card.range.open-end", {start: range.start})}</>;
    default:
      throw new UnreachableCaseError(range);
  }
};
