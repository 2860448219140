import {ISyllabusTermVideoLesson} from "app/employee/state/syllabus/mutations";
import {PropsWithChildren} from "react";
import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle} from "@ionic/react";
import {VideoThumbnail} from "app/employee/syllabus/VideoThumbnail";
import {isNil} from "app/utils/stdlib";
import {VideoLessonTitleBadge} from "app/employee/syllabus/VideoLessonTitleBadge";
import {styled} from "styled-components";
import {OptionalLink} from "app/employee/syllabus/OptionalLink";
import {getVideoStats} from "app/employee/syllabus/getVideoStats";

interface ILessonCardProps {
  lesson: ISyllabusTermVideoLesson;
  subtitle?: JSX.Element;
}

const TitleLink = styled(OptionalLink)`
  color: inherit;
  text-decoration: inherit;
`;

export const LessonCard = ({lesson, subtitle, children}: PropsWithChildren<ILessonCardProps>) => {
  const videoLesson = lesson.videoLesson;
  const title = videoLesson.title;
  const {canPlay, playLink} = getVideoStats(lesson);

  return (
    <IonCard className="ion-no-margin">
      <IonCardHeader>
        <VideoThumbnail lesson={lesson} />
        <IonCardTitle>
          <TitleLink disabled={!canPlay} to={playLink}>
            {title}
          </TitleLink>{" "}
          <VideoLessonTitleBadge lesson={lesson} />
        </IonCardTitle>
        {!isNil(subtitle) && <IonCardSubtitle>{subtitle}</IonCardSubtitle>}
      </IonCardHeader>
      <IonCardContent>{children}</IonCardContent>
    </IonCard>
  );
};
