import {useParams} from "react-router-dom";
import {VideoLessonTest} from "app/employee/test/VideoLessonTest";
import {NoTest} from "app/employee/test/NoTest";
import {useCurrentAssignmentQuestion, useTestState} from "app/employee/state/syllabus/selectors";
import {IonButton, IonContent, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {useTranslation} from "react-i18next";
import {FlexColumnCenter} from "app/employee/flexUtils";
import {homeOutline} from "ionicons/icons";
import {linkEmplCourseHome} from "app/employee/core/employeeLinks";
import {MenuButton} from "app/employee/menu/MenuButton";

export const ScreenVideoLessonTest = () => {
  const {t} = useTranslation();
  const {canDisplayTestResult} = useTestState();
  const {questionId} = useParams<{questionId?: string}>();
  const maybeCurrentQuestion = useCurrentAssignmentQuestion(questionId);

  let content: JSX.Element | null;
  if (canDisplayTestResult) {
    if (maybeCurrentQuestion.available) {
      const {current, next, prev, progress, videoLesson} = maybeCurrentQuestion;
      content = (
        <VideoLessonTest current={current} next={next} prev={prev} progress={progress} videoLesson={videoLesson} />
      );
    } else {
      content = <NoTest />;
    }
  } else {
    content = (
      <>
        <IonHeader>
          <IonToolbar>
            <HomeBackButton />
            <IonTitle>{t("test.title")}</IonTitle>
            <MenuButton />
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <h1 className="ion-text-center">{t("test.cant-view-test")}</h1>
          <FlexColumnCenter>
            <IonButton color="primary" routerDirection="back" routerLink={linkEmplCourseHome()}>
              <IonIcon icon={homeOutline} slot="start" />
              {t("g.back-to-home")}
            </IonButton>
          </FlexColumnCenter>
        </IonContent>
      </>
    );
  }

  return content;
};
