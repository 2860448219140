import {
  ControlCardInstanceRemoveRowsDocument,
  IControlCardInstanceFullFragment,
  IControlCardInstanceId
} from "app/gql/graphqlSchema";
import {forwardRef, useCallback, useEffect, useImperativeHandle} from "react";
import {useLoadingContext} from "app/employee/LoadingContext";
import {useIonAlert, useIonViewWillLeave} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {query} from "app/gql/client";
import {ID} from "app/utils/types";
import {Deferred} from "app/utils/deferred";
import {isNil} from "app/utils/stdlib";

interface IProps {}

export interface DeleteControlCardRowsHandle {
  doDelete: (
    instanceId: IControlCardInstanceId,
    rowIds: ID[],
    itemName?: string
  ) => Promise<IControlCardInstanceFullFragment | null>;
}

export const DeleteControlCardRows = forwardRef<DeleteControlCardRowsHandle, IProps>((_, componentRef) => {
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();
  const [presentAlert, dismissAlert] = useIonAlert();
  const {t} = useTranslation();

  const confirmDeleteRows = useCallback(
    async (rowIds: ID[], itemName?: string) => {
      const rowCount = rowIds.length;
      const deferred = new Deferred<boolean>();

      const header = !isNil(itemName)
        ? t("control-card.row.action.confirm-delete.title-name", {
            name: itemName
          })
        : t("control-card.row.action.confirm-delete.title", {
            count: rowCount
          });
      await presentAlert({
        header,
        buttons: [
          {text: t("g.cancel"), handler: () => deferred.resolve(false), role: "cancel"},
          {
            text: t("g.delete"),
            role: "destructive",
            handler: () => deferred.resolve(true)
          }
        ]
      });

      return deferred.promise;
    },
    [presentAlert, t]
  );

  useEffect(
    () => () => {
      dismissAlert();
    },
    [dismissAlert]
  );

  useIonViewWillLeave(() => {
    dismissAlert();
  }, [dismissAlert]);

  useImperativeHandle(
    componentRef,
    () => ({
      doDelete: async (instanceId, rowIds, itemName?: string) => {
        if (!(await confirmDeleteRows(rowIds, itemName))) {
          return null;
        }
        try {
          startLoading();
          const result = await query(ControlCardInstanceRemoveRowsDocument, {input: {instanceId, rowIds}});
          return result.controlCardInstanceRemoveRows;
        } catch (e) {
          await showExceptionError(e);
          return null;
        } finally {
          finishLoading();
        }
      }
    }),
    [confirmDeleteRows, finishLoading, showExceptionError, startLoading]
  );

  return null;
});

DeleteControlCardRows.displayName = "DeleteControlCardRows";
