import {ISyllabusInProgressVideoLesson, ISyllabusTerm, ISyllabusTermPlan} from "app/employee/state/syllabus/mutations";
import {inProgressVideoLessonPredicate} from "app/employee/syllabus/utils";

export type IGetInProgressVideoLessonReturn =
  | {
      lesson: ISyllabusInProgressVideoLesson;
      termPlan?: ISyllabusTermPlan;
    }
  | undefined;

export const getInProgressVideoLesson = (terms: ISyllabusTerm[]): IGetInProgressVideoLessonReturn => {
  const adHoc = terms.flatMap((term) => term.adhocVideoLessons).filter(inProgressVideoLessonPredicate);
  if (adHoc.length > 0) {
    return {lesson: adHoc[0]};
  }

  const vl = terms
    .flatMap((term) =>
      term.termPlans.map((termPlan) => ({
        term,
        termPlan
      }))
    )
    .flatMap(({term, termPlan}) =>
      termPlan.lessons.filter(inProgressVideoLessonPredicate).map((lesson) => ({
        term,
        termPlan,
        lesson
      }))
    );
  if (vl.length > 0) {
    return vl[0];
  }
};
