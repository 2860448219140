import {IonIcon, IonItem, IonLabel, IonList, IonListHeader, useIonAlert, useIonViewWillLeave} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {isNil} from "app/utils/stdlib";
import {cloudOutline} from "ionicons/icons";
import {linkEmplControlCardInstance} from "app/employee/core/employeeLinks";
import {IControlCardInstanceDeckFragment} from "app/gql/graphqlSchema";
import {IIonItem} from "app/utils/types";
import {cardCrossedIcon} from "app/utils/icons/ionicFixedIcons";

export const ArchivedControlCards = ({instances}: {instances: IControlCardInstanceDeckFragment[]}) => {
  const {t} = useTranslation();
  const [presentAlert, dismissAlert] = useIonAlert();
  useIonViewWillLeave(() => {
    dismissAlert();
  }, [dismissAlert]);

  if (instances.length === 0) {
    return null;
  }
  return (
    <IonList>
      <IonListHeader>
        <IonLabel>{t("control-card.location.archived-title")}</IonLabel>
      </IonListHeader>
      {instances.map((instance) => {
        if (isNil(instance)) {
          return null;
        }
        let itemProps: IIonItem;

        if (!isNil(instance.notApplicableBy)) {
          itemProps = {
            onClick: async () => {
              await presentAlert({
                header: t("control-card.location.not-applicable.header"),
                message: t("control-card.location.not-applicable.message", {
                  notApplicableBy: instance.notApplicableBy?.name,
                  notApplicableReason: instance.notApplicableReason
                }),
                buttons: [{role: "cancel", text: t("g.close")}]
              });
            },
            color: "not-applicable",
            button: true,
            detail: true,
            detailIcon: cardCrossedIcon
          };
        } else if (!isNil(instance.completedBy)) {
          itemProps = {
            routerLink: linkEmplControlCardInstance(instance.instanceId.instanceId),
            routerDirection: "forward",
            detail: true,
            color: "completed"
          };
        } else {
          itemProps = {
            routerLink: linkEmplControlCardInstance(instance.instanceId.instanceId),
            routerDirection: "forward",
            detail: true,
            color: "incomplete"
          };
        }

        return (
          <IonItem key={instance.instanceId.instanceId} lines="full" {...itemProps}>
            <IonLabel>{instance.name}</IonLabel>
            <IonIcon icon={cloudOutline} slot="end" />
          </IonItem>
        );
      })}
    </IonList>
  );
};
