import {
  ControlCardInstanceCompleteRowsDocument,
  IControlCardInstanceFullFragment,
  IControlCardInstanceId
} from "app/gql/graphqlSchema";
import {query} from "app/gql/client";
import {useCallback} from "react";
import {useLoadingContext} from "app/employee/LoadingContext";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {ID} from "app/utils/types";
import {isNil} from "app/utils/stdlib";
import {linkEmplControlCardInstance} from "app/employee/core/employeeLinks";
import {useIonAlert, useIonRouter, useIonToast} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {useCompleteControlCard} from "app/employee/controlCard/actions/useCompleteControlCard";
import {scrollValueInstance} from "app/employee/controlCard/input/scrollValueInstance";

const completeControlCardRows = async (instanceId: IControlCardInstanceId, rows: ID[]) => {
  const result = await query(ControlCardInstanceCompleteRowsDocument, {input: {instanceId, rowIds: rows}});
  return result.controlCardInstanceCompleteRows;
};

const rangeGenerator = [
  (currentIndex: number, length: number) => [currentIndex + 1, length - 1],
  (currentIndex: number) => [0, currentIndex - 1]
];

const findNextIncompleteRowId = (instance: IControlCardInstanceFullFragment, rowId: ID) => {
  for (const rangeFunc of rangeGenerator) {
    const currentIndex = instance.rows.findIndex((r) => r.rowId === rowId);
    const [from, to] = rangeFunc(currentIndex, instance.rows.length);
    for (let i = from; i <= to; i++) {
      const row = instance.rows[i];
      if (isNil(row.completedBy)) {
        return row.rowId;
      }
    }
  }

  return null;
};

const findErrorColumns = (newInstance: IControlCardInstanceFullFragment, rowId: string) => {
  const row = newInstance.rows.find((r) => r.rowId === rowId);
  if (isNil(row)) {
    return [];
  }
  return row.columns.filter(
    (c) => c.validationResult.__typename === "ErrorValidationResult" && isNil(c.overriddenValidationResult)
  );
};

export const useCompleteControlCardRow = (rowId: ID) => {
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();
  const {instance, setInstance} = useControlCardInstanceContext();
  const {push} = useIonRouter();
  const [presentToast] = useIonToast();
  const {t} = useTranslation();
  const {completeControlCard} = useCompleteControlCard();
  const [presentAlert, dismissAlert] = useIonAlert();

  const completeRow = useCallback(async () => {
    try {
      startLoading();
      const newInstance = await completeControlCardRows(instance.id, [rowId]);
      setInstance(newInstance);
      return newInstance;
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  }, [finishLoading, instance.id, rowId, setInstance, showExceptionError, startLoading]);

  const completeRowAndContinue = useCallback(async () => {
    const newInstance = await completeRow();
    if (isNil(newInstance)) {
      return;
    }
    const errorColumns = findErrorColumns(newInstance, rowId);
    if (errorColumns.length > 0) {
      await presentAlert({
        header: t("control-card.row.action.complete-error.title", {
          defaultValue: "Контролната карта не може да бъде приключена"
        }),
        message: t("control-card.row.action.complete-error.message", {count: errorColumns.length}),
        buttons: [
          {
            text: t("g.ok"),
            role: "confirm",
            handler: async () => {
              await dismissAlert();
              scrollValueInstance(errorColumns[0]);
            }
          }
        ]
      });
      return;
    }
    const nextRowId = findNextIncompleteRowId(newInstance, rowId);
    if (!isNil(nextRowId)) {
      push(linkEmplControlCardInstance(newInstance.id.instanceId, nextRowId), "forward");
      return;
    }

    await presentToast({
      message: t("control-card.row.action.complete-success-last-record"),
      position: "top",
      color: "success",
      duration: 2000
    });

    await completeControlCard(newInstance);
  }, [completeRow, rowId, presentToast, t, completeControlCard, presentAlert, dismissAlert, push]);

  return {completeRow, completeRowAndContinue};
};
