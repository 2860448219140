import {Redirect} from "react-router-dom";
import {WatchCurrentVideo} from "app/employee/syllabus/WatchCurrentVideo";
import {SentryRoute} from "app/sentryRoutes";
import {IonPage, IonRouterOutlet} from "@ionic/react";
import {SyllabusCourse} from "app/employee/syllabus/SyllabusCourse";
import {WatchPastVideo} from "app/employee/syllabus/WatchPastVideo";
import {ScreenVideoLessonTest} from "app/employee/test/ScreenVideoLessonTest";
import {ScreenVideoLessonTestComplete} from "app/employee/test/result/ScreenVideoLessonTestResult";
import {WatchVideoAnswerSegment} from "app/employee/syllabus/WatchVideoAnswerSegment";
import {SyllabusLoadingPage} from "app/employee/page/SyllabusLoadingPage";
import {NotFoundPage} from "app/employee/page/NotFoundPage";
import {IncDocHome} from "app/employee/incdoc/IncDocHome";
import {AddEditSupplier} from "app/employee/incdoc/supplier/AddEditSupplier";
import {Feature} from "app/gql/graphqlSchema";
import {RoutingGuard} from "app/employee/RoutingGuard";
import {SupplierList} from "app/employee/incdoc/supplier/SupplierList";
import {FolderListPage} from "app/employee/organizer/folderList/FolderListPage";
import {useDefaultPage} from "app/employee/hooks/useDefaultPage";
import {LocationControlCards} from "./controlCard/location/LocationControlCards";
import {ControlCardInstance} from "app/employee/controlCard/ControlCardInstance";
import {AddPrimaryKeyRow} from "app/employee/controlCard/primaryKey/AddPrimaryKeyRow";
import {ControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {ControlCardPrintInstance} from "app/employee/controlCard/ControlCardPrintInstance";
import {Notifications} from "app/employee/notification/Notifications";
import {Broadcast} from "app/employee/broadcast/Broadcast";
import {HelpPage} from "app/employee/help/HelpPage";
import {FolderPage} from "app/employee/organizer/folder/FolderPage";
import {DocumentPage} from "app/employee/organizer/document/DocumentPage";

export const Routing = () => {
  const defaultPage = useDefaultPage();
  if (!defaultPage) {
    return <NotFoundPage />;
  }

  return (
    <IonRouterOutlet>
      <SentryRoute exact path="/employees/course/current">
        <RoutingGuard feature={Feature.Course}>
          <SyllabusLoadingPage alwaysRefresh>
            <SyllabusCourse />
          </SyllabusLoadingPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute exact path="/employees/course/current/watch/answer/:questionId">
        <RoutingGuard feature={Feature.Course}>
          <SyllabusLoadingPage>
            <WatchVideoAnswerSegment />
          </SyllabusLoadingPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute exact path="/employees/course/current/watch">
        <RoutingGuard feature={Feature.Course}>
          <SyllabusLoadingPage>
            <WatchCurrentVideo />
          </SyllabusLoadingPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute exact path="/employees/course/current/test/result">
        <RoutingGuard feature={Feature.Course}>
          <SyllabusLoadingPage>
            <ScreenVideoLessonTestComplete />
          </SyllabusLoadingPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute
        exact
        path={["/employees/course/current/test", "/employees/course/current/test/question/:questionId"]}
      >
        <RoutingGuard feature={Feature.Course}>
          <SyllabusLoadingPage>
            <ScreenVideoLessonTest />
          </SyllabusLoadingPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute exact path="/employees/course/past/watch/:assignmentId">
        <RoutingGuard feature={Feature.Course}>
          <SyllabusLoadingPage>
            <WatchPastVideo />
          </SyllabusLoadingPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute exact path="/employees/inc-doc">
        <RoutingGuard feature={Feature.IncomingControl}>
          <IonPage>
            <IncDocHome />
          </IonPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute exact path={["/employees/inc-doc/supplier/add", "/employees/inc-doc/supplier/:supplierId/edit"]}>
        <RoutingGuard feature={Feature.IncomingControl}>
          <IonPage>
            <AddEditSupplier />
          </IonPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute exact path="/employees/inc-doc/supplier">
        <RoutingGuard feature={Feature.IncomingControl}>
          <IonPage>
            <SupplierList />
          </IonPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute exact path={["/employees/organizer", "/employees/organizer/:locationId"]}>
        <RoutingGuard feature={Feature.Organizer}>
          <IonPage>
            <FolderListPage />
          </IonPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute exact path="/employees/organizer/:locationId/folder/:folderId">
        <RoutingGuard feature={Feature.Organizer}>
          <IonPage>
            <FolderPage />
          </IonPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute exact path="/employees/organizer/:locationId/document/:documentId">
        <RoutingGuard feature={Feature.Organizer}>
          <IonPage>
            <DocumentPage />
          </IonPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute exact path={["/employees/control-card", "/employees/control-card/:locationId"]}>
        <RoutingGuard feature={Feature.ControlCard}>
          <IonPage>
            <LocationControlCards />
          </IonPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute exact path="/employees/control-card/fill/:instanceId/action/add/primary-row">
        <RoutingGuard feature={Feature.ControlCard}>
          <IonPage>
            <ControlCardInstanceContext>
              <AddPrimaryKeyRow />
            </ControlCardInstanceContext>
          </IonPage>
        </RoutingGuard>
      </SentryRoute>
      <SentryRoute
        exact
        path={["/employees/control-card/fill/:instanceId", "/employees/control-card/fill/:instanceId/row/:rowId"]}
      >
        <RoutingGuard feature={Feature.ControlCard}>
          <IonPage>
            <ControlCardInstanceContext>
              <ControlCardInstance />
            </ControlCardInstanceContext>
          </IonPage>
        </RoutingGuard>
      </SentryRoute>

      <SentryRoute exact path="/employees/control-card/print/:instanceId">
        <RoutingGuard feature={Feature.ControlCard}>
          <IonPage>
            <ControlCardInstanceContext>
              <ControlCardPrintInstance />
            </ControlCardInstanceContext>
          </IonPage>
        </RoutingGuard>
      </SentryRoute>

      <SentryRoute exact path="/employees/notifications">
        <IonPage>
          <Notifications />
        </IonPage>
      </SentryRoute>

      <SentryRoute exact path="/employees/broadcast/:broadcastId">
        <IonPage>
          <Broadcast />
        </IonPage>
      </SentryRoute>

      <SentryRoute exact path="/employees/help">
        <IonPage>
          <HelpPage />
        </IonPage>
      </SentryRoute>

      <SentryRoute exact path="/employees">
        <Redirect to={defaultPage} />
      </SentryRoute>
      <SentryRoute component={NotFoundPage} />
    </IonRouterOutlet>
  );
};
