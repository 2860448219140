import {query} from "app/gql/client";
import {IControlCardValue, IValueInstanceFragment, SaveControlCardValueDocument} from "app/gql/graphqlSchema";
import {useCallback, useState} from "react";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";

export const useSaveControlCardValue = (valueInstance: Pick<IValueInstanceFragment, "id">) => {
  const {instance, updateValue} = useControlCardInstanceContext();
  const [saving, setSaving] = useState(false);
  const saveValue = useCallback(
    async (newValue: IControlCardValue) => {
      setSaving(true);
      try {
        const result = await query(SaveControlCardValueDocument, {
          input: {
            newValue: JSON.stringify(newValue),
            valueIds: [valueInstance.id],
            instanceId: instance.id
          }
        });
        const updatedValues = result.controlCardInstanceUpdateValue.values;
        for (const updatedValue of updatedValues) {
          updateValue(updatedValue);
        }
      } finally {
        setSaving(false);
      }
    },
    [valueInstance, instance.id, updateValue]
  );
  return {saveValue, saving} as const;
};
