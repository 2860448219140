import {refreshSyllabus} from "app/employee/state/syllabus/mutations";
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  SegmentChangeEventDetail
} from "@ionic/react";
import {MenuButton} from "app/employee/menu/MenuButton";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {useSyllabusTerms} from "app/employee/state/syllabus/selectors";
import {Trans, useTranslation} from "react-i18next";
import {checkmarkCircle, hourglass, playCircle} from "ionicons/icons";
import {useMemo, useState} from "react";
import {IonSegmentCustomEvent} from "@ionic/core/dist/types/components";
import {assertNotNil} from "app/utils/stdlib";
import {UnreachableCaseError} from "ts-essentials";
import {SyllabusCompletedTermPlan} from "app/employee/syllabus/SyllabusCompletedTermPlan";
import {SyllabusPendingTermPlan} from "app/employee/syllabus/SyllabusPendingTermPlan";
import {SyllabusInProgressTermPlan} from "app/employee/syllabus/SyllabusInProgressTermPlan";

enum SyllabusSegment {
  Pending = "Pending",
  Completed = "Completed",
  InProgress = "InProgress"
}

export const SyllabusCourse = () => {
  const [segment, setSegment] = useState<SyllabusSegment>(SyllabusSegment.InProgress);
  const terms = useSyllabusTerms();
  const {t} = useTranslation();

  const titles = useMemo(
    () =>
      ({
        [SyllabusSegment.InProgress]: (
          <Trans i18nKey="syllabus.in-progress.title" t={t}>
            Текуща тема
          </Trans>
        ),
        [SyllabusSegment.Pending]: (
          <Trans i18nKey="syllabus.pending.title" t={t}>
            Текущо обучение
          </Trans>
        ),
        [SyllabusSegment.Completed]: (
          <Trans i18nKey="syllabus.completed.title" t={t}>
            Завършени обучения
          </Trans>
        )
      }) as const,
    [t]
  );

  let content: JSX.Element | null = null;
  switch (segment) {
    case SyllabusSegment.InProgress: {
      content = <SyllabusInProgressTermPlan terms={terms} />;
      break;
    }
    case SyllabusSegment.Pending: {
      content = <SyllabusPendingTermPlan terms={terms} />;
      break;
    }
    case SyllabusSegment.Completed: {
      content = <SyllabusCompletedTermPlan terms={terms} />;
      break;
    }
    default:
      throw new UnreachableCaseError(segment);
  }

  const handleSegmentChange = (event: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
    assertNotNil(event.detail.value);
    setSegment(event.detail.value as SyllabusSegment);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{titles[segment]}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonRefresher onIonRefresh={refreshSyllabus} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>
        {content}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonSegment className="fst-ion-segment" onIonChange={handleSegmentChange} value={segment}>
            <IonSegmentButton value={SyllabusSegment.InProgress}>
              <IonIcon icon={playCircle} />
              <IonLabel className="ion-text-wrap">{titles[SyllabusSegment.InProgress]}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={SyllabusSegment.Pending}>
              <IonIcon icon={hourglass} />
              <IonLabel className="ion-text-wrap">{titles[SyllabusSegment.Pending]}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={SyllabusSegment.Completed}>
              <IonIcon icon={checkmarkCircle} />
              <IonLabel className="ion-text-wrap">{titles[SyllabusSegment.Completed]}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
