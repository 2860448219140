import {useTranslation} from "react-i18next";
import {usePermissions} from "app/employee/hooks/usePermissions";
import {useCallback, useRef} from "react";
import {IOrgDocUploaderHandle, OrgDocUploader} from "app/employee/organizer/OrgDocUploader";
import {
  IEmployeeLocationFragment,
  IOrganizerDocumentFragment,
  IOrganizerFolderFragment,
  IOrganizerFragment
} from "app/gql/graphqlSchema";
import {useDocumentContextMenu} from "app/employee/organizer/document/useDocumentContextMenu";
import {CustomPanel, Table} from "app/employee/organizer/OrganizerUI";
import {IonButton, IonFab, IonIcon, IonLabel, IonList} from "@ionic/react";
import {documentOutline, ellipsisVerticalCircleOutline, folderOutline, locationOutline} from "ionicons/icons";
import {getLocationText} from "app/employee/utils";
import {DocumentItem} from "app/employee/organizer/document/DocumentItem";
import {ListPadding} from "app/employee/incdoc/ListPadding";
import {Uploady} from "@rpldy/uploady";
import {getUploadyDestination} from "app/gql/client";
import {useDocumentDelete} from "app/employee/organizer/document/useDocumentDelete";

interface IProps {
  organizer: IOrganizerFragment;
  location: IEmployeeLocationFragment;
  folder: IOrganizerFolderFragment;
  document: IOrganizerDocumentFragment;
  onBack: () => void | Promise<void>;
  onRefresh: () => void | Promise<void>;
}

export const DocumentContent = ({organizer, location, folder, document, onRefresh, onBack}: IProps) => {
  const {t} = useTranslation();

  const {canEditOrganizerLocation} = usePermissions();
  const canEditOrganizer = canEditOrganizerLocation(location.id);
  const uploaderRef = useRef<IOrgDocUploaderHandle | null>(null);
  const onEditDocument = useCallback(
    async (document: IOrganizerDocumentFragment) => {
      if (canEditOrganizer) {
        await uploaderRef.current?.editDocument(document);
      }
    },
    [canEditOrganizer]
  );

  const onEdit = useCallback(async () => {
    await onEditDocument(document);
  }, [document, onEditDocument]);

  const onDelete = useDocumentDelete(document, onBack);

  const {showOptions} = useDocumentContextMenu({
    name: document.name,
    onDelete,
    onEdit
  });

  return (
    <>
      <CustomPanel>
        <Table>
          <div>
            <IonIcon icon={documentOutline} />
          </div>
          <div>
            <strong>{document.name}</strong>
          </div>
          <div>
            <IonIcon icon={folderOutline} />
          </div>
          <div>{folder.name}</div>
          <div>
            <IonIcon icon={locationOutline} />
          </div>
          <div>{getLocationText(location)}</div>
        </Table>
      </CustomPanel>

      <IonList>
        <DocumentItem canEdit={canEditOrganizer} document={document} onUploadCompleted={onRefresh} />
      </IonList>
      <ListPadding />
      {canEditOrganizer && (
        <>
          <Uploady concurrent destination={getUploadyDestination()} maxConcurrent={2} method="POST" multiple>
            <OrgDocUploader onCompleted={onRefresh} organizer={organizer} ref={uploaderRef} />
          </Uploady>
          <IonFab horizontal="end" slot="fixed" vertical="bottom">
            <IonButton onClick={showOptions} shape="round">
              <IonIcon icon={ellipsisVerticalCircleOutline} slot="end" />
              <IonLabel>{t("g.action", {defaultValue: "Действия"})}</IonLabel>
            </IonButton>
          </IonFab>
        </>
      )}
    </>
  );
};
