import {useTranslation} from "react-i18next";
import {useIonActionSheet} from "@ionic/react";
import {useCallback} from "react";
import {addOutline, closeOutline} from "ionicons/icons";

interface IContextMenuProps {
  onAdd: () => void | Promise<void>;
}

export const useFolderListContextMenu = ({onAdd}: IContextMenuProps) => {
  const {t} = useTranslation();

  const [presentActionSheet] = useIonActionSheet();

  const showOptions = useCallback(
    async () =>
      presentActionSheet({
        header: t("g.action"),

        buttons: [
          {
            text: t("organizer.folder-list.add", {defaultValue: "Нова папка"}),
            icon: addOutline,
            handler: onAdd
          },
          {
            text: t("g.close"),
            icon: closeOutline,
            role: "cancel"
          }
        ]
      }),
    [onAdd, presentActionSheet, t]
  );

  return {showOptions};
};
