import {IAnyDate, MaybeNil} from "app/utils/types";
import {isNil} from "app/utils/stdlib";
import {useTranslation} from "react-i18next";
import {parseAnyDate, SHORT_DATE_TIME_FORMAT_OPTS} from "app/utils/dates";

interface Props {
  ts: MaybeNil<IAnyDate>;
}

export const Timestamp = ({ts}: Props) => {
  const {i18n} = useTranslation();

  if (isNil(ts)) {
    return null;
  } else {
    const luxonTs = parseAnyDate(ts);
    const tsStr = luxonTs.toLocaleString(SHORT_DATE_TIME_FORMAT_OPTS, {
      locale: i18n.language
    });
    return <>{tsStr}</>;
  }
};
