import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonProgressBar,
  IonTitle,
  IonToolbar,
  useIonToast
} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {
  createIncomingDocument,
  IncomingDocumentSupplier,
  ISearchResultIncomingDocument,
  loadSuppliers,
  updateIncomingDocument,
  useIncDocStore
} from "app/employee/state/incdoc/mutations";
import {selectMaybeLoaded} from "app/state/common/loadable";
import {MaybeNil} from "app/utils/types";
import {useCallback, useEffect, useRef, useState} from "react";
import {checkmarkCircleOutline, closeOutline, textOutline} from "ionicons/icons";
import {shallow} from "zustand/shallow";
import {getCurrentClientId} from "app/state/currentClient/mutations";
import {assertNotNil, fractionToPercent, isNil, isNotBlank, prependExisting} from "app/utils/stdlib";
import {useDefaultLocationId, useEmployeeLocations} from "app/employee/state/bootstrapEmployee/selectors";
import {RhfRequiredLabel} from "app/employee/incdoc/RequiredLabel";
import {AddEditSupplier} from "app/employee/incdoc/supplier/AddEditSupplier";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {IonModalCustomEvent} from "@ionic/core/dist/types/components";
import {OverlayEventDetail} from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import {ISearchResultSupplier} from "app/employee/state/supplier/mutations";
import {useDismiss} from "app/employee/incdoc/useDismiss";
import {linkEmplIncDocHome} from "app/employee/core/employeeLinks";
import {MenuButton} from "app/employee/menu/MenuButton";
import {textExtractor} from "app/employee/incdoc/supplier/TypeaheadSelect";
import {usePermissions} from "app/employee/hooks/usePermissions";
import {IUseAttachmentCollectionEditorReturn} from "app/components/hooks/useAttachmentCollectionEditor";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {RhfIonInput} from "app/employee/rhf/RhfIonInput";
import {RhfFstIonDatetimeButton} from "app/employee/rhf/RhfFstIonDatetimeButton";
import {RhfIonTextarea} from "app/employee/rhf/RhfIonTextarea";
import {RhfTypeaheadSelect} from "app/employee/rhf/RhfTypeaheadSelect";
import {DateTime} from "luxon";
import {ErrorMessage} from "app/employee/incdoc/supplier/ErrorMessage";
import {t} from "i18next";
import {zLocalDate, zLocationId, zNormalizedString, zSupplierId} from "app/utils/validator";

import {getLocationText} from "app/employee/utils";
import {useLoadingContext} from "app/employee/LoadingContext";
import {one23Icon} from "app/utils/icons/ionicFixedIcons";
import {AttachmentCollectionEdit} from "app/employee/att/AttachmentCollectionEdit";
import {UploadButton} from "app/employee/att/UploadButton";
import {ListHeaderErrorMessage} from "app/employee/rhf/GlobalErrorMessage";
import {parseErrorResult} from "app/utils/parseErrorResult";

interface IProps
  extends Pick<
    IUseAttachmentCollectionEditorReturn,
    "updateAttachments" | "unifiedAttachments" | "uploadProgress" | "showFileUpload"
  > {
  incomingDocument: MaybeNil<ISearchResultIncomingDocument>;
}

const formSchema = z.object({
  supplierId: zSupplierId,
  locationId: zLocationId,
  documentNumber: zNormalizedString.pipe(z.string().min(1, t("g.required"))),
  documentDate: zLocalDate,
  notes: zNormalizedString
});

type IFormData = z.infer<typeof formSchema>;

const getSupplierText = (supplier: Pick<IncomingDocumentSupplier, "name" | "eik" | "notes">) => {
  let text = supplier.name;
  if (isNotBlank(supplier.eik)) {
    text += ` (${supplier.eik})`;
  }
  if (isNotBlank(supplier.notes)) {
    text += `, ${supplier.notes}`;
  }
  return text;
};

export const AddEditDocument = ({
  unifiedAttachments,
  showFileUpload,
  uploadProgress,
  incomingDocument,
  updateAttachments
}: IProps) => {
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();
  const {t} = useTranslation();
  const [presentToast] = useIonToast();
  const [inputMode, setInputMode] = useState<"decimal" | "text">("decimal");
  const inputModeSliderRef = useRef<HTMLIonItemSlidingElement | null>(null);
  const documentNumberInputRef = useRef<HTMLIonInputElement | null>(null);
  const supplierRef = useRef<HTMLIonModalElement | null>(null);
  const [supplierDefaults, setSupplierDefaults] = useState<{name: string} | undefined>(undefined);
  const backHandler = useDismiss(linkEmplIncDocHome());
  const [defaultLocationId, setDefaultLocationId] = useDefaultLocationId();

  const {
    control,
    handleSubmit,
    watch,
    formState: {isSubmitting, errors},
    setError,
    setValue
  } = useForm<IFormData>({
    defaultValues: {
      supplierId: incomingDocument?.supplier.id,
      locationId: incomingDocument?.location?.id ?? defaultLocationId ?? undefined,
      documentNumber: incomingDocument?.documentNumber ?? "",
      documentDate: incomingDocument?.documentDate ?? DateTime.now().toISODate(),
      notes: incomingDocument?.notes ?? ""
    },
    resolver: zodResolver(formSchema)
  });

  const {canAddIncDocLocation} = usePermissions();
  const locations = prependExisting(
    useEmployeeLocations().filter((location) => canAddIncDocLocation(location.id)),
    incomingDocument?.location
  );

  const {availableSuppliers} = useIncDocStore(
    (state) => ({
      availableSuppliers: selectMaybeLoaded(state.suppliers) ?? []
    }),
    shallow
  );
  const suppliers = prependExisting(availableSuppliers, incomingDocument?.supplier);

  useEffect(() => {
    if (incomingDocument) {
      assertNotNil(incomingDocument.location);
      setValue("supplierId", incomingDocument.supplier.id);
      setValue("locationId", incomingDocument.location.id);
      setValue("documentNumber", incomingDocument.documentNumber);
      setValue("documentDate", incomingDocument.documentDate);
      setValue("notes", incomingDocument.notes);
    }
  }, [incomingDocument, setValue]);

  const saveDisabled =
    !uploadProgress.completed || isSubmitting || (!incomingDocument && unifiedAttachments.length === 0);

  const onAddHandler = async (form: IFormData) => {
    startLoading();
    try {
      assertNotNil(form.locationId);
      setDefaultLocationId([{id: form.locationId}]);
      const createdDocument = await createIncomingDocument({
        clientId: getCurrentClientId(),
        documentDate: form.documentDate,
        supplierId: form.supplierId,
        locationId: form.locationId,
        documentNumber: form.documentNumber,
        notes: form.notes
      });
      if (createdDocument.__typename === "IncomingDocumentSuccessResult") {
        const collectionId = createdDocument.incomingDocument.attachmentCollection.id;
        await updateAttachments(collectionId);
        await presentToast({
          position: "top",
          message: t("incdoc.doc.add-document-success", {defaultValue: "Успешно добавяне на документ"}),
          color: "success",
          duration: 2000
        });
        await backHandler();
      } else {
        parseErrorResult(createdDocument, setError);
      }
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  };

  const onUpdateHandler = async (form: IFormData) => {
    startLoading();
    try {
      assertNotNil(incomingDocument);
      assertNotNil(form.locationId);
      setDefaultLocationId([{id: form.locationId}]);
      const updatedDocument = await updateIncomingDocument({
        id: incomingDocument.id,
        documentDate: form.documentDate,
        supplierId: form.supplierId,
        locationId: form.locationId,
        documentNumber: form.documentNumber,
        notes: form.notes
      });
      if (updatedDocument.__typename === "IncomingDocumentSuccessResult") {
        const collectionId = updatedDocument.incomingDocument.attachmentCollection.id;
        await updateAttachments(collectionId);
        await presentToast({
          position: "top",
          message: t("incdoc.doc.update-document-success", {defaultValue: "Успешно редактиране на документ"}),
          color: "success",
          duration: 2000
        });
        await backHandler();
      } else {
        parseErrorResult(updatedDocument, setError);
      }
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  };

  const setDecimalInputMode = async () => {
    setInputMode("decimal");
    await inputModeSliderRef.current?.close();
    await documentNumberInputRef?.current?.setFocus();
  };

  const setTextInputMode = async () => {
    setInputMode("text");
    await inputModeSliderRef.current?.close();
    await documentNumberInputRef?.current?.setFocus();
  };

  const title = incomingDocument
    ? t("incdoc.doc.edit-document-title", {defaultValue: "Редактиране на документ"})
    : t("incdoc.doc.add-document-title", {defaultValue: "Добавяне на документ"});

  const onSaveHandler = incomingDocument ? onUpdateHandler : onAddHandler;

  const handleNewSupplier = useCallback(
    async (e: IonModalCustomEvent<OverlayEventDetail<MaybeNil<ISearchResultSupplier>>>) => {
      await loadSuppliers();
      if (!isNil(e.detail.data)) {
        const supplierId = e.detail.data.id;
        setValue("supplierId", supplierId, {shouldValidate: true});
      }
      setSupplierDefaults(undefined);
    },
    [setValue, setSupplierDefaults]
  );

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{title}</IonTitle>
          <MenuButton />
        </IonToolbar>
        <IonProgressBar type="determinate" value={uploadProgress.completed ? 1 : uploadProgress.progress} />
      </IonHeader>
      <IonContent>
        <FstIonModal onIonModalDidDismiss={handleNewSupplier} ref={supplierRef}>
          <AddEditSupplier defaults={supplierDefaults} />
        </FstIonModal>
        <IonList>
          <ListHeaderErrorMessage errors={errors} />
          <RhfTypeaheadSelect
            options={locations.map((location) => {
              return {
                key: location.id.locationId,
                value: location.id,
                text: getLocationText(location)
              };
            })}
            required
            rhf={{control, name: "locationId"}}
            textExtractor={textExtractor}
            title={t("incdoc.doc.location", {defaultValue: "Обект"})}
          />
          <RhfTypeaheadSelect
            onAddNew={(searchText) => {
              setSupplierDefaults({name: searchText});
              supplierRef?.current?.present();
            }}
            options={suppliers.map((supplier) => {
              return {
                key: supplier.id.supplierId,
                value: supplier.id,
                text: getSupplierText(supplier)
              };
            })}
            required
            rhf={{control, name: "supplierId"}}
            textExtractor={textExtractor}
            title={t("incdoc.doc.supplier", {defaultValue: "Доставчик"})}
          />
          <IonItemSliding ref={inputModeSliderRef}>
            <IonItem>
              <RhfIonInput
                inputMode={inputMode}
                labelPlacement="stacked"
                onSubmitValue={async () => {
                  const input = await documentNumberInputRef?.current?.getInputElement();
                  input?.blur();
                }}
                placeholder={t("incdoc.doc.doc-num-placeholder", {defaultValue: "Въведете номерът на документа"})}
                ref={documentNumberInputRef}
                rhf={{control, name: "documentNumber"}}
              >
                <div slot="label">
                  {t("incdoc.doc.doc-num", {defaultValue: "Номер на документ"})}{" "}
                  <RhfRequiredLabel errors={errors} field="documentNumber" watch={watch} />
                </div>
              </RhfIonInput>
            </IonItem>
            <IonItemOptions side="start">
              <IonItemOption color={inputMode === "decimal" ? "secondary" : "light"} onClick={setDecimalInputMode}>
                <IonIcon slot="icon-only" src={one23Icon} />
              </IonItemOption>
              <IonItemOption color={inputMode === "text" ? "secondary" : "light"} onClick={setTextInputMode}>
                <IonIcon icon={textOutline} slot="icon-only" />
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
          <IonItem>
            <IonLabel>
              {t("incdoc.doc.doc-date", {defaultValue: "Дата на документа"})}{" "}
              <RhfRequiredLabel errors={errors} field="documentDate" watch={watch} />
              {errors.documentDate ? (
                <p>
                  <ErrorMessage>{errors.documentDate.message}</ErrorMessage>
                </p>
              ) : null}
            </IonLabel>
            <RhfFstIonDatetimeButton
              multiple={false}
              presentation="date"
              rhf={{control, name: "documentDate"}}
              slot="end"
            >
              <IonLabel slot="title">{t("incdoc.doc.doc-date")}</IonLabel>
            </RhfFstIonDatetimeButton>
          </IonItem>
          <IonItem lines="full">
            <IonLabel>
              <AttachmentCollectionEdit
                disabled={isSubmitting}
                showFileUpload={showFileUpload}
                unifiedAttachments={unifiedAttachments}
              />
            </IonLabel>
          </IonItem>
          <IonItem>
            <RhfIonTextarea
              label={t("incdoc.doc.notes", {defaultValue: "Бележки"})}
              labelPlacement="floating"
              placeholder={t("incdoc.doc.notes-placeholder", {defaultValue: "Допълнителни бележки (ако има нужда)"})}
              rhf={{control, name: "notes"}}
            />
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton color="primary" fill="outline" onClick={() => backHandler()}>
              <IonIcon icon={closeOutline} slot="start" />
              {t("g.cancel", {defaultValue: "Откажи"})}
            </IonButton>
            <UploadButton
              color="secondary"
              disabled={isSubmitting}
              fill="solid"
              showFileUpload={showFileUpload}
              unifiedAttachments={unifiedAttachments}
            />
            <IonButton color="primary" disabled={saveDisabled} fill="solid" onClick={handleSubmit(onSaveHandler)}>
              <IonIcon icon={checkmarkCircleOutline} slot="start" />
              {!uploadProgress.completed
                ? t("g.uploading", {
                    defaultValue: "Качване ({{uploadProgress}})",
                    uploadProgress: fractionToPercent(uploadProgress.progress)
                  })
                : t("g.save", {defaultValue: "Запази"})}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
