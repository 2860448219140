import {FieldPath, FieldValues, useController} from "react-hook-form";
import {hofForwardRef, IIonCheckbox, noop, RhfProps, RhfRef} from "app/utils/types";
import {IonCheckbox} from "@ionic/react";
import {ForwardedRef, RefCallback, useImperativeHandle, useRef} from "react";
import {assignRef} from "app/utils/stdlib";

const reportValidity = () => true;

export const RhfIonCheckbox = hofForwardRef(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    {rhf, disabled, ...rest}: IIonCheckbox & RhfProps<TFieldValues, TName>,
    ref: ForwardedRef<HTMLIonCheckboxElement>
  ) => {
    const {
      field: {onChange, ref: fieldRef, value, disabled: rhfDisabled, ...restField},
      formState: {isSubmitting}
    } = useController(rhf);

    const rhfRef = useRef<RhfRef | null>(null);
    const inputRef: RefCallback<HTMLIonCheckboxElement> = (element) => {
      assignRef(ref, element);
      rhfRef.current = {
        focus: () => element?.focus(),
        select: () => element?.focus(),
        setCustomValidity: noop,
        reportValidity
      };
    };
    useImperativeHandle(fieldRef, () => rhfRef.current);

    return (
      <IonCheckbox
        checked={Boolean(value)}
        ref={inputRef}
        {...rest}
        {...restField}
        disabled={isSubmitting || disabled || rhfDisabled}
        onIonChange={(event) => onChange(event.detail.checked)}
      />
    );
  }
);
