import {IValueFragment} from "app/gql/graphqlSchema";
import {EmptyValue} from "app/employee/controlCard/display/EmptyValue";
import {InvalidValue} from "app/employee/controlCard/display/InvalidValue";
import {IonImg} from "@ionic/react";

interface IProps {
  value: IValueFragment;
}

export const DisplaySignature = ({value}: IProps) => {
  let result: JSX.Element;
  if (value.__typename === "ControlCardSignatureValue") {
    result = <IonImg src={value.signature} />;
  } else if (value.__typename === "ControlCardEmptyValue") {
    result = <EmptyValue />;
  } else {
    result = <InvalidValue />;
  }

  return result;
};
