import {ControlCardInstanceUncompleteRowsDocument, IControlCardInstanceId} from "app/gql/graphqlSchema";
import {query} from "app/gql/client";
import {useCallback} from "react";
import {useLoadingContext} from "app/employee/LoadingContext";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {ID} from "app/utils/types";

const uncompleteControlCardRows = async (instanceId: IControlCardInstanceId, rows: ID[]) => {
  const result = await query(ControlCardInstanceUncompleteRowsDocument, {input: {instanceId, rowIds: rows}});
  return result.controlCardInstanceUncompleteRows;
};

export const useUncompleteControlCardRow = (rowId: ID) => {
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();

  const {instance, setInstance} = useControlCardInstanceContext();

  const uncompleteRow = useCallback(async () => {
    try {
      startLoading();
      const newInstance = await uncompleteControlCardRows(instance.id, [rowId]);
      setInstance(newInstance);
    } catch (e) {
      await showExceptionError(e);
    } finally {
      finishLoading();
    }
  }, [finishLoading, instance.id, rowId, setInstance, showExceptionError, startLoading]);

  return {uncompleteRow};
};
