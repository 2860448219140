import {IColumnFragment, IValueFragment} from "app/gql/graphqlSchema";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {z} from "zod";
import {useBackNavigation} from "app/employee/hooks/useBackNavigation";
import {linkEmplControlCardInstance} from "app/employee/core/employeeLinks";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {checkmarkCircleOutline, closeOutline} from "ionicons/icons";
import {RhfIonInput} from "app/employee/rhf/RhfIonInput";
import {t} from "i18next";
import {isNil} from "app/utils/stdlib";

interface IProps {
  column: IColumnFragment;
  setValues: (value: IValueFragment[]) => void | Promise<void>;
}

const formSchema = z.object({
  value: z.string().min(1, t("g.required"))
});

type IFormData = z.infer<typeof formSchema>;

export const PrimaryRowTextValue = ({column, setValues: setControlCardValues}: IProps) => {
  const {instance, canEdit} = useControlCardInstanceContext();
  const disabled = !canEdit;

  const backNavigation = useBackNavigation(linkEmplControlCardInstance(instance.id.instanceId));
  const {t} = useTranslation();
  const {
    control,
    handleSubmit,
    formState: {isSubmitting, isValid}
  } = useForm<IFormData>({
    resolver: zodResolver(formSchema)
  });

  const saveSelectedValue = handleSubmit(async (data) => {
    const value = data.value;
    await setControlCardValues([{text: value, __typename: "ControlCardTextValue"}]);
  });

  const {title, description} = column;

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{t("control-card.add-new-record-title", {title})}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{t("control-card.add-new-record-title", {title})}</IonCardTitle>
            {!isNil(description) && <IonCardSubtitle>{description}</IonCardSubtitle>}
          </IonCardHeader>

          <IonCardContent>
            <RhfIonInput
              clearInput
              fill="outline"
              label={title}
              labelPlacement="floating"
              rhf={{control, name: "value"}}
            />
          </IonCardContent>
        </IonCard>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonToolbar>
            <IonButtons slot="primary">
              <IonButton color="primary" fill="outline" onClick={backNavigation}>
                <IonIcon icon={closeOutline} slot="start" />
                {t("g.cancel")}
              </IonButton>
              <IonButton
                color="primary"
                disabled={isSubmitting || !isValid || disabled}
                fill="solid"
                onClick={saveSelectedValue}
              >
                <IonIcon icon={checkmarkCircleOutline} slot="start" />
                {t("g.add")}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
