import {useTranslation} from "react-i18next";
import {useIonActionSheet, useIonAlert} from "@ionic/react";
import {useCallback} from "react";
import {cameraOutline, closeOutline, pencilOutline, trashOutline} from "ionicons/icons";

interface IContextMenuProps {
  onEdit: () => void | Promise<void>;
  onUploadDocument: () => void | Promise<void>;
  onDelete: () => void | Promise<void>;
  name: string;
}

export const useFolderContextMenu = ({onEdit, onUploadDocument, onDelete, name}: IContextMenuProps) => {
  const {t} = useTranslation();
  const [presentActionSheet] = useIonActionSheet();
  const [presentConfirmation] = useIonAlert();

  const showOptions = useCallback(
    async () =>
      presentActionSheet({
        header: name,

        buttons: [
          {
            text: t("organizer.folder.add-new-document", {defaultValue: "Нов документ"}),
            icon: cameraOutline,
            handler: onUploadDocument
          },
          {
            text: t("organizer.folder.edit", {defaultValue: "Редактирай"}),
            icon: pencilOutline,
            handler: onEdit
          },
          {
            role: "destructive",
            text: t("g.delete"),
            icon: trashOutline,
            handler: async () => {
              await presentConfirmation({
                header: t("organizer.folder.confirm-delete.title", {
                  defaultValue: "Изтрий {{name}}?",
                  name
                }),
                message: t("organizer.folder.confirm-delete.message", {
                  defaultValue: "Всички документи в папката ще бъдат изтрити!"
                }),
                buttons: [
                  {
                    text: t("g.cancel"),
                    role: "cancel"
                  },
                  {
                    text: t("g.delete", {defaultValue: "Изтрий"}),
                    role: "destructive",
                    handler: onDelete
                  }
                ]
              });
            }
          },
          {
            text: t("g.close"),
            icon: closeOutline,
            role: "cancel"
          }
        ]
      }),
    [name, onDelete, onEdit, onUploadDocument, presentActionSheet, presentConfirmation, t]
  );

  return {showOptions};
};
