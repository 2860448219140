import {useState} from "react";
import {useIonViewWillEnter} from "@ionic/react";

const incCallback = (prevKey: number) => prevKey + 1;

export const useRemount = () => {
  const [key, setKey] = useState<number>(0);
  useIonViewWillEnter(() => {
    console.log("remounting");
    setKey(incCallback);
  }, []);
  return key;
};
