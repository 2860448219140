import {
  ControlCardInstanceCompleteDocument,
  IControlCardInstanceFullFragment,
  IControlCardInstanceId
} from "app/gql/graphqlSchema";
import {query} from "app/gql/client";
import {useCallback} from "react";
import {useLoadingContext} from "app/employee/LoadingContext";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {linkEmplControlCardInstance, linkEmplControlCardLocation} from "app/employee/core/employeeLinks";
import {useIonAlert, useIonRouter} from "@ionic/react";
import {isNil} from "app/utils/stdlib";
import {useTranslation} from "react-i18next";
import {scrollValueInstance} from "app/employee/controlCard/input/scrollValueInstance";

const completeControlCardApi = async (instanceId: IControlCardInstanceId) => {
  const result = await query(ControlCardInstanceCompleteDocument, {input: instanceId});
  return result.controlCardInstanceComplete;
};

const findErrorColumns = (newInstance: IControlCardInstanceFullFragment) => {
  return newInstance.rows.flatMap((row) =>
    row.columns.filter(
      (c) => c.validationResult.__typename === "ErrorValidationResult" && isNil(c.overriddenValidationResult)
    )
  );
};

export const useCompleteControlCard = () => {
  const {startLoading, finishLoading, showExceptionError} = useLoadingContext();
  const {instance, setInstance, isTransposedCard} = useControlCardInstanceContext();
  const {push} = useIonRouter();
  const [presentAlert, dismissAlert] = useIonAlert();
  const {t} = useTranslation();

  const completeControlCard = useCallback(
    async (updatedInstance?: IControlCardInstanceFullFragment) => {
      if (!isTransposedCard) {
        const actualInstance = updatedInstance ?? instance;
        const incompleteRows = actualInstance.rows.filter((r) => isNil(r.completedBy));
        if (incompleteRows.length > 0) {
          await presentAlert({
            header: t("control-card.complete-alert.title", {count: incompleteRows.length}),
            message: t("control-card.complete-alert.message"),
            buttons: [
              {
                text: t("control-card.complete-alert.confirm"),
                role: "confirm",
                handler: () => {
                  push(linkEmplControlCardInstance(actualInstance.id.instanceId, incompleteRows[0].rowId), "forward");
                }
              }
            ]
          });
          return;
        }
      }

      let newInstance;
      try {
        startLoading();
        newInstance = await completeControlCardApi(instance.id);
        setInstance(newInstance);
      } catch (e) {
        await showExceptionError(e);
      } finally {
        finishLoading();
      }

      if (isNil(newInstance)) {
        return;
      }

      const errorColumns = findErrorColumns(newInstance);
      if (errorColumns.length > 0) {
        await presentAlert({
          header: t("control-card.complete-error.title", {
            defaultValue: "Контролната карта не може да бъде приключена"
          }),
          message: t("control-card.complete-error.message", {count: errorColumns.length}),
          buttons: [
            {
              text: t("g.ok"),
              role: "confirm",
              handler: async () => {
                await dismissAlert();
                scrollValueInstance(errorColumns[0]);
              }
            }
          ]
        });
        return;
      }

      push(linkEmplControlCardLocation(instance.location.id.locationId), "forward");
    },
    [
      dismissAlert,
      finishLoading,
      instance,
      isTransposedCard,
      presentAlert,
      push,
      setInstance,
      showExceptionError,
      startLoading,
      t
    ]
  );

  return {completeControlCard};
};
