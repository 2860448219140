import {isNil, scoreToPercent} from "app/utils/stdlib";
import {UnreachableCaseError} from "ts-essentials";
import {
  ISyllabusCompletedVideoLesson,
  ISyllabusInProgressVideoLesson,
  ISyllabusTermPlan,
  ISyllabusTermVideoLesson
} from "app/employee/state/syllabus/mutations";
import {PlanPeriodAndDeadline} from "app/employee/syllabus/PlanPeriodAndDeadline";
import {DateDisplay} from "app/employee/DateDisplay";
import {CurrentVideoButtons} from "app/employee/syllabus/CurrentVideoButtons";
import {SmallLessonCard} from "app/employee/syllabus/SmallLessonCard";
import {LessonCard} from "app/employee/syllabus/LessonCard";
import {FlexColumn} from "app/employee/flexUtils";
import {parseDuration} from "app/utils/dates";
import {Trans, useTranslation} from "react-i18next";
import {Timestamp} from "app/employee/Timestamp";

interface IProps {
  lesson: ISyllabusTermVideoLesson;
  termPlan?: ISyllabusTermPlan;
}

const InProgressStartedAt = ({lesson}: {lesson: ISyllabusInProgressVideoLesson}) => {
  const {t} = useTranslation();
  return (
    <Trans i18nKey="syllabus.topic.started-at" t={t}>
      Започната на <DateDisplay dt={lesson.startedAt} />.
    </Trans>
  );
};

const CompletedAt = ({lesson}: {lesson: ISyllabusCompletedVideoLesson}) => {
  const {t} = useTranslation();
  const scorePercent = scoreToPercent(lesson.score);
  return (
    <Trans i18nKey="syllabus.topic.completed-at" scorePercent={scorePercent} t={t}>
      Завършена на <DateDisplay dt={lesson.completeAt} /> с успех {{scorePercent}}.
    </Trans>
  );
};

const TestProgress = ({lesson}: {lesson: ISyllabusTermVideoLesson}) => {
  const {t} = useTranslation();
  if (lesson.__typename === "TermPlanInProgressVideoLesson" && lesson.watchCompleted) {
    const testProgress = lesson.testProgress;
    const totalCorrectAnswers = testProgress.totalCorrectAnswers;
    if (totalCorrectAnswers > testProgress.totalEmployeeAnswers) {
      const totalEmployeeAnswers = lesson.testProgress.totalEmployeeAnswers;
      return (
        <span>
          <Trans
            i18nKey="syllabus.in-progress.test-progress"
            t={t}
            totalCorrectAnswers={totalCorrectAnswers}
            totalEmployeeAnswers={totalEmployeeAnswers}
          >
            Тестът не е предаден. Отговорени {{totalEmployeeAnswers}} от {{totalCorrectAnswers}} въпроса.
          </Trans>{" "}
          {!isNil(lesson.testProgress.lastAnsweredAt) && (
            <Trans i18nKey="syllabus.in-progress.last-answered-at" t={t}>
              Последният отговор е даден на <Timestamp ts={lesson.testProgress.lastAnsweredAt} />
            </Trans>
          )}
        </span>
      );
    } else if (lesson.testProgress.totalCorrectAnswers > 0) {
      const currentScorePercent = scoreToPercent(lesson.testResult.currentScorePercent);
      const minScorePercent = scoreToPercent(lesson.testResult.minScoreToPassPercent);
      return (
        <>
          {t("syllabus.in-progress.current-test-progress", {
            defaultValue: "Текущ резултат от теста {{currentScorePercent}} при мин. {{minScorePercent}}.",
            currentScorePercent,
            minScorePercent
          })}{" "}
          {lesson.testResult.hasRequireQuestion &&
            t("syllabus.in-progress.there-is-important-question", {defaultValue: "Има сбъркан задължителен въпрос"})}
        </>
      );
    }
  }

  return null;
};

const LessonPlanDetails = ({termPlan}: {termPlan: ISyllabusTermPlan}) => {
  const {t} = useTranslation();
  const type = (
    <>
      {termPlan.clientPlan.__typename === "ClientFullPlan" &&
        t("syllabus.in-progress.from-induction-course", {defaultValue: "От встъпително обучение"})}
      {termPlan.clientPlan.__typename === "ClientRefreshPlan" &&
        t("syllabus.in-progress.from-refresh-course", {defaultValue: "От опреснително обучение"})}
    </>
  );
  return (
    <>
      {type} <PlanPeriodAndDeadline termPlan={termPlan} />
    </>
  );
};

const WatchProgress = ({lesson}: {lesson: ISyllabusInProgressVideoLesson}) => {
  const {t} = useTranslation();

  if (!isNil(lesson.watchedLength) && !lesson.watchCompleted && !isNil(lesson.watchProgress)) {
    const watchedLength = parseDuration(lesson.watchedLength);
    const lessonDuration = parseDuration(lesson.videoLesson.length);
    const remainingLength = lessonDuration.minus(watchedLength);
    let message: string;
    if (remainingLength.as("seconds") >= 60) {
      const lengthHuman = remainingLength.toFormat("m");
      message = t("player.remaining-time-minutes", {
        minutes: lengthHuman,
        defaultValue: "Остават {{minutes}} минути."
      });
    } else {
      const lengthHuman = remainingLength.toFormat("s");
      message = t("player.remaining-time-seconds", {
        seconds: lengthHuman,
        defaultValue: "Остават {{seconds}} секунди."
      });
    }
    return <span>{message}</span>;
  } else {
    return null;
  }
};

export const LessonRow = ({lesson, termPlan}: IProps) => {
  const {t} = useTranslation();
  if (lesson.__typename === "TermPlanCompletedVideoLesson") {
    return (
      <SmallLessonCard lesson={lesson}>
        <CompletedAt lesson={lesson} />
      </SmallLessonCard>
    );
  } else if (lesson.__typename === "TermPlanInProgressVideoLesson") {
    return (
      <LessonCard lesson={lesson} subtitle={termPlan && <LessonPlanDetails termPlan={termPlan} />}>
        <FlexColumn>
          <div>
            <InProgressStartedAt lesson={lesson} /> <TestProgress lesson={lesson} /> <WatchProgress lesson={lesson} />
          </div>
          <div>
            <CurrentVideoButtons lesson={lesson} />
          </div>
        </FlexColumn>
      </LessonCard>
    );
  } else if (lesson.__typename === "TermPlanPendingVideoLesson") {
    return (
      <SmallLessonCard
        lesson={lesson}
        subtitle={
          <span>
            {t("syllabus.pending.locked", {defaultValue: "Все още е недостъпна. Имате незавършена текуща тема"})}
          </span>
        }
      />
    );
  } else {
    throw new UnreachableCaseError(lesson);
  }
};
