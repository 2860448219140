import {IonBackButton, IonButton, IonButtons, IonIcon, isPlatform, useIonRouter} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {styled} from "styled-components";
import LogoSvg from "app/utils/icons/logo.svg?react";
import {useModalContext} from "app/employee/incdoc/FstIonModal";
import {arrowBackOutline} from "ionicons/icons";
import {useDefaultPage} from "app/employee/hooks/useDefaultPage";

const StyledButton = styled(IonButton)`
  &&& {
    height: 100%;
  }
`;

const MenuLogo = styled(LogoSvg)`
  height: 40px;
  width: 46px;
`;

export const HomeBackButton = () => {
  const defaultPage = useDefaultPage() ?? "#";

  const {t} = useTranslation();
  const ionRouter = useIonRouter();
  const modalContext = useModalContext();

  if (modalContext) {
    return (
      <IonButtons slot="start">
        <IonButton fill="clear" onClick={() => modalContext.dismiss()}>
          <IonIcon icon={arrowBackOutline} slot="icon-only" />
        </IonButton>
      </IonButtons>
    );
  } else {
    if (ionRouter.canGoBack()) {
      const backButtonText = isPlatform("ios") ? t("g.back", {defaultValue: "Назад"}) : undefined; // returns true when running on a iOS device
      return (
        <IonButtons slot="start">
          <IonBackButton text={backButtonText} />
        </IonButtons>
      );
    } else {
      return (
        <IonButtons slot="start">
          <StyledButton onClick={() => ionRouter.push(defaultPage, "root", "push")} shape="round">
            <MenuLogo />
          </StyledButton>
        </IonButtons>
      );
    }
  }
};
