import {IonButton, IonChip, IonContent, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {useTranslation} from "react-i18next";
import {callOutline, documentTextOutline, mailOutline} from "ionicons/icons";
import {useBootstrapEmployeeStore} from "app/employee/state/bootstrapEmployee/mutations";
import {assertCondition} from "app/utils/stdlib";
import {DateTime} from "luxon";
import {LOCALE, SHORT_DATE_FORMAT_OPTS} from "app/utils/dates";
import {AppContext} from "app/gql/appContext";
import {viberIcon} from "app/utils/icons/ionicFixedIcons";
import {styled} from "styled-components";

const email = "office@fsacademy.bg";
const phoneHuman = "0895 77 55 12";
const phoneFull = "+359895775512";
const body = "Моля, опишете подробно проблема, който имате:\n---------\n\n";

const SmallText = styled.div`
  padding: 0.25rem 0.1rem;
  font-size: 0.8rem;
`;

export const HelpPage = () => {
  const {t} = useTranslation();
  const bootstrapResult = useBootstrapEmployeeStore((state) => state.bootstrapResult.value);
  assertCondition(bootstrapResult?.__typename === "BootstrapEmployee");

  const {name: employeeName} = bootstrapResult.employee;
  const {name: clientName} = bootstrapResult.employee.client;
  const date = DateTime.now().toLocaleString(SHORT_DATE_FORMAT_OPTS, {locale: LOCALE});

  const subject = `Заявка за помощ от ${employeeName} (${clientName}) на ${date}`;
  const helpRef = `/i/help?x-fst-context=${encodeURIComponent(AppContext.EMPLOYEE)}`;
  const viberLink = `viber://chat?number=${encodeURIComponent(phoneFull)}`;

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{t("help.title")}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal">
        <p>{t("help.p1")}</p>
        <p className="ion-padding-top">
          <IonChip color="primary">1</IonChip>
          <strong>{t("help.manual-title")}</strong>
        </p>
        <p>
          <IonButton href={helpRef} target="_blank">
            <IonIcon icon={documentTextOutline} slot="start" />

            {t("help.manual-action")}
          </IonButton>
        </p>
        <p className="ion-padding-top">
          <IonChip color="primary">2</IonChip>
          <strong>{t("help.viber-title")}</strong>
        </p>
        <p>
          <IonButton href={viberLink} target="_blank">
            <IonIcon icon={viberIcon} slot="start" />
            {t("help.viber-action")}
          </IonButton>
          <SmallText>{t("help.work-hours")}</SmallText>
        </p>
        <p className="ion-padding-top">
          <IonChip color="primary">3</IonChip>
          <strong>{t("help.email-title")}</strong>
        </p>
        <p>
          <IonButton href={`mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}>
            <IonIcon icon={mailOutline} slot="start" />
            {t("help.email-action", {email})}
          </IonButton>
          <SmallText>{t("help.work-hours")}</SmallText>
        </p>
        <p className="ion-padding-top">
          <IonChip color="primary">4</IonChip>
          <strong>{t("help.phone-title", {phone: phoneHuman})}</strong>
        </p>
        <p>
          <IonButton href={`tel:${phoneFull}`} target="_blank">
            <IonIcon icon={callOutline} slot="start" />
            {t("help.phone-action", {phone: phoneHuman})}
          </IonButton>
          <SmallText>{t("help.work-hours")}</SmallText>
        </p>
      </IonContent>
    </>
  );
};
