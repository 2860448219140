import {IonButton, IonContent, IonFab, IonHeader, IonIcon, IonLabel, IonTitle, IonToolbar} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {useTranslation} from "react-i18next";
import {DocumentList, IDocumentListHandle} from "app/employee/incdoc/DocumentList";
import {cameraOutline} from "ionicons/icons";
import {FlexColumn} from "app/employee/flexUtils";
import {getUploadyDestination} from "app/gql/client";
import {DocumentUploader, IDocumentUploaderHandle} from "app/employee/incdoc/DocumentUploader";
import {Uploady} from "@rpldy/uploady";
import {useCallback, useRef} from "react";
import {IncDocFilter} from "app/employee/incdoc/IncDocFilter";
import {ListPadding} from "app/employee/incdoc/ListPadding";
import {FilterContainer} from "app/employee/incdoc/FilterContainer";
import {TitleRow} from "app/employee/incdoc/TitleRow";
import {usePermissions} from "app/employee/hooks/usePermissions";

export const IncDocHome = () => {
  const {t} = useTranslation();

  const uploaderRef = useRef<IDocumentUploaderHandle>(null);
  const listRef = useRef<IDocumentListHandle>(null);

  const refreshCallback = useCallback(async () => {
    await listRef.current?.refresh();
  }, []);

  const {canAddIncDocAny} = usePermissions();

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{t("incdoc.title", {defaultValue: "Входящ Контрол"})}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal">
        <FlexColumn>
          <TitleRow>
            <h1>{t("incdoc.title", {defaultValue: "Входящ Контрол"})}</h1>
            <FilterContainer>
              <IncDocFilter refreshDocuments={refreshCallback} />
            </FilterContainer>
          </TitleRow>
        </FlexColumn>
        <DocumentList editDocument={(document) => uploaderRef.current?.editDocument(document)} ref={listRef} />
        <Uploady concurrent destination={getUploadyDestination()} maxConcurrent={2} method="POST" multiple>
          <DocumentUploader onCompleted={refreshCallback} ref={uploaderRef} />
        </Uploady>
        <ListPadding />
        {canAddIncDocAny() && (
          <IonFab horizontal="end" slot="fixed" vertical="bottom">
            <FlexColumn>
              <IonButton onClick={() => uploaderRef.current?.showFileUpload()} shape="round">
                <IonIcon icon={cameraOutline} slot="start" />
                <IonLabel>{t("incdoc.add-doc", {defaultValue: "Добави документ"})}</IonLabel>
              </IonButton>
            </FlexColumn>
          </IonFab>
        )}
      </IonContent>
    </>
  );
};
