import {FieldPath, FieldValues, useController} from "react-hook-form";
import {FstIonDatetimeButton, IFstIonDatetimeButton} from "app/employee/core/FstIonDatetimeButton";
import {hofForwardRef, noop, RhfProps, RhfRef} from "app/utils/types";
import {ForwardedRef, RefCallback, useImperativeHandle, useRef} from "react";
import {assignRef, defaultScrollIntoView} from "app/utils/stdlib";
import {ionDateTimeParser} from "app/utils/dates";

const reportValidity = () => true;

export const RhfFstIonDatetimeButton = hofForwardRef(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    {rhf, disabled, presentation, ...rest}: IFstIonDatetimeButton & RhfProps<TFieldValues, TName>,
    ref: ForwardedRef<HTMLIonButtonElement>
  ) => {
    const {
      field: {onChange, value, name, onBlur, disabled: rhfDisabled, ref: fieldRef},
      formState: {isSubmitting}
    } = useController(rhf);

    const rhfRef = useRef<RhfRef | null>(null);
    const inputRef: RefCallback<HTMLIonButtonElement> = (element) => {
      assignRef(ref, element);

      rhfRef.current = {
        focus: () => element?.scrollIntoView(defaultScrollIntoView),
        select: () => noop,
        setCustomValidity: noop,
        reportValidity
      };
    };
    useImperativeHandle(fieldRef, () => rhfRef.current);

    return (
      <FstIonDatetimeButton
        ref={inputRef}
        {...rest}
        disabled={isSubmitting || disabled || rhfDisabled}
        name={name}
        onBlur={onBlur}
        onIonChange={(event) => onChange(ionDateTimeParser(presentation, event.detail.value))}
        presentation={presentation}
        value={value}
      />
    );
  }
);
