import {MaybeNil} from "app/utils/types";
import {Duration as LuxonDuration} from "luxon";
import {isNil} from "app/utils/stdlib";
import {useTranslation} from "react-i18next";

interface Props {
  duration: MaybeNil<string>;
}

const DurationDisplay = ({duration}: Props) => {
  const {i18n} = useTranslation();
  if (isNil(duration)) {
    return null;
  } else {
    const luxonDuration = LuxonDuration.fromISO(duration, {locale: i18n.language});
    return <>{luxonDuration.shiftTo("days").toHuman()}</>;
  }
};

export default DurationDisplay;
