import {IonButton, IonButtons, IonIcon, IonMenuButton} from "@ionic/react";
import {PropsWithChildren} from "react";
import {useModalContext} from "app/employee/incdoc/FstIonModal";
import {closeOutline, menuOutline, notifications} from "ionicons/icons";
import {AnimatedIonIconBadge} from "app/employee/menu/AnimatedIonIconBadge";

import {useNotificationCount} from "app/employee/notification/useNotificationCount";

export const MenuButton = ({children}: PropsWithChildren<unknown>) => {
  const modalContext = useModalContext();
  const {unreadCount} = useNotificationCount();

  if (modalContext) {
    return (
      <IonButtons slot="end">
        <IonButton onClick={() => modalContext?.dismiss()}>
          <IonIcon icon={closeOutline} slot="icon-only" />
        </IonButton>
      </IonButtons>
    );
  } else {
    return (
      <IonButtons slot="end">
        {children}
        <IonMenuButton aria-label="Open main menu" color="primary">
          <IonIcon icon={menuOutline} />
          {unreadCount > 0 && <AnimatedIonIconBadge icon={notifications} />}
        </IonMenuButton>
      </IonButtons>
    );
  }
};
