import {useMemo} from "react";
import {IOrganizerFolderFragment, OrganizerDocumentState} from "app/gql/graphqlSchema";
import {IonIcon} from "@ionic/react";
import {checkmarkCircleOutline, warningOutline} from "ionicons/icons";

export const FolderIcon = ({folder}: {folder: IOrganizerFolderFragment}) => {
  const hasExpired = useMemo(() => folder.documents.some((d) => d.expired), [folder.documents]);
  const hasExpiresSoon = useMemo(() => folder.documents.some((d) => d.expiresSoon), [folder.documents]);
  const hasIncomplete = useMemo(
    () => folder.documents.some((d) => d.state !== OrganizerDocumentState.Complete),
    [folder.documents]
  );
  if (hasExpired || hasExpiresSoon || hasIncomplete) {
    const color = hasExpired ? "danger" : "warning";
    return <IonIcon color={color} icon={warningOutline} />;
  } else {
    return <IonIcon color="success" icon={checkmarkCircleOutline} />;
  }
};
