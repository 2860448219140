import {AttachmentVariantType, IAttachmentCollectionFragment, IAttachmentVariant} from "app/gql/graphqlSchema";
import {IonButton, IonIcon, IonImg} from "@ionic/react";
import {ID, MaybeNil} from "app/utils/types";
import {A11y, Keyboard, Mousewheel, Navigation, Pagination, Zoom} from "swiper/modules";
import {Swiper, SwiperRef, SwiperSlide} from "swiper/react";
import {styled} from "styled-components";
import {closeOutline, reloadOutline, shareSocialOutline} from "ionicons/icons";
import documentPlaceholder from "app/employee/att/document-placeholder.png";
import {useTranslation} from "react-i18next";
import {useMemo, useRef} from "react";
import {PaginationOptions, SwiperModule, ZoomOptions} from "swiper/types";
import {IShareDialogHandle, ShareDialog} from "app/employee/att/ShareDialog";
import AutoSizer from "react-virtualized-auto-sizer";
import {useImageRotate} from "app/employee/incdoc/useImageRotate";

type IMinimalVariant = Pick<IAttachmentVariant, "id" | "url" | "metadata" | "variantType">;

const PageLayout = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

const SwiperContainer = styled.div`
  flex: 1 1 auto;

  .swiper {
    background-color: #eee;
  }
`;

const SlideWrapper = styled.a`
  user-select: none;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

interface IProps {
  att: IAttachmentCollectionFragment;
  name: MaybeNil<string>;
  attId: MaybeNil<ID>;
  onClose: () => void;
}

const findVariant = (variants: IMinimalVariant[], type: AttachmentVariantType) =>
  variants.find((v) => v.variantType === type);

const selectVariants = (variants: IMinimalVariant[]) =>
  ({
    [AttachmentVariantType.FullSize]: findVariant(variants, AttachmentVariantType.FullSize),
    [AttachmentVariantType.Thumbnail]: findVariant(variants, AttachmentVariantType.Thumbnail),
    [AttachmentVariantType.Original]: findVariant(variants, AttachmentVariantType.Original)
  }) as const;

const swiperModules: SwiperModule[] = [Navigation, Pagination, A11y, Keyboard, Mousewheel, Zoom];
const paginationOptions: PaginationOptions = {clickable: true};
const zoomOptions: ZoomOptions = {
  maxRatio: 5
};

export const AttachmentCollectionView = ({att, attId, onClose, name}: IProps) => {
  const {t} = useTranslation();
  const shareDialogRef = useRef<IShareDialogHandle>(null);

  const attachmentCollection = useMemo(() => {
    return att.attachments.map((a) => {
      const variants = selectVariants(a.variants);
      const displayVariant = variants.FULL_SIZE ?? variants.THUMBNAIL;
      return {
        id: a.id,
        variants,
        displayVariant
      };
    });
  }, [att.attachments]);

  const swiperRef = useRef<SwiperRef>(null);
  const {registerImage, rotateImage, getCssTransform} = useImageRotate();

  const initialSlide = attachmentCollection.findIndex((a) => a.id === attId);
  return (
    <>
      <PageLayout>
        <CloseButtonContainer>
          <IonButton fill="clear" onClick={() => rotateImage(swiperRef.current?.swiper.realIndex)}>
            <IonIcon icon={reloadOutline} slot="icon-only" />
          </IonButton>
          <IonButton
            fill="clear"
            onClick={() => shareDialogRef.current?.showShare(swiperRef.current?.swiper.realIndex)}
          >
            <IonIcon icon={shareSocialOutline} slot="start" />
            {t("g.share.button", {defaultValue: "Сподели"})}
          </IonButton>
          <IonButton fill="clear" onClick={onClose}>
            <IonIcon icon={closeOutline} slot="icon-only" />
          </IonButton>
        </CloseButtonContainer>
        <SwiperContainer>
          <AutoSizer>
            {({width, height}) => (
              <div style={{width, height}}>
                <Swiper
                  height={height}
                  initialSlide={initialSlide >= 0 ? initialSlide : undefined}
                  keyboard
                  loop
                  modules={swiperModules}
                  mousewheel
                  navigation
                  pagination={paginationOptions}
                  ref={swiperRef}
                  width={width}
                  zoom={zoomOptions}
                >
                  {attachmentCollection.map((a, index) => {
                    return (
                      <SwiperSlide key={a.id} virtualIndex={index} zoom>
                        <SlideWrapper
                          className="swiper-zoom-target"
                          href={a.variants.ORIGINAL?.url ?? "#"}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <IonImg
                            ref={registerImage(index)}
                            src={a.displayVariant?.url ?? documentPlaceholder}
                            style={{height, width, ...getCssTransform(index)}}
                          />
                        </SlideWrapper>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            )}
          </AutoSizer>
        </SwiperContainer>
      </PageLayout>
      <ShareDialog attachmentCollection={att} name={name} ref={shareDialogRef} />
    </>
  );
};
