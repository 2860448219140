import {IColumnFragment, IValueFragment} from "app/gql/graphqlSchema";
import {EmptyValue} from "app/employee/controlCard/display/EmptyValue";
import {DisplayOption} from "app/employee/controlCard/display/DisplayOption";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardMultiChoiceColumn"};
  value: IValueFragment;
}

export const DisplayMultiChoice = ({column, value}: IProps) => {
  if (value.__typename === "ControlCardMultiValue") {
    if (value.values.length === 0) {
      return <EmptyValue />;
    } else if (value.values.length === 1) {
      return <DisplayOption options={column.options} value={value} />;
    }
    return (
      <ul>
        {value.values.map((value, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              <DisplayOption options={column.options} value={value} />
            </li>
          );
        })}
      </ul>
    );
  } else {
    return <DisplayOption options={column.options} value={value} />;
  }
};
