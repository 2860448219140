import {ISyllabusTerm} from "app/employee/state/syllabus/mutations";
import {getInProgressVideoLesson} from "app/employee/state/syllabus/inProgressVideoLessons";
import {LessonRow} from "app/employee/syllabus/LessonRow";
import {useTranslation} from "react-i18next";

interface IProps {
  terms: ISyllabusTerm[];
}
export const SyllabusInProgressTermPlan = ({terms}: IProps) => {
  const inProgressVideoLesson = getInProgressVideoLesson(terms);
  const {t} = useTranslation();
  if (inProgressVideoLesson) {
    const {lesson, termPlan} = inProgressVideoLesson;
    return <LessonRow lesson={lesson} termPlan={termPlan} />;
  } else {
    return <h4>{t("syllabus.in-progress.no-in-progress-topic", {defaultValue: "Няма текуща тема"})}</h4>;
  }
};
