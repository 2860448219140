import {Question} from "app/employee/test/Question";
import {TestNavigation} from "app/employee/test/TestNavigation";
import {ITestProgress} from "app/employee/state/syllabus/selectors";
import {DeepReadonly} from "ts-essentials";
import {isNotNil} from "app/utils/stdlib";
import {IonContent, IonFooter, IonHeader, IonProgressBar, IonTitle, IonToolbar} from "@ionic/react";
import {MenuButton} from "app/employee/menu/MenuButton";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";

type IProps = Omit<ITestProgress, "available">;

export const VideoLessonTest = ({progress, current, prev, next, videoLesson}: DeepReadonly<IProps>) => {
  const hasAnswer = isNotNil(current.employeeAnswer?.answerId);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{videoLesson.title}</IonTitle>
          <IonProgressBar value={progress.index / progress.total} />
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Question progress={progress} question={current} />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <TestNavigation hasAnswer={hasAnswer} next={next} prev={prev} />
        </IonToolbar>
      </IonFooter>
    </>
  );
};
