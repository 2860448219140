import {Trans, useTranslation} from "react-i18next";
import {isNil} from "app/utils/stdlib";
import {DateDisplay} from "app/employee/DateDisplay";
import {IonBadge} from "@ionic/react";
import {IOrganizerDocumentFragment} from "app/gql/graphqlSchema";

const NoExpiration = () => {
  const {t} = useTranslation();
  return <IonBadge color="success">{t("organizer.never-expires", {defaultValue: "Безсрочен"})}</IonBadge>;
};

export const Validity = ({document}: {document: IOrganizerDocumentFragment}) => {
  const {t} = useTranslation();
  if (!isNil(document.validFrom) && !isNil(document.validUntil)) {
    return (
      <Trans i18nKey="organizer.valid-from-until" t={t}>
        Валиден от{" "}
        <strong>
          <DateDisplay dt={document.validFrom} />
        </strong>{" "}
        до{" "}
        <strong>
          <DateDisplay dt={document.validUntil} />
        </strong>
      </Trans>
    );
  } else if (!isNil(document.validFrom)) {
    return (
      <Trans i18nKey="organizer.valid-from" t={t}>
        Валиден от{" "}
        <strong>
          <DateDisplay dt={document.validFrom} />
        </strong>{" "}
        <NoExpiration />
      </Trans>
    );
  } else if (!isNil(document.validUntil)) {
    return (
      <Trans i18nKey="organizer.valid-until" t={t}>
        Валиден до{" "}
        <strong>
          <DateDisplay dt={document.validUntil} />
        </strong>{" "}
      </Trans>
    );
  } else {
    return <NoExpiration />;
  }
};
