import {IValueInstanceFragment} from "app/gql/graphqlSchema";

export const scrollValueInstance = (valueInstance: IValueInstanceFragment) => {
  try {
    document.querySelectorAll(`[data-scroll-to-value-instance-id="${valueInstance.id}"]`).forEach((el) => {
      el.scrollIntoView({behavior: "smooth", block: "center"});
    });
  } catch (e) {
    console.error("Failed to scroll into view", e);
  }
};
