import {IAttachmentCollectionFragment} from "app/gql/graphqlSchema";
import {IFileToShare, MaybeNil} from "app/utils/types";
import {IonActionSheet, useIonAlert, useIonLoading} from "@ionic/react";
import {forwardRef, useCallback, useImperativeHandle, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {closeOutline, cloudDownloadOutline, shareSocialOutline} from "ionicons/icons";
import {ActionSheetButton} from "@ionic/core/components";
import {downloadUrlAsFile} from "app/utils/stdlib";
import {useBoolean} from "@fluentui/react-hooks";

interface IProps {
  attachmentCollection: IAttachmentCollectionFragment;
  name: MaybeNil<string>;
}

export interface IShareDialogHandle {
  showShare: (attachmentIndex?: number) => void;
}

export const ShareDialog = forwardRef<IShareDialogHandle, IProps>(({attachmentCollection, name}, ref) => {
  const {t} = useTranslation();

  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const performShare = useCallback(
    async (shareData: ShareData) => {
      let canShare: boolean;
      try {
        canShare = navigator.canShare(shareData);
        console.log("Can share returned", canShare);
      } catch (e) {
        console.warn("Can share threw an exception", e);
        canShare = false;
      }
      if (!canShare) {
        await presentAlert({
          header: t("g.share.error.title", {defaultValue: "Грешка при споделяне"}),
          message: t("g.share.error.generic", {
            defaultValue: "Вашият браузър не поддържа този начин за споделяне на файлове. Моля опитайте другите опции"
          }),
          buttons: [t("g.ok")]
        });
        return;
      }
      try {
        await navigator.share(shareData);
      } catch (e) {
        console.warn("Share threw an exception", e);
        // if (
        //   e &&
        //   typeof e === "object" &&
        //   "message" in e &&
        //   typeof e.message === "string" &&
        //   e.message.indexOf("aborted") !== -1
        // ) {
        //   return;
        // }
        // await presentAlert({
        //   header: t("g.share.error.title", {defaultValue: "Грешка при споделяне"}),
        //   message: t("g.share.error.generic", {
        //     defaultValue: "Вашият браузър не поддържа този начин за споделяне на файлове. Моля опитайте другите опции"
        //   }),
        //   buttons: [t("g.ok")]
        // });
      }
    },
    [presentAlert, t]
  );

  const shareAttach = useCallback(
    async (file: IFileToShare) => {
      let downloadedFile: File;
      try {
        await presentLoading({
          message: t("g.share.download.downloading", {defaultValue: "Изтегляне на файла, моля изчакайте..."})
        });
        downloadedFile = await downloadUrlAsFile(file.url, file.filename);
      } catch (e) {
        await presentAlert({
          header: t("g.share.download.error.title", {defaultValue: "Грешка при изтегляне на файла"}),
          message: t("g.share.download.error.message", {
            defaultValue: "Възникна грешка при изтегляне на файла. Моля опитайте отново."
          }),
          buttons: [t("g.ok")]
        });
        return;
      } finally {
        await dismissLoading();
      }
      const shareData: ShareData = {
        files: [downloadedFile],
        text: `Споделен файл от FSAcademy: ${name}`,
        title: name ?? undefined
      };
      await performShare(shareData);
    },
    [dismissLoading, name, performShare, presentAlert, presentLoading, t]
  );

  const shareDownload = useCallback(async (file: IFileToShare) => {
    window.open(file.url, "_blank");
  }, []);

  const buttons = useMemo<ActionSheetButton[]>(() => {
    const pdfFileToShare: IFileToShare = {url: attachmentCollection.pdfUrl, filename: `${name}.pdf`};
    return [
      {
        text: t("g.share.pdf-file-attach"),
        icon: shareSocialOutline,
        handler: async () => {
          await shareAttach(pdfFileToShare);
        }
      },
      {
        text: t("g.share.pdf-file-download"),
        icon: cloudDownloadOutline,
        handler: async () => {
          await shareDownload(pdfFileToShare);
        }
      },
      {
        text: t("g.close"),
        icon: closeOutline,
        role: "cancel"
      }
    ];
  }, [attachmentCollection, name, shareAttach, shareDownload, t]);

  const [isOpen, {setTrue: openActionSheet, setFalse: closeActionSheet}] = useBoolean(false);

  useImperativeHandle(
    ref,
    () => ({
      showShare: openActionSheet
    }),
    [openActionSheet]
  );

  return (
    <IonActionSheet
      buttons={buttons}
      header={t("g.share.title", {defaultValue: "Споделяне на документ {{name}}", name})}
      isOpen={isOpen}
      onDidDismiss={closeActionSheet}
    />
  );
});

ShareDialog.displayName = "ShareDialog";
