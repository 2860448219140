import {ISyllabusTerm} from "app/employee/state/syllabus/mutations";
import {DetailedProgress} from "app/employee/syllabus/DetailedProgress";
import {useTranslation} from "react-i18next";

interface IProps {
  terms: ISyllabusTerm[];
}

export const SyllabusCompletedTermPlan = ({terms}: IProps) => {
  const {t} = useTranslation();
  const completedTermPlans = terms
    .flatMap((t) => t.termPlans)
    .filter((tp) => tp.completed)
    .reverse();

  if (completedTermPlans.length === 0) {
    return <h4>{t("syllabus.completed.no-completed", {defaultValue: "Няма завършени обучения"})}</h4>;
  } else {
    return <DetailedProgress termPlans={completedTermPlans} />;
  }
};
