import {styled} from "styled-components";
import {IonAlert, IonSpinner} from "@ionic/react";
import {ApiState} from "app/state/common/loadable";
import {PropsWithChildren} from "react";
import {useTranslation} from "react-i18next";
import {fullRefresh} from "app/employee/core/fullRefresh";

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  ion-spinner {
    width: 20%;
    height: 20%;
  }
`;

interface IProps {
  loadingState: ApiState;
}

export const ContentSpinner = ({loadingState, children}: PropsWithChildren<IProps>) => {
  const {t} = useTranslation();

  if (loadingState === ApiState.OK) {
    return <>{children}</>;
  } else {
    const showSpinner = loadingState === ApiState.LOADING || loadingState === ApiState.NA;
    const isAlertOpen = loadingState === ApiState.ERROR;

    return (
      <>
        {showSpinner && (
          <LoadingWrapper>
            <IonSpinner />
          </LoadingWrapper>
        )}
        <IonAlert
          buttons={[
            {
              text: t("error.unexpected.reload-button"),
              role: "confirm",
              handler: fullRefresh
            }
          ]}
          isOpen={isAlertOpen}
          message={t("g.loading-error")}
          onDidDismiss={fullRefresh}
        />
      </>
    );
  }
};
