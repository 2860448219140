import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonRouter
} from "@ionic/react";
import {MenuButton} from "app/employee/menu/MenuButton";
import {LoadingLoadable} from "app/employee/Loading";
import {useTranslation} from "react-i18next";
import {useOrganizer} from "../useOrganizer";
import {arrowBackOutline} from "ionicons/icons";
import {linkEmplOrganizer} from "app/employee/core/employeeLinks";
import {isNil} from "app/utils/stdlib";
import {z} from "zod";
import {useSafePathParams} from "app/components/hooks/useSafeParams";
import {useCallback} from "react";
import {FolderContent} from "app/employee/organizer/folder/FolderContent";

const folderParams = z.object({
  folderId: z.string()
});

export const FolderPage = () => {
  const {t} = useTranslation();
  const {organizerLoadable, refreshOrganizer, location} = useOrganizer();
  const {folderId: selectedFolderId} = useSafePathParams(folderParams);
  const ionRouter = useIonRouter();

  const backLink = linkEmplOrganizer(location?.id.locationId);
  const organizer = organizerLoadable.value;
  const folder = organizer?.folders.find((f) => f.id.folderId === selectedFolderId);
  const documents = folder?.documents;

  const handleGoBack = useCallback(() => {
    ionRouter.push(backLink, "back", "pop");
  }, [backLink, ionRouter]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" routerDirection="back" routerLink={backLink}>
              <IonIcon icon={arrowBackOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>{t("organizer.title", {defaultValue: "Органайзер"})}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <LoadingLoadable loadable={organizerLoadable} />
        <IonRefresher onIonRefresh={refreshOrganizer} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>
        {!isNil(location) && !isNil(organizer) && !isNil(folder) && !isNil(documents) && (
          <FolderContent
            documents={documents}
            folder={folder}
            location={location}
            onBack={handleGoBack}
            onRefresh={refreshOrganizer}
            organizer={organizer}
          />
        )}
      </IonContent>
    </>
  );
};
