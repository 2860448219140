import "@ionic/react/css/core.css";
import {render} from "react-dom";
import {IonApp, setupIonicReact} from "@ionic/react";
import {StrictMode} from "react";
import {EmployeeApp} from "app/employee/EmployeeApp";
import {initI18n} from "app/employee/i18n";
import {I18nextProvider} from "react-i18next";
import {ErrorBoundary} from "@sentry/react";
import {fallbackRender} from "app/employee/core/Fallback";
import {StyleSheetManager} from "styled-components";
import {forwardAllProps} from "app/utils/stdlib";
import {Autoupdate} from "app/components/Autoupdate";

export default () => {
  setupIonicReact({
    experimentalCloseWatcher: true
    // mode: "ios"
  });

  initI18n().then((i18n) => {
    render(
      <StrictMode>
        <StyleSheetManager enableVendorPrefixes shouldForwardProp={forwardAllProps}>
          <I18nextProvider defaultNS="translation" i18n={i18n}>
            <IonApp>
              <ErrorBoundary fallback={fallbackRender}>
                <Autoupdate />
                <EmployeeApp />
              </ErrorBoundary>
            </IonApp>
          </I18nextProvider>
        </StyleSheetManager>
      </StrictMode>,
      document.getElementById("container")
    );
  });
};
