import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {HomeBackButton} from "app/employee/menu/HomeBackButton";
import {MenuButton} from "app/employee/menu/MenuButton";
import {LoadingLoadable} from "app/employee/Loading";
import {TitleRow} from "app/employee/incdoc/TitleRow";
import {useTranslation} from "react-i18next";
import {isNil} from "app/utils/stdlib";
import {getLocationText} from "app/employee/utils";
import {FolderListContent} from "app/employee/organizer/folderList/FolderListContent";
import {FstIonSelect} from "app/employee/core/FstIonSelect";
import {useOrganizer} from "app/employee/organizer/useOrganizer";

export const FolderListPage = () => {
  const {t} = useTranslation();

  const {organizerLoadable, refreshOrganizer, location, locations, handleLocationChange} = useOrganizer();
  const organizer = organizerLoadable.value;

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <HomeBackButton />
          <IonTitle>{t("organizer.title", {defaultValue: "Органайзер"})}</IonTitle>
          <MenuButton />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <LoadingLoadable loadable={organizerLoadable} />
        <IonRefresher onIonRefresh={refreshOrganizer} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>
        {!isNil(location) ? (
          <>
            <IonList lines="full">
              <IonItem color="highlight">
                <FstIonSelect
                  className="ion-text-wrap"
                  label={t("organizer.location", {defaultValue: "Обект"})}
                  onIonChange={handleLocationChange}
                  value={location.id}
                >
                  {locations.map((l) => (
                    <IonSelectOption key={l.id.locationId} value={l.id}>
                      {getLocationText(l)}
                    </IonSelectOption>
                  ))}
                </FstIonSelect>
              </IonItem>
            </IonList>
            {!isNil(organizer) && (
              <FolderListContent location={location} onRefresh={refreshOrganizer} organizer={organizer} />
            )}
          </>
        ) : (
          <TitleRow className="ion-padding-horizontal">
            <h1>{t("organizer.no-locations", {defaultValue: "Нямате достъп до органайзера на нито един обект"})}</h1>
          </TitleRow>
        )}
      </IonContent>
    </>
  );
};
