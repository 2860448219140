import {IonIcon} from "@ionic/react";
import {calendarNumberOutline, locationOutline, readerOutline} from "ionicons/icons";
import {useControlCardInstanceContext} from "app/employee/controlCard/ControlCardInstanceContext";
import {styled} from "styled-components";
import {getLocationText} from "app/employee/utils";
import {DateDisplay} from "app/employee/DateDisplay";
import {ReactNode} from "react";

const Table = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  margin: 0.5rem;
`;

const CustomPanel = styled.div`
  border: 1px solid var(--fst-border-color);
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  margin: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const SummaryAdditionalRow = styled.div<{$color?: "completed" | "incomplete" | "not-applicable"}>`
  border-top: 1px solid var(--fst-border-color);
  padding: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
  ${({$color}) => $color && `background-color: var(--ion-color-${$color});`}
`;

export const SummaryAdditionalGridRow = styled(SummaryAdditionalRow)`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.5rem;
`;

export const ControlCardInstanceSummary = ({children}: {children?: ReactNode}) => {
  const {instance} = useControlCardInstanceContext();
  return (
    <CustomPanel>
      <Table>
        <div>
          <IonIcon icon={readerOutline} />
        </div>
        <div>
          <strong>{instance.name}</strong>
        </div>
        <div>
          <IonIcon icon={calendarNumberOutline} />
        </div>
        <div>
          <DateDisplay dt={instance.date} />
        </div>
        <div>
          <IonIcon icon={locationOutline} />
        </div>
        <div>{getLocationText(instance.location)}</div>
      </Table>
      {children}
    </CustomPanel>
  );
};
