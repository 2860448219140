import {ISyllabusTermVideoLesson} from "app/employee/state/syllabus/mutations";
import {IGraphQlDuration, MaybeNil} from "app/utils/types";
import {useVideoStreams} from "app/employee/state/bootstrapEmployee/selectors";
import {posterReplacement} from "app/utils/video";
import {parseDurationOptional} from "app/utils/dates";
import {styled} from "styled-components";
import {IonIcon, IonImg, IonProgressBar} from "@ionic/react";
import {isNil} from "app/utils/stdlib";
import {ImageOverlay} from "app/employee/syllabus/ImageOverlay";
import {lockClosedOutline, playCircle} from "ionicons/icons";
import {getVideoStats} from "app/employee/syllabus/getVideoStats";

export const usePoster = (videoLesson: ISyllabusTermVideoLesson, watchedLength: MaybeNil<IGraphQlDuration>) => {
  const streams = useVideoStreams(videoLesson.videoLesson.streamingLinks);
  return streams?.poster ? posterReplacement(streams.poster, parseDurationOptional(watchedLength)) : null;
};

const IonIconOverlay = styled(IonIcon)`
  width: 40%;
  height: 40%;
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Img16x9 = styled(IonImg)`
  aspect-ratio: 16/9;
`;

interface IProps {
  lesson: ISyllabusTermVideoLesson;
}

export const VideoThumbnail = ({lesson}: IProps) => {
  const videoLesson = lesson.videoLesson;
  const title = videoLesson.title;

  const {canPlay, playLink, thumbnailLength, watchProgress, showProgress} = getVideoStats(lesson);
  const poster = usePoster(lesson, thumbnailLength);

  if (isNil(poster)) {
    return null;
  } else {
    return (
      <ThumbnailWrapper>
        <ImageOverlay
          disabled={!canPlay}
          overlay={<IonIconOverlay icon={canPlay ? playCircle : lockClosedOutline} />}
          to={playLink}
        >
          <Img16x9 alt={title} src={poster} />
        </ImageOverlay>
        {!isNil(watchProgress) && showProgress && <IonProgressBar type="determinate" value={watchProgress} />}
      </ThumbnailWrapper>
    );
  }
};
