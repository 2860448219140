import {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useIonAlert, useIonLoading, useIonViewWillLeave} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {assertNotNil, isNil} from "app/utils/stdlib";
import {getErrorMessage} from "app/utils/parseErrorResult";
import {fullRefresh, pageRefresh} from "app/employee/core/fullRefresh";
import {IApplicationError, transformToApplicationError} from "app/gql/handleGraphqlErrors";
import PQueue from "p-queue";

const useLoading = () => {
  const queueRef = useRef(
    new PQueue({concurrency: 1}).on("error", (err) => {
      console.error("loadingQueue error", err);
    })
  );

  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert, dismissAlert] = useIonAlert();

  const {t} = useTranslation();
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const loadingShown = useRef<boolean>(false);

  const [loadingState, setLoadingState] = useState(0);

  const startLoading = useCallback(() => setLoadingState((prevState) => prevState + 1), []);
  const finishLoading = useCallback(() => setLoadingState((prevState) => Math.max(prevState - 1, 0)), []);

  useEffect(() => {
    const shouldShow = loadingState > 0;

    if (shouldShow) {
      if (loadingShown.current || !isNil(timeoutIdRef.current)) {
        return;
      }
      timeoutIdRef.current = setTimeout(() => {
        loadingShown.current = true;
        queueRef.current.add(() =>
          presentLoading({
            backdropDismiss: false,
            message: t("g.loading")
          })
        );
      }, 500);
    } else {
      if (!isNil(timeoutIdRef.current)) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
      if (loadingShown.current) {
        loadingShown.current = false;
        queueRef.current.add(() => dismissLoading());
      }
    }
  }, [dismissLoading, loadingState, presentLoading, t]);

  const showAppError = useCallback(
    async (appError: IApplicationError) => {
      const message = getErrorMessage(appError);
      queueRef.current.add(() => dismissAlert());
      let refresh: typeof fullRefresh | typeof pageRefresh;
      switch (appError.__typename) {
        case "GenericError":
        case "NetworkError":
        case "ServerError":
          refresh = pageRefresh;
          break;
        default:
          refresh = fullRefresh;
      }
      queueRef.current.add(() =>
        presentAlert({
          header: t("g.loading-error"),
          message,
          buttons: [
            {
              text: t("error.unexpected.reload-button"),
              role: "confirm",
              handler: refresh
            }
          ],
          backdropDismiss: false
        })
      );
    },
    [dismissAlert, presentAlert, t]
  );

  const showExceptionError = useCallback(
    async (e: unknown) => {
      const appError = transformToApplicationError(e);
      await showAppError(appError);
    },
    [showAppError]
  );

  const cleanup = useCallback(() => {
    if (!isNil(timeoutIdRef.current)) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
    loadingShown.current = false;
    queueRef.current.add(() => dismissLoading());
    queueRef.current.add(() => dismissAlert());
  }, [dismissAlert, dismissLoading]);

  useEffect(() => cleanup, [cleanup]);

  useIonViewWillLeave(cleanup, [cleanup]);

  return useMemo(
    () => ({
      startLoading,
      finishLoading,
      showExceptionError,
      showAppError,
      loading: loadingState > 0
    }),
    [startLoading, finishLoading, showExceptionError, showAppError, loadingState]
  );
};

type ILoadingContext = ReturnType<typeof useLoading>;
const LoadingContext = createContext<ILoadingContext | null>(null);

export const LoadingProvider = ({children}: {children: React.ReactNode}) => {
  const loading = useLoading();
  return <LoadingContext.Provider value={loading}>{children}</LoadingContext.Provider>;
};

export const useLoadingContext = () => {
  const context = useContext(LoadingContext);
  assertNotNil(context);
  return context;
};
