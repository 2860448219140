import {useCallback} from "react";
import {useIonRouter} from "@ionic/react";

export const useBackNavigation = (fallBackLink: string) => {
  const ionRouter = useIonRouter();

  return useCallback(() => {
    if (ionRouter.canGoBack()) {
      ionRouter.goBack();
    } else {
      ionRouter.push(fallBackLink, "back", "pop");
    }
  }, [ionRouter, fallBackLink]);
};
