import {useTranslation} from "react-i18next";
import {IonText} from "@ionic/react";
import {isNil} from "app/utils/stdlib";
import {FieldErrors, FieldPath, FieldValues, UseFormWatch} from "react-hook-form";

export const RequiredLabel = ({value}: {value: unknown}) => {
  const {t} = useTranslation();

  if (isNil(value) || (typeof value === "string" && value.trim() === "")) {
    return <IonText color="danger">({t("g.required", {defaultValue: "задължително"})})</IonText>;
  } else {
    return null;
  }
};

export const RhfRequiredLabel = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  watch,
  field,
  errors
}: {
  watch: UseFormWatch<TFieldValues>;
  field: TName;
  errors: FieldErrors<TFieldValues>;
}) => {
  const {t} = useTranslation();
  if (errors[field]) {
    return null;
  }
  const value = watch(field) as unknown;
  if (isNil(value) || (typeof value === "string" && value.trim() === "")) {
    return <IonText color="danger">({t("g.required", {defaultValue: "задължително"})})</IonText>;
  } else {
    return null;
  }
};
