import {RefObject, useCallback, useRef} from "react";
import {IShakaHandle} from "app/employee/video/ShakaPlayer";
import {isNil} from "app/utils/stdlib";
import {IModalHandle} from "app/employee/video/VideoPlayerModal";

export const useVideoDialog = (shakaHandle: RefObject<IShakaHandle>, modalHandle: RefObject<IModalHandle>) => {
  const wasInFullScreen = useRef<boolean | null>(null);

  const exitFullScreenState = useCallback(async () => {
    const player = shakaHandle.current;
    if (player) {
      const isFullScreen = player.isFullScreenEnabled();
      if (isFullScreen) {
        await player.toggleFullScreen();
      }
      wasInFullScreen.current = isFullScreen;
    } else {
      wasInFullScreen.current = null;
    }
  }, [shakaHandle]);

  const restoreFullScreenState = useCallback(async () => {
    const player = shakaHandle.current;
    if (player && !isNil(wasInFullScreen.current) && wasInFullScreen.current && !player.isFullScreenEnabled()) {
      await player.toggleFullScreen();
    }
    wasInFullScreen.current = null;
  }, [shakaHandle]);

  const openModal = useCallback(async () => {
    console.log("Open modal");
    await modalHandle.current?.show();
    await exitFullScreenState();
  }, [exitFullScreenState, modalHandle]);

  const closeModal = useCallback(async () => {
    console.log("Closing modal");
    await modalHandle.current?.hide();
    await restoreFullScreenState();
  }, [modalHandle, restoreFullScreenState]);

  return {openModal, closeModal};
};
