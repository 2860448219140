import {IonCard, IonCardContent} from "@ionic/react";
import {useTranslation} from "react-i18next";

export const ControlCardEditDisabledBanner = () => {
  const {t} = useTranslation();
  return (
    <IonCard color="danger">
      <IonCardContent>{t("control-card.location.edit-disabled-banner")}</IonCardContent>
    </IonCard>
  );
};
