import {IColumnFragment, IValueFragment} from "app/gql/graphqlSchema";
import {DisplayOption} from "app/employee/controlCard/display/DisplayOption";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardChoiceColumn"};
  value: IValueFragment;
}

export const DisplayChoice = ({column, value}: IProps) => {
  return <DisplayOption options={column.options} value={value} />;
};
