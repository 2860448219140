import {IColumnFragment, IValueFragment} from "app/gql/graphqlSchema";
import {EmptyValue} from "app/employee/controlCard/display/EmptyValue";
import {InvalidValue} from "app/employee/controlCard/display/InvalidValue";
import {TimeDisplay} from "app/employee/TimeDisplay";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardTimeColumn"};
  value: IValueFragment;
}
export const DisplayTime = ({value}: IProps) => {
  let result: JSX.Element;
  if (value.__typename === "ControlCardTimeValue") {
    result = <TimeDisplay time={value.time} />;
  } else if (value.__typename === "ControlCardEmptyValue") {
    result = <EmptyValue />;
  } else {
    result = <InvalidValue />;
  }

  return result;
};
