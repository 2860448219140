import {IonButton, IonItem, IonLabel, IonList, IonModal, IonRadio, IonText} from "@ionic/react";
import {styled} from "styled-components";
import {forwardRef, Fragment, Ref, useCallback, useMemo, useRef} from "react";
import {useOverrideControlCardValidationResult} from "app/employee/controlCard/actions/useOverrideControlCardValidationResult";
import {IValueInstanceFragment} from "app/gql/graphqlSchema";
import {IIonModal} from "app/utils/types";
import {useTranslation} from "react-i18next";
import {RhfIonTextarea} from "app/employee/rhf/RhfIonTextarea";
import {RhfIonRadioGroup} from "app/employee/rhf/RhfIonRadioGroup";
import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {zNormalizedString, zNormalizedStringToNull, zToNull} from "app/utils/validator";
import {useMergedRefs} from "@fluentui/react-hooks";
import {UnreachableCaseError} from "ts-essentials";
import {assertNotNil, isNil} from "app/utils/stdlib";

const CustomIonModal = styled(IonModal)`
  --width: calc(100% - var(--ion-safe-area-left) - var(--ion-safe-area-right) - 2rem);
  --min-width: 250px;

  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Title = styled(IonText)`
  padding: 1rem 1rem 0 1rem;
  font-weight: bold;
`;

interface IProps extends IIonModal {
  valueInstance: Pick<IValueInstanceFragment, "id">;
}

enum ReasonOption {
  NotApplicable = "not-applicable",
  Other = "other"
}

const zReasonOption = z.nativeEnum(ReasonOption);

interface IOverrideValidationResultFormProps extends IProps {
  onClose: () => void;
}

const OverrideValidationResultForm = ({valueInstance, onClose}: IOverrideValidationResultFormProps) => {
  const {t} = useTranslation();
  const {overrideValidationResult} = useOverrideControlCardValidationResult(valueInstance);

  const reasonOptions = useMemo(
    () => [
      {
        name: t("control-card.override-validation-result.option.not-applicable", {defaultValue: "Неприложимо"}),
        value: ReasonOption.NotApplicable
      },
      {
        name: t("control-card.override-validation-result.option.other", {defaultValue: "Друго"}),
        value: ReasonOption.Other
      }
    ],
    [t]
  );

  const formSchema = useMemo(() => {
    const zOtherTextValidator = zNormalizedString.pipe(z.string().min(6));

    return z
      .strictObject({
        reasonOption: zReasonOption.nullable(),
        reasonText: zToNull.or(zNormalizedStringToNull)
      })
      .superRefine((data, ctx) => {
        if (isNil(data.reasonOption)) {
          ctx.addIssue({
            message: t("control-card.override-validation-result.reason-option-required", {
              defaultValue: "Трябва да изберете една от следните опции:"
            }),
            path: ["reasonOption"],
            code: "custom"
          });
          return z.NEVER;
        }
        if (data.reasonOption === ReasonOption.Other) {
          const result = zOtherTextValidator.safeParse(data.reasonText);
          if (result.error) {
            ctx.addIssue({message: result.error.errors[0].message, path: ["reasonText"], code: "custom"});
            return z.NEVER;
          }
        }
      });
  }, [t]);

  type IFormData = z.infer<typeof formSchema>;

  const {
    control,
    formState: {isSubmitting, disabled: formDisabled},
    handleSubmit,
    watch
  } = useForm<IFormData>({
    values: {
      reasonOption: null,
      reasonText: null
    },
    resolver: zodResolver(formSchema)
  });

  const reasonOption = watch("reasonOption");

  const handleOverride = handleSubmit(async (data) => {
    let reason: string;
    assertNotNil(data.reasonOption);
    if (data.reasonOption === ReasonOption.NotApplicable) {
      reason = "Неприложимо";
    } else if (data.reasonOption === ReasonOption.Other) {
      assertNotNil(data.reasonText);
      reason = data.reasonText;
    } else {
      throw new UnreachableCaseError(data.reasonOption);
    }

    await overrideValidationResult(reason);
    onClose();
  });

  return (
    <Wrapper>
      <Title>
        {t("control-card.override-validation-result.title", {
          defaultValue: "Отбележете причина защо не можете да попълните това поле:"
        })}
      </Title>
      <IonList lines="full">
        <RhfIonRadioGroup rhf={{control, name: "reasonOption"}}>
          <Controller
            control={control}
            name="reasonOption"
            render={({fieldState}) => {
              if (!fieldState.error) {
                // eslint-disable-next-line react/jsx-no-useless-fragment
                return <></>;
              }
              return (
                <IonItem>
                  <IonLabel>
                    <IonText color="danger">{fieldState.error.message}</IonText>
                  </IonLabel>
                </IonItem>
              );
            }}
          />
          {reasonOptions.map(({name, value}) => (
            <Fragment key={value}>
              <IonItem>
                <IonRadio disabled={isSubmitting || formDisabled} justify="start" labelPlacement="end" value={value}>
                  {name}
                </IonRadio>
              </IonItem>
              {value === ReasonOption.Other && reasonOption === ReasonOption.Other && (
                <IonItem>
                  <RhfIonTextarea
                    placeholder={t("control-card.override-validation-result.option.other-placeholder", {
                      defaultValue: "Напишете причина"
                    })}
                    rhf={{control, name: "reasonText"}}
                  />
                </IonItem>
              )}
            </Fragment>
          ))}
        </RhfIonRadioGroup>
      </IonList>
      <ButtonBar>
        <IonButton disabled={isSubmitting} fill="clear" onClick={onClose}>
          {t("g.cancel")}
        </IonButton>
        <IonButton disabled={isSubmitting || formDisabled} fill="clear" onClick={handleOverride}>
          {t("g.save")}
        </IonButton>
      </ButtonBar>
    </Wrapper>
  );
};

const OverrideValidationResultDialogInner = ({valueInstance, ...rest}: IProps, ref: Ref<HTMLIonModalElement>) => {
  const modalRef = useRef<HTMLIonModalElement>(null);
  const mergedRef = useMergedRefs(ref, modalRef);

  const handleClose = useCallback(() => modalRef.current?.dismiss(), []);

  return (
    <CustomIonModal {...rest} ref={mergedRef}>
      <OverrideValidationResultForm onClose={handleClose} valueInstance={valueInstance} />
    </CustomIonModal>
  );
};

export const OverrideValidationResultDialog = forwardRef(OverrideValidationResultDialogInner);
