import {RefObject, useCallback, useRef} from "react";
import {noop} from "app/utils/types";
import {useAsync} from "@fluentui/react-hooks";
import {IWatchAgain, IWatchCurrent, IWatchSegment} from "app/employee/video/VideoPlayer";
import {isNil} from "app/utils/stdlib";
import {IShakaHandle} from "app/employee/video/ShakaPlayer";

export const useProgressCallback = (
  playerRef: RefObject<IShakaHandle>,
  playerControl: IWatchAgain | IWatchCurrent | IWatchSegment
) => {
  const updateProgressRef = useRef<() => void>(noop);
  const updateProgressThrottledRef = useRef<() => void>(noop);
  const async = useAsync();

  updateProgressRef.current = useCallback(() => {
    if (playerRef.current && playerControl.type === "current") {
      const currentTimeSec = playerRef.current?.currentTime();
      if (!isNil(currentTimeSec)) {
        console.log("updateProgress", currentTimeSec.toFormat("hh:mm:ss.SSS"));
        playerControl.updateProgress(currentTimeSec);
      }
    }
  }, [playerControl, playerRef]);

  updateProgressThrottledRef.current = async.throttle(updateProgressRef.current, 5000, {
    leading: false,
    trailing: true
  });

  const updateProgress = useCallback(() => updateProgressRef.current(), []);
  const updateProgressThrottled = useCallback(() => updateProgressThrottledRef.current(), []);
  return {
    updateProgress,
    updateProgressThrottled
  };
};
