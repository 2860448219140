import {ISyllabusTermPlan} from "app/employee/state/syllabus/mutations";
import {IonBadge} from "@ionic/react";
import {useTranslation} from "react-i18next";

interface IProps {
  plan: ISyllabusTermPlan["clientPlan"];
}

const badgeColors: Record<ISyllabusTermPlan["clientPlan"]["__typename"], string> = {
  ClientFullPlan: "induction-course",
  ClientRefreshPlan: "refresher-course"
};

export const PlanBadge = ({plan}: IProps) => {
  const {t} = useTranslation();
  const planType = plan.__typename;
  return (
    <strong>
      {planType === "ClientFullPlan" && (
        <IonBadge color={badgeColors[planType]}>
          {t("syllabus.in-progress.induction-course", {defaultValue: "Встъпително"})}
        </IonBadge>
      )}
      {planType === "ClientRefreshPlan" && (
        <IonBadge color={badgeColors[planType]}>
          {t("syllabus.in-progress.refresh-course", {defaultValue: "Опреснително"})}
        </IonBadge>
      )}{" "}
    </strong>
  );
};
