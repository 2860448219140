import {IColumnFragment, IValueFragment} from "app/gql/graphqlSchema";
import {EmptyValue} from "app/employee/controlCard/display/EmptyValue";
import {InvalidValue} from "app/employee/controlCard/display/InvalidValue";
import {DisplayNumber as DisplayNumberShared} from "app/shared/DisplayNumber";

interface IProps {
  column: IColumnFragment & {__typename: "ControlCardNumberColumn"};
  value: IValueFragment;
}

export const DisplayNumber = ({column, value}: IProps) => {
  if (value.__typename === "ControlCardNumberValue") {
    return <DisplayNumberShared unit={column.unit} value={value.number} />;
  } else if (value.__typename === "ControlCardEmptyValue") {
    return <EmptyValue />;
  } else {
    return <InvalidValue />;
  }
};
