import {IonButton, IonContent, IonIcon, IonPage, IonRefresher, IonRefresherContent} from "@ionic/react";
import {fullRefresh} from "app/employee/core/fullRefresh";
import {homeOutline} from "ionicons/icons";
import {FlexColumnCenter} from "app/employee/flexUtils";
import {useTranslation} from "react-i18next";
import LogoSvg from "app/utils/icons/logo.svg?react";
import {styled} from "styled-components";

const Logo = styled(LogoSvg)`
  height: 250px;
`;

export const NotFoundPage = () => {
  const {t} = useTranslation();
  return (
    <IonPage>
      <IonContent className="ion-padding">
        <IonRefresher onIonRefresh={fullRefresh} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>
        <FlexColumnCenter>
          <Logo />
          <h1>{t("g.page-not-found")}</h1>
          <IonButton color="primary" onClick={fullRefresh}>
            <IonIcon icon={homeOutline} slot="start" />
            {t("g.back-to-home")}
          </IonButton>
        </FlexColumnCenter>
      </IonContent>
    </IonPage>
  );
};
