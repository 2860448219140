import {IonRefresher, IonRefresherContent, RefresherEventDetail, useIonViewWillEnter} from "@ionic/react";
import {init, ISearchResultIncomingDocument, search, useIncDocStore} from "app/employee/state/incdoc/mutations";
import {useSearchStore} from "app/state/hooks/useSearchStore";
import {DocumentCard} from "app/employee/incdoc/DocumentCard";
import {FlexColumn} from "app/employee/flexUtils";
import {forwardRef, PropsWithChildren, useCallback, useImperativeHandle} from "react";
import {LoadingLoadable} from "app/employee/Loading";
import {IPageFunc, Pagination} from "app/employee/incdoc/Pagination";
import {isEmpty} from "app/utils/stdlib";
import {useTranslation} from "react-i18next";
import {ApiState} from "app/state/common/loadable";

export const refreshDocuments = async (event?: CustomEvent<RefresherEventDetail>) => {
  console.log("Reloading Documents...");
  try {
    await search();
    console.log("Reloaded Documents ✅");
  } catch (e) {
    console.warn("Reloaded Documents ❌", e);
    throw e;
  } finally {
    event?.detail.complete();
  }
};

export interface IDocumentListHandle {
  refresh: () => Promise<void>;
}

interface IProps {
  editDocument: (document: ISearchResultIncomingDocument) => void;
}

export const DocumentList = forwardRef<IDocumentListHandle, PropsWithChildren<IProps>>(({editDocument}, ref) => {
  const {t} = useTranslation();
  useIonViewWillEnter(() => {
    init();
  }, []);

  const {
    selectList: documents,
    selectPage: page,
    // selectSort: sort,
    // toggleSort,
    setPageNumber,
    selectLoadingState: searchLoadingState,
    selectListLoadable: documentsLoadable
    // setPageSize,
  } = useSearchStore(useIncDocStore);

  useImperativeHandle(ref, () => ({
    refresh: refreshDocuments
  }));

  const pageFunc: IPageFunc = useCallback((itemCount) => t("incdoc.pagination-count", {itemCount}), [t]);

  return (
    <>
      <LoadingLoadable loadable={documentsLoadable} />
      <IonRefresher onIonRefresh={refreshDocuments} slot="fixed">
        <IonRefresherContent />
      </IonRefresher>
      <FlexColumn>
        <Pagination
          page={page}
          pageFunc={pageFunc}
          setPage={async (page) => {
            setPageNumber(page);
            await refreshDocuments();
          }}
        />
        {documents?.map((d) => (
          <DocumentCard document={d} editDocument={editDocument} key={d.id.documentId} refresh={refreshDocuments} />
        ))}
        {searchLoadingState === ApiState.OK && isEmpty(documents) && (
          <h3>{t("incdoc.no-documents", {defaultValue: "Няма намерени документи"})}</h3>
        )}
        <Pagination
          page={page}
          pageFunc={pageFunc}
          setPage={async (page) => {
            setPageNumber(page);
            await refreshDocuments();
          }}
        />
      </FlexColumn>
    </>
  );
});

DocumentList.displayName = "DocumentList";
