import {AttachmentVariantType, IAttachmentCollectionFragment, IAttachmentVariant} from "app/gql/graphqlSchema";
import {styled} from "styled-components";
import {IonCard, IonImg, useIonViewWillLeave} from "@ionic/react";
import {useTranslation} from "react-i18next";
import documentPlaceholder from "app/employee/att/document-placeholder.png";
import {ID, MaybeNil} from "app/utils/types";
import {isNil} from "app/utils/stdlib";
import {CSSProperties, useCallback, useRef, useState} from "react";
import {AttachmentCollectionView} from "app/employee/att/AttachmentCollectionView";
import {FstIonModal} from "app/employee/incdoc/FstIonModal";
import {AddAttachment} from "app/employee/att/AddAttachment";
import {FlexRight, FlexRow} from "app/employee/flexUtils";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`;

const Attachment = styled(IonCard)`
  min-width: 100px;
  max-width: 150px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  justify-content: stretch;
`;

const AttachmentThumbnail = styled(IonImg)`
  width: 150px;
`;

type IMinimalVariant = Pick<IAttachmentVariant, "id" | "url" | "metadata" | "variantType">;

interface IProps {
  attCollection: IAttachmentCollectionFragment;
  name: MaybeNil<string>;
  onUploadCompleted?: () => void | Promise<void>;
  canUpload: boolean;
}

const selectThumbnail = (variants: IMinimalVariant[]) =>
  variants.find((v) => v.variantType === AttachmentVariantType.Thumbnail);

const getAspectRatio = (variant: MaybeNil<Pick<IAttachmentVariant, "metadata">>): CSSProperties => {
  const width = variant?.metadata?.find((m) => m.key === "width")?.value;
  const height = variant?.metadata?.find((m) => m.key === "height")?.value;
  if (!isNil(width) && !isNil(height)) {
    return {
      aspectRatio: `${width}/${height}`
    };
  } else {
    return {};
  }
};

export const AttachmentCollection = ({attCollection, name, onUploadCompleted, canUpload}: IProps) => {
  const {t} = useTranslation();
  const modal = useRef<HTMLIonModalElement>(null);
  const [viewAttachmentId, setViewAttachmentId] = useState<ID | null>(null);

  const viewFullscreen = (attachmentId: ID) => {
    setViewAttachmentId(attachmentId);
    modal.current?.present();
  };

  const closeModal = useCallback(() => {
    setViewAttachmentId(null);
    modal.current?.dismiss();
  }, []);

  useIonViewWillLeave(() => {
    closeModal();
  }, [closeModal]);

  return (
    <>
      <FlexRow>
        <div>
          {t("g.uploaded-files", {defaultValue: "Качени файлове: {{count}}", count: attCollection.attachments.length})}
        </div>
        <FlexRight>
          <AddAttachment attCollection={attCollection} canUpload={canUpload} onUploadCompleted={onUploadCompleted} />
        </FlexRight>
      </FlexRow>
      <Container>
        {attCollection.attachments.map((a) => {
          const thumbnail = selectThumbnail(a.variants);
          const url = thumbnail?.url ?? documentPlaceholder;
          const aspectRatio = getAspectRatio(thumbnail);
          return (
            <Attachment key={a.id} onClick={() => viewFullscreen(a.id)}>
              <AttachmentThumbnail src={url} style={aspectRatio} />
            </Attachment>
          );
        })}
        <FstIonModal ref={modal}>
          <AttachmentCollectionView att={attCollection} attId={viewAttachmentId} name={name} onClose={closeModal} />
        </FstIonModal>
      </Container>
    </>
  );
};
