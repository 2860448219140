import {IAnyTime, MaybeNil} from "app/utils/types";
import {SHORT_TIME_FORMAT_OPTS, timeApiToLuxon} from "app/utils/dates";
import {isNil} from "app/utils/stdlib";
import {useTranslation} from "react-i18next";

interface Props {
  time: MaybeNil<IAnyTime>;
}

export const TimeDisplay = ({time}: Props) => {
  const {i18n} = useTranslation();
  if (isNil(time)) {
    return null;
  } else {
    const luxonTs = timeApiToLuxon(time);
    const tsStr = luxonTs.toLocaleString(SHORT_TIME_FORMAT_OPTS, {locale: i18n.language});
    return <>{tsStr}</>;
  }
};
