import {create as zustandCreate} from "zustand";
import {immer} from "zustand/middleware/immer";
import {MaybeNil, ZodProperties} from "app/utils/types";
import {CompleteStatus, ControlCardCategory, ILocationControlCardDeckInputV2Input} from "app/gql/graphqlSchema";
import {z} from "zod";
import {zControlCardTemplateId, zLocalDate, zLocationId, zNormalizedStringToNull, zToNull} from "app/utils/validator";

export const zControlCardCategory = z.nativeEnum(ControlCardCategory);
export const zCompleteStatus = z.nativeEnum(CompleteStatus);

type ILocationControlCardFilterInput = Pick<
  ILocationControlCardDeckInputV2Input,
  "category" | "dailyDate" | "recurringDateRange" | "recurringTemplateIds" | "completeStatus" | "query" | "locationId"
>;

export const locationControlCardFilterSchema = z.object<ZodProperties<ILocationControlCardFilterInput>>({
  locationId: zLocationId,
  category: zControlCardCategory,
  dailyDate: zLocalDate,
  recurringDateRange: z.object<ZodProperties<ILocationControlCardFilterInput["recurringDateRange"]>>({
    start: zLocalDate,
    end: zLocalDate
  }),
  completeStatus: zCompleteStatus,
  query: zToNull.or(zNormalizedStringToNull),
  recurringTemplateIds: z.array(zControlCardTemplateId)
});

export type ILocationControlCardFilter = z.infer<typeof locationControlCardFilterSchema>;

type ILocationControlCardGlobalFilterStore = {
  filter: MaybeNil<ILocationControlCardFilter>;
};

export const useLocationControlCardGlobalFilter = zustandCreate(
  immer<ILocationControlCardGlobalFilterStore>(() => ({
    filter: null
  }))
);
